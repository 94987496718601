import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { deleteRecipe } from "../../../../redux/slices/admin";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";

const Deleterecipe = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  // const adminDataSelector = useAdminSelector();
  // const recipeData = adminDataSelector?.getRecipeDetails?.data;

  const handleDeleteUser = () => {
    let params = {
      id: props?.userId,
    };
    setloading(true);
    dispatch(
      deleteRecipe({
        ...params,
        cb(res) {
          setloading(false);
          if (res?.data) {
            toast.success("Recipe deleted successfully");
            props.closeModal();
            props.refreshData();
          }
          if (res?.data || props.typeId) {
            navigate("/admin/recipe");
          } else {
          }
        },
      })
    );
  };
  return (
    <div className="py-3">
      <h5 className="font-28 text-center">
        Are you Sure you want to delete the recipe "{props.Recipetitle}"?
      </h5>
      <p className="text text-center py-3">
        You are about to delete the recipe. You won't be able to undo this.
      </p>
      <div className="row justify-content-center mt-4">
        <div className="d-grid gap-1 col-lg-3 col-md-6">
          <button
            className="primaryBtn submitBtn m-0"
            type="button"
            onClick={(e) => handleDeleteUser(e)}
          >
            <span>
              Yes, Delete
              {loading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}{" "}
            </span>
          </button>
        </div>
        <div className="d-grid gap-1 col-lg-3 col-md-6">
          <button
            className="primaryBtn cancelBtn m-0"
            onClick={(e) => props.closeModal()}
          >
            <span>No, Cancel</span>
          </button>
        </div>
      </div>
    </div>
  );
};
export default Deleterecipe;
