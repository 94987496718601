import React, { useEffect, useState } from "react";
import * as Images from "../../../../utilities/images";
import { useDispatch } from "react-redux";
import { changePassword } from "../../../../redux/slices/user";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toggleNotification } from "../../../../redux/slices/admin";
import { Spinner } from "react-bootstrap";
import { useUserSelector } from "../../../../redux/selector/user";

const Setting = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [serverError, setServerError] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  // console.log(isChecked, "isChecked");
  const [loading, setLoading] = useState(false);
  const authUserSelector = useUserSelector();
  const userInfo = authUserSelector?.loginUserProfile?.data;

  const handleToggle = () => {
    setIsChecked(!isChecked);
    let params = {
      is_notification: !isChecked === true ? 1 : 0,
    };

    dispatch(
      toggleNotification({
        ...params,
        cb(res) {
          if (res) {
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };
  useEffect(() => {
    setIsChecked(userInfo?.notification_status == 1 ? !isChecked : isChecked);
  }, []);

  const formik = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
      confirmPassword: "",
    },

    validationSchema: Yup.object({
      old_password: Yup.string()
        .matches(
          /^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,
          "Password must contain at least 1 number, 1 uppercase letter, and 1 special character"
        )
        .required("Old Password is required"),

      new_password: Yup.string()
        .matches(
          /^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,
          "Password must contain at least 1 number, 1 uppercase letter, and 1 special character"
        )
        .required("New Password is required"),
      confirmPassword: Yup.string()
        .oneOf(
          [Yup.ref("new_password"), null],
          "New Password and confirm Password must match"
        )
        .required("Confirm Password is required"),
    }),
    onSubmit: (values) => {
      let params = {
        oldPassword: values?.old_password,
        newPassword: values?.new_password,
        confirmPassword: values?.confirmPassword,
      };
      setLoading(true);
      dispatch(
        changePassword({
          ...params,
          cb(res) {
            setLoading(false);
            if (res?.data?.status_code == 400) {
              setServerError({ error: res.data.message });
              return false;
            }
            if (res?.data?.status_code == 200) {
              toast.success("Password changed Successfully");
              localStorage.removeItem("token");
              navigate("/login");
              setServerError("");
            } else {
            }
          },
        })
      );
    },
  });
  const handleReset = () => {
    formik.resetForm();
  };

  return (
    <>
      <div className="contentCommon mb-2 setting-main-outer">
        <div className="courseCard mt-3">
          <div className="d-flex py-4 px-3 justify-content-between align-items-center w-100">
            <div className="d-flex align-items-center">
              {" "}
              <div className="dashCardImg me-4">
                <img src={Images.bellwhite} alt="" className="img-fluid" />
              </div>
              <div className="middletext">
                <p className="text_ m-0 pb-1 medText">Notification</p>
                {/* <p className="text m-0">

                </p> */}
              </div>
            </div>
            <div className="switchOuter">
              <label className="switch">
                <input
                  type="checkbox"
                  id="toggleSwitch"
                  checked={isChecked}
                  onChange={handleToggle}
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
        </div>
        <div className="courseCard mt-3">
          <div className="d-inline d-md-flex py-4 px-3 justify-content-between align-items-center w-100">
            <div className="d-flex">
              {" "}
              <div className="change-password-key me-4">
                <img src={Images.key} alt="" className="img-fluid keycardImg" />
              </div>
              <form onSubmit={formik.handleSubmit}>
                <div className="psw">
                  <p className="text_ m-0 pb-1 medText">Change Password</p>
                  <div className="d-flex inputOut">
                    <div className="loginForm inputOuter">
                      <div className="position-relative">
                        <span className="lockIcon">
                          <img className="icon_" src={Images.lock} alt="" />{" "}
                        </span>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Old Password"
                          maxLength={25}
                          {...formik.getFieldProps("old_password")}
                        />
                      </div>
                      {formik.touched.old_password &&
                        formik.errors.old_password ? (
                        <div className="validationMsg">
                          {" "}
                          {formik.errors.old_password}
                        </div>
                      ) : null}
                    </div>
                    <div className="loginForm inputOuter ">
                      <div className="position-relative">
                        <span className="lockIcon">
                          <img className="icon_" src={Images.lock} alt="" />{" "}
                        </span>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="New Password"
                          maxLength={25}
                          {...formik.getFieldProps("new_password")}
                        />
                      </div>
                      {formik.touched.new_password &&
                        formik.errors.new_password ? (
                        <div className="validationMsg">
                          {" "}
                          {formik.errors.new_password}
                        </div>
                      ) : null}
                    </div>
                    <div className="loginForm inputOuter ">
                      <div className="position-relative">
                        <span className="lockIcon">
                          <img className="icon_" src={Images.lock} alt="" />{" "}
                        </span>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Confirm Password"
                          maxLength={25}
                          {...formik.getFieldProps("confirmPassword")}
                        />
                      </div>
                      {formik.touched.confirmPassword &&
                        formik.errors.confirmPassword ? (
                        <div className="validationMsg">
                          {" "}
                          {formik.errors.confirmPassword}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {serverError ? (
                    <span
                      className={
                        serverError?.error ? "validationMsg" : "d-none"
                      }
                    >
                      {serverError?.error ? serverError?.error : ""}
                    </span>
                  ) : (
                    ""
                  )}
                  <div className="row  mt-2 mt-md-5">
                    <div className="d-grid gap-1 col-lg-2 col-md-6">
                      <button
                        className="primaryBtn submitBtn m-0"
                        type="button"
                        onClick={formik.handleSubmit}
                      >
                        <span>Change</span>
                        {loading && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="ms-1"
                          />
                        )}{" "}
                      </button>
                    </div>
                    <div className="d-grid gap-1 col-lg-2 col-md-6">
                      <button
                        className="primaryBtn cancelBtn m-0"
                        type="button"
                        onClick={handleReset}
                      >
                        <span>Discard</span>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Setting;
