import React, { useState, useEffect } from "react";
import * as Images from "../../../utilities/images";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { userLogin } from "../../../redux/slices/auth";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { Spinner } from "react-bootstrap";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [serverError, setServerError] = useState("");
  const [loading, setLoading] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Please enter valid email address")
        .required("Email is required"),
      password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .required("Password is required"),
    }),
    onSubmit: (values) => {
      let params = {
        email: values.email.trim(),
        password: values.password,
        role: values.email.trim() === "admin@yopmail.com" ? 1 : 2,
      };
      setLoading(true);

      dispatch(
        userLogin({
          ...params,
          cb(res) {
            setLoading(false);
            if (res?.data?.status_code == 400) {
              setServerError({ error: res.data.message });
              return false;
            }
            if (res?.data?.status_code == 200) {
              setServerError("");
            }
            const updatedState = res?.data?.data?.user;
            localStorage.setItem("myReduxState", JSON.stringify(updatedState));
            if (res?.data?.data?.user?.role == 1) {
              navigate("/admin/dashboard");
            }
            if (res?.data?.data?.user?.role == 2) {
              navigate("/");
            }
          },
        })
      );
      // console.log("Form submitted with values:", values);
    },
  });

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Login";
  }, []);

  return (
    <>
      <section className="loginpage">
        <div className="container-fluid">
          <div className="row item-center flex-row">
            <div className=" col-lg-5 col-md-6">
              <div className="logininner">
                <Link className="colorpink" to={"/"}>
                  <img className="backicon" src={Images.backarrow} alt="" />
                  Back to home
                </Link>
                <div className="header-logo">
                  <Link to={"/"}>
                    <img src={Images.logo} alt="" />
                  </Link>
                </div>

                <div className="loginForm">
                  <h1 className="heading">Login</h1>
                  <p className="text">Enter your account details below.</p>
                  <form onSubmit={formik.handleSubmit}>
                    <div className="mb-4 form-group">
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control"
                          name="email"
                          placeholder="Email"
                          {...formik.getFieldProps("email")}
                        />
                        <img className="icon_" src={Images.mail} alt="" />
                      </div>
                      {formik.touched.email && formik.errors.email ? (
                        <div className="validationMsg">
                          {formik.errors.email}
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-3 form-group">
                      <div className="position-relative">
                        <input
                          type={passwordShown ? "text" : "password"}
                          //type="text"
                          className="form-control id_password"
                          placeholder="Password"
                          name="password"
                          {...formik.getFieldProps("password")}
                        />
                        <img className="icon_" src={Images.lock} alt="" />
                        <span
                          className="toggle_password_ info_icon"
                          onClick={() => {
                            togglePasswordVisiblity(!passwordShown);
                          }}
                        >
                          <span
                            className={
                              passwordShown
                                ? "show-icon togglePassword"
                                : "hide-icon togglePassword"
                            }
                            id=""
                          ></span>
                        </span>
                      </div>
                      {formik.touched.password && formik.errors.password ? (
                        <div className="validationMsg">
                          {formik.errors.password}
                        </div>
                      ) : null}
                    </div>
                    {serverError ? (
                      <span
                        className={
                          serverError?.error ? "validationMsg" : "d-none"
                        }
                      >
                        {serverError?.error ? serverError?.error : ""}
                      </span>
                    ) : (
                      ""
                    )}
                    <div className="form-group">
                      <div className="text-end forgotPw">
                        <Link to="/forgotpassword" className="subtext mb-0">
                          Forgot Password ?
                        </Link>
                      </div>
                      <div className="form-group pb-3">
                        <div className="form-check d-flex align-items-end">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id=""
                          />
                          <label htmlFor="">
                            <p className="text mb-0">Keep me Logged in</p>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-between align-items-center py-2 loginBtnOuter">
                      <div className="">
                        <button className="login-btn" type="submit">
                          Login
                          {loading && (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              className="ms-1"
                            />
                          )}
                          {""}
                        </button>
                        <span className="center-img">
                          <img
                            src={Images.centerrectangle}
                            alt=""
                            onClick={formik.handleSubmit}
                          />
                        </span>
                        <span className="circle-btn">
                          <img
                            src={Images.arrow}
                            alt=""
                            onClick={formik.handleSubmit}
                          />
                        </span>
                      </div>
                      <div className="accountText">
                        <p className="text mb-0 ">Don't have an account?</p>
                        <Link to="/signup" className="text colorPink fontBold">
                          Sign Up
                          <img
                            className="signup"
                            src={Images.signuparrow}
                            alt=""
                          />
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
                <p className="subtext copyrightText pb-3">
                  © copyright{moment().year()} All rights reserved
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-7 p-0">
              <div className="imgouter">
                <img className="img-fluid w-100" src={Images.banner} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
