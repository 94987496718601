import React, { useEffect, useState } from "react";
import * as Images from "../../../utilities/images";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { contactUs } from "../../../redux/slices/user";
import { toast } from "react-toastify";
import { useAuthSelector } from "../../../redux/selector/auth";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Spinner } from "react-bootstrap";

const Contactus = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authSelector = useAuthSelector();
  var token = localStorage.getItem("authToken");
  const isLoggedIn = authSelector.isLoggedIn;
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      title: "",
      email: "",
      subject: "",
      message: "",
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .matches(/^[a-zA-Z]+$/, "Title should only contain letters")
        .min(2, "Title should be at least 2 characters")
        .required("Title is required"),

      email: Yup.string()
        .email("Please enter valid email address")
        .required("Email is required"),
      subject: Yup.string()
        .min(2, "Subject should be at least 2 characters")
        .required("Subject is required"),
      message: Yup.string()
        .min(2, "Message should be at least 2 characters")
        .required("Message is required"),
    }),
    onSubmit: (values) => {
      let params = {
        name: values?.title,
        email: values?.email.trim(),
        subject: values?.subject,
        message: values?.message,
      };
      setLoading(true);
      dispatch(
        contactUs({
          ...params,
          cb(res) {
            setLoading(false);
            if (res?.data?.status_code == 400) {
              setErrorMessage({ error: res.data.message });
              return false;
            }
            if (res?.data?.status_code == 200) {
              setErrorMessage("");
              toast.success("Messsage sent successfully");
              navigate("/thankyou");
            } else {
            }
          },
        })
      );
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Contact-Us";
  }, []);
  return (
    <>
      <section className="favoritesBanner contactBanner pb-2">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2 className="bigHeading pt-5 pb-4 ">Contact Us</h2>
              {/* <p className="text px-3">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p> */}
            </div>
          </div>
        </div>
      </section>
      <section className="contactUs_section">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-3 col-md-12 col-sm-12">
              <div className="contactus_leftTouchin">
                <div className="top">
                  <p className="getin_Touch">Get In Touch</p>
                  <ul className="contactus_getintouch">
                    <li>
                      <a className="touch_className" href="#">
                        <img
                          className="left_icon"
                          src={Images.phonewhite}
                          alt="callIcon_image"
                        />
                        <div className="left_boxtxt">703-232-1566</div>
                      </a>
                    </li>
                    <li>
                      <a className="touch_className" href="#">
                        <img
                          className="left_icon"
                          src={Images.locationwhite}
                          alt="locationIcon_image"
                        />
                        <div className="left_boxtxt">
                          PO Box 1622 Annandale, VA 22003
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="contactUs_googlemapImg">
                  <div className="leftmapbox">
                    <img
                      className="contactUs_mapImg"
                      src={Images.map}
                      alt="mapImage"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-9 col-md-12 col-sm-12">
              <div className="right_div">
                <form onSubmit={formik.handleSubmit} className="contactus_form">
                  <p className="heading pb-5">Send a Message</p>
                  <div className="row">
                    <div className="col-md-6 mb-4">
                      <input
                        className="contactUsforminput_field"
                        type="text"
                        placeholder="Name"
                        maxLength={25}
                        {...formik.getFieldProps("title")}
                      />
                      {formik.touched.title && formik.errors.title ? (
                        <div className="validationMsg">
                          {formik.errors.title}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-6 mb-4">
                      <input
                        className="contactUsforminput_field "
                        type="text"
                        placeholder="Email"
                        {...formik.getFieldProps("email")}
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <div className="validationMsg">
                          {formik.errors.email}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-12 mb-4">
                      <input
                        className="contactUsforminput_field "
                        type="text"
                        placeholder="Subject"
                        maxLength={50}
                        {...formik.getFieldProps("subject")}
                      />
                      {formik.touched.subject && formik.errors.subject ? (
                        <div className="validationMsg">
                          {formik.errors.subject}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-12">
                      <textarea
                        className="contactUsforminput_field   contactusTextarea contact-us-textarea"
                        type="text"
                        placeholder="Message"
                        rows="2"
                        cols="30"
                        maxLength={500}
                        {...formik.getFieldProps("message")}
                      />
                      {formik.touched.message && formik.errors.message ? (
                        <div className="validationMsg">
                          {formik.errors.message}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {errorMessage ? (
                    <span
                      className={
                        errorMessage?.error ? "validationMsg" : "d-none"
                      }
                    >
                      {errorMessage?.error ? errorMessage?.error : ""}
                    </span>
                  ) : (
                    ""
                  )}
                  <div className="login_create">
                    <div className="pt-4">
                      <button className="login-btn sendButton" type="submit">
                        Send
                        {loading && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="ms-1"
                          />
                        )}{" "}
                      </button>
                      <span
                        className="center-img"
                        onClick={formik.handleSubmit}
                      >
                        <img src={Images.centerrectangle} alt="" />
                      </span>
                      <span
                        className="circle-btn circle-btnResp"
                        onClick={formik.handleSubmit}
                      >
                        <img src={Images.arrow} alt="" />
                      </span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contactus;
