import React, { useEffect, useState } from "react";
import * as Images from "../../../utilities/images";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  favClass,
  favClassList,
  loginUserProfile,
} from "../../../redux/slices/user";
import ReactPaginate from "react-paginate";
import "sweetalert2/dist/sweetalert2.min.css";
import { useUserSelector } from "../../../redux/selector/user";
import Swal from "sweetalert2";

const Favorites = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;
  const [activeTab, setActiveTab] = useState("");

  useEffect(() => {
    setActiveTab(location?.pathname);
  }, [location?.pathname]);

  const [getFavClasses, setFavClasses] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [perPageSize, setPerPageSize] = useState(10);
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const authUserSelector = useUserSelector();
  const userInfo = authUserSelector?.loginUserProfile?.data;

  const getClassList = (page = currentPage, limit = 9) => {
    let params = {
      page: page,
      limit: limit,
    };
    dispatch(
      favClassList({
        ...params,
        cb(res) {
          if (res?.data) {
            setTotalPages(res?.data?.data?.total_pages);
            setTotalCount(res?.data?.data?.totalRecords);
            setFavClasses(res?.data?.data?.list);
          } else {
          }
        },
      })
    );
  };
  const getUser = () => {
    dispatch(
      loginUserProfile({
        cb(res) {
          // console.log(res, "responseDataaaForLikeCount");
          // setRecipeLikeCount(res?.data?.data?.total_favourite);
          // setClassLikeCount(res?.data?.data?.total_classes_favourite);
          if (res) {
          } else {
            // toast.error("something went wrong");
          }
        },
      })
    );
  };

  const handleFavClass = (id, isFav) => {
    let params = {
      class_id: id,
    };
    dispatch(
      favClass({
        ...params,
        cb(res) {
          if (res.status) {
            const classCopy = [...getFavClasses];
            const classIndex = classCopy.findIndex((x) => x.id === id);
            if (classIndex >= 0) {
              let favClass = classCopy[classIndex];
              favClass = {
                ...favClass,
                is_favorite: isFav,
              };
              // .is_favorite=isFav
              classCopy[classIndex] = favClass;
              setFavClasses(classCopy);
              getUser();
            }

            getClassList();
          } else {
          }
        },
      })
    );
  };
  const handleShowAlert = () => {
    setShowAlert(true);
    Swal.fire({
      title: "Add subscription",
      text: "Kindly add subscription for view classes",
      icon: "warning",
      iconColor: "red",
      customClass: {
        popup: "mySwalClass",
      },
      showCancelButton: true,
      confirmButtonText: "Add",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("redirectUrl");
        navigate("/addsubscription", { state: pathname });
      }
    });
  };
  const handlePageClick = (selectedItem) => {
    const selectedPage = selectedItem.selected + 1;
    setCurrentPage(selectedPage);
    getClassList(selectedPage, perPageSize);
  };

  const handleViewClassDetails = (data) => {
    if (userInfo?.isSubscribe == false && data?.access_level == 2) {
      handleShowAlert();
      return false;
    } else if (data?.id) {
      navigate(`/classdetailpage/${data?.id}`);
    }
  };

  const handleViewRecipies = () => {
    navigate("/favRecipies");
  };

  const handleViewClasses = () => {
    navigate("/favClasses");
  };

  useEffect(() => {
    getClassList();
    window.scrollTo(0, 0);
    document.title = "Favourite Classes";
  }, []);

  return (
    <>
      <section className="favoritesBanner favclassBanner">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center pt-5">
              <h2 className="bigHeading py-2 ">Favourites</h2>
              <p className="text px-3">Recipes or Classes you Like</p>
            </div>

            <div className="FavRecipies">
              <div className="nav nav-pills  mb-4">
                <button
                  className={activeTab == "/favRecipies" ? "active" : ""}
                  onClick={(e) => handleViewRecipies(e)}
                >
                  Recipe
                </button>
                <button
                  className={activeTab == "/favClasses" ? "active" : ""}
                  onClick={(e) => handleViewClasses(e)}
                >
                  Classes
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="tabsSection">
        <div className="tab-content clearfix">
          <div className="tab-pane active" id="Recipe">
            <div className=" RecipeTabsOuter">
              <div className="container-xxl container-fluid">
                <div className="row">
                  <div className="tab-pane" id="Classes">
                    <div className="RecipeTabsOuter">
                      <div className="container">
                        <div className="row">
                          {getFavClasses?.length > 0
                            ? getFavClasses?.map((data, index) => {
                              return (
                                <div
                                  className="col-md-6 col-lg-4 mb-4  "
                                  key={index}
                                >
                                  <div className="classBox position-relative">
                                    <div className="classBoxInner">
                                      <div
                                        className="classVedioImg"
                                        onClick={() =>
                                          handleViewClassDetails(data)
                                        }
                                      >
                                        <img
                                          className="img-fluid w-100"
                                          src={data?.thumbnail_image}
                                          alt=""
                                          onClick={() =>
                                            handleViewClassDetails(data)
                                          }
                                        />
                                      </div>
                                      <div className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex">
                                          {data?.rating > 0 && (
                                            <div
                                              className="subtext classtextbox me-2"
                                              onClick={() =>
                                                handleViewClassDetails(data)
                                              }
                                            >
                                              <img
                                                className="star"
                                                src={Images.star}
                                                alt=""
                                              />
                                              {data?.rating > 0
                                                ? data?.rating?.toFixed(1)
                                                : ""}
                                            </div>
                                          )}
                                          {data?.comments_count > 0 && (
                                            <div
                                              className="classtextbox subtext "
                                              onClick={() =>
                                                handleViewClassDetails(data)
                                              }
                                            >
                                              <img
                                                src={Images.commenticon}
                                                alt=""
                                              />
                                              {data?.comments_count > 0
                                                ? data?.comments_count
                                                : ""}
                                            </div>
                                          )}
                                        </div>
                                        {data?.like_count > 0 && (
                                          <div
                                            className="classtextbox subtext"
                                            onClick={() =>
                                              handleViewClassDetails(data)
                                            }
                                          >
                                            <img
                                              src={Images.solarlike}
                                              alt=""
                                            />
                                            {data?.like_count
                                              ? data?.like_count
                                              : ""}
                                          </div>
                                        )}
                                      </div>
                                      <h5 className="bigText py-2">
                                        {data?.title}
                                      </h5>
                                      <p className="subtext pararecipe">
                                        {data?.description}
                                      </p>
                                      <div className="d-flex justify-content-between align-items-end">
                                        <div
                                          className=""
                                          onClick={() =>
                                            handleViewClassDetails(data)
                                          }
                                        >
                                          <p className="bigText font-10 text-uppercase m-0 pb-2">
                                            instructor:
                                          </p>

                                          <div className="d-flex">
                                            {data?.instructors?.map(
                                              (data) => {
                                                return (
                                                  <img
                                                    className="instructionUser"
                                                    src={data?.img_url}
                                                    alt=""
                                                  />
                                                );
                                              }
                                            )}
                                          </div>
                                        </div>
                                        <div className="">
                                          <button
                                            className="button seeFullrecipe text-capitalize m-0 text-capitalize"
                                            onClick={() =>
                                              handleViewClassDetails(data)
                                            }
                                          >
                                            Watch Video
                                            <span>
                                              <img
                                                className="signup"
                                                src={Images.signuparrow}
                                                alt=""
                                              />
                                            </span>
                                          </button>
                                          {data?.is_favorite == true ? (
                                            <span className="heartCircle">
                                              <img
                                                src={Images.Vector}
                                                alt=""
                                                onClick={() =>
                                                  handleFavClass(
                                                    data?.id,
                                                    false
                                                  )
                                                }
                                              />
                                            </span>
                                          ) : (
                                            <span className="heartCircle">
                                              <img
                                                src={Images.heart}
                                                alt=""
                                                onClick={() =>
                                                  handleFavClass(
                                                    data?.id,
                                                    true
                                                  )
                                                }
                                              />
                                            </span>
                                          )}
                                        </div>
                                        {data?.access_level == 2 ? (
                                          <button
                                            className="recipe-tagPaid"
                                            onClick={() =>
                                              handleViewClassDetails(data)
                                            }
                                          >
                                            Paid
                                          </button>
                                        ) : (
                                          <button
                                            className="recipe-tag"
                                            onClick={() =>
                                              handleViewClassDetails(data)
                                            }
                                          >
                                            Free
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                            : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {getFavClasses?.length > 0 ? (
            <ReactPaginate
              className="paginationBox RecipePagination mb-0"
              breakLabel=""
              // nextLabel="next >"
              onPageChange={handlePageClick}
              nextClassName={"pagination-next"}
              previousClassName={"pagination-previous"}
              pageRangeDisplayed={5}
              marginPagesDisplayed={0}
              pageCount={totalPages}
              previousLabel="prev"
              renderOnZeroPageCount={null}
            />
          ) : (
            <h3 className="noDataText-Fav">No Favorite Classes added yet</h3>
          )}
        </div>
      </section>
    </>
  );
};

export default Favorites;
