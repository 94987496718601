import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { defaultCard } from "../../../redux/slices/user";
import { Spinner } from "react-bootstrap";

const DefaultCards = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleDeleteUser = () => {
    let params = {
      paymentMethodId: props.cardId,
    };
    setLoading(true);
    dispatch(
      defaultCard({
        ...params,
        cb(res) {
          if (res?.data) {
            setLoading(false);
            props.refreshData();
           props.closeModal();
          } else {
          }
        },
      })
    );
  };
  return (
    <div className="py-3">
      <h5 className="font-28 text-center">
        Are you Sure you want to set default this Card ?
      </h5>
      <p className="text text-center py-3">
        You are about to set default this card. You won't be able to undo this.
      </p>
      <div className="row justify-content-center mt-4">
        <div className="d-grid gap-1 col-lg-3 col-md-6">
          <button
            className="primaryBtn submitBtn m-0"
            type="button"
            onClick={(e) => handleDeleteUser(e)}
          >
            <span>Yes</span>
            {loading && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="ms-1"
              />
            )}{" "}
          </button>
        </div>
        <div className="d-grid gap-1 col-lg-3 col-md-6">
          <button
            className="primaryBtn cancelBtn m-0"
            onClick={(e) => props.closeModal()}
          >
            <span>No, Cancel</span>
          </button>
        </div>
      </div>
    </div>
  );
};
export default DefaultCards;
