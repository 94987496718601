import { Link, useNavigate } from "react-router-dom";
import * as Images from "../../../utilities/images";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getCards } from "../../../redux/slices/user";
import CustomModal from "../../components/modals/modal";
import DeleteCard from "./deleteCard";
import DefaultCards from "./SetDefaultCard";
import moment from "moment";
import { Spinner } from "react-bootstrap";

const PaymentMethod = () => {
  const [showcards, setShowCards] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const getCardList = () => {
    dispatch(
      getCards({
        cb(res) {
          if (res?.data) {
            setShowCards(res?.data?.data);
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };

  useEffect(() => {
    getCardList();
    window.scrollTo(0, 0);
    document.title = "Payment-method";
  }, []);

  const handleAddNewCard = () => {
    navigate("/AddCard");
  };

  const handleAddDefault = (cardId) => {
    setModalDetail({
      show: true,
      title: "defaultCard",
      flag: "Default",
      id: cardId,
    });
    setKey(Math.random());
  };

  const handleDelCard = (cardId) => {
    setModalDetail({
      show: true,
      title: "DeleteCard",
      flag: "Delete",
      id: cardId,
    });
    setKey(Math.random());
  };

  return (
    <>
      <section className="loginpage">
        <div className="container-fluid">
          <div className="row item-center flex-row">
            <div className=" col-lg-5 col-md-6">
              <div className="logininner changePasswordOuter">
                <div className="header-logo">
                  {/* <Link to={"/"}>
                    <img src={Images.logo} alt="" />
                  </Link> */}
                </div>
                <div className="loginForm">
                  <Link className="colorpink" to={"/addsubscription"}>
                    <img className="backicon" src={Images.backarrow} alt="" />
                    Go Back
                  </Link>
                  <h1 className="heading py-3">Payment Method</h1>
                </div>
                <div>
                  <p className="subtext copyrightText pb-3">
                    © copyright{moment().year()}. All rights reserved
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-7 p-0">
              <div className="imgouter editProfile">
                <div className="formTabs">
                  <div className="header-logo">
                    <Link to={"/"}>
                      <img src={Images.logo} alt="" />
                    </Link>
                  </div>
                  <div className="loginForm">
                    <Link className="colorpink" to="/viewEdit">
                      <img className="backicon" src={Images.backarrow} alt="" />
                      Go Back
                    </Link>
                    <h1 className="heading py-3">Payment Method</h1>
                  </div>
                </div>
                <div className="row pb-5">
                  <div className="col-md-6">
                    <p className="text mb-0">
                      Choose your payment method with Credit/Debit card here.
                    </p>
                  </div>
                  <div className="col-md-6 text-end">
                    <button
                      className="addNewCard login-btn"
                      onClick={(e) => handleAddNewCard(e)}
                    >
                      + add new card
                    </button>
                  </div>
                </div>
                <div className="row cards-box">
                  {showcards?.length > 0 ? (
                    showcards?.map((data, index) => {
                      return (
                        <div className="col-md-12 col-lg-6 mb-4">
                          {data?.setDefault == true ? (
                            <div className="cardOuter active">
                              <div className="cardInner">
                                <div className="d-flex justify-content-between align-items-center pb-4">
                                  <div key={index}>
                                    <img src={Images.cardSign} alt="" />
                                  </div>
                                  <div>
                                    <img
                                      src={Images.whitebin}
                                      alt=""
                                      onClick={() => handleDelCard(data?.id)}
                                    />
                                    <img
                                      className="checkIcon"
                                      src={Images.checked}
                                      alt=""
                                    />
                                  </div>
                                </div>
                                <p className="bigText mb-0 pb-4">
                                  <img src={Images.starswhite} alt="" />
                                  <span>{data?.card?.last4}</span>
                                </p>
                                <div className="d-flex justify-content-between align-items-center">
                                  <p className="text_ mb-0 medText">
                                    {data?.billing_details?.name
                                      ? data?.billing_details?.name
                                      : ""}
                                  </p>
                                  <p className="subtext mb-0 medText">
                                    {data?.card?.exp_month}/
                                    {data?.card?.exp_year}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="cardOuter ">
                              <div className="cardInner">
                                <div className="d-flex justify-content-between align-items-center pb-4">
                                  <div key={index}>
                                    <img src={Images.cardSign} alt="" />
                                  </div>
                                  <div>
                                    <img
                                      src={Images.whitebin}
                                      alt=""
                                      onClick={() => handleDelCard(data?.id)}
                                    />
                                    <img
                                      className="checkIcon"
                                      src={Images.uncheked}
                                      alt=""
                                      onClick={() => handleAddDefault(data?.id)}
                                    />
                                  </div>
                                </div>
                                <p className="bigText mb-0 pb-4">
                                  <img src={Images.starswhite} alt="" />
                                  <span>{data?.card?.last4}</span>
                                </p>
                                <div className="d-flex justify-content-between align-items-center">
                                  <p className="text_ mb-0 medText">
                                    {data?.billing_details?.name
                                      ? data?.billing_details?.name
                                      : ""}
                                  </p>
                                  <p className="subtext mb-0 medText">
                                    {data?.card?.exp_month}/
                                    {data?.card?.exp_year}
                                  </p>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })
                  ) : (
                    <>
                      {showcards?.length == 0 ? (
                        <div className="colorBlue text text-center py-3">
                          <h3 className="noDataText mt-4 mb-4">
                            No cards Found!{" "}
                          </h3>
                        </div>
                      ) : (
                        <Spinner
                          className="pageLoader"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="copyrightBottom pt-5">
                <p className="subtext copyrightText pb-3">
                  © copyright{moment().year()}. All rights reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        className={modalDetail.flag === "Delete" ? "delete" : ""}
        child={
          modalDetail.flag === "Delete" ? (
            <DeleteCard
              closeModal={() => {
                handleOnCloseModal();
              }}
              refreshData={() => getCardList()}
              cardId={modalDetail?.id}
            />
          ) : modalDetail.flag === "Default" ? (
            <DefaultCards
              closeModal={() => {
                handleOnCloseModal();
              }}
              cardId={modalDetail?.id}
              refreshData={() => getCardList()}
            />
          ) : (
            ""
          )
        }
        header={
          <div className="modalHeader_">
            <div className="common_">
              {modalDetail.flag === "Default" ? (
                <h2 className="headingSmall_">Default Card</h2>
              ) : modalDetail.flag === "Delete" ? (
                <h2 className="headingSmall_">Delete Card</h2>
              ) : (
                ""
              )}
            </div>
          </div>
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default PaymentMethod;
