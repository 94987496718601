import { Link, useNavigate } from "react-router-dom";
import * as Images from "../../../utilities/images";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { changePassword } from "../../../redux/slices/user";
import { toast } from "react-toastify";
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Spinner } from "react-bootstrap";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordConfirmShown, setConfirmPasswordShown] = useState(false);
  const [passwordShownOld, setPasswordShownOld] = useState(false);
  const [serverError, setServerError] = useState("");
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
      confirmPassword: "",
    },

    validationSchema: Yup.object({
      old_password: Yup.string()
        .matches(
          /^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,
          "Password must contain at least 1 number, 1 uppercase letter, and 1 special character"
        )
        .required("Old Password is required").max(8, 'The password must be max 8 characters'),

      new_password: Yup.string()
        .matches(
          /^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,
          "Password must contain at least 1 number, 1 uppercase letter, and 1 special character"
        )
        .required("New Password is required").max(8, 'The password must be max 8 characters'),
      confirmPassword: Yup.string()
        .oneOf(
          [Yup.ref("new_password"), null],
          "New Password and confirm Password must match"
        )
        .required("Confirm Password is required").max(8, 'The password must be max 8 characters'),
    }),
    onSubmit: (values) => {
      let params = {
        oldPassword: values?.old_password,
        newPassword: values?.new_password,
        confirmPassword: values?.confirmPassword,
      };
      setLoading(true);
      dispatch(
        changePassword({
          ...params,
          cb(res) {
            setLoading(false);
            if (res?.data?.status_code == 400) {
              setServerError({ error: res.data.message });
              return false;
            }
            if (res?.data?.status_code == 200) {
              toast.success("Password changed Successfully");
              localStorage.removeItem("token");
              navigate("/login");
              setServerError("");
            } else {
            }
          },
        })
      );
    },
  });

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const togglePasswordVisiblity1 = () => {
    setPasswordShownOld(passwordShownOld ? false : true);
  };
  const toggleConfirmPasswordVisiblity = () => {
    setConfirmPasswordShown(passwordConfirmShown ? false : true);
  };

  return (
    <>
      <section className="loginpage">
        <div className="container-fluid">
          <div className="row item-center flex-row">
            <div className=" col-lg-5 col-md-6">
              <div className="logininner changePasswordOuter">
                <div className="header-logo">
                  <Link to={"/"}>
                    <img src={Images.logo} alt="" />
                  </Link>
                </div>
                <div className="loginForm">
                  <Link className="colorpink" to="/">
                    <img className="backicon" src={Images.backarrow} alt="" />
                    Go Back
                  </Link>
                  <h1 className="heading py-3">Change Password</h1>
                </div>
                <div className="">
                  <p className="subtext copyrightText pb-3">
                    © copyright{moment().year()}. All rights reserved
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-7 p-0">
              <div className="imgouter editProfile passwordouter">
                <button
                  className="login-btn editProfileBtn"
                  type="button"
                  onClick={formik.handleSubmit}
                >
                  Change
                  {loading && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="ms-1"
                    />
                  )}{" "}
                </button>

                <form
                  onSubmit={formik.handleSubmit}
                  className="adminProfileForm"
                >
                  <div className="formTabs">
                    <div className="header-logo">
                      <Link to={"/"}>
                        <img src={Images.logo} alt="" />
                      </Link>
                    </div>
                    <div className="loginForm">
                      <Link className="colorpink" to="/">
                        <img
                          className="backicon"
                          src={Images.backarrow}
                          alt=""
                        />
                        Go Back
                      </Link>
                      <h1 className="heading py-3">Change Password</h1>
                    </div>
                  </div>

                  <div
                    className={
                      formik.touched.old_password && formik.errors.old_password
                        ? ""
                        : "mb-4"
                    }
                  >
                    <div className="form-group updateFormGroup ChangePW">
                      <label className="text">Old Password</label>
                      <input
                        type={passwordShownOld ? "text" : "password"}
                        className="form-control Text"
                        name="oldPass"
                        placeholder="Enter Old password"
                        {...formik.getFieldProps("old_password")}
                      />

                      <span
                        className="toggle_password_ info_icon"
                        onClick={() => {
                          togglePasswordVisiblity1(!passwordShownOld);
                        }}
                      >
                        <span
                          className={
                            passwordShownOld
                              ? "show-icon togglePassword"
                              : "hide-icon togglePassword"
                          }
                          id=""
                        ></span>
                      </span>
                    </div>
                    {formik.touched.old_password &&
                      formik.errors.old_password ? (
                      <div className="validationMsg">
                        {" "}
                        {formik.errors.old_password}
                      </div>
                    ) : null}
                  </div>

                  <div
                    className={
                      formik.touched.new_password && formik.errors.new_password
                        ? ""
                        : "mb-4"
                    }
                  >
                    <div className="form-group updateFormGroup ChangePW">
                      <label className="text">New Password</label>
                      <input
                        type={passwordShown ? "text" : "password"}
                        className="form-control Text "
                        name="lastName"
                        placeholder="Enter New Password "
                        {...formik.getFieldProps("new_password")}
                      />
                      <span
                        className="toggle_password_ info_icon"
                        onClick={() => {
                          togglePasswordVisiblity(!passwordShown);
                        }}
                      >
                        <span
                          className={
                            passwordShown
                              ? "show-icon togglePassword"
                              : "hide-icon togglePassword"
                          }
                          id=""
                        ></span>
                      </span>
                    </div>
                    {formik.touched.new_password &&
                      formik.errors.new_password ? (
                      <div className="validationMsg">
                        {" "}
                        {formik.errors.new_password}
                      </div>
                    ) : null}
                  </div>
                  <div
                    className={
                      formik.touched.confirmPassword &&
                        formik.errors.confirmPassword
                        ? ""
                        : "mb-4"
                    }
                  >
                    <div className="form-group updateFormGroup ChangePW">
                      <label className="text">Confirm Password</label>
                      <input
                        type={passwordConfirmShown ? "text" : "password"}
                        className="form-control Text"
                        name="email"
                        placeholder="Confirm New Password "
                        {...formik.getFieldProps("confirmPassword")}
                      />
                      <span
                        className="toggle_password_ info_icon"
                        onClick={() => {
                          toggleConfirmPasswordVisiblity(!passwordConfirmShown);
                        }}
                      >
                        <span
                          className={
                            passwordConfirmShown
                              ? "show-icon togglePassword"
                              : "hide-icon togglePassword"
                          }
                          id=""
                        ></span>
                      </span>
                    </div>
                    {formik.touched.confirmPassword &&
                      formik.errors.confirmPassword ? (
                      <div className="validationMsg">
                        {" "}
                        {formik.errors.confirmPassword}
                      </div>
                    ) : null}
                  </div>

                  {serverError ? (
                    <span
                      className={
                        serverError?.error ? "validationMsg" : "d-none"
                      }
                    >
                      {serverError?.error ? serverError?.error : ""}
                    </span>
                  ) : (
                    ""
                  )}
                  <div className="copyrightBottom">
                    <p className="subtext copyrightText pb-3">
                      © copyright{moment().year()}. All rights reserved
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default ChangePassword;
