import React, { useState, useEffect } from "react";
import * as Images from "../../../../utilities/images";
import CustomModal from "../../../components/modals/modal";
import UserDetailPage from "./userDetailPage";
import DeleteUser from "./deleteUser";
import { getAllUser } from "../../../../redux/slices/user";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import ReactPaginate from "react-paginate";
import { Spinner } from "react-bootstrap";

const User = () => {
  const dispatch = useDispatch();
  const [userList, setuserList] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [perPageSize, setPerPageSize] = useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [key, setKey] = useState(Math.random());

  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const handleUserDetail = (userid) => {
    setModalDetail({
      show: true,
      title: "userdetailpage",
      flag: "detail",
      id: userid,
    });
    setKey(Math.random());
  };
  const handleDeleteUser = (userid) => {
    setModalDetail({
      show: true,
      title: "userDelete",
      flag: "Delete",
      id: userid,
    });
    setKey(Math.random());
  };

  const handlePageClick = (selectedItem) => {
    const selectedPage = selectedItem.selected + 1;
    setCurrentPage(selectedPage);
    getallUserList(selectedPage, perPageSize);
  };

  const handleChangePages = (e) => {
    setPerPageSize(e.target.value);
    setCurrentPage(1);
    getallUserList(1, e.target.value);
  };

  let pagingDataLabel = perPageSize * currentPage;
  pagingDataLabel = pagingDataLabel > totalCount ? totalCount : pagingDataLabel;

  const getallUserList = (
    page = currentPage,
    limit = perPageSize,
    keyword = searchValue
  ) => {
    let params = {
      page: page,
      limit: limit,
      keyword: keyword,
    };
    if (Boolean(keyword)) {
      params = { ...params, keyword: keyword };
    }
    dispatch(
      getAllUser({
        ...params,
        cb(res) {
          if (res?.data) {
            setTotalPages(res?.data?.data?.total_pages);
            setTotalCount(res?.data?.data?.totalRecords);
            setuserList(res?.data?.data?.data);
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };

  useEffect(() => {
    getallUserList();
    window.scrollTo(0, 0);
    document.title = "Users-List";
  }, []);

  return (
    <>
      <div className="contentCommon insidecontent_">
        <div className="searchDataTable">
          <div className="serachBar">
            <img
              src={Images.searchiconpink}
              alt="Search"
              className="searchIcon"
            />
            <input
              type="text"
              name="Search"
              className="form-control search subtext"
              placeholder="Search..."
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
                getallUserList(1, 10, e.target.value);
              }}
            />
          </div>
        </div>
        <div className="spaceInner insideSpace_ mt-3">
          <div className="   innerCourseCart_ space w-100">
            <table className="table table-hover table-responsive userTable tableBorder_ ">
              <thead>
                <tr>
                  <th scope="col">S.No</th>
                  <th scope="col">Full Name </th>
                  <th scope="col">Email</th>
                  <th scope="col">Status</th>
                  <th scope="col">Created At</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {userList?.length > 0 ? (
                  userList?.map((data, index) => {
                    return (
                      <tr key={index}>
                        <td scope="row">
                          {perPageSize * (currentPage - 1) + (index + 1)}
                        </td>
                        <td>{data?.full_name}</td>
                        <td>{data?.email}</td>
                        <td>
                          <div className="">
                            {data?.status === 1 ? (
                              <div className="activeStatus">Active</div>
                            ) : (
                              <div className="in activeStatus">Inactive</div>
                            )}
                          </div>
                        </td>
                        <td>{moment(data?.createdAt).format("YYYY-DD-MM")}</td>
                        <td className="">
                          <img
                            onClick={() => handleUserDetail(data?.id)}
                            src={Images.eye}
                            alt="eye"
                            className="eye"
                          />
                          <img
                            onClick={() => handleDeleteUser(data?.id)}
                            src={Images.trashdark}
                            alt="delete"
                            className="delete ps-1"
                          />
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <>
                    {userList?.length == 0 ? (
                      <tr>
                        <td
                          className="colorBlue text text-center py-3"
                          colSpan={8}
                        >
                          <h3 className="noDataText mt-4 mb-4">
                            No user Found{" "}
                          </h3>
                        </td>
                      </tr>
                    ) : (
                      <Spinner
                        className="pageLoader"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                  </>
                )}
              </tbody>
            </table>
            {userList?.length > 0 ? (
              <>
                <ReactPaginate
                  className="paginationBox mb-0"
                  breakLabel=""
                  nextLabel="next >"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  marginPagesDisplayed={0}
                  pageCount={totalPages}
                  previousLabel="< prev"
                  renderOnZeroPageCount={null}
                />
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center ">
          <div className="dataTables_length pt-3" id="staffsTable_length">
            <label>
              {" "}
              <span className="text">Show</span>{" "}
              <select
                name="staffsTable_length"
                aria-controls="staffsTable"
                id="perPageId"
                onChange={(e) => handleChangePages(e)}
                value={perPageSize}
                className="custom-select custom-select-sm form-control form-control-sm"
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="75">75</option>
                <option value="100">100</option>
              </select>
              &nbsp;{" "}
              <span className="subtext">
                Showing {`${perPageSize * (currentPage - 1) + 1}`} to{" "}
                {`${perPageSize * (currentPage - 1) + userList?.length}`} of{" "}
                {`${totalCount}`} entries
              </span>{" "}
            </label>
          </div>
        </div>
      </div>

      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        className={
          modalDetail.flag === "detail" ? "commonWidth customContent" : ""
        }
        ids={
          modalDetail.flag === "detail"
            ? "detail"
            : modalDetail.flag === "Delete"
            ? "delete"
            : ""
        }
        child={
          modalDetail.flag === "detail" ? (
            <UserDetailPage
              closeModal={() => {
                handleOnCloseModal();
              }}
              refreshData={() => getallUserList()}
              userId={modalDetail?.id}
            />
          ) : modalDetail.flag === "Delete" ? (
            <DeleteUser
              closeModal={() => {
                handleOnCloseModal();
              }}
              refreshData={() => getallUserList()}
              userId={modalDetail?.id}
            />
          ) : (
            ""
          )
        }
        header={
          <div className="modalHeader_">
            <div className="common_">
              {modalDetail.flag === "detail" ? (
                <h2 className="headingSmall_">User Detail</h2>
              ) : modalDetail.flag === "Delete" ? (
                <h2 className="headingSmall_"></h2>
              ) : (
                ""
              )}
            </div>
          </div>
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default User;
