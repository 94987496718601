import { Route, Routes, useNavigate } from "react-router-dom";
import * as Containers from "../app/containers";
import * as Layouts from "../app/layouts";
import PrivateCheck from "../app/layouts/PrivateCheck";
import { useEffect } from "react";
import { useUserSelector } from "../redux/selector/user";

const Router = () => {
  const authUserSelector = useUserSelector();
  const navigate = useNavigate();
  const ValidLoggedIn = authUserSelector?.loginUserProfile?.data;

  useEffect(() => {
    if (ValidLoggedIn && ValidLoggedIn?.role === 2) {
      if (window.location.pathname.includes("admin")) {
        navigate("/");
      } else {
        navigate(window.location.pathname);
      }
    } else if (ValidLoggedIn?.role == 1) {
      if (window.location.pathname.includes("admin")) {
        navigate(window.location.pathname);
      } else {
        navigate("/admin/dashboard");
      }
    }
  }, [ValidLoggedIn?.role]);

  window.addEventListener("storage", (e) => {
    // console.log(`New Value: ${e.newValue}`);
    // console.log(`Key Changed: ${e.key}`);
    if (e.key === "myReduxState") {
      var parsedDetails = JSON.parse(e.newValue);
    }
    if (parsedDetails?.role === 1) {
      window.open("/admin/dashboard", "_self");
    } else if (parsedDetails?.role === 2) {
      window.open("/", "_self");
    }
  });

  return (
    <>
      {/* main layout */}
      <Routes>
        <Route element={<Layouts.MainLayout2 />}>
          <Route path="/" element={<Containers.Home />} />
          <Route path="aboutus" element={<Containers.Aboutus />} />
          <Route path="contactus" element={<Containers.Contactus />} />
          <Route path="thankyou" element={<Containers.ThankYou />} />
          <Route path="privacypolicy" element={<Containers.Privacypolicy />} />
          <Route
            path="termsconditions"
            element={<Containers.Termsconditions />}
          />

          <Route path="recipes" element={<Containers.Recipes />} />
        </Route>

        <Route element={<Layouts.MainLayout />}>
          <Route
            path="recipedetailpage/:id"
            element={<Containers.Recipedetailpage />}
          />
          <Route path="classespage" element={<Containers.Classespage />} />
          {/* <Route path="favorites" element={<Containers.Favorites />} /> */}
          <Route path="favClasses" element={<Containers.FavoriteClasses />} />
          <Route path="favRecipies" element={<Containers.FavoriteRecipies />} />

          <Route
            path="liveStreamingUser"
            element={<Containers.LiveStreamingUser />}
          />
          <Route
            path="joinStreaming"
            element={<Containers.JoinLiveStreaming />}
          />
          <Route
            path="classdetailpage/:id"
            element={<Containers.Classdetailpage />}
          />

          <Route path="addsubscription" element={<Containers.Subscription />} />
        </Route>

        {/* auth layout */}
        <Route element={<Layouts.AuthLayout />}>
          <Route element={<PrivateCheck auth={false} />}>
            <Route path="/signup" element={<Containers.Signup />} />
            <Route path="/login" element={<Containers.Login />} />
            <Route path="/verification" element={<Containers.Verification />} />
            <Route
              path="/forgotpassword"
              element={<Containers.ForgotPassword />}
            />
            <Route path="/enterotp" element={<Containers.Enterotp />} />
            <Route
              path="/recoverpassword"
              element={<Containers.Recoverpassword />}
            />
          </Route>
        </Route>

        {/* inner layout */}
        <Route element={<Layouts.InnerLayout />}>
          <Route element={<PrivateCheck auth={true} />}>
            <Route path="/viewEdit" element={<Containers.viewEdit />} />
            <Route
              path="/changePassword"
              element={<Containers.changePassword />}
            />
            <Route path="/notification" element={<Containers.notification />} />
            <Route
              path="/planSubscription"
              element={<Containers.planSubscription />}
            />
            <Route
              path="/PaymentMethod"
              element={<Containers.PaymentMethod />}
            />

            <Route
              path="/user-support-chat"
              element={<Containers.SupportChatUser />}
            />

            <Route path="/AddCard" element={<Containers.AddCard />} />
            <Route
              path="/updateProfile"
              element={<Containers.updateProfile />}
            />
            <Route path="/dashboard" element={<Containers.Dashboard />} />
            <Route path="/recipes" element={<Containers.Recipes />} />
          </Route>
        </Route>
        <Route path="/admin" element={<Layouts.AdminInnerLayout />}>
          <Route element={<PrivateCheck auth={false} />}>
            <Route path="dashboard" element={<Containers.AdminDashboard />} />
            <Route path="myProfile" element={<Containers.MyProfile />} />
            <Route
              path="updatemyprofile"
              element={<Containers.UpdateMyProfile />}
            />
            <Route
              path="editmyprofile"
              element={<Containers.EditMyProfile />}
            />
            <Route path="user" element={<Containers.User />} />
            <Route path="recipe" element={<Containers.Recipe />} />
            <Route path="addrecipe" element={<Containers.AddRecipe />} />
            <Route path="plan" element={<Containers.AddPlan />} />
            <Route path="editplan/:id" element={<Containers.EditPlans />} />
            <Route path="addplan" element={<Containers.Addplandetail />} />
            <Route path="goLive" element={<Containers.LiveModal />} />
            <Route
              path="detailrecipe/:id"
              element={<Containers.DetailRecipe />}
            />
            <Route path="editrecipe/:id" element={<Containers.EditRecipe />} />
            <Route path="classes" element={<Containers.Classes />} />
            <Route
              path="liveStreaming/:id"
              element={<Containers.LiveStreaming />}
            />
            <Route path="addclass" element={<Containers.AddClass />} />
            <Route
              path="classdetail/:id"
              element={<Containers.ClassDetail />}
            />
            <Route path="editclass/:id" element={<Containers.EditClass />} />
            <Route path="info" element={<Containers.Info />} />
            <Route path="reports" element={<Containers.Reports />} />
            <Route path="supportChat" element={<Containers.SupportChat />} />
            <Route path="settings" element={<Containers.Setting />} />
          </Route>
          <Route path="dashboard" element={<Containers.AdminDashboard />} />
          <Route path="myProfile" element={<Containers.MyProfile />} />
          <Route
            path="updatemyprofile"
            element={<Containers.UpdateMyProfile />}
          />
          <Route path="editmyprofile" element={<Containers.EditMyProfile />} />
          <Route path="user" element={<Containers.User />} />
          <Route path="addrecipe" element={<Containers.AddRecipe />} />
          <Route path="detailrecipe" element={<Containers.DetailRecipe />} />
          <Route path="classes" element={<Containers.Classes />} />
          <Route path="addclass" element={<Containers.AddClass />} />
          <Route path="classdetail" element={<Containers.ClassDetail />} />
        </Route>

        <Route path="*" element={<Containers.NotFound />} />
      </Routes>
    </>
  );
};

export default Router;
