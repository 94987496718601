import React, { useEffect, useState } from "react";
import * as Images from "../../../utilities/images";
import { userOtp } from "../../../redux/slices/auth";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";

const ForgotPassword = () => {
  const [serverError, setServerError] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Please enter valid email address")
        .required("Email is required"),
    }),
    onSubmit: (values) => {
      let params = {
        email: values?.email.trim(),
        type: "2",
        role: values?.email.trim() === "admin@yopmail.com" ? 1 : 2,
      };
      dispatch(
        userOtp({
          ...params,
          cb(res) {
            if (res?.data?.status_code == 400) {
              setServerError({ error: res.data.message });
              return false;
            }
            if (res?.data?.status_code == 200) {
              navigate("/enterotp", { state: { email: values?.email } });
              localStorage.setItem("email", values?.email);
              setServerError("");
            } else {
            }
          },
        })
      );
    },
  });
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Forgot Password";
  }, []);

  return (
    <>
      <section className="loginpage">
        <div className="container-fluid">
          <div className="row item-center flex-row">
            <div className=" col-lg-5 col-md-6">
              <div className="logininner">
                <div className="header-logo">
                  <Link to={"/"}>
                    <img src={Images.logo} alt="" />
                  </Link>
                </div>
                <div className="loginForm">
                  <Link className="colorpink" to={"/login"}>
                    <img className="backicon" src={Images.backarrow} alt="" />
                    Back to Login
                  </Link>
                  <h1 className="heading py-3">Forgot Password? </h1>
                  <p className="text pb-3 forgotPara">
                    Enter the email address that associated with your account
                    for Verify.
                  </p>
                  <form onSubmit={formik.handleSubmit}>
                    <div className="mb-4 form-group">
                      <div className="position-relative">
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          placeholder="Enter Email Address"
                          {...formik.getFieldProps("email")}
                        />
                        <img className="icon_" src={Images.mail} alt="" />
                      </div>
                      {formik.touched.email && formik.errors.email ? (
                        <div className="validationMsg">
                          {formik.errors.email}
                        </div>
                      ) : null}
                      {serverError ? (
                        <span
                          className={
                            serverError?.error ? "validationMsg" : "d-none"
                          }
                        >
                          {serverError?.error ? serverError?.error : ""}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="dflex item-center py-3">
                      <button className="login-btn" type="submit">
                        Verify
                      </button>
                      <span className="center-img">
                        <img
                          src={Images.centerrectangle}
                          alt=""
                          onClick={formik.handleSubmit}
                        />
                      </span>
                      <span className="circle-btn">
                        <img
                          src={Images.arrow}
                          alt=""
                          onClick={formik.handleSubmit}
                        />
                      </span>
                    </div>
                    <p className="subtext copyrightText pb-3">
                      ©copyright{moment().year()}. All rights reserved
                    </p>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-7 pr-0">
              <div className="imgouter">
                <img className="img-fluid w-100" src={Images.banner} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ForgotPassword;
