import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Images from "../../../../utilities/images";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getClassDetails } from "../../../../redux/slices/admin";
import moment from "moment-timezone";
import DeleteClass from "./deleteClass";
import CustomModal from "../../../components/modals/modal";
import {
  generateTokenForStreaming,
  getAllreviewClass,
  replyComment,
  deleteComment,
} from "../../../../redux/slices/user";
import ConfirmLiveStream from "./confirmModalLiveStream";
import ReactStars from "react-rating-stars-component";
import { Spinner } from "react-bootstrap";
import Swal from "sweetalert2";

const ClassDetail = () => {
  const { id } = useParams();
  const vidRef = useRef(null);
  const [typeId] = useState(2);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [totalComments, setTotalComments] = useState("");
  const [isVideoPlayed, setIsVideoPlayed] = useState(false);
  const [getDetails, setGetDetails] = useState([]);
  const [key, setKey] = useState(Math.random());
  const [commentList, setCommentsList] = useState([]);
  const [totalCommentsrecords, setTotalCommentRecords] = useState("");
  const [commentKey, setCommentKey] = useState(Math.random());
  const [page, setPage] = useState(1);
  const [showCommentBox, setShowCommentBox] = useState(false);
  const [userId, setUserById] = useState("");
  const [loadingSendReplyComment, setLoadingSendReplyComment] = useState(false);
  const [replyComments, setReplyComments] = useState("");
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const handlePlayVideo = () => {
    setIsVideoPlayed(true);
    vidRef.current.play();
  };
  const handlePauseVideo = () => {
    setIsVideoPlayed(false);
    vidRef.current.pause();
  };

  const handleDeleteUser = (userid, Classtitle) => {
    setModalDetail({
      show: true,
      title: "Deleteclass",
      flag: "Delete",
      id: userid,
      Classtitle: Classtitle,
    });
    setKey(Math.random());
  };
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
  };

  const classDetails = () => {
    let params = {
      id: id,
    };
    dispatch(
      getClassDetails({
        ...params,
        cb(res) {
          if (res?.data) {
            setGetDetails(res?.data?.data);
            getAllComments(page, res?.data?.data?.id);
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };
  useEffect(() => {
    classDetails();
    window.scrollTo(0, 0);
    document.title = "Classdetails";
  }, []);

  const getCurrentDateTime = () => {
    var m = moment(); // get "now" as a moment
    var s = m.format();
    return s;
  };
  const handleStartStreaming = (classid) => {
    let params = {
      class_id: classid,
    };
    dispatch(
      generateTokenForStreaming({
        ...params,
        cb(res) {
          if (res?.data) {
            navigate(`/admin/liveStreaming/${classid}`, {
              state: {
                tokenId: res?.data?.data?.token,
                roomID: res?.data?.data?.roomID,
                appId: res?.data?.data?.appId,
                channel: res?.data?.data?.channelName,
                uid: res?.data?.data?.uid,
                classId: classid,
              },
            });
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };

  const handleStartStreamingBeforeTime = (classid) => {
    setModalDetail({
      show: true,
      title: "Confirm",
      flag: "confirm",
      id: classid,
    });
    setKey(Math.random());
  };

  const getAllComments = (pageCmt = page, id) => {
    let params = {
      page: pageCmt,
      limit: 4,
      model_id: id,
      model_type: 2,
    };
    dispatch(
      getAllreviewClass({
        ...params,
        cb(res) {
          if (res?.data) {
            if (parseInt(res?.data?.data?.current_page) == 1) {
              //setCommentsList(res?.data?.data?.list);
              setCommentsList(handleComentReplies(res?.data?.data?.list));
              setTotalCommentRecords(res?.data?.data?.totalRecords);
            } else {
              setCommentsList((commentList) => {
                const cmt = [...commentList, ...res?.data?.data?.list];
                return cmt;
              });
            }
            setTotalComments(res?.data?.data?.totalRecords);
            setCommentKey(Math.random());
            setPage(res?.data?.data?.current_page);
          } else {
          }
        },
      })
    );
  };

  const handleComentReplies = (comment) => {
    let _comment = [];
    comment.map((data) => {
      _comment.push({ ...data, itemToShow: 2 });
    });
    return _comment;
  };

  const handleShowInput = (id) => {
    setShowCommentBox(true);
    setUserById(id);
  };

  const handlePostCommentsReply = (id, index) => {
    if (!replyComments) {
      toast.error("Add a comment");
      return;
    }
    let params = {
      parent_id: id,
      comments: replyComments,
    };
    setLoadingSendReplyComment(true);
    dispatch(
      replyComment({
        ...params,
        cb(res) {
          setLoadingSendReplyComment(false);
          if (res.status) {
            // getAllComments(id,page);
            setShowCommentBox(false);
            setReplyComments("");

            setCommentsList((commentList) => {
              var newData = [...commentList];
              const newComments = [
                res.data.data,
                ...commentList[index].comments,
              ];
              newData[index] = {
                ...newData[index],
                comments: newComments,
              };

              return newData;
            });
          } else {
          }
        },
      })
    );
  };

  const handleViewMore = (i) => {
    let _commentList = [...commentList];
    _commentList[i].itemToShow += 2;
    setCommentsList(_commentList);
  };

  // remove comment
  const handleRemoveComment = (id) => {
    Swal.fire({
      title: "Delete Comment",
      text: "Are you sure you want to delete",
      icon: "warning",
      iconColor: "red",
      customClass: {
        popup: "mySwalClass",
      },
      showCancelButton: true,
      confirmButtonText: "Yes, Delete",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed && id) {
        dispatch(
          deleteComment({
            id,
            cb(res) {
              if (res?.status === 200) {
                getAllComments(page, getDetails?.id);
                toast.success("Comment deleted successfully");
              }
            },
          })
        );
      }
    });
  };

  return (
    <>
      <div className="detailRecipe">
        <div className="row mt-4">
          <div className="col-12">
            <div className="contentCommon">
              <div className="px-3">
                <div className="courseHead">
                  <div className="backBtn">
                    <Link to={"/admin/classes"}>
                      <button className="btn colorPink px-0 pb-2">
                        <img
                          src={Images.rightarrowpink}
                          alt="arrow"
                          className="me-2"
                        />{" "}
                        Go to Back
                      </button>
                    </Link>
                  </div>
                  <div className="detail-recipe-outer">
                    <div className="details-heading-recipe">
                      <h1 className="courseHeading font-28 m-0">
                        {getDetails?.title}
                      </h1>
                    </div>
                    <div className="commonCourse">
                      <div className="editDeleteBtn">
                        <Link to={`/admin/editclass/${id}`}>
                          <button
                            type="button"
                            className="btn outlineInfo me-2 editBtn "
                            data-bs-toggle="modal"
                            data-bs-target="#courseEdit"
                            data-backdrop="static"
                            data-keyboard="false"
                          >
                            Edit
                          </button>
                        </Link>
                        <button
                          onClick={() =>
                            handleDeleteUser(getDetails?.id, getDetails?.title)
                          }
                          type="button"
                          className="btn dangerBtn outlineDanger editBtn"
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="courseCard detailRecipeCard">
                    <div className="detailRecipeCardInner">
                      <div className="d-flex me-3">
                        <img className="me-3" src={Images.typeicon} alt="" />
                        <div className="">
                          <p className="subtext m-0 medText">Type</p>
                          <p className="subtext m-0">
                            {getDetails?.category_title}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex me-3">
                        <img className="me-3" src={Images.date} alt="" />
                        <div className="">
                          <p className="subtext m-0 medText">Date</p>
                          <p className="subtext m-0">
                            {moment(getDetails?.date).format("YYYY-MM-DD")}
                          </p>
                        </div>
                      </div>
                      {getDetails?.type == 1 ? (
                        <div className="d-flex me-3">
                          <img className="me-3" src={Images.clockicon} alt="" />
                          <div className="">
                            <p className="subtext m-0 medText">Time </p>
                            <p className="subtext m-0">
                              {moment(getDetails?.time, "HH:mm").format(
                                "hh:mm A"
                              )}
                            </p>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {getDetails?.type == 1 ? (
                        <div className="d-flex me-3">
                          <img
                            className="me-3"
                            src={Images.servingicon}
                            alt=""
                          />
                          <div className="">
                            <p className="subtext m-0 medText">Duration</p>
                            <p className="subtext m-0">
                              {getDetails?.duration} Min
                            </p>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="d-flex me-3">
                        <img
                          className="me-3"
                          src={Images.celendericon}
                          alt=""
                        />
                        <div className="">
                          <p className="subtext m-0 medText">Created At</p>
                          <p className="subtext m-0">
                            {moment(getDetails?.createdAt).format("YYYY-MM-DD")}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex me-3">
                        <img className="me-3" src={Images.usericon} alt="" />
                        <div className="">
                          <p className="subtext m-0 medText">by</p>
                          <p className="subtext m-0">
                            {/* {getDetails?.created_by_name} */}
                            Lynnette
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {getDetails?.type == 2 ? (
                  <div className="py-3 ">
                    {Boolean(getDetails?.class_video) ? (
                      <>
                        <h5 className="medText py-3">Recipe Video</h5>
                        <div className="vedioOuter">
                          {isVideoPlayed === false ? (
                            <div className="playVedio">
                              <img
                                src={Images.vedioicon}
                                onClick={() => handlePlayVideo()}
                                alt="Play Video"
                              />
                            </div>
                          ) : (
                            <img
                              className="pausedIcon"
                              src={Images.paused}
                              onClick={() => handlePauseVideo()}
                              alt="Pause Video"
                            />
                          )}
                          <video
                            onPause={() => {
                              setIsVideoPlayed(false);
                            }}
                            onPlay={() => {
                              setIsVideoPlayed(true);
                            }}
                            poster={getDetails?.thumbnail_image}
                            ref={vidRef}
                            className="img-fluid w-100 vedio"
                            controls
                            autoPlay
                            loop
                          >
                            <source src={getDetails?.class_video} />
                          </video>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <div className="py-4 recipebgimg">
                    <img
                      src={
                        getDetails?.thumbnail_image
                          ? getDetails?.thumbnail_image
                          : Images.usericon
                      }
                      alt="img"
                      className="img-fluid w-100"
                    />
                    <div className="loader">
                      <img className=" w-100" src={Images.loader} alt="" />
                    </div>
                    <div className="startStreamingBtn">
                      <button
                        className="login-btn"
                        onClick={() => {
                          getDetails?.date <= getCurrentDateTime()
                            ? handleStartStreaming(getDetails?.id)
                            : handleStartStreamingBeforeTime(getDetails?.id);
                        }}
                      >
                        <span>
                          <img className="me-2" src={Images.youtube} alt="" />
                        </span>
                        Start Streaming
                      </button>
                    </div>
                  </div>
                )}

                <p className="text">{getDetails?.description}</p>

                <div className="IngredientsBox">
                  {getDetails?.instructors?.length > 0 ? (
                    <div className="p-4">
                      <>
                        <div className="d-flex align-items-center">
                          <img src={Images.profile} alt="" />
                          <p className="medText m-0 ms-3">instructors</p>
                        </div>

                        <ul className="IngredientsList p-0 mt-4">
                          {getDetails?.instructors?.map((data) => {
                            return (
                              <li className="userimgfeild">
                                <img
                                  className="userImg1"
                                  src={data?.img_url}
                                  alt=""
                                />
                                {data?.full_name}
                              </li>
                            );
                          })}
                        </ul>
                      </>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="borderBox">
                  <hr className="borderLine"></hr>
                </div>
                <div className="commentOnrecipeBox mt-5">
                  <div className="flexBox justify-content-between align-items-center">
                    <article>
                      <h6 className="commentsHeading">Comments on Class </h6>
                      <p className="commentCount">
                        {totalComments
                          ? `${totalComments} Comments posted by users`
                          : "No Comments posted yet"}
                      </p>
                    </article>
                    <div className="flexBox">
                      <article className="align-items-center">
                        <button type="button" className="ratingButton">
                          Rating
                          <img
                            src={Images.star}
                            alt="star"
                            className="buttonStar"
                          />
                          <span className="ratingValue">
                            {getDetails?.rating?.toFixed(2)}
                          </span>
                        </button>
                      </article>
                      <figure className="m-0">
                        <img
                          src={Images.commeticon}
                          alt="commeticon"
                          className="img-fluid commeticon"
                        />
                      </figure>
                    </div>
                  </div>
                </div>

                <div key={commentKey}>
                  {commentList?.map((data, index) => {
                    return (
                      <>
                        <div className="aboutus_Anna">
                          <div className="profile_outer">
                            <img
                              className="img-fluid"
                              src={
                                data?.created_by?.profile_image
                                  ? data?.created_by?.profile_image
                                  : Images.defaultUser
                              }
                              //src={Images.heart}
                              alt="Anna"
                            />
                            <div className="ms-1 me-1 d-flex w-100 align-items-center">
                              <div className="me-1">
                                <div className="classdetailText">
                                  <p className="DefaultPara medText m-0">
                                    {data?.created_by?.full_name}
                                  </p>
                                  <p className="subtext m-0">
                                    {moment(data?.createdAt).format(
                                      "MMMM D, YYYY"
                                    )}
                                  </p>
                                </div>
                              </div>
                              <div>
                                <ReactStars
                                  classNames="commented-stars"
                                  count={5}
                                  value={data?.rate}
                                  size={24}
                                  activeColor="#ffd700"
                                  edit={false}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="ReportsOuter">
                            <div className="Readme_button">
                              <button
                                className="reply_button subtext colorPink "
                                onClick={() => handleShowInput(data?.id)}
                              >
                                Reply
                                <img
                                  className="ms-2"
                                  src={Images.rotatearrowpink}
                                  alt=""
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div>
                          <p className="text">{data?.review}</p>
                        </div>
                        <div className="larrow_">
                          {data?.comments?.map((replied, i) => {
                            if (i < data?.itemToShow)
                              return (
                                <>
                                  <img src={Images.aboutusLarrow} alt="" />
                                  <div className="aboutus_leena">
                                    <div className="leena_Profile">
                                      <div className="d-flex align-items-center">
                                        <div className="profile_outer">
                                          <img
                                            className="img-fluid"
                                            src={
                                              replied?.created_by?.profile_image
                                                ? replied?.created_by
                                                    ?.profile_image
                                                : Images.defaultUser
                                            }
                                            //src={Images.heart}
                                            alt="Anna"
                                          />
                                        </div>
                                        <div className="ms-3 me-3 replyComments">
                                          <p className="DefaultPara medText m-0">
                                            {replied?.created_by?.full_name}
                                          </p>
                                          <p className="subtext m-0">
                                            {moment(replied?.createdAt).format(
                                              "MMMM D, YYYY"
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                      <p className="text replyCommentText">
                                        {replied?.comments}
                                      </p>
                                    </div>
                                    {replied?.created_by?.role === 1 && (
                                      <div
                                        onClick={() =>
                                          handleRemoveComment(replied?.id)
                                        }
                                        className="anna_Report_Outer remove-comment-outer"
                                      >
                                        <img
                                          alt="remove-comment"
                                          src={Images.removeCommentLogo}
                                        />
                                        <span className="remove-comment-text">
                                          Remove
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                </>
                              );
                          })}
                          {data.itemToShow < data?.comments?.length ? (
                            <div
                              onClick={() => handleViewMore(index)}
                              style={{
                                color: "black",
                                fontWeight: 500,
                                cursor: "pointer",
                              }}
                            >
                              View More
                            </div>
                          ) : null}

                          {showCommentBox && userId == data?.id && (
                            <form>
                              <p className="DefaultPara medText">Comment</p>
                              <div className="container p-0">
                                <div className="row">
                                  <div className="col-md-12">
                                    <textarea
                                      className="contactUsforminput_field contactusTextarea"
                                      type="text"
                                      placeholder="Your Comment"
                                      rows="4"
                                      cols="50"
                                      value={replyComments}
                                      onChange={(e) =>
                                        setReplyComments(
                                          e.target.value.trimStart()
                                        )
                                      }
                                    ></textarea>
                                  </div>
                                </div>
                                <div className="mt-4">
                                  <button
                                    className="login-btn"
                                    type="button"
                                    onClick={() =>
                                      handlePostCommentsReply(data?.id, index)
                                    }
                                  >
                                    Post Comment
                                    {loadingSendReplyComment && (
                                      <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        className="ms-1"
                                      />
                                    )}{" "}
                                  </button>
                                  <span className="center-img">
                                    <img
                                      src={Images.centerrectangle}
                                      alt=""
                                      onClick={() =>
                                        handlePostCommentsReply(data?.id, index)
                                      }
                                    />
                                  </span>
                                  <span className="circle-btn">
                                    <img
                                      src={Images.arrow}
                                      alt=""
                                      onClick={() =>
                                        handlePostCommentsReply(data?.id, index)
                                      }
                                    />
                                  </span>
                                </div>
                              </div>
                            </form>
                          )}
                        </div>
                      </>
                    );
                  })}
                </div>
                {commentList?.length > 3 &&
                totalCommentsrecords != commentList?.length ? (
                  <div
                    style={{
                      color: "black",
                      fontWeight: 500,
                      cursor: "pointer",
                    }}
                  >
                    onClick=
                    {() => {
                      getAllComments(parseInt(page) + 1, getDetails?.id);
                    }}
                    View More
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {modalDetail.show && (
        <CustomModal
          key={key}
          show={modalDetail.show}
          backdrop="static"
          showCloseBtn={true}
          isRightSideModal={false}
          mediumWidth={false}
          className={modalDetail.flag === "Delete" ? "delete" : ""}
          child={
            modalDetail.flag === "Delete" ? (
              <DeleteClass
                onCloseModal={() => handleOnCloseModal()}
                typeId={typeId}
                userId={modalDetail?.id}
                Classtitle={modalDetail?.Classtitle}
              />
            ) : modalDetail.flag === "confirm" ? (
              <ConfirmLiveStream
                onCloseModal={() => {
                  handleOnCloseModal();
                }}
                refreshData={() => classDetails()}
                classid={modalDetail?.id}
                Classtitle={modalDetail?.Classtitle}
              />
            ) : (
              ""
            )
          }
          onCloseModal={() => handleOnCloseModal()}
        />
      )}
    </>
  );
};

export default ClassDetail;
