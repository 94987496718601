import React, { useEffect, useState } from "react";
import * as Images from "../../../utilities/images";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { favRecipe, favRecipeList, loginUserProfile } from "../../../redux/slices/user";
import ReactPaginate from "react-paginate";
import "sweetalert2/dist/sweetalert2.min.css";
import { useUserSelector } from "../../../redux/selector/user";
import Swal from "sweetalert2";

const FavoriteRecipies = () => {
  const dispatch = useDispatch();
  const [getFavRecipes, setFavRecipes] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [perPageSize, setPerPageSize] = useState(10);
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const [activeTab, setActiveTab] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const authUserSelector = useUserSelector();
  const userInfo = authUserSelector?.loginUserProfile?.data;

  useEffect(() => {
    setActiveTab(location?.pathname);
  }, [location?.pathname]);
  const getRecipeList = (page = currentPage, limit = 9) => {
    let params = {
      page: page,
      limit: limit,
    };
    dispatch(
      favRecipeList({
        ...params,
        cb(res) {
          if (res?.data) {
            setTotalPages(res?.data?.data?.total_pages);
            setTotalCount(res?.data?.data?.totalRecords);
            setFavRecipes(res?.data?.data?.list);
          } else {
          }
        },
      })
    );
  };
  const handleShowAlert = () => {
    setShowAlert(true);
    Swal.fire({
      title: "Add subscription",
      text: "Kindly add subscription for view recipies",
      icon: "warning",
      iconColor: "red",
      customClass: {
        popup: "mySwalClass",
      },
      showCancelButton: true,
      confirmButtonText: "Add",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("redirectUrl");
        navigate("/addsubscription", { state: pathname });
      }
    });
  };

  const handleViewRecipe = (data) => {
    if (userInfo?.isSubscribe == false && data?.access_level == 2) {
      handleShowAlert();
      return false;
    } else if (data?.id) {
      navigate(`/recipedetailpage/${data?.id}`);
    }
  };

  const handleFav = (id, isFav) => {
    let params = {
      recipe_id: id,
    };
    dispatch(
      favRecipe({
        ...params,
        cb(res) {
          // console.log(res,"resposneDataaInLisst")
          if (res.status) {
            const recipesCopy = [...getFavRecipes];
            const recipeIndex = recipesCopy.findIndex((x) => x.id === id);
            if (recipeIndex >= 0) {
              let favRecipe = recipesCopy[recipeIndex];
              favRecipe = {
                ...favRecipe,
                is_favorite: isFav,
              };
              // .is_favorite=isFav
              recipesCopy[recipeIndex] = favRecipe;
              setFavRecipes(recipesCopy);

            }

            getRecipeList();
          } else {
          }
        },
      })
    );
  };

  const handlePageClick = (selectedItem) => {
    const selectedPage = selectedItem.selected + 1;
    setCurrentPage(selectedPage);
    getRecipeList(selectedPage, perPageSize);
  };

  const handleViewRecipies = () => {
    navigate("/favRecipies");
  };

  const handleViewClasses = () => {
    navigate("/favClasses");
  };

  useEffect(() => {
    getRecipeList();
    window.scrollTo(0, 0);
    document.title = "Favourite Recipies";
  }, []);

  return (
    <>
      <section className="favoritesBanner favclassBanner">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center pt-5">
              <h2 className="bigHeading py-2 ">Favourites</h2>
              <p className="text px-3">Recipes or Classes you Like</p>
            </div>

            <div className="FavRecipies">
              <div className="nav nav-pills  mb-4">
                <button
                  className={activeTab == "/favRecipies" ? "active" : ""}
                  onClick={(e) => handleViewRecipies(e)}
                >
                  Recipe
                </button>
                <button
                  className={activeTab == "/favClasses" ? "active" : ""}
                  onClick={(e) => handleViewClasses(e)}
                >
                  Classes
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="tabsSection">
        <div className="tab-content clearfix">
          <div className="tab-pane active" id="Recipe">
            <div className=" RecipeTabsOuter">
              <div className="container-xxl container-fluid">
                <div className="row">
                  {getFavRecipes?.length > 0
                    ? getFavRecipes?.map((data, index) => {
                      return (
                        <div
                          className="col-lg-4 col-md-6 mb-4 px-4"
                          key={index}
                        >
                          <div className="foodBoxOuter ">
                            <div className="foodInner">
                              <div className="boxImg ">
                                <div className="fullrecipeImg">
                                  <img
                                    src={data?.thumbnail_image}
                                    alt=""
                                    onClick={() => handleViewRecipe(data)}
                                  />
                                </div>
                                {data?.rating > 0 && (
                                  <div
                                    className="subtext ratingBox"
                                    onClick={() => handleViewRecipe(data)}
                                  >
                                    <img
                                      className="star"
                                      src={Images.star}
                                      alt=""
                                    />
                                    {data?.rating > 0
                                      ? data?.rating?.toFixed(1)
                                      : ""}
                                  </div>
                                )}
                              </div>
                              <div className="foodBox">
                                <div className="foodboxInner">
                                  <div className="title-tag mb-1">
                                    <h5 className="bigText">{data?.title}</h5>
                                    {data?.access_level == 2 ? (
                                      <button
                                        className="recipe-tagPaid"
                                        onClick={() => handleViewRecipe(data)}
                                      >
                                        Paid
                                      </button>
                                    ) : (
                                      <button
                                        className="recipe-tag"
                                        onClick={() => handleViewRecipe(data)}
                                      >
                                        Free
                                      </button>
                                    )}
                                  </div>
                                  <p className="subtext pararecipe ">
                                    {data?.description}
                                  </p>

                                  <button
                                    className="button seeFullrecipe text-capitalize"
                                    onClick={() => handleViewRecipe(data)}
                                  >
                                    See full Recipe
                                    <span>
                                      <img
                                        className="signup"
                                        src={Images.signuparrow}
                                        alt=""
                                      />
                                    </span>
                                  </button>

                                  {data?.is_favorite == true ? (
                                    <span className="heartCircle">
                                      <img
                                        src={Images.Vector}
                                        alt=""
                                        onClick={() =>
                                          handleFav(data?.id, false)
                                        }
                                      />
                                    </span>
                                  ) : (
                                    <span className="heartCircle">
                                      <img
                                        alt=""
                                        src={Images.heart}
                                        onClick={() =>
                                          handleFav(data?.id, true)
                                        }
                                      />
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                    : ""}
                </div>
              </div>
            </div>
          </div>

          {getFavRecipes?.length > 0 ? (
            <ReactPaginate
              className="paginationBox RecipePagination mb-0"
              breakLabel=""
              // nextLabel="next >"
              onPageChange={handlePageClick}
              nextClassName={"pagination-next"}
              previousClassName={"pagination-previous"}
              pageRangeDisplayed={5}
              marginPagesDisplayed={0}
              pageCount={totalPages}
              previousLabel="prev"
              renderOnZeroPageCount={null}
            />
          ) : (
            <h3 className="noDataText-Fav">No Favorite Recipies added yet</h3>
          )}
        </div>
      </section>
    </>
  );
};

export default FavoriteRecipies;
