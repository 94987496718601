import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Images from "../../../utilities/images";
import { useAuthSelector } from "../../../redux/selector/auth";
import { useDispatch } from "react-redux";
import {
  checkCurrentEvent,
  joinliveStream,
  loginUserProfile,
  notificationsList,
  postOneSignalSubscriptionId,
  totalCountNotification,
  openSideMenu,
} from "../../../redux/slices/user";
import { toast } from "react-toastify";
import { logout } from "../../../redux/slices/auth";
import { useUserSelector } from "../../../redux/selector/user";
// import OneSignal from "react-onesignal";
import { socketInstance } from "./socketNew";
import { restAllData } from "../../../redux/slices/commanAction";
import useMediaQuery from "@mui/material/useMediaQuery";

//Firebase
import { getToken, onMessage } from "firebase/messaging";
import { messaging } from "../../../utilities/firebase";
import PushMessage from "../common/PushMessage";

const MainNav = () => {
  var token = localStorage.getItem("authToken");
  const navigate = useNavigate();
  const userData = useUserSelector();
  const authSelector = useAuthSelector();
  const isLoggedIn = authSelector.isLoggedIn;
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("");
  const [roomIdFromLiveStream, setRoomIdFromLiveStream] = useState("");
  const [isShowMenuBar, setShowMenuBar] = useState(false);
  const [recipeLikeCount, setRecipeLikeCount] = useState("");
  const [classLikeCount, setClassLikeCount] = useState("");
  const location = useLocation();
  const authUserSelector = useUserSelector();
  const [isSidebarOpen, setIsSidebarOpen] = useState(
    authUserSelector?.isOpenSidebar
  );

  const notificationData = authUserSelector?.notificationsList?.data?.list;
  const checkLiveEvent = authUserSelector?.checkCurrentEvent?.data;
  const loggedInUser = authUserSelector?.loginUserProfile?.data;
  const [getCurrentRoomId, setCurrentRoomId] = useState("");
  const [notificationCount, setTotalNotificationCount] = useState("");
  const matches = useMediaQuery("(max-width:991px)");

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(
      logout({
        cb(res) {
          if (res?.data) {
            navigate("/");
            dispatch(restAllData());
          }
        },
      })
    );
  };
  const handleShowfavList = () => {
    getUser();
    navigate("/favRecipies");
    setShowMenuBar(true);
  };
  const handleCallNotificationCount = () => {
    getNotificationList();
  };
  const getUser = () => {
    dispatch(
      loginUserProfile({
        cb(res) {
          if (res) {
            setRecipeLikeCount(res?.data?.data?.total_favourite);
            setClassLikeCount(res?.data?.data?.total_classes_favourite);
          }
        },
      })
    );
  };

  const getNotificationCount = () => {
    dispatch(
      totalCountNotification({
        cb(res) {
          if (res) {
            setTotalNotificationCount(res?.data?.data?.total);
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };
  function countUnreadNotifications(notificationData) {
    const unreadCount = notificationData?.reduce((count, notification) => {
      if (!notification.is_read) {
        return count + 1;
      }
      return count;
    }, 0);
    return unreadCount;
  }
  const unreadCount = countUnreadNotifications(notificationData);

  useEffect(() => {
    getUser();
    getNotificationCount();
    setActiveTab(location?.pathname);
  }, []);

  // useEffect(() => {
  //   if (OneSignal && token) {
  //     setTimeout(() => {
  //       if (OneSignal.User.PushSubscription.id === undefined) {
  //         localStorage.removeItem("onesignal-notification-prompt");
  //         OneSignal.Slidedown.promptPush();
  //       } else {
  //         if (
  //           OneSignal.User.PushSubscription.id !==
  //           loggedInUser.onesignal_subscription_id
  //         ) {
  //           updateSubscriptionID(OneSignal.User.PushSubscription.id);
  //         }
  //       }
  //     }, 5000);
  //   }
  // }, [OneSignal, loggedInUser]);

  // if (OneSignal) {
  //   OneSignal.Slidedown.addEventListener("change", (e) => {
  //     if (e.current.optedIn == true && e.current.id) {
  //       if (e.current.id != loggedInUser?.onesignal_subscription_id) {
  //         updateSubscriptionID(OneSignal?.User?.PushSubscription.id);
  //       }
  //     }
  //   });
  // }

  const updateSubscriptionID = (id) => {
    let params = {
      onesignal_subscription_id: id,
    };
    dispatch(
      postOneSignalSubscriptionId({
        ...params,
        cb(res) { },
      })
    );
  };
  useEffect(() => {
    if (socketInstance && token) {
      socketInstance.on("connection", (response) => { });
      socketInstance.on("liveStreaming", (data) => {
        setRoomIdFromLiveStream(data?.data?.room_Id);
        if (data?.data?.room_Id) {
          currentLiveEvent();
        }
      });
      socketInstance.on("endliveStreaming", (data) => {
        if (data) {
          currentLiveEvent();
        }
      });
    }
  }, [socketInstance, token]);

  useEffect(() => {
    if (checkLiveEvent?.id) {
      currentLiveEvent();
    }
  }, [checkLiveEvent?.id]);

  const currentLiveEvent = () => {
    dispatch(
      checkCurrentEvent({
        cb(res) {
          if (res) {
            setCurrentRoomId(res?.data?.data?.id);
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };
  // useEffect(() => {
  //   if (isFavorite !== undefined) {
  //     getUser();
  //   }
  // }, [isFavorite]);

  useEffect(() => {
    currentLiveEvent();
    dispatch(openSideMenu(false));
  }, []);

  const getNotificationList = () => {
    dispatch(
      notificationsList({
        cb(res) { },
      })
    );
  };
  const handleJoinLiveStream = () => {
    dispatch(
      joinliveStream({
        cb(res) {
          if (res?.data) {
            socketInstance.emit("join_livestreaming", {
              room_id: getCurrentRoomId
                ? getCurrentRoomId
                : roomIdFromLiveStream,
            });

            socketInstance.on("join_livestreaming", (response) => { });

            navigate("/joinStreaming", {
              state: {
                tokenId: res?.data?.data?.token,
                roomID: getCurrentRoomId
                  ? getCurrentRoomId
                  : roomIdFromLiveStream,
                appId: res?.data?.data?.appId,
                channel: res?.data?.data?.channelName,
                uid: res?.data?.data?.uid,
              },
            });
            window.location.reload();
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };
  const closeSidebar = () => {
    dispatch(openSideMenu(false));
  };
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  useEffect(() => {
    dispatch(openSideMenu(isSidebarOpen));
  }, [isSidebarOpen]);

  useEffect(() => {
    if (userData?.success) {
      getUser();
    }
  }, [userData?.success]);


  //Firebase
  async function requestPermission() {
    //requesting permission using Notification API
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      const token = await getToken(messaging, {
        vapidKey: "BNUbH9c7i9EQ9MfCcbGc0ePbsjhAh0ySFJ_u9xyPNNvWXDdphZhoXWjDeuin7tP2Nzi7tx6lN9i7npzZd22WzLg",
      });

      //We can send token to server
      // console.log("Token generated : ", token);
      updateSubscriptionID(token)
    } else if (permission === "denied") {
      //notifications are blocked
      // alert("You denied for the notification");
    }
  }

  useEffect(() => {
    requestPermission();
  }, []);

  onMessage(messaging, (payload) => {
    toast(<PushMessage notification={payload.notification} />);
  });

  return (
    <>
      <nav className="navbar navbar-expand-lg header">
        <div className="container-xxl container-fluid px-md-4">
          {location.pathname != "/PaymentMethod" &&
            location.pathname != "/AddCard" &&
            location.pathname != "/notification" &&
            location.pathname != "/viewEdit" &&
            location.pathname != "/changePassword" &&
            location.pathname != "/planSubscription" &&
            location.pathname != "/updateProfile" &&
            location.pathname != "/liveStreaming" &&
            location.pathname != "/liveStreamingUser" &&
            location.pathname != "/user-support-chat" &&
            location.pathname != "/joinStreaming" && (
              <>
                <Link className="navbar-brand" to="/">
                  <img src={Images.logo} alt="" />
                </Link>

                <div className="rightlive d-flex">
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span
                      className="navbar-toggler-icon"
                      onClick={() => {
                        toggleSidebar();
                        setShowMenuBar(false);
                      }}
                    >
                      <i className="fas fa-bars"></i>
                    </span>
                  </button>
                </div>
              </>
            )}

          <div
            className={
              isShowMenuBar && matches ? "d-none" : "collapse navbar-collapse"
            }
            id="navbarSupportedContent"
          >
            {location.pathname != "/PaymentMethod" &&
              location.pathname != "/AddCard" &&
              location.pathname != "/notification" &&
              location.pathname != "/viewEdit" &&
              location.pathname != "/changePassword" &&
              location.pathname != "/planSubscription" &&
              location.pathname != "/updateProfile" &&
              location.pathname != "/liveStreaming" &&
              location.pathname != "/liveStreamingUser" &&
              location.pathname != "/user-support-chat" &&
              location.pathname != "/joinStreaming" && (
                <ul className="navbar-nav mb-2 mb-lg-0 ms-auto align-items-baseline">
                  <li
                    className={
                      activeTab == "/" ? "nav_link active" : "nav_link"
                    }
                  >
                    <Link
                      onClick={() => {
                        setActiveTab("/");
                        closeSidebar();
                        setShowMenuBar(true);
                      }}
                      to="/"
                    >
                      Home
                    </Link>
                  </li>
                  <li
                    className={
                      activeTab == "/recipes" ? "nav_link active" : "nav_link"
                    }
                  >
                    <Link
                      onClick={() => {
                        setActiveTab("/recipes");
                        closeSidebar();
                        setShowMenuBar(true);
                      }}
                      to="/recipes"
                    >
                      Recipe
                    </Link>
                  </li>
                  {token && isLoggedIn && (
                    <>
                      <li
                        className={
                          activeTab == "/classespage"
                            ? "nav_link active"
                            : "nav_link"
                        }
                      >
                        <Link
                          onClick={() => {
                            setActiveTab("/classespage");
                            closeSidebar();
                            setShowMenuBar(true);
                          }}
                          to="/classespage"
                          id="navbarDropdown"
                        >
                          Classes
                        </Link>
                      </li>
                      <li
                        className={
                          activeTab == "/addsubscription"
                            ? "nav_link active"
                            : "nav_link"
                        }
                      >
                        <Link
                          onClick={() => {
                            setActiveTab("/addsubscription");
                            closeSidebar();
                            setShowMenuBar(true);
                          }}
                          to="/addsubscription"
                        >
                          Subscription
                        </Link>
                      </li>
                    </>
                  )}

                  <li
                    className={
                      activeTab == "/aboutus" ? "nav_link active" : "nav_link"
                    }
                  >
                    <Link
                      onClick={() => {
                        setActiveTab("/aboutus");
                        closeSidebar();
                        setShowMenuBar(true);
                      }}
                      to="/aboutus"
                    >
                      About
                    </Link>
                  </li>
                  <li
                    className={
                      activeTab == "/contactus"
                        ? "nav_link active"
                        : "nav_link me-3"
                    }
                  >
                    <Link
                      onClick={() => {
                        setActiveTab("/contactus");
                        closeSidebar();
                        setShowMenuBar(true);
                      }}
                      to="/contactus"
                    >
                      Contact
                    </Link>
                  </li>
                  <li className="desktopLive" onClick={handleJoinLiveStream}>
                    {checkLiveEvent?.status == 1 ? (
                      <div class="circle" onClick={handleJoinLiveStream}>
                        <p class="livetxt">Live</p>
                      </div>
                    ) : (
                      ""
                    )}
                  </li>

                  {!token ? (
                    <li className="p-0 buttonOuter">
                      <Link to="/signup">
                        <button className="header-btn signUp" type="submit">
                          Signup
                        </button>
                      </Link>
                      <Link to="/login">
                        <button className="header-btn login" type="submit">
                          Login
                        </button>
                      </Link>
                    </li>
                  ) : (
                    <li className="d-flex align-items-start profileBtnOuter">
                      <div className="favouriteIcon">
                        <div className="countOuter">
                          <span onClick={(e) => handleShowfavList(e)}>
                            {classLikeCount + recipeLikeCount}
                          </span>
                        </div>
                        <span>
                          <img
                            src={Images.pinkheart}
                            alt=""
                            onClick={(e) => handleShowfavList(e)}
                          />
                        </span>
                      </div>
                      <div
                        className="dropdown"
                        onClick={handleCallNotificationCount}
                      >
                        <button
                          className="dropdown-toggle profileBtn border-0"
                          id="dropdownMenuLink"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img
                            className="userImgNav"
                            src={
                              loggedInUser?.profile_image
                                ? loggedInUser?.profile_image
                                : Images.defaultUser
                            }
                            alt=""
                          />
                          {loggedInUser?.first_name}
                        </button>

                        <ul className="dropdown-menu ProfileDropdown">
                          <div className="d-flex dropdownInner">
                            <span>
                              <img
                                className="img-fluid userImgNav"
                                alt=""
                                src={
                                  loggedInUser?.profile_image
                                    ? loggedInUser?.profile_image
                                    : Images.defaultUser
                                }
                              />
                            </span>
                            <div className="userprofileOut">
                              <p className="bigText m-0 p-0">
                                {loggedInUser?.first_name}
                              </p>
                              <p className="text m-0 p-0 emailText">
                                {loggedInUser?.email}
                              </p>{" "}
                              <Link
                                to="/viewEdit"
                                className="subtext colorPink fontBold"
                              >
                                View & Edit Profile
                                <img
                                  className="signup"
                                  src={Images.signuparrow}
                                  alt=""
                                />
                              </Link>
                            </div>
                          </div>
                          <li>
                            <img src={Images.Notification} alt="" />
                            <Link
                              className="dropdown-item text"
                              to="/notification"
                            >
                              Notifications
                            </Link>
                            <span className="notificationBox">
                              {/* {notificationCount
                                ? notificationCount
                                : loggedInUser?.total_notification} */}
                              {unreadCount}
                            </span>
                          </li>
                          <li>
                            <img src={Images.Password} alt="" />
                            <Link
                              className="dropdown-item text"
                              to="/changePassword"
                            >
                              Change Password
                            </Link>
                          </li>
                          <li>
                            <img src={Images.Wallet} alt="" />
                            <Link
                              className="dropdown-item text"
                              to="/planSubscription"
                            >
                              Plan & Subscription
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item text ms-0"
                              to="/PaymentMethod"
                            >
                              <img
                                className="me-3"
                                src={Images.creditCard}
                                alt=""
                              />
                              Payment Method
                            </Link>
                          </li>

                          <li>
                            <Link
                              className="dropdown-item text ms-0"
                              // to="/user-support-chat"
                              onClick={() => {
                                window.location.href = "/user-support-chat";
                              }}
                            >
                              <img
                                className="me-3"
                                src={Images.supportChatLogo}
                                alt=""
                              />
                              Support Chat
                            </Link>
                          </li>

                          <li
                            className="dropdown-item text"
                            onClick={(e) => handleLogout(e)}
                          >
                            <img className="me-3" src={Images.Login} alt="" />
                            Logout
                          </li>
                        </ul>
                      </div>
                    </li>
                  )}
                </ul>
              )}
          </div>
        </div>
      </nav>
    </>
  );
};

export default MainNav;
