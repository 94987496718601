import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { generateTokenForStreaming } from "../../../../redux/slices/user";
import { Spinner } from "react-bootstrap";

const ConfirmLiveStream = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleStartStreaming = () => {
    let params = {
      class_id: props.classid,
    };
    setLoading(true);
    dispatch(
      generateTokenForStreaming({
        ...params,
        cb(res) {
          if (res?.data) {
            navigate(`/admin/liveStreaming/${props?.classid}`, {
              state: {
                tokenId: res?.data?.data?.token,
                roomID: res?.data?.data?.roomID,
                appId: res?.data?.data?.appId,
                channel: res?.data?.data?.channelName,
                uid: res?.data?.data?.uid,
                classId: props?.classid,
              },
            });
            setLoading(false);
            window.location.reload()
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };
  return (
    <div className="py-3">
      <h5 className="font-28 text-center">
        Are you Sure you want to start class before added time?
      </h5>
      <p className="text text-center py-3">
        You are about to start the live class. You won't be able to undo this.
      </p>
      <div className="row justify-content-center mt-4">
        <div className="d-grid gap-1 col-lg-3 col-md-6">
          <button
            className="primaryBtn submitBtn m-0"
            type="button"
            onClick={(e) => handleStartStreaming(e)}
          >
            <span>
              Yes, Start
              {loading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  className="ms-1"
                  aria-hidden="true"
                />
              )}{" "}
            </span>
          </button>
        </div>
        <div className="d-grid gap-1 col-lg-3 col-md-6">
          <button
            className="primaryBtn cancelBtn m-0"
            onClick={(e) => props.onCloseModal()}
          >
            <span>No, Cancel</span>
          </button>
        </div>
      </div>
    </div>
  );
};
export default ConfirmLiveStream;
