import React, { useEffect, useState } from "react";
import * as Images from "../../../utilities/images";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import { socketInstance } from "../../components/common/socketNew";
import { showLiveComments } from "../../../redux/slices/user";
import { useDispatch } from "react-redux";
import { useAuthSelector } from "../../../redux/selector/auth";
import AgoraRTC from "agora-rtc-sdk-ng";
import { toast } from "react-toastify";

const LiveStreamingUser = () => {
  const toastId = React.useRef(null);
  const authSelector = useAuthSelector();
  const userId = authSelector?.userLogin?.data?.user;
  const location = useLocation();
  const dispatch = useDispatch();
  const [rate, setRate] = useState(1);
  const [totalViews, setTotalViews] = useState("");
  const [totalRating, setTotalRating] = useState("");
  const [addComment, setAddComment] = useState("");
  const [showRatingSection, setShowRatingSection] = useState(true);
  const [showCommentsSection, setshowCommentsSection] = useState(false);
  const [liveCommentsList, setLiveCommentsList] = useState([]);
  const navigate = useNavigate();
  const [uidId, setUidId] = useState("");
  const [appId, setAppId] = useState("");
  const [channelName, setChannelName] = useState("");
  const [tokenId, setToeknId] = useState("");
  const [roomID, setRoomID] = useState("");
  const [classId, setclassId] = useState("");

  const ratingChanged = (value) => {
    setRate(value);
  };

  useEffect(() => {
    if (location?.state?.uid) {
      setUidId(location?.state?.uid);
      setAppId(location?.state?.appId);
      setChannelName(location?.state?.channel);
      setToeknId(location?.state?.tokenId);
      setRoomID(location?.state?.roomID);
      setclassId(location?.state?.classId);
      localStorage.setItem("liveStream", JSON.stringify(location.state));
    } else if (localStorage.getItem("liveStream")) {
      let location = localStorage.getItem("liveStream");
      location = JSON.parse(location);
      setUidId(location?.uid);
      setAppId(location?.appId);
      setChannelName(location?.channel);
      setToeknId(location?.tokenId);
      setRoomID(location?.roomID);
      setclassId(location?.classId);
    }
    return () => {
      socketInstance?.disconnect();
      // console.log("disconnect");
    };
  }, []);


  useEffect(() => {
    if (socketInstance && roomID) {
      socketInstance.connect()
      socketInstance.on("connection", (response) => {
        // console.log("Connected", response);
      });
      socketInstance.emit("join_class", { uidId });
      socketInstance.on("join_class", (response) => {
        // console.log("join_class", response);
      });
      socketInstance.on("liveClass", (data) => {
        // console.log(data, "liveClass");
      });
      socketInstance.on("sync_data", (response) => {
        // console.log("sync_data", response);
        setTotalViews(response.totalUsers);
        setTotalRating(response.totalrating);
      });
      socketInstance.on("add_comment", (data) => {
        // console.log("addCommentss", data);
        getAllLiveComments(roomID);
      });
      getAllLiveComments(roomID);
    }
  }, [roomID]);

  const handleAddRating = () => {
    // console.log("ratingadataaa");
    socketInstance.emit("live_rating", {
      class_id: classId,
      rating: rate,
    });
    // console.log("jj");
    socketInstance.on("live_rating", (data) => {
      // console.log("klkl");

      // console.log(data, "live_rating");
      setShowRatingSection(false);
      localStorage.setItem("rating", classId);
    });

    socketInstance.on("sync_data", (response) => {
      // console.log("sync_data", response);
      setTotalViews(response.totalUsers);
      setTotalRating(response.totalrating);
    });
  };
  const handleShowCommentBoxes = () => {
    setshowCommentsSection(true);
  };
  const handleCross = () => {
    setshowCommentsSection(false);
    setAddComment(null);
  };
  const handleSendComments = (event) => {
    // console.log("handlecommentsend");
    let addComment = event.target[0].value;
    // console.log(addComment, "addcomment");
    event.preventDefault();
    if (!addComment) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please add comment!");
        return false;
      }
    } else {
      // console.log("wo");
      socketInstance.emit("add_comment", {
        room_id: roomID,
        comment: addComment,
      });
      // console.log("wo2");
      socketInstance.on("add_comment", (data) => {
        // console.log("helloname");
        if (data.statusCode === 200) {
          // console.log("addCommentss", data);
          setAddComment("");
          event.target.reset();
          setLiveCommentsList((prevData) => {
            const isIdPresent = prevData.some(
              (item) => item?.id === data?.data?.id
            );
            if (!isIdPresent) {
              return [...prevData, data.data];
            } else {
              return prevData; // Return the previous data without any changes
            }
          });
        } else {
          if (!toast.isActive(toastId.current)) {
            toastId.current = toast.error("Comment is too long");
            return false;
          }
        }
      });
      // console.log("wo3");
      socketInstance.on("sync_data", (response) => {
        // console.log("sync_data", response);
        setTotalViews(response.totalUsers);
        setTotalRating(response.totalrating);
      });
    }
  };
  const getAllLiveComments = (roomID) => {
    let params = {
      room_id: roomID,
    };
    dispatch(
      showLiveComments({
        ...params,
        cb(res) {
          if (res?.data) {
            // console.log(res, "live comments");
            const sortedData = [...res?.data?.data?.commentlist].sort(
              (a, b) => new Date(a?.updatedAt) - new Date(b?.updatedAt)
            );
            setLiveCommentsList(sortedData);
          } else {
          }
        },
      })
    );
  };

  if (socketInstance) {
    socketInstance.on("end_for_all", (response) => {
      navigate("/classespage");
    });
  }

  useEffect(() => {
    window.onpopstate = () => {
      handleEndCall();
    };
  });

  const handleEndCall = async () => {
    //  channelParameters.localAudioTrack.close();
    //   channelParameters.localVideoTrack.close();
    await agoraEngine.leave();
    socketInstance.emit("leftend_for_all_class", {});
    socketInstance.on("end_for_all", (response) => {
      // console.log("end_for_all", response);
    });
    navigate("/classespage");
    window.location.reload()
  };

  const options = {
    appId: appId,
    channel: channelName,
    token: tokenId,
    uid: uidId,
    role: "audience",
  };

  const channelParameters = {
    localAudioTrack: null,
    localVideoTrack: null,
    remoteAudioTrack: null,
    remoteVideoTrack: null,
    remoteUid: null,
  };

  const agoraEngine = AgoraRTC.createClient({ mode: "live", codec: "vp9" });
  // console.log(agoraEngine);
  AgoraRTC.setLogLevel(3);

  const newStartVideoProcess = async () => {
    // console.log("newStartVideoProcess");
    await agoraEngine.join(
      options.appId,
      options.channel,
      options.token,
      options.uid
    );

    channelParameters.localAudioTrack =
      await AgoraRTC.createMicrophoneAudioTrack();
    channelParameters.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
  };

  agoraEngine.on("user-published", async (user, mediaType) => {
    // console.log(user, mediaType, "mediiiiiiiiiii");
    const wrapper = document.getElementById("remoteVideoPlayer");
    const remotePlayerContainer = document.createElement("div");
    await agoraEngine.subscribe(user, mediaType);
    // console.log("subscribe success");
    if (mediaType === "video") {
      channelParameters.remoteVideoTrack = user.videoTrack;
      channelParameters.remoteAudioTrack = user.audioTrack;
      channelParameters.remoteUid = user.uid.toString();
      remotePlayerContainer.id = user.uid.toString();
      wrapper.append(remotePlayerContainer);

      if (options.role !== "host") {
        channelParameters.remoteVideoTrack.play(remotePlayerContainer);
      }
    }

    if (mediaType === "audio") {
      channelParameters.remoteAudioTrack = user.audioTrack;
      channelParameters.remoteAudioTrack.play();
    }
  });

  useEffect(() => {
    if (agoraEngine) {
      newStartVideoProcess();
    }
    return async () => {
      // await agoraEngine.leave();
      // console.log("You left the channel");
    };
  }, [agoraEngine]);

  useEffect(() => {
    if (localStorage.getItem("rating") == classId) {
      setShowRatingSection(false);
    }
  }, [classId]);
  return (
    <>
      <>
        <section className="liveStreaming">
          <div className="container-fluid px-0">
            <div className="livestreamingOuter">
              <div id="remoteVideoPlayer"></div>
              <div
                className="livevedio"
                style={{ display: "flex", width: "100vw", height: "100vh" }}
              >
                <div className="App">
                  <div className="flexRow">
                    <div className="d-flex justify-content-between align-items-center">
                      {/* <Link to="/classespage"> */}
                      <button
                        onClick={(e) => handleEndCall(e)}
                        className="button mb-0"
                      >
                        <span className="youtubeIcon">
                          {" "}
                          <img src={Images.backarrowwhite} alt="" />
                        </span>
                        Go Back{" "}
                      </button>
                      {/* </Link> */}
                      <div className="d-flex align-items-center topboxOuter">
                        <div className="d-flex align-items-center">
                          <div className="subtextOuter">
                            <p className="subtext mb-0">
                              <span className="me-2">
                                <img
                                  className="yellowStar"
                                  src={Images.yellowstar}
                                  alt=""
                                />
                              </span>
                              {totalRating}
                            </p>
                          </div>
                          <div className="subtextOuter">
                            <p className="subtext mb-0">
                              <span className="me-2">
                                <img src={Images.pinkeye} alt="" />
                              </span>
                              {totalViews}
                            </p>
                          </div>
                        </div>
                        <div className="ms-2">
                          <button className="button mb-0">
                            <span className="youtubeIcon">
                              {" "}
                              <img src={Images.youtube} alt="" />
                            </span>
                            <span>
                              <img src={Images.reddot} alt="" />{" "}
                            </span>
                            Live recipes{" "}
                          </button>
                        </div>
                      </div>
                    </div>

                    {showCommentsSection && (
                      <div className="col-md-4 p-0">
                        <div className="msgBox">
                          <div className="msgBoxHead">
                            <div className="d-flex align-items-center">
                              <div className="msg_box">
                                <img
                                  className="w-100"
                                  src={Images.msgbox}
                                  alt=""
                                />
                              </div>
                              <span className="colorWhite font-26">
                                Messages
                              </span>
                            </div>
                            <div className="">
                              <img
                                className="img-fluid"
                                src={Images.crossCircle}
                                alt=""
                                onClick={(e) => handleCross(e)}
                              />
                            </div>
                          </div>

                          <div className="pt-4 px-5 pb-4 chat_Box">
                            {liveCommentsList?.map((data, index) => {
                              return (
                                <>
                                  {userId?.id == data?.created_by?.id ? (
                                    <div
                                      className="chatBoxOuter border-26 replyChatBox"
                                      key={index}
                                    >
                                      <div className="chatBoxInner chatboxReply">
                                        <span>
                                          <img
                                            className="user"
                                            src={
                                              data?.created_by?.profile_image
                                            }
                                            alt=""
                                          />
                                        </span>
                                        <div className="me-3">
                                          <p className="colorWhite subtext mb-0">
                                            {data?.created_by?.full_name}
                                          </p>
                                          <p className="colorWhite subtext mb-0 textLiveStreaming">
                                            {data?.comment}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="chatBoxOuter border-26">
                                      <div className="chatBoxInner">
                                        <div className="d-flex">
                                          <span>
                                            <img
                                              className="user"
                                              src={
                                                data?.created_by?.profile_image
                                                  ? data?.created_by
                                                    ?.profile_image
                                                  : Images.userimg
                                              }
                                              alt=""
                                            />
                                          </span>
                                          <div className="ms-3">
                                            <p className="colorWhite subtext mb-0">
                                              {data?.created_by?.full_name}
                                            </p>
                                            <p className="colorWhite subtext mb-0 textLiveStreaming">
                                              {data?.comment}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </>
                              );
                            })}
                          </div>

                          <div className="py-3 px-3  chatInputbox chatInputboxResp">
                            <form
                              onSubmit={(event) => handleSendComments(event)}
                            >
                              <input
                                className="text"
                                type="text"
                                placeholder="Type here..."
                              />

                              <button className="send-live-msg" type="submit">
                                <img src={Images.adminsend} alt="" />
                              </button>
                            </form>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="d-flex align-items-center justify-content-between mb-4">
                      <div className="d-flex">
                        {showRatingSection && (
                          <div className="ratingbox">
                            <div className="ratingboxInner">
                              <div className="d-flex">
                                <div>
                                  <ReactStars
                                    count={5}
                                    onChange={ratingChanged}
                                    size={24}
                                    value={rate}
                                    activeColor="#ffd700"
                                  />
                                </div>
                              </div>
                              <div className="ms-3 me-3">
                                <p className="text mb-0 colorWhite">
                                  Rate the Class
                                </p>
                                {/* <p className="text mb-0 colorWhite">& Comment</p> */}
                              </div>
                              <div className="">
                                <img
                                  src={Images.rotatearrowWhite}
                                  alt=""
                                  onClick={(e) => handleAddRating(e)}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="Buttons endcallouter">
                        <button
                          className="endCallButton login-btn bg-danger"
                          type="button"
                          id="leave"
                          onClick={(e) => handleEndCall(e)}
                        >
                          <img
                            className="muteIcon"
                            src={Images.endcall}
                            alt=""
                          />
                          Leave
                        </button>
                      </div>

                      <div className="mszBox">
                        <img
                          className="w-100"
                          src={Images.msgbox}
                          alt=""
                          onClick={(e) => handleShowCommentBoxes(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </>
  );
};

export default LiveStreamingUser;
