import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../components/admin/sidebar";
import Navbar from "../components/admin/navbar";
import { useAuthSelector } from "../../redux/selector/auth";
import { useUserSelector } from "../../redux/selector/user";

const AdminInnerLayout = () => {
  const navigate = useNavigate();
  const authSelector = useAuthSelector();
  var token = localStorage.getItem("authToken");
  const isLoggedIn = authSelector.isLoggedIn;
  const authUserSelector = useUserSelector();
  const ValidLoggedIn = authUserSelector?.loginUserProfile;
  useEffect(() => {
    if (ValidLoggedIn && ValidLoggedIn?.role === 1) {
      navigate("/");
    }
  }, [ValidLoggedIn?.role]);

  return (
    <>
      <main className="mainAdmin" id="main">
        {token && isLoggedIn ? (
          <>
            {window.location.pathname.includes("/liveStreaming") ||
            window.location.pathname.includes("/goLive") ? null : (
              <Sidebar />
            )}

            <div
              className={`commonBody ${
                window.location.pathname.includes("/liveStreaming")
                  ? "streamLiveBody"
                  : window.location.pathname.includes("/goLive")
                  ? "streamLiveBody"
                  : ""
              }`}
            >
              {window.location.pathname.includes("/liveStreaming") ||
              window.location.pathname.includes("/goLive") ? null : (
                <Navbar />
              )}
              <Outlet />
            </div>
          </>
        ) : (
          <Navigate to="/"></Navigate>
        )}
      </main>
    </>
  );
};

export default AdminInnerLayout;
