import { createSlice } from "@reduxjs/toolkit";
import { restAllData } from "../commanAction";

const initialState = {
  isLoggedIn: false,
  loading: false,
  getAllUserData: {},
  adminUser: {},
  userLogin: {},
  favRecipe: {},
  favRecipeList: {},
  favClassList: {},
  loginUserProfile: {},
  generateTokenForStreaming: {},
  checkliveStream: {},
  checkCurrentEvent: {},
  notificationsList: {},
  isOpenSidebar: false,
  success: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  extraReducers: (builder) => builder.addCase(restAllData, () => initialState),
  reducers: {
    openSideMenu: (state, action) => {
      state.isOpenSidebar = action.payload;
    },
    getAllUser: (state) => {
      state.loading = true;
    },
    setAllUser: (state, action) => {
      state.loading = false;
      state.getAllUserData = action.payload;
    },

    updateProfile: (state) => {
      state.loading = true;
    },
    setUpdateprofile: (state, action) => {
      state.loading = false;
      state.userLogin = action.payload;
      state.adminUser = action.payload;
    },
    uploadPicture: (state) => {
      state.loading = true;
    },
    setUploadPicture: (state, action) => {
      state.loading = false;
    },
    getUserById: (state) => {
      state.loading = true;
    },

    setGetUserById: (state, action) => {
      state.loading = false;
    },
    userStatus: (state) => {
      state.loading = true;
    },

    setUserStatus: (state, action) => {
      state.loading = false;
    },
    removePicture: (state) => {
      state.loading = true;
    },

    setRemovePicture: (state, action) => {
      state.loading = false;
    },
    deleteUserByadmin: (state) => {
      state.loading = true;
    },

    setdeleteUserByadmin: (state, action) => {
      state.loading = false;
      state.deleteUserByadmin = action.payload;
    },
    dashboardUser: (state) => {
      state.loading = true;
    },

    setDashboardUser: (state, action) => {
      state.loading = false;
    },
    trendingRecipes: (state) => {
      state.loading = true;
    },
    setTrendingRecipes: (state, action) => {
      state.loading = false;
      state.trendingRecipes = action.payload;
    },
    loginUserProfile: (state) => {
      state.loading = true;
    },
    setLoginUserProfile: (state, action) => {
      state.loading = false;
      state.loginUserProfile = action.payload;
    },
    createLike: (state) => {
      state.loading = true;
    },
    setCreateLike: (state, action) => {
      state.loading = false;
      state.createLike = action.payload;
    },
    createClassLike: (state) => {
      state.loading = true;
    },
    setCreateClassLike: (state, action) => {
      state.loading = false;
      state.createLike = action.payload;
    },
    addRating: (state) => {
      state.loading = true;
    },
    setAddRating: (state, action) => {
      state.loading = false;
      state.addRating = action.payload;
    },
    getAllreviews: (state) => {
      state.loading = true;
    },
    setAllreviews: (state, action) => {
      state.loading = false;
      state.getAllreviews = action.payload;
    },
    getAllreviewClass: (state) => {
      state.loading = true;
    },
    setAllreviewClass: (state, action) => {
      state.loading = false;
      state.getAllreviewClass = action.payload;
    },
    deleteComment: (state) => {
      state.loading = true;
    },
    setDeleteComment: (state) => {
      state.loading = false;
    },
    replyComment: (state) => {
      state.loading = true;
    },
    setReplyComment: (state, action) => {
      state.loading = false;
      state.replyComment = action.payload;
    },
    favRecipe: (state) => {
      state.loading = true;
      state.success = false;
    },
    setFavRecipe: (state, action) => {
      state.loading = false;
      state.favRecipe = action.payload;
      state.success = true;
    },
    favClass: (state) => {
      state.loading = true;
      state.success = false;
    },
    setFavClass: (state, action) => {
      state.loading = false;
      state.favClass = action.payload;
      state.success = true;
    },
    reportComment: (state) => {
      state.loading = true;
    },
    setReportComment: (state, action) => {
      state.loading = false;
      state.reportComment = action.payload;
    },
    suggestion: (state) => {
      state.loading = true;
    },
    setSuggestion: (state, action) => {
      state.loading = false;
      state.suggestion = action.payload;
    },
    favRecipeList: (state) => {
      state.loading = true;
    },
    setFavRecipeList: (state, action) => {
      state.loading = false;
      state.favRecipeList = action.payload;
    },
    favClassList: (state) => {
      state.loading = true;
    },
    setFavClassList: (state, action) => {
      state.loading = false;
      state.favClassList = action.payload;
    },
    createSubscription: (state) => {
      state.loading = true;
      state.success = false;
    },
    setCreateSubscription: (state, action) => {
      state.loading = false;
      state.createSubscription = action.payload;
      state.success = true;
    },
    cancelSubscription: (state) => {
      state.loading = true;
    },
    setCancelSubscription: (state, action) => {
      state.loading = false;
      state.cancelSubscription = action.payload;
    },
    updateSubscription: (state) => {
      state.loading = true;
    },
    setUpdateSubscription: (state, action) => {
      state.loading = false;
      state.updateSubscription = action.payload;
    },
    getCards: (state) => {
      state.loading = true;
    },
    setGetCardsList: (state, action) => {
      state.loading = false;
      state.getCards = action.payload;
    },
    addCards: (state) => {
      state.loading = true;
    },
    setAddCards: (state, action) => {
      state.loading = false;
      state.addCards = action.payload;
    },
    deleteCards: (state) => {
      state.loading = true;
    },
    setDeleteCards: (state, action) => {
      state.loading = false;
      state.deleteCards = action.payload;
    },
    defaultCard: (state) => {
      state.loading = true;
    },
    setDefaultCard: (state, action) => {
      state.loading = false;
      state.defaultCard = action.payload;
    },
    addBooking: (state) => {
      state.loading = true;
    },
    setAddBooking: (state, action) => {
      state.loading = false;
      state.addBooking = action.payload;
    },
    getBooking: (state) => {
      state.loading = true;
    },
    setBooking: (state, action) => {
      state.loading = false;
      state.getBooking = action.payload;
    },
    cancelBooking: (state) => {
      state.loading = true;
    },
    setCancelBooking: (state, action) => {
      state.loading = false;
      state.cancelBooking = action.payload;
    },
    generateTokenForStreaming: (state) => {
      state.loading = true;
    },
    setGenerateTokenForStreaming: (state, action) => {
      state.loading = false;
      state.generateTokenForStreaming = action.payload;
    },
    showLiveComments: (state) => {
      state.loading = true;
    },
    setShowLiveComments: (state, action) => {
      state.loading = false;
      state.showLiveComments = action.payload;
    },
    changePassword: (state) => {
      state.loading = true;
    },
    setUpdatePassword: (state, action) => {
      state.loading = false;
      state.updatePassword = action.payload;
    },
    contactUs: (state) => {
      state.loading = true;
    },
    setContactUs: (state, action) => {
      state.loading = false;
      state.contactUs = action.payload;
    },
    joinliveStream: (state) => {
      state.loading = true;
    },
    setJoinliveStream: (state, action) => {
      state.loading = false;
      state.joinliveStream = action.payload;
    },
    checkliveStream: (state) => {
      state.loading = true;
    },
    setCheckliveStream: (state, action) => {
      state.loading = false;
      state.checkliveStream = action.payload;
    },
    checkCurrentEvent: (state) => {
      state.loading = true;
    },
    setCheckCurrentEvent: (state, action) => {
      state.loading = false;
      state.checkCurrentEvent = action.payload;
    },
    postOneSignalSubscriptionId: (state) => {
      state.loading = true;
    },
    setPostOneSignalSubscriptionId: (state, action) => {
      state.loading = false;
      state.postOneSignalSubscriptionId = action.payload;
    },
    notificationsList: (state) => {
      state.loading = true;
    },
    setNotificationList: (state, action) => {
      state.loading = false;
      state.notificationsList = action.payload;
    },
    clerAllNotificationsList: (state) => {
      state.loading = true;
    },
    setClearAllNotificationList: (state, action) => {
      state.loading = false;
      state.clerAllNotificationsList = action.payload;
    },
    isNotify: (state) => {
      state.loading = true;
    },
    setIsNotify: (state, action) => {
      state.loading = false;
      state.isNotify = action.payload;
    },
    totalCountNotification: (state) => {
      state.loading = true;
    },
    setTotalCountNotification: (state, action) => {
      state.loading = false;
      state.totalCountNotification = action.payload;
    },
    isRead: (state) => {
      state.loading = true;
    },
    setIsRead: (state, action) => {
      state.loading = false;
      state.isRead = action.payload;
    },
    onErrorStopLoad: (state) => {
      state.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getAllUser,
  setAllUser,
  notificationsList,
  setNotificationList,
  isNotify,
  setIsNotify,
  isRead,
  setIsRead,
  totalCountNotification,
  setTotalCountNotification,
  clerAllNotificationsList,
  setClearAllNotificationList,
  updateProfile,
  setUpdateprofile,
  uploadPicture,
  setUploadPicture,
  getUserById,
  setGetUserById,
  userStatus,
  setUserStatus,
  removePicture,
  setRemovePicture,
  deleteUserByadmin,
  setdeleteUserByadmin,
  dashboardUser,
  setDashboardUser,
  loginUserProfile,
  setLoginUserProfile,
  createLike,
  setCreateLike,
  addRating,
  setAddRating,
  favClass,
  setFavClass,
  getAllreviews,
  setAllreviews,
  replyComment,
  setReplyComment,
  favRecipe,
  setFavRecipe,
  reportComment,
  setReportComment,
  suggestion,
  setSuggestion,
  favRecipeList,
  setFavRecipeList,
  createSubscription,
  setCreateSubscription,
  getCards,
  setGetCardsList,
  addCards,
  setAddCards,
  deleteCards,
  setDeleteCards,
  defaultCard,
  setDefaultCard,
  generateTokenForStreaming,
  setGenerateTokenForStreaming,
  trendingRecipes,
  setTrendingRecipes,
  createClassLike,
  setCreateClassLike,
  getAllreviewClass,
  setAllreviewClass,
  favClassList,
  setFavClassList,
  addBooking,
  setAddBooking,
  getBooking,
  setBooking,
  cancelBooking,
  setCancelBooking,
  showLiveComments,
  setShowLiveComments,
  changePassword,
  setUpdatePassword,
  contactUs,
  setContactUs,
  setRating,
  cancelSubscription,
  setCancelSubscription,
  updateSubscription,
  setUpdateSubscription,
  postOneSignalSubscriptionId,
  setPostOneSignalSubscriptionId,
  joinliveStream,
  setJoinliveStream,
  checkliveStream,
  setCheckliveStream,
  setCheckCurrentEvent,
  checkCurrentEvent,
  onErrorStopLoad,
  openSideMenu,
  deleteComment,
  setDeleteComment
} = userSlice.actions;

export default userSlice.reducer;
