import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useUserSelector } from "../../../../redux/selector/user";
import { getChatList } from "../../../../redux/slices/admin";
import * as Images from "../../../../utilities/images";
import { socketInstance } from "../../../components/common/socketNew";

const SupportChat = () => {
  document.title = "Support-Chat";
  const toastId = useRef(null);
  const chatRef = useRef(null);
  const [getChatsListUsers, setGetChatsUsersList] = useState([]);
  const [showMessgaesContentBox, setShowMessagesContentBox] = useState(false);
  const [chatList, setChatList] = useState([]);
  const [usersId, setUsersId] = useState(null);
  const [userName, setUserName] = useState("");
  const [userImage, setUserImage] = useState(null);
  const [userRoomId, setUserRoomId] = useState(null);
  const dispatch = useDispatch();
  const authUserSelector = useUserSelector();
  const userInfo = authUserSelector?.loginUserProfile?.data;

  const handleChatScroll = () => {
    if (chatRef?.current) {
      // chatRef?.current?.scrollIntoView({
      //   block: "end",
      //   inline: "end",
      //   behavior: "smooth",
      // });
    }
  };

  useEffect(() => {
    let params = {
      page: 1,
      limit: 10,
    };
    dispatch(
      getChatList({
        ...params,
        cb(res) {
          if (res?.data) {
            setGetChatsUsersList(res?.data?.data?.list);
          }
        },
      })
    );
  }, []);

  useEffect(() => {
    if (socketInstance) {
      socketInstance.on("connection", (response) => {});
      socketInstance.emit("join_chat", {
        to_id: usersId,
      });
      socketInstance.on("join_chat", (data) => {});
      socketInstance.emit("get_chat", {
        room_id: userRoomId,
        page: 1,
        limit: 1000,
      });
      socketInstance.on("get_chat", (data) => {
        setSortedChatData(data?.data?.list);
        handleChatScroll();
      });
    }
  }, []);

  const handleSendMessages = (e) => {
    let sendChat = e.target[0].value;
    e.preventDefault();
    if (!sendChat) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please add comment!");
        return false;
      }
    } else {
      socketInstance.emit("send_message", {
        to_id: usersId,
        message: sendChat,
        message_type: 1,
      });
      socketInstance.on("send_message", (data) => {
        if (data.statusCode === 200) {
          e.target.reset();
        }
        socketInstance.emit("get_chat", {
          room_id: userRoomId,
          page: 1,
          limit: 1000,
        });
        socketInstance.on("get_chat", (data) => {
          setSortedChatData(data?.data?.list);
          handleChatScroll();
        });
      });
    }
  };

  const handleOpenChats = (data) => {
    setUsersId(data?.user_id);
    setUserName(data?.user_name);
    setUserRoomId(data?.room_id);
    setUserImage(data?.user_image);
    setShowMessagesContentBox(true);
    socketInstance.emit("join_chat", {
      to_id: data?.user_id,
    });
    socketInstance.on("join_chat", (data) => {});
    socketInstance.emit("get_chat", {
      room_id: data?.room_id,
      page: 1,
      limit: 1000,
    });
    socketInstance.on("get_chat", (data) => {
      setSortedChatData(data?.data?.list);
    });
    handleChatScroll();
  };

  const handleCloseChats = () => {
    setShowMessagesContentBox(false);
  };

  function setSortedChatData(chatDataResult) {
    const sortedListData = [...chatDataResult].sort(
      (a, b) => new Date(a?.created_at) - new Date(b?.created_at)
    );
    setChatList(sortedListData);
    handleChatScroll();
  }

  return (
    <>
      <div className="contentCommon">
        <div className="row">
          <div className="col-xl-3 col-lg-6">
            <div className="chatUserList">
              <div className="flexBox justify-content-between chatListHeader">
                <article>
                  <h4 className="chatListHeading">Chat List</h4>
                </article>
              </div>
              <div className="commanScrollbar">
                {getChatsListUsers?.length > 0 ? (
                  getChatsListUsers?.map((data, index) => {
                    return (
                      <div
                        key={index}
                        className="flexBox userlistChat"
                        onClick={() => handleOpenChats(data)}
                      >
                        <img
                          src={
                            data?.user_image
                              ? data?.user_image
                              : Images.profileImg
                          }
                          alt="chatUserImg"
                          className="img-fluid chatUserImg"
                        />
                        <div className="w-100">
                          <article className="flexBox justify-content-between">
                            <h6 className="chatUserName">{data?.user_name}</h6>
                            <span className="chatTiming">
                              {" "}
                              {moment(data?.created_at).fromNow()}
                            </span>
                          </article>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="chatNodata">
                    <h3 className="noDataText">No users Joined yet!</h3>
                  </div>
                )}
              </div>
            </div>
          </div>
          {showMessgaesContentBox && (
            <div className="col-xl-9 col-lg-6">
              <div className="chatUserList">
                <div className="flexBox justify-content-between chatListHeader">
                  <div className="flexBox">
                    <figure>
                      <img
                        src={userImage ? userImage : Images.profileImg}
                        alt=""
                        className="img-fluid chatUserImg"
                      />
                    </figure>
                    <article>
                      <h6 className="chatUserName">{userName}</h6>
                      <p className="userMsg flexBox">
                        <span className="userStatus"></span>Online
                      </p>
                    </article>
                  </div>
                  <div>
                    <button className="removeBtn" onClick={handleCloseChats}>
                      Close
                    </button>
                  </div>
                </div>

                <div className="commanScrollbar chatSection">
                  {chatList?.length > 0
                    ? chatList?.map((data, index) => {
                        return (
                          <>
                            <>
                              {data?.from_id != 1 ? (
                                <div
                                  ref={
                                    index === chatList?.length - 1
                                      ? chatRef
                                      : null
                                  }
                                  key={index}
                                  className="otherComments"
                                >
                                  <h6 className="commentUser">{userName}</h6>
                                  <span className="commentuserTimeing">
                                    {" "}
                                    {moment(data?.created_at).fromNow()}
                                  </span>
                                  <p className="userComment">{data?.message}</p>
                                </div>
                              ) : (
                                ""
                              )}
                            </>
                            <>
                              {data?.from_id == 1 ? (
                                <div
                                  ref={
                                    index === chatList?.length - 1
                                      ? chatRef
                                      : null
                                  }
                                  key={index}
                                  className="otherCommentsreply justify-content-end"
                                >
                                  <h6 className="commentUser">
                                    {userInfo?.full_name}
                                  </h6>
                                  <span className="commentuserTimeing">
                                    {" "}
                                    {moment(data?.created_at).fromNow()}
                                  </span>
                                  <p className="userComment">{data?.message}</p>
                                </div>
                              ) : (
                                ""
                              )}
                            </>
                          </>
                        );
                      })
                    : ""}
                </div>

                <form onSubmit={(e) => handleSendMessages(e)}>
                  <div className="commentchat-input commentchat-input-bg">
                    <input type="text" placeholder="Your Comment" />

                    <button className="border-0" type="submit">
                      <img alt="send-logo" src={Images.sendLogo} />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default SupportChat;
