import React from "react";
import * as Images from "../../../utilities/images";
const ThankYou = () => {
  return (
    <>
      <section className="favoritesBanner thanksBanner py-0">
        <div className="container">
          <div className="row pt-5">
            <div className="col-md-12 text-center">
              <div className="thankuPage">
               <img src={Images.smile} alt=""/>
                <h2 className="heading colorPink">Thank You!</h2>
                <h5 className="medText colorPink">Thanks for contacting us,</h5>
                <h5 className="medText colorPink">We'll be in touch very soon.</h5>
              </div>
            </div>
          </div>
        </div>
      </section>
    
    </>
  );
};

export default ThankYou;
