import React, { useEffect, useState } from "react";
import * as Images from "../../../utilities/images";
import { Swiper, SwiperSlide } from "swiper/react";
import { useDispatch } from "react-redux";
import { getAboutUs } from "../../../redux/slices/admin";
import { toast } from "react-toastify";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";

const Aboutus = () => {
  const dispatch = useDispatch();
  const [aboutUs, setAboutUs] = useState(null);

  const aboutsUs = () => {
    let params = {
      page_type: 3,
    };
    dispatch(
      getAboutUs({
        ...params,
        cb(res) {
          if (res?.data) {
            setAboutUs(res?.data?.data?.description);
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };

  const handleClickDown = () => {
    window.scrollTo({ top: 10000, behavior: 'smooth' });
  };

  useEffect(() => {
    aboutsUs();
    window.scrollTo(0, 0);
    document.title = "About-Us";
  }, []);

  return (
    <>
      <section className="favoritesBanner">
        <div className="container">
          <div className="col-md-12 text-center pt-5">
            <h2 className="bigHeading pt-5 pb-4 ">About Us</h2>
            <p className="text pb-5">Our Story</p>
          </div>
        </div>
      </section>
      <section>
        <div className="healthyfood_Images">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-2 col-md-2 col-2">
                <img
                  className="img-fluid  aboutusbanner_img"
                  src={Images.about1}
                  alt=""
                />
              </div>
              <div className="col-lg-2 col-md-2 col-2 p-0">
                <div className="Aboutus_menuImg">
                  <img
                    className="img-fluid aboutusbanner_img ms-2 mb-3"
                    src={Images.about2}
                    alt=""
                  />
                  <img
                    className="img-fluid aboutusbanner_img ms-2"
                    src={Images.about3}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-lg-2  col-md-2 col-2">
                <img
                  className="img-fluid aboutusbanner_img ms-2 me-2 p-0"
                  src={Images.about4}
                  alt=""
                />
              </div>
              <div className="col-lg-2 col-md-2 col-2">
                <div className="Aboutus_menuImg">
                  <img
                    className="img-fluid aboutusbanner_img ms-2 mb-3"
                    src={Images.about5}
                    alt=""
                  />
                  <img
                    className="img-fluid aboutusbanner_img ms-2"
                    src={Images.about6}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-2">
                <img
                  className="img-fluid aboutusbanner_img"
                  src={Images.about7}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="contentSection">
        <div className="container">
          <div className="BannerContent">
            <div dangerouslySetInnerHTML={{ __html: aboutUs }} />
          </div>
        </div>
      </section>
      <section className="founder">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="founderimg_lynnette">
                <img
                  className="img-fluid founderimg_Lynnette"
                  src={Images.aboutuser}
                  alt="owner_img"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="ownerdetail_Lynnette">
                <p className="text colorPink">About </p>
                <p className="subheading">Lynnette</p>
                <p className="text_"> Founder of Lynntte’s Kitchen</p>
                <p className="text">
                  I have always had a love for cooking and baking since I was a small child.  Recipes such as our famous macaroni and cheese, which we have on our office catering and mobile catering menus, and our yummy customer favorite, sweet potato cake, were passed down to me from my grandmother!  Over the years, my team and I have developed many recipes, seasonings, and spices that we have utilized in recipes from our menu items on our corporate catering menu today!  But this isn’t about our recipe development, this is about our first video and recipe instructions app; the Lynnette’s Kitchen app.
                  <br /><br />Over the years of running my catering business, I’ve taught classes for Living Social, and Wilton’s Bakery and have given demonstrations for MetroCooking DC and corporate clients in the DC metro area.  My team and I have contested on cooking shows such as Cupcake Wars and Sugar Showdown.
                  <br /><br />Over the years, I have gotten into social media and have given numerous cooking and baking demonstrations to share my passion and love for food and cooking with my audience.  Most of my audience are aspiring home cooks and home bakers like yourself.
                  <br /><br />With Lynnette’s Kitchen App, I am so excited to be able to present my cooking demonstrations, recipes, and instructions to you and make it as convenient as being available in your pocket on your phone. So, download the app, try a recipe or two, and enjoy your culinary experience with the Lynnette’s Kitchen App!
                </p>
                <p className="medText signText">Lynnette</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="downarrow">
        <img src={Images.downarrowWhite} alt="" onClick={handleClickDown} />
      </div>
      <section className="py-5">
        <div className="container">
          <div className="py-5 py-sm-2">
            <h3 className="subheading text-center">
              Why Choose Lynnette’s Kitchen
            </h3>
          </div>
          <div className="row">
            <div className="col-md-4 text-center">
              <div className="whyChooseContent">
                <img src={Images.live1} alt="" />
                <h5 className="medText py-3">Library of Recipes</h5>
                <p className="text">
                  Lynnette’s Kitchen app covers an array of recipes from savory to desserts to sauces, marinades, homemade dressings, and much more.  Subscribers of the app will get recipes, recipe tips, and tricks along with step-by-step video instructions from a professional chef leading to a valuable experience.  Subscribers will also leave a rating on recipes or give tips to help other subscribers who may try a recipe in the future.
                </p>
              </div>
            </div>
            <div className="col-md-4 text-center">
              <div className="whyChooseContent">
                <img src={Images.live2} alt="" />
                <h5 className="medText py-3">Live Streams</h5>
                <p className="text">
                  Lynnette’s Kitchen app features live streaming (as well as pre-recorded) cooking videos.  The live stream will feature a chatroom allowing subscribers to dialogue with one another during live cooking demonstrations, ask Chef Lynnette’s questions, or simply comment on the video during the live stream.
                </p>
              </div>
            </div>
            <div className="col-md-4 text-center">
              <div className="whyChooseContent">
                <img src={Images.live3} alt="" />
                <h5 className="medText py-3">Cooking Classes</h5>
                <p className="text">
                  Lynnette’s Kitchen app offers specialty classes in the areas of cake baking and decorating, cookie baking and decorating, small party catering, and more.  Classes will be offered in both live and pre-recorded formats.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="ourCustomers">
        <div className="container">
          <div className="py-4">
            <h3 className="subHeading text-center ourCustomerHead">What Our Customers Say</h3>
          </div>

          <div className="row">
            <Swiper
              navigation={true}
              modules={[Pagination, Navigation]}
              className="mySwiper"
              slidesPerView={3}
              spaceBetween={30}
              breakpoints={{
                1800: {
                  slidesPerView: 3,
                },
                1200: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 2,
                },
                992: {
                  slidesPerView: 2,
                },
                991: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 1,
                },
                767: {
                  slidesPerView: 1,
                },
                480: {
                  slidesPerView: 1,
                },
                375: {
                  slidesPerView: 1,
                },
                360: {
                  slidesPerView: 1,
                },
                320: {
                  slidesPerView: 1,
                },
              }}
            >
              <SwiperSlide>
                <div className="col-md-12">
                  <div className="shapeBg">
                    <div className="userImg">
                      <img className="img-fluid" src={Images.userimg} alt="" />
                    </div>
                    <div className="shapebgInner">
                      <div className="">
                        <h6 className="bigText text-center">Julia Hoffmann</h6>
                        <ul className="starOuter">
                          <li>
                            <img className="img-fluid" src={Images.star} alt="" />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} alt="" />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} alt="" />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} alt="" />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} alt="" />
                          </li>
                        </ul>
                        <p className="text-center subtext">
                          Lorem Ipsum is simply dummy text of the printing is
                          the best and typesetting industry Lorem Ipsum is
                          simply dummy text of the printing is the best and
                          typesetting industry Ipsum is simply dummy tex
                          typesetting industry Lorem Ipsum is simply dummy text
                          of the printing is the best and typesetting
                          industryIpsum is simply dummy tex.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="col-md-12">
                  <div className="shapeBg">
                    <div className="userImg">
                      <img className="img-fluid" src={Images.userimg} alt="" />
                    </div>
                    <div className="shapebgInner">
                      <div className="">
                        <h6 className="bigText text-center">Julia Hoffmann</h6>
                        <ul className="starOuter">
                          <li>
                            <img className="img-fluid" src={Images.star} alt="" />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} alt="" />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} alt="" />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} alt="" />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} alt="" />
                          </li>
                        </ul>
                        <p className="text-center subtext">
                          Lorem Ipsum is simply dummy text of the printing is
                          the best and typesetting industry Lorem Ipsum is
                          simply dummy text of the printing is the best and
                          typesetting industry Ipsum is simply dummy tex
                          typesetting industry Lorem Ipsum is simply dummy text
                          of the printing is the best and typesetting
                          industryIpsum is simply dummy tex.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="col-md-12">
                  <div className="shapeBg">
                    <div className="userImg">
                      <img className="img-fluid" src={Images.userimg} alt="" />
                    </div>
                    <div className="shapebgInner">
                      <div className="">
                        <h6 className="bigText text-center">Julia Hoffmann</h6>
                        <ul className="starOuter">
                          <li>
                            <img className="img-fluid" src={Images.star} alt="" />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} alt="" />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} alt="" />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} alt="" />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} alt="" />
                          </li>
                        </ul>
                        <p className="text-center subtext">
                          Lorem Ipsum is simply dummy text of the printing is
                          the best and typesetting industry Lorem Ipsum is
                          simply dummy text of the printing is the best and
                          typesetting industry Ipsum is simply dummy tex
                          typesetting industry Lorem Ipsum is simply dummy text
                          of the printing is the best and typesetting
                          industryIpsum is simply dummy tex.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="col-md-12">
                  <div className="shapeBg">
                    <div className="userImg">
                      <img className="img-fluid" src={Images.userimg} alt="" />
                    </div>
                    <div className="shapebgInner">
                      <div className="">
                        <h6 className="bigText text-center">Julia Hoffmann</h6>
                        <ul className="starOuter">
                          <li>
                            <img className="img-fluid" src={Images.star} alt="" />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} alt="" />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} alt="" />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} alt="" />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} alt="" />
                          </li>
                        </ul>
                        <p className="text-center subtext">
                          Lorem Ipsum is simply dummy text of the printing is
                          the best and typesetting industry Lorem Ipsum is
                          simply dummy text of the printing is the best and
                          typesetting industry Ipsum is simply dummy tex
                          typesetting industry Lorem Ipsum is simply dummy text
                          of the printing is the best and typesetting
                          industryIpsum is simply dummy tex.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="col-md-12">
                  <div className="shapeBg">
                    <div className="userImg">
                      <img className="img-fluid" src={Images.userimg} alt="" />
                    </div>
                    <div className="shapebgInner">
                      <div className="">
                        <h6 className="bigText text-center">Julia Hoffmann</h6>
                        <ul className="starOuter">
                          <li>
                            <img className="img-fluid" src={Images.star} alt="" />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} alt="" />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} alt="" />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} alt="" />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} alt="" />
                          </li>
                        </ul>
                        <p className="text-center subtext">
                          Lorem Ipsum is simply dummy text of the printing is
                          the best and typesetting industry Lorem Ipsum is
                          simply dummy text of the printing is the best and
                          typesetting industry Ipsum is simply dummy tex
                          typesetting industry Lorem Ipsum is simply dummy text
                          of the printing is the best and typesetting
                          industryIpsum is simply dummy tex.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="col-md-12">
                  <div className="shapeBg">
                    <div className="userImg">
                      <img className="img-fluid" src={Images.userimg} alt="" />
                    </div>
                    <div className="shapebgInner">
                      <div className="">
                        <h6 className="bigText text-center">Julia Hoffmann</h6>
                        <ul className="starOuter">
                          <li>
                            <img className="img-fluid" src={Images.star} alt="" />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} alt="" />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} alt="" />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} alt="" />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} alt="" />
                          </li>
                        </ul>
                        <p className="text-center subtext">
                          Lorem Ipsum is simply dummy text of the printing is
                          the best and typesetting industry Lorem Ipsum is
                          simply dummy text of the printing is the best and
                          typesetting industry Ipsum is simply dummy tex
                          typesetting industry Lorem Ipsum is simply dummy text
                          of the printing is the best and typesetting
                          industryIpsum is simply dummy tex.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="col-md-12">
                  <div className="shapeBg">
                    <div className="userImg">
                      <img className="img-fluid" src={Images.userimg} alt="" />
                    </div>
                    <div className="shapebgInner">
                      <div className="">
                        <h6 className="bigText text-center">Julia Hoffmann</h6>
                        <ul className="starOuter">
                          <li>
                            <img className="img-fluid" src={Images.star} alt="" />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} alt="" />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} alt="" />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} alt="" />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} alt="" />
                          </li>
                        </ul>
                        <p className="text-center subtext">
                          Lorem Ipsum is simply dummy text of the printing is
                          the best and typesetting industry Lorem Ipsum is
                          simply dummy text of the printing is the best and
                          typesetting industry Ipsum is simply dummy tex
                          typesetting industry Lorem Ipsum is simply dummy text
                          of the printing is the best and typesetting
                          industryIpsum is simply dummy tex.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="col-md-12">
                  <div className="shapeBg">
                    <div className="userImg">
                      <img className="img-fluid" src={Images.userimg} alt="" />
                    </div>
                    <div className="shapebgInner">
                      <div className="">
                        <h6 className="bigText text-center">Julia Hoffmann</h6>
                        <ul className="starOuter">
                          <li>
                            <img className="img-fluid" src={Images.star} alt="" />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} alt="" />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} alt="" />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} alt="" />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} alt="" />
                          </li>
                        </ul>
                        <p className="text-center subtext">
                          Lorem Ipsum is simply dummy text of the printing is
                          the best and typesetting industry Lorem Ipsum is
                          simply dummy text of the printing is the best and
                          typesetting industry Ipsum is simply dummy tex
                          typesetting industry Lorem Ipsum is simply dummy text
                          of the printing is the best and typesetting
                          industryIpsum is simply dummy tex.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="col-md-12">
                  <div className="shapeBg">
                    <div className="userImg">
                      <img className="img-fluid" src={Images.userimg} alt="" />
                    </div>
                    <div className="shapebgInner">
                      <div className="">
                        <h6 className="bigText text-center">Julia Hoffmann</h6>
                        <ul className="starOuter">
                          <li>
                            <img className="img-fluid" src={Images.star} alt="" />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} alt="" />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} alt="" />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} alt="" />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} alt="" />
                          </li>
                        </ul>
                        <p className="text-center subtext">
                          Lorem Ipsum is simply dummy text of the printing is
                          the best and typesetting industry Lorem Ipsum is
                          simply dummy text of the printing is the best and
                          typesetting industry Ipsum is simply dummy tex
                          typesetting industry Lorem Ipsum is simply dummy text
                          of the printing is the best and typesetting
                          industryIpsum is simply dummy tex.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </section>
      <section>
        <div className="downloadAppsOuter aboutusPage">
          <div className="container-lg container-fluid">
            <div className="downloadApps">
              <div className="row">
                <div className="col-lg-5 col-md-12">
                  <h3 className="subheading colorWhite">Download Our App</h3>
                  <p className="text colorWhite">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting.
                  </p>
                  <div className="pt-5 d-flex pt-md-2 appBtnOuter">
                    <button className="appBtn">
                      <div className="appIcon">
                        <img src={Images.apple} alt="" />
                      </div>
                      <div className="">
                        <p className="extraSmallText m-0 colorWhite">
                          Download on the
                        </p>
                        <p className="text m-0 colorWhite">App Store</p>
                      </div>
                    </button>
                    <button className="appBtn">
                      <div className="appIcon">
                        <img src={Images.playstore} alt="" />
                      </div>
                      <div className="">
                        <p className="extraSmallText m-0 colorWhite text-start">
                          Get it On
                        </p>
                        <p className="text m-0 colorWhite">App Store</p>
                      </div>
                    </button>
                  </div>
                </div>
                <div className="col-lg-7 col-md-12">
                  <div className="twoMobileImg">
                    <img className="img-fluid" src={Images.twomobile} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Aboutus;
