import { all, fork, spawn } from "redux-saga/effects";
import authSaga from "./auth";
import userSaga from "./user";
import adminSaga from "./admin";
import socketSaga from "./socket"

export default function* rootSaga() {
    yield all([
        spawn(authSaga),
        spawn(userSaga),
        spawn(adminSaga),
       spawn(socketSaga),
        // saga1 can also yield [ fork(actionOne), fork(actionTwo) ]
        // fork(saga2),
    ]);
}
