import React, { useState, useEffect } from "react";
import * as Images from "../../../../utilities/images";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { getCategories, getClasses } from "../../../../redux/slices/admin";
import CustomModal from "../../../components/modals/modal";
import Deleteclass from "./deleteClass";
import { generateTokenForStreaming } from "../../../../redux/slices/user";
import ConfirmLiveStream from "./confirmModalLiveStream";
import { Spinner } from "react-bootstrap";
import moment from "moment";  

const Classes = () => {
  const dispatch = useDispatch();
  const [getClass, setGetClass] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [perPageSize, setPerPageSize] = useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [Categories, setCategories] = useState([]);
  const [catId, setCatId] = useState("");
  const [option, setOption] = useState("");
  const [key, setKey] = useState(Math.random());
  const [sortData, setSortData] = useState("all");
  const navigate = useNavigate();

  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const classList = (
    page = currentPage,
    limit = perPageSize,
    keyword = searchValue,
    sort,
    id
  ) => {
    let params = {
      page: page,
      limit: limit,
      keyword: keyword,
      sort: sort == undefined ? sortData : sort,
      cat_id: id == undefined ? "" : id,
    };
    if (Boolean(keyword)) {
      params = { ...params, keyword: keyword };
    }
    dispatch(
      getClasses({
        ...params,
        cb(res) {
          if (res?.data) {
            setTotalPages(res?.data?.data?.total_pages);
            setTotalCount(res?.data?.data?.totalRecords);
            setGetClass(res?.data?.data?.list);
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };
  const getCurrentDateTime = () => {
    var m = moment(); // get "now" as a moment
    var s = m.format();
    return s;
  };
  const handleStartStreaming = (classid) => {
    let params = {
      class_id: classid,
    };
    dispatch(
      generateTokenForStreaming({
        ...params,
        cb(res) {
          if (res?.data) {
            navigate(`/admin/liveStreaming/${classid}`, {
              state: {
                tokenId: res?.data?.data?.token,
                roomID: res?.data?.data?.roomID,
                appId: res?.data?.data?.appId,
                channel: res?.data?.data?.channelName,
                uid: res?.data?.data?.uid,
                classId: classid,
              },
            });
            window.location.reload()
          }
        },
      })
    );
  };
  useEffect(() => {
    classList();
    getCategory();
    window.scrollTo(0, 0);
    document.title = "Classes-List";
  }, []);

  const getCategory = () => {
    dispatch(
      getCategories({
        cb(res) {
          if (res?.data) {
            setCategories(res?.data?.data);
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };

  const handlePageClick = (selectedItem) => {
    const selectedPage = selectedItem.selected + 1;
    setCurrentPage(selectedPage);
    classList(selectedPage, perPageSize);
  };

  const handleChangePages = (e) => {
    setPerPageSize(e.target.value);
    setCurrentPage(1);
    classList(1, e.target.value);
  };
  const handleFilterData = (id, title) => {
    setCatId(id);
    setOption(title);
    classList(currentPage, perPageSize, searchValue, sortData, id);
  };
  const handleDeleteRecipe = (classId, Classtitle) => {
    setModalDetail({
      show: true,
      title: "DeleteClass",
      flag: "Delete",
      id: classId,
      Classtitle: Classtitle,
    });
    setKey(Math.random());
  };
  const handleViewClass = (id) => {
    if (id) {
      navigate(`/admin/classdetail/${id}`);
    }
  };
  const handleEditRecipe = (id) => {
    if (id) {
      navigate(`/admin/editclass/${id}`);
    }
  };

  const handleStartStreamingBeforeTime = (classid) => {
    setModalDetail({
      show: true,
      title: "Confirm",
      flag: "confirm",
      id: classid,
    });
    setKey(Math.random());
  };

  const handleSortData = (value) => {
    setSortData(value);
    classList(currentPage, perPageSize, searchValue, value, catId);
  };

  let pagingDataLabel = perPageSize * currentPage;
  pagingDataLabel = pagingDataLabel > totalCount ? totalCount : pagingDataLabel;

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="tab-content class-content-resp" id="pills-tabContent">
            <div className="tab-pane fade active show" id="pills-home">
              <div className="contentCommon insidecontent_ mb-2 ">
                <div className="searchDataTable">
                  <div className="serachBar">
                    <img
                      src={Images.searchiconpink}
                      alt="Search"
                      className="searchIcon"
                    />
                    <input
                      type="text"
                      name="Search"
                      className="form-control search subtext"
                      placeholder="Search..."
                      value={searchValue}
                      onChange={(e) => {
                        setSearchValue(e.target.value);
                        classList(1, 10, e.target.value, sortData, catId);
                      }}
                    />
                  </div>
                  <div className="d-flex align-items-center classes-select-items gap-2">
                    <div className="customSelect">
                      <label className="text me-2">Filter By</label>
                      <select
                        onChange={(e) => handleFilterData(e.target.value)}
                      >
                        <option value={""}> All</option>
                        {Categories.map((data, index) => (
                          <option key={index} value={data.id}>
                            {data.title}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="customSelect">
                      <label className="text me-2">Sort By</label>
                      <select
                        value={sortData}
                        onChange={(e) => handleSortData(e.target.value)}
                      >
                        <option value="all">All</option>
                        <option value="today_live">
                          {" "}
                          Today's Live Streams
                        </option>
                        <option value="existing">Existing</option>
                        <option value="upcoming">Upcoming</option>
                      </select>
                    </div>
                    <div className="add-classess-btn">
                      <Link to={"/admin/addclass"}>
                        <button className="login-btn">Add Classes</button>
                        <span className="center-img">
                          <img src={Images.centerrectangle} alt="" />
                        </span>
                        <span className="circle-btn recipeBtnCircle ">
                          <img
                            className="img-fluid"
                            src={Images.plussign}
                            alt=""
                          />
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="innerCourseCart_ space bgremove_">
                  {getClass?.length > 0 ? (
                    getClass?.map((data, index) => {
                      return (
                        <div key={index} className="courseCardCon">
                          <div className="courseCard mt-3">
                            <div className="courseImg">
                              <img
                                onClick={() => handleViewClass(data?.id)}
                                src={
                                  data?.thumbnail_image
                                    ? data?.thumbnail_image
                                    : Images.foodbox
                                }
                                alt="img"
                              />
                            </div>
                            <div className="courseDetails">
                              <div className="courseDetailsInner pe-2">
                                <div className="toptextOuter">
                                  <p className="bigText pb-2 m-0">
                                    {data?.title}
                                  </p>
                                  <p className="text p-0">
                                    {data?.description}
                                  </p>
                                </div>
                                <ul className="courseDurationDetials courseDurationDetialsClass">
                                  <li>
                                    <p>
                                      <img src={Images.type1} alt="icon" />
                                      &nbsp; Type
                                    </p>
                                    <h6>{data?.category_title}</h6>
                                  </li>
                                  {data?.type == 1 ? (
                                    <li>
                                      <p>
                                        <img src={Images.clocknew} alt="icon" />
                                        &nbsp; Duration
                                      </p>
                                      <h6>{data?.duration} Min</h6>
                                    </li>
                                  ) : (
                                    ""
                                  )}
                                  <li>
                                    <p>
                                      <img src={Images.dateicon} alt="icon" />
                                      &nbsp; Date
                                    </p>
                                    <h6>
                                      {" "}
                                      {moment(data?.date)
                                        .utc()
                                        .format("YYYY-MM-DD")}{" "}
                                    </h6>
                                  </li>
                                  {data?.type == 1 ? (
                                    <li>
                                      <p>
                                        <img src={Images.clock} alt="icon" />
                                        &nbsp; Time
                                      </p>
                                      <h6>
                                        {moment(data?.time, "HH:mm").format(
                                          "hh:mm A"
                                        )}
                                      </h6>
                                    </li>
                                  ) : (
                                    ""
                                  )}

                                  {data?.type == 1 ? (
                                    <li>
                                      <p>
                                        <img
                                          src={Images.classtype}
                                          alt="icon"
                                        />
                                        &nbsp; Class Type
                                      </p>
                                      <h6>Live Class</h6>
                                    </li>
                                  ) : (
                                    ""
                                  )}
                                  {data?.type == 2 ? (
                                    <li>
                                      <p>
                                        <img
                                          src={Images.classtype}
                                          alt="icon"
                                        />
                                        &nbsp; Class Type
                                      </p>
                                      <h6>Pre Recorded</h6>
                                    </li>
                                  ) : (
                                    ""
                                  )}
                                </ul>
                              </div>
                              <div className="rightArrowOuter">
                                <div className="rightArrow dropdown text-end">
                                  <img
                                    onClick={() => handleViewClass(data?.id)}
                                    src={Images.nextcircle}
                                    alt="arrow"
                                    className="me-3 eye"
                                  />
                                  <img
                                    src={Images.threedot}
                                    alt=""
                                    className="dots dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  />
                                  <ul className="dropdown-menu courseDrpodown threedot">
                                    <li
                                      onClick={() => handleViewClass(data?.id)}
                                    >
                                      <a className="dropdown-item text_">
                                        {" "}
                                        <img
                                          src={Images.eye}
                                          alt="icon"
                                          className="me-3"
                                        />{" "}
                                        View Details
                                      </a>
                                    </li>
                                    <li
                                      onClick={() => handleEditRecipe(data?.id)}
                                    >
                                      <a className="dropdown-item text_">
                                        <img
                                          src={Images.editpendark}
                                          alt="icon"
                                          className="me-3"
                                        />{" "}
                                        &nbsp;Edit
                                      </a>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleDeleteRecipe(
                                          data?.id,
                                          data?.title
                                        )
                                      }
                                    >
                                      <a className="dropdown-item text_">
                                        {" "}
                                        <img
                                          src={Images.trash}
                                          alt="icon"
                                          className="me-3"
                                        />{" "}
                                        Delete
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                                {data?.type == 1 ? (
                                  <div className="mt-5 d-flex align-items-center justify-content-end">
                                    <button
                                      className="login-btn"
                                      onClick={() => {
                                        data?.date <= getCurrentDateTime()
                                          ? handleStartStreaming(data?.id)
                                          : handleStartStreamingBeforeTime(
                                              data?.id,
                                              data
                                            );
                                      }}
                                    >
                                      <span>
                                        <img
                                          className="me-2"
                                          src={Images.youtube}
                                          alt=""
                                        />
                                      </span>
                                      Start Streaming
                                    </button>
                                    <span className="center-img">
                                      <img
                                        src={Images.centerrectangle}
                                        alt=""
                                        onClick={() => {
                                          data?.date <= getCurrentDateTime()
                                            ? handleStartStreaming(data?.id)
                                            : handleStartStreamingBeforeTime(
                                                data?.id
                                              );
                                        }}
                                      />
                                    </span>
                                    <span className="circle-btn">
                                      <img
                                        src={Images.arrow}
                                        alt=""
                                        onClick={() => {
                                          data?.date <= getCurrentDateTime()
                                            ? handleStartStreaming(data?.id)
                                            : handleStartStreamingBeforeTime(
                                                data?.id
                                              );
                                        }}
                                      />
                                    </span>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <>
                      {getClass?.length == 0 ? (
                        <h3 className="noDataText">No Class Found</h3>
                      ) : (
                        <Spinner
                          className="pageLoader"
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                    </>
                  )}
                </div>
                <div className="d-flex justify-content-between align-items-center showpage_">
                  <div
                    className="dataTables_length pt-3"
                    id="staffsTable_length"
                  >
                    <label>
                      {" "}
                      <span className="text">Show</span>{" "}
                      <select
                        name="staffsTable_length"
                        aria-controls="staffsTable"
                        className="custom-select custom-select-sm form-control form-control-sm"
                        onChange={(e) => handleChangePages(e)}
                        value={perPageSize}
                      >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="75">75</option>
                        <option value="100">100</option>
                      </select>
                      &nbsp;{" "}
                      <span className="subtext">
                        Showing {`${perPageSize * (currentPage - 1) + 1}`} to{" "}
                        {`${
                          perPageSize * (currentPage - 1) + getClass?.length
                        }`}{" "}
                        of {`${totalCount}`} entries{" "}
                      </span>{" "}
                    </label>
                  </div>
                  {getClass?.length > 0 ? (
                    <>
                      <ReactPaginate
                        className="paginationBox mb-0"
                        breakLabel=""
                        nextLabel="next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={0}
                        pageCount={totalPages}
                        previousLabel="< prev"
                        renderOnZeroPageCount={null}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          {modalDetail.show && (
            <CustomModal
              key={key}
              show={modalDetail.show}
              backdrop="static"
              showCloseBtn={true}
              isRightSideModal={false}
              mediumWidth={false}
              className={modalDetail.flag === "Delete" ? "delete" : ""}
              child={
                modalDetail.flag === "Delete" ? (
                  <Deleteclass
                    onCloseModal={() => {
                      handleOnCloseModal();
                    }}
                    refreshData={() => classList()}
                    userId={modalDetail?.id}
                    Classtitle={modalDetail?.Classtitle}
                  />
                ) : modalDetail.flag === "confirm" ? (
                  <ConfirmLiveStream
                    onCloseModal={() => {
                      handleOnCloseModal();
                    }}
                    refreshData={() => classList()}
                    classid={modalDetail?.id}
                    Classtitle={modalDetail?.Classtitle}
                  />
                ) : (
                  ""
                )
              }
              onCloseModal={() => handleOnCloseModal()}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Classes;
