import React, { useEffect, useRef, useState } from "react";
import * as Images from "../../utilities/images";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { socketInstance } from "../components/common/socketNew";
import AgoraRTC from "agora-rtc-sdk-ng";
import { toast } from "react-toastify";
import { useAuthSelector } from "../../redux/selector/auth";
import { showLiveComments } from "../../redux/slices/user";
import { useDispatch } from "react-redux";

const JoinLiveStreaming = () => {
  const toastId = useRef(null);
  const chatRef = useRef();
  const authSelector = useAuthSelector();
  const userId = authSelector?.userLogin?.data?.user;
  const location = useLocation();
  const dispatch = useDispatch();
  const [totalViews, setTotalViews] = useState("");
  const [totalComments, setTotalComments] = useState("");
  const [addComment, setAddComment] = useState("");
  const [showCommentsSection, setshowCommentsSection] = useState(false);
  const [liveCommentsList, setLiveCommentsList] = useState([]);
  const navigate = useNavigate();
  const [uidId, setUidId] = useState("");
  const [appId, setAppId] = useState("");
  const [channelName, setChannelName] = useState("");
  const [tokenId, setToeknId] = useState("");
  const [roomID, setRoomId] = useState("");

  useEffect(() => {
    window.onpopstate = () => {
      handleEndCall();
    };
  });

  useEffect(() => {
    if (location?.state?.uid) {
      setUidId(location?.state?.uid);
      setAppId(location?.state?.appId);
      setChannelName(location?.state?.channel);
      setToeknId(location?.state?.tokenId);
      setRoomId(location?.state?.roomID);
      localStorage.setItem("liveStreamjoin", JSON.stringify(location.state));
    } else if (localStorage.getItem("liveStreamjoin")) {
      let location = localStorage.getItem("liveStreamjoin");
      location = JSON.parse(location);
      setUidId(location?.uid);
      setAppId(location?.appId);
      setChannelName(location?.channel);
      setToeknId(location?.tokenId);
      setRoomId(location?.roomID);
    }
    return () => {
      socketInstance.disconnect();
    };
  }, []);

  useEffect(() => {
    let location = localStorage.getItem("liveStreamjoin");
    location = JSON.parse(location);
    if (socketInstance && (location?.state?.roomID || location?.roomID)) {
      socketInstance.connect();
      socketInstance.on("connection", (response) => {});
      socketInstance.on("join_livestreaming", (response) => {});
      socketInstance.emit("sync_live_data", {
        room_id: location?.state?.roomID || location?.roomID,
      });
      socketInstance.on("sync_live_data", (response) => {
        setTotalViews(response?.data?.totalUsers);
        setTotalComments(response?.data?.totalcoments);
      });
      // socketInstance.on("add_livestreaming_comment", (data) => {
      //   console.log("add_livestreaming_comment", data);
      //   getAllLiveComments(location?.state?.roomID || location?.roomID);
      // });
      getAllLiveComments(location?.state?.roomID || location?.roomID);
    }
  }, [roomID]);

  const handleShowCommentBoxes = () => {
    setshowCommentsSection(true);
  };
  const handleCross = () => {
    setshowCommentsSection(false);
    setAddComment(null);
  };

  const handleSendComments = (event) => {
    let addComment = event.target[0].value;
    event.preventDefault();
    if (!addComment) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please add comment!");
        return false;
      }
    } else {
      socketInstance.emit("add_livestreaming_comment", {
        room_id: roomID,
        comment: addComment,
      });
      socketInstance.on("add_livestreaming_comment", (data) => {
        if (data.statusCode === 200) {
          setAddComment("");
          event.target.reset();
          setLiveCommentsList((prevData) => {
            const isIdPresent = prevData.some(
              (item) => item?.id === data?.data?.id
            );
            if (!isIdPresent) {
              return [...prevData, data.data];
            } else {
              return prevData;
            }
          });
        } else {
          if (!toast.isActive(toastId.current)) {
            toastId.current = toast.error("Comment is too long");
            return false;
          }
        }
        socketInstance.emit("sync_live_data", {
          room_id: roomID,
        });
        socketInstance.on("sync_live_data", (response) => {
          setTotalViews(response?.data?.totalUsers);
          setTotalComments(response?.data?.totalcoments);
        });
      });
    }
  };

  if (socketInstance) {
    socketInstance.on("Livestreaming_end_for_all", (response) => {
      navigate("/");
    });
  }
  const handleEndCall = async () => {
    //  channelParameters.localAudioTrack.close();
    //   channelParameters.localVideoTrack.close();
    await agoraEngine.leave();
    socketInstance.emit("Livestreaming_end_for_all", {});
    socketInstance.on("Livestreaming_end_for_all", (response) => {});
    navigate("/");
    window.location.reload();
  };

  const options = {
    appId: appId,
    channel: channelName,
    token: tokenId,
    uid: uidId,
    role: "audience",
  };
  const channelParameters = {
    localAudioTrack: null,
    localVideoTrack: null,
    remoteAudioTrack: null,
    remoteVideoTrack: null,
    remoteUid: null,
  };
  const agoraEngine = AgoraRTC.createClient({ mode: "live", codec: "vp9" });
  AgoraRTC.setLogLevel(3);

  const newStartVideoProcess = async () => {
    await agoraEngine.join(
      options.appId,
      options.channel,
      options.token,
      options.uid
    );
    channelParameters.localAudioTrack =
      await AgoraRTC.createMicrophoneAudioTrack();
    channelParameters.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
  };
  agoraEngine.on("user-published", async (user, mediaType) => {
    const wrapper = document.getElementById("remoteVideoPlayer");
    const remotePlayerContainer = document.createElement("div");
    await agoraEngine.subscribe(user, mediaType);
    if (mediaType === "video") {
      channelParameters.remoteVideoTrack = user.videoTrack;
      channelParameters.remoteAudioTrack = user.audioTrack;
      channelParameters.remoteUid = user.uid.toString();
      remotePlayerContainer.id = user.uid.toString();
      wrapper.append(remotePlayerContainer);

      if (options.role !== "host") {
        channelParameters.remoteVideoTrack.play(remotePlayerContainer);
      }
    }

    if (mediaType === "audio") {
      channelParameters.remoteAudioTrack = user.audioTrack;
      channelParameters.remoteAudioTrack.play();
    }
  });

  useEffect(() => {
    if (agoraEngine) {
      newStartVideoProcess();
    }
    return async () => {
      // await agoraEngine.leave();
      // console.log("You left the channel");
    };
  }, [agoraEngine, options.appId]);

  const getAllLiveComments = (roomID) => {
    let params = {
      room_id: roomID,
    };
    dispatch(
      showLiveComments({
        ...params,
        cb(res) {
          if (res?.data) {
            const sortedData = [...res?.data?.data?.commentlist].sort(
              (a, b) => new Date(a?.updatedAt) - new Date(b?.updatedAt)
            );
            setLiveCommentsList(sortedData);
          } else {
          }
        },
      })
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Live-Streaming";
  }, []);

  return (
    <>
      <>
        <section className="liveStreaming">
          <div className="container-fluid px-0">
            <div className="livestreamingOuter">
              <div id="remoteVideoPlayer"></div>
              <div
                className="livevedio"
                style={{ display: "flex", width: "100vw", height: "100vh" }}
              >
                <div className="App">
                  <div className="flexRow">
                    <div className="d-flex justify-content-between align-items-center">
                      <button onClick={handleEndCall} className="button mb-0">
                        <span className="youtubeIcon">
                          {" "}
                          <img src={Images.backarrowwhite} alt="" />
                        </span>
                        Go Back{" "}
                      </button>

                      <div className="d-flex align-items-center topboxOuter">
                        <div className="d-flex align-items-center">
                          <div className="subtextOuter">
                            <p className="subtext mb-0">
                              <span className="me-2">
                                <img src={Images.pinkeye} alt="" />
                              </span>
                              {totalViews}
                            </p>
                          </div>
                          <div className="subtextOuter">
                            <p className="subtext mb-0">
                              <span className="me-2">
                                <img src={Images.commenticon} alt="" />
                              </span>
                              {totalComments}
                            </p>
                          </div>
                        </div>
                        <div className="ms-2">
                          <button className="button mb-0">
                            <span className="youtubeIcon">
                              {" "}
                              <img src={Images.youtube} alt="" />
                            </span>
                            <span>
                              <img src={Images.reddot} alt="" />{" "}
                            </span>
                            Live{" "}
                          </button>
                        </div>
                      </div>
                    </div>

                    {showCommentsSection && (
                      <div className="col-md-4 p-0">
                        <div className="msgBox">
                          <div className="msgBoxHead">
                            <div className="d-flex align-items-center">
                              <div className="msg_box">
                                <img
                                  className="w-100"
                                  src={Images.msgbox}
                                  alt=""
                                />
                              </div>
                              <span className="colorWhite font-26">
                                Messages
                              </span>
                            </div>
                            <div className="">
                              <img
                                className="img-fluid"
                                src={Images.crossCircle}
                                alt=""
                                onClick={(e) => handleCross(e)}
                              />
                            </div>
                          </div>

                          <div className="pt-4 px-5 pb-4 chat_Box">
                            {liveCommentsList?.map((data, index) => {
                              return (
                                <>
                                  {userId?.id == data?.created_by?.id ? (
                                    <div
                                      className="chatBoxOuter border-26 replyChatBox"
                                      key={index}
                                    >
                                      <div className="chatBoxInner chatboxReply">
                                        <span>
                                          <img
                                            className="user"
                                            src={
                                              data?.created_by?.profile_image
                                            }
                                            alt=""
                                          />
                                        </span>
                                        <div className="me-3">
                                          <p className="colorWhite subtext mb-0">
                                            {data?.created_by?.full_name}
                                          </p>
                                          <p className="colorWhite subtext mb-0 textLiveStreaming">
                                            {data?.comment}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="chatBoxOuter border-26">
                                      <div className="chatBoxInner">
                                        <div className="d-flex">
                                          <span>
                                            <img
                                              className="user"
                                              src={
                                                data?.created_by?.profile_image
                                                  ? data?.created_by
                                                      ?.profile_image
                                                  : Images.userimg
                                              }
                                              alt=""
                                            />
                                          </span>
                                          <div className="ms-3">
                                            <p className="colorWhite subtext mb-0">
                                              {data?.created_by?.full_name}
                                            </p>
                                            <p className="colorWhite subtext mb-0 textLiveStreaming">
                                              {data?.comment}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </>
                              );
                            })}
                          </div>
                          <div className="py-3 px-3  chatInputbox chatInputboxResp">
                            <form
                              onSubmit={(event) => handleSendComments(event)}
                            >
                              <input
                                className="text"
                                type="text"
                                placeholder="Type here..."
                              />

                              <button className="send-live-msg" type="submit">
                                <img src={Images.adminsend} alt="" />
                              </button>
                            </form>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="d-flex align-items-center justify-content-between mb-4">
                      <div className="Buttons endcallouter">
                        <button
                          className="endCallButton login-btn bg-danger"
                          type="button"
                          id="leave"
                          onClick={(e) => handleEndCall(e)}
                        >
                          <img
                            className="muteIcon"
                            src={Images.endcall}
                            alt=""
                          />
                          Leave
                        </button>
                      </div>

                      <div className="mszBox">
                        <img
                          className="w-100"
                          src={Images.msgbox}
                          alt=""
                          onClick={(e) => handleShowCommentBoxes(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </>
  );
};

export default JoinLiveStreaming;
