import { Link } from "react-router-dom";
import * as Images from "../../utilities/images";

const NotFound = () => {
  return (
    <div>
      <section className="favoritesBanner">
        <div className="container">
          <div className="col-md-12 text-center pt-0">
            <h1 className="courseHeading font-28 pb-2  text-center">
              Page Not found!
            </h1>
            <div className="userImg">
              <img className="img-fluid" src={Images.notFound} alt="" />
            </div>
            <Link className="colorpink" to={"/"}>
          <img className="backicon" src={Images.backarrow} alt="" />
          Back to home
        </Link>
          </div>
        </div>
     
      </section>
    </div>
  );
};
export default NotFound;
