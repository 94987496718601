import React, { useState, useEffect } from "react";
import * as Images from "../../../utilities/images";
import { useDispatch } from "react-redux";
import {
  loginUserProfile,
  removePicture,
  updateProfile,
  uploadPicture,
} from "../../../redux/slices/user";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { Spinner } from "react-bootstrap";

const UpdateMyProfile = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [profilePicOld, setprofilePicOld] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    email: "",
  });
  const introSchema = Yup.object().shape({
    first_name: Yup.string()
      .matches(/^[a-zA-Z]+$/, "First name should only contain letters.")
      .min(2, "First name is Too Short!")
      .max(25, "First name is Too Long!")
      .required("First name is required!"),
    last_name: Yup.string()
      .matches(/^[a-zA-Z]+$/, "Last name should only contain letters.")
      .min(2, "Last name is Too Short!")
      .max(25, "Last name is Too Long!")
      .required("Last name is required!"),
  });

  const onSubmit = (values) => {
    let params = {
      first_name: values?.first_name,
      last_name: values?.last_name,
      email: values?.email,
    };
    setLoading(true);
    dispatch(
      updateProfile({
        ...params,
        cb(res) {
          setLoading(false);
          if (res?.data?.status_code == 400) {
            setErrorMessage({ error: res.data.message });
            return false;
          }
          if (res?.data?.status_code == 200) {
            setErrorMessage("");
            getAdminUser();
            toast.success("Admin profile updated successfully!");
          } else {
          }
        },
      })
    );
  };

  const getAdminUser = () => {
    dispatch(
      loginUserProfile({
        cb(res) {
          if (res) {
            const { first_name, last_name, email } = res.data.data;
            setData({
              first_name,
              last_name,
              email,
            });
            setprofilePicOld(res?.data?.data?.profile_image);
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };

  const handleReset = () => {
    getAdminUser();
    window.location.reload();
  };
  const onUploadPicture = (e) => {
    if (e.target.files?.length <= 0) return;

    const isImageValid = /\.(gif|jpeg|tiff?|png|jpg|PNG|JPEG)$/i.test(
      e.target.files[0].name
    );

    if (isImageValid === false) {
      toast.error("Please select valid image format");
      return;
    }

    let params = {
      image: e.target.files[0],
    };

    dispatch(
      uploadPicture({
        ...params,
        cb(res) {
          if (res.data) {
            getAdminUser();
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };
  const handleRemovePicture = (e) => {
    e.preventDefault();
    dispatch(
      removePicture({
        cb(res) {
          if (res.data) {
            toast.success("Profile Picture removed");
            getAdminUser();
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };

  useEffect(() => {
    getAdminUser();
    window.scrollTo(0, 0);
    document.title = "Update-Profile";
  }, []);

  return (
    <>
      <div className="contentCommon ">
        <div className="space">
          <div className="cart_max_inner mb-3 mt-4">
            <div className="cart_max_child text-center py-4">
              <div className="cart_img dropdown mb-3">
                <img
                  src={profilePicOld ? profilePicOld : Images.dummyAdminlogo}
                  className="img-fluid profileBig mx-0"
                  alt="user img"
                />
                <img
                  src={Images.editimg}
                  alt=""
                  className="profile_edit dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                ></img>
                <ul className="dropdown-menu updateProfileDropdown updateprofileDropdown ">
                  <li>
                    <input
                      type="file"
                      id="profileImg"
                      name="image"
                      className="form-control d-none"
                      onChange={onUploadPicture}
                      accept="image/png, image/gif, image/jpeg"
                    />

                    <label for="profileImg">
                      <span id="file-name" className="file-box d-none">
                        Choose File
                      </span>
                      <img src={Images.uploadimg} alt="img" className="me-2" />
                      <span className="uploadSpan">Upload New Image</span>
                    </label>
                  </li>
                  <li>
                    <span onClick={(e) => handleRemovePicture(e)}>
                      <img src={Images.trash} alt="Remove" className="me-2" />{" "}
                      &nbsp;Remove
                    </span>
                  </li>
                </ul>
              </div>
              <div className="row justify-content-center mt-4">
                <div className="col-md-6">
                  <Formik
                    initialValues={{ ...data }}
                    validationSchema={introSchema}
                    enableReinitialize
                    onSubmit={onSubmit}
                  >
                    {({ errors, touched }) => (
                      <Form className="profileForm">
                        <div className="row ">
                          <div className="mb-0 mb-md-4 form-group col-md-6">
                            <div className="position-relative">
                              <Field
                                name="first_name"
                                placeholder="Update First Name"
                                id="first_name"
                                autoComplete="off"
                                className="form-control mb-2"
                              />

                              <img
                                className="inputIcon"
                                src={Images.profile}
                                alt=""
                              />
                            </div>
                            {errors.first_name && touched.first_name ? (
                              <div className="validationMsg">
                                {errors.first_name}
                              </div>
                            ) : null}
                          </div>
                          <div className="mb-0 mb-md-4 form-group col-md-6">
                            <div className="position-relative">
                              <Field
                                name="last_name"
                                placeholder="Update Last Name "
                                id="last_name"
                                autoComplete="off"
                                className="form-control mb-2"
                              />

                              <img
                                className="inputIcon"
                                src={Images.profile}
                                alt=""
                              />
                            </div>
                            {errors.last_name && touched.last_name ? (
                              <div className="validationMsg">
                                {errors.last_name}
                              </div>
                            ) : null}
                          </div>
                          <div className="mb-3 form-group col-md-12">
                            <Field
                              name="email"
                              placeholder="Update LastName Name"
                              id="email"
                              autoComplete="off"
                              className="form-control mb-2 disableClass"
                              disabled
                            />
                            <img
                              className="inputIcon"
                              src={Images.mail}
                              alt=""
                            />
                          </div>
                          {errorMessage ? (
                            <span
                              className={
                                errorMessage?.error ? "validationMsg" : "d-none"
                              }
                            >
                              {errorMessage?.error ? errorMessage?.error : ""}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="mt-4 cancle-update-profile">
                          <div className="">
                            <button
                              className="primaryBtn cancelBtn m-0"
                              type="button"
                              onClick={handleReset}
                            >
                              <span>Cancel</span>
                            </button>
                          </div>
                          <div className="">
                            <button
                              className="primaryBtn submitBtn m-0"
                              type="submit"
                            >
                              <span>Update</span>
                              {loading && (
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                  className="ms-1"
                                />
                              )}
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateMyProfile;
