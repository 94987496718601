import { CKEditor } from "ckeditor4-react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getAboutUs,
  getPrivacyPolicy,
  getTermsConditions,
  updateInfo,
} from "../../../../redux/slices/admin";
import { useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";

const Info = () => {
  const dispatch = useDispatch();
  const [ckKey, setCkKey] = useState(Math.random());
  const [aboutUs, setAboutUs] = useState(null);
  const [privacyPolicy, setPrivacyPolicy] = useState(null);
  const [termsConditions, setTermsConditions] = useState(null);
  const [loading, setLoading] = useState(false);

  const aboutsUs = () => {
    let params = {
      page_type: 3,
    };

    dispatch(
      getAboutUs({
        ...params,
        cb(res) {
          if (res?.data) {
            setAboutUs(res?.data?.data?.description);
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };

  const PrivacyPolicyData = () => {
    let params = {
      page_type: 2,
    };

    dispatch(
      getPrivacyPolicy({
        ...params,
        cb(res) {
          if (res) {
            setPrivacyPolicy(res?.data?.data?.description);
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };

  const TermsConditionsData = () => {
    let params = {
      page_type: 1,
    };

    dispatch(
      getTermsConditions({
        ...params,
        cb(res) {
          if (res?.data) {
            setTermsConditions(res?.data?.data?.description);
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };
  const handleUpdateTermsConditions = () => {
    let params = {
      title: "Terms & Conditions",
      description: termsConditions,
      page_type: 1,
    };
    setLoading(true);
    dispatch(
      updateInfo({
        ...params,
        cb(res) {
          setLoading(false);
          if (res?.data) {
            //TermsConditionsData();
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };
  const handleUpdatePrivacyPolicy = () => {
    let params = {
      title: "Privacy Policy",
      description: privacyPolicy,
      page_type: 2,
    };
    setLoading(true);
    dispatch(
      updateInfo({
        ...params,
        cb(res) {
          setLoading(false);
          if (res?.data) {
            //PrivacyPolicyData();
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };
  const handleUpdateAboutUs = () => {
    let params = {
      title: "About Us",
      description: aboutUs,
      page_type: 3,
    };
    setLoading(true);
    dispatch(
      updateInfo({
        ...params,
        cb(res) {
          setLoading(false);
          if (res?.data) {
            //aboutsUs();
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };
  useEffect(() => {
    PrivacyPolicyData();
    TermsConditionsData();
    aboutsUs();
    window.scrollTo(0, 0);
    document.title = "Info-Pages";
  }, []);

  return (
    <>
      <ul className="nav nav-pills IngredientsList mb-4 infotab_">
        <li className="active">
          <Link to="#Privacy" data-toggle="tab">
            Privacy & policy
          </Link>
        </li>
        <li>
          <Link to="#Terms" data-toggle="tab">
            Terms & Conditions
          </Link>
        </li>
        <li>
          <Link to="#About" data-toggle="tab">
            About US
          </Link>
        </li>
      </ul>
      <div className="contentCommon privacyPolicy">
        <div id="exTab1" className="container">
          <div className="tab-content clearfix">
            <div className="tab-pane active" id="Privacy">
              <h3 className="medText"> Privacy & policy</h3>
              {/* <p className="text">Text here </p> */}
              {/* {loading ? ( */}
              <div className="contentOuter">
                {/* <p className="text_">{privacyPolicy?.title}</p> */}
                {privacyPolicy && (
                  <CKEditor
                    key={ckKey}
                    initData={privacyPolicy}
                    config={{
                      removePlugins: [
                        "EasyImage",
                        "ImageUpload",
                        "MediaEmbed",
                        "Table",
                        "table",
                        "TableToolbar",
                        "image",
                      ],
                      toolbarLocation: ["bottom"],

                      removeButtons:
                        "Table,TableToolbar,Anchor,HorizontalRule,Blockquote",
                      versionCheck: false,
                    }}
                    name="editor"
                    readOnly={false}
                    onChange={(event, editor) => {
                      const data = event.editor.getData();

                      setPrivacyPolicy(data);
                    }}
                    type="classic"
                  />
                )}
              </div>
              {/* ) : (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="infoSpinner"
                />
              )}{" "} */}
              <div className="row mt-4">
                <div className="col-12">
                  <div className="courseFoot">
                    <button
                      className="primaryBtn submitBtn m-0"
                      type="button"
                      onClick={(e) => handleUpdatePrivacyPolicy(e)}
                    >
                      {loading && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="ms-1"
                        />
                      )}{" "}
                      <span>Update</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="tab-pane" id="Terms">
              <h3 className="medText"> Terms & Conditions</h3>
              {/* <p className="text">Text here </p> */}
              {/* {loading ? ( */}
              <div className="contentOuter">
                {/* <p className="text_">{termsConditions?.title}</p> */}
                {termsConditions && (
                  <CKEditor
                    key={ckKey}
                    initData={termsConditions}
                    config={{
                      removePlugins: [
                        "EasyImage",
                        "ImageUpload",
                        "MediaEmbed",
                        "Table",
                        "table",
                        "TableToolbar",
                        "image",
                      ],
                      toolbarLocation: ["bottom"],
                      removeButtons:
                        "Table,TableToolbar,Anchor,HorizontalRule,Blockquote",
                      versionCheck: false,
                    }}
                    name="editor"
                    readOnly={false}
                    onChange={(event, editor) => {
                      const data = event.editor.getData();

                      setTermsConditions(data);
                    }}
                    type="classic"
                  />
                )}
              </div>
              {/* ) : (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="infoSpinner"
                />
              )}{" "} */}
              <div className="row mt-4">
                <div className="col-12">
                  <div className="courseFoot">
                    <button
                      className="primaryBtn submitBtn m-0"
                      type="button"
                      onClick={(e) => handleUpdateTermsConditions(e)}
                    >
                      {loading && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="ms-1"
                        />
                      )}{" "}
                      <span>Update</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane" id="About">
              <h3 className="medText"> About Us</h3>
              {/* <p className="text">Text here </p> */}
              {/* {loading ? ( */}
              <div className="contentOuter">
                {/* <p className="text_">{aboutUs?.title}</p> */}

                {aboutUs && (
                  <CKEditor
                    key={ckKey}
                    initData={aboutUs}
                    config={{
                      removePlugins: [
                        "EasyImage",
                        "ImageUpload",
                        "MediaEmbed",
                        "Table",
                        "table",
                        "TableToolbar",
                        "image",
                      ],

                      toolbarLocation: ["bottom"],
                      removeButtons:
                        "Table,TableToolbar,Anchor,HorizontalRule,Blockquote",
                      versionCheck: false,
                    }}
                    name="editor"
                    readOnly={false}
                    onChange={(event, editor) => {
                      const data = event.editor.getData();
                      setAboutUs(data);
                    }}
                    type="classic"
                  />
                )}
              </div>
              {/* ) : (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="infoSpinner"
                />
              )}{" "} */}
              <div className="row mt-4">
                <div className="col-12">
                  <div className="courseFoot">
                    <button
                      className="primaryBtn submitBtn m-0"
                      type="button"
                      onClick={(e) => handleUpdateAboutUs(e)}
                    >
                      {loading && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="ms-1"
                        />
                      )}{" "}
                      <span>Update</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Info;
