import React, { useEffect } from "react";
import { socketInstance } from "../../../components/common/socketNew";
import { useNavigate } from "react-router-dom";
import { useAdminSelector } from "../../../../redux/selector/admin";

const EndCallModal = (props) => {
  const adminSelector = useAdminSelector();
  const getRoomId = adminSelector?.goLive?.data;

  const navigate = useNavigate();

  const handleEndCall = () => {
    socketInstance.emit("endliveStreaming", {
      room_id: getRoomId?.roomID,
    });
    socketInstance.on("endliveStreaming", (data) => {
    });
    navigate("/admin/dashboard");
    window.location.reload();
  };

  return (
    <div className="py-3">
      <h5 className="font-28 text-center">
        Are you Sure you want to end the live session ?
      </h5>

      <div className="row justify-content-center mt-4">
        <div className="d-grid gap-1 col-lg-3 col-md-6">
          <button
            className="primaryBtn submitBtn m-0"
            type="button"
            onClick={(e) => handleEndCall(e)}
          >
            <span>Yes, Exit</span>
          </button>
        </div>
        <div className="d-grid gap-1 col-lg-3 col-md-6">
          <button
            className="primaryBtn cancelBtn m-0"
            onClick={(e) => props.closeModal()}
          >
            <span>No, Cancel</span>
          </button>
        </div>
      </div>
    </div>
  );
};
export default EndCallModal;
