import io from "socket.io-client";

// const loginToken = store?.getState().auth?.userLogin?.data?.token;
// console.log(store.getState(),'token');
// console.log(loginToken,"loginToken");

const token = localStorage.getItem("authToken");
export const socketInstance = io(
  `${process.env.REACT_APP_API_URL}`,
  {
    path: "/api/v1/connect",
    extraHeaders: { Authorization: token },
  }
);
