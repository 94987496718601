import { Outlet } from "react-router-dom";
import MainFooter from "../components/common/mainFooter";
import MainNav from "../components/common/mainNav";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const MainLayout2 = () => {
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );

  return (
    <>
      <MainNav />
      <main className="main">
        <Elements stripe={stripePromise}>
          <Outlet />
        </Elements>
      </main>
      <MainFooter />
    </>
  );
};

export default MainLayout2;
