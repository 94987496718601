import React, { useState, useEffect } from "react";
import * as Images from "../../../../utilities/images";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getCategories,
  getRecipeDetails,
  updateRecipeDetails,
} from "../../../../redux/slices/admin";
import { toast } from "react-toastify";
import CustomModal from "../../../components/modals/modal";
import AttachClass from "./attachclass";
import { Spinner } from "react-bootstrap";
import { useAdminSelector } from "../../../../redux/selector/admin";
import AWS from "aws-sdk";
import ProgressBar from "react-bootstrap/ProgressBar";

const EditRecipe = () => {
  AWS.config.update({
    region: process.env.REACT_APP_AWS_S3_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  });

  const s3 = new AWS.S3();

  const [videoLoading, setVideoLoading] = useState(false);
  const [videoLoadingPercent, setVideoLoadingPercent] = useState(0);
  const toastId = React.useRef(null);
  const [videoKey, setVideoKey] = useState(0);
  const [loader, setLoader] = useState(false);
  const { loading } = useAdminSelector();
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [cookingTime, setCookingTime] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [categoryTitle, setCategoryTitle] = useState("");
  const [direction, setDirection] = useState("");
  const [thumbnailImage, setThumbnailImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [recipeVideo, setRecipeVideo] = useState(null);
  const [previewVideo, setPreviewVideo] = useState(null);
  const [videoThumbnail, setVideoThumbnail] = useState(null);
  const [showAttach, setShowAttach] = useState(true);
  const [previewthumbnail, setPreviewThumbnail] = useState(null);
  const [accesslevels, setAccesslevels] = useState("");

  const [showVideoOuter, setShowVideoOuter] = useState(true);
  const [ingredient, setIngredient] = useState([
    { value: "", id: Math.random() },
  ]);
  const [errorsIngredient, setErrorIngredient] = useState([]);
  const [nutrition, setNutrition] = useState([
    { value: "", id: Math.random() },
  ]);
  const [errorsNutrition, setErrorNutrition] = useState([]);
  const [steps, setSteps] = useState([{ value: "", id: Math.random() }]);
  const [errorsSteps, setErrorsSteps] = useState([]);
  const [activeTabServing, setActiveTabServing] = useState("1");
  const [Categories, setCategories] = useState([]);
  const [classVideo, setClassVideo] = useState("");
  const [newFileName, setNewFileName] = useState("");
  const [fileExtension, setFileExtension] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const accesLevels = [
    { id: 1, title: "Free" },
    { id: 2, title: "Paid" },
  ];
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
  const handleVideoError = (event) => {
    console.error("Video error:", event.target.error);
    const url1 =
      "https://d1u9rinm8sz0kp.cloudfront.net/" +
      newFileName +
      "/" +
      fileExtension +
      "/" +
      newFileName +
      "." +
      fileExtension;
    setTimeout(() => {
      setPreviewVideo(null);
      setPreviewVideo(url1);
      setVideoKey((prevKey) => prevKey + 1);
    }, 30000);
    setLoader(true);

    console.log(url1, "url");
    // Additional error handling logic, if needed
  };

  const handleVideoSuccess = () => {
    console.log("Video is ready to play!");
    setLoader(false);
    // Additional success handling logic, if needed
  };
  //for Nutration
  const handleInputChangeNutrition = (index, event) => {
    const { name, value } = event.target;
    const list = [...nutrition];
    const itemData = list[index];

    list[index] = { value: value, id: itemData.id };
    setNutrition(list);

    const val = value.trim();
    const errorIndex = errorsNutrition.findIndex((x) => x.id === itemData.id);
    const errorObjData = getErrorObj(val, itemData, "Nutrition");
    const errorsNutritionCopy = [...errorsNutrition];

    if (errorObjData !== null) {
      if (errorIndex >= 0) {
        errorsNutritionCopy[errorIndex] = errorObjData;
        setErrorNutrition(errorsNutritionCopy);
      } else {
        errorsNutritionCopy.push(errorObjData);
        setErrorNutrition(errorsNutritionCopy);
      }
    } else {
      if (errorIndex >= 0) {
        errorsNutritionCopy.splice(errorIndex, 1);
        setErrorNutrition(errorsNutritionCopy);
      }
    }
  };
  const handleAddInputNutrition = () => {
    setNutrition([...nutrition, { value: "", id: Math.random() }]);
  };

  const handleRemoveInputNutrition = (index) => {
    const list = [...nutrition];
    const listData = list[index];

    list.splice(index, 1);
    setNutrition(list);

    const errorIndex = errorsNutrition.findIndex((x) => x.id === listData.id);
    if (errorIndex >= 0) {
      const errorsNutritionCopy = [...errorsNutrition];
      errorsNutritionCopy.splice(errorIndex, 1);
      setErrorNutrition(errorsNutritionCopy);
    }
  };

  //for Ingredients
  const handleInputChangeIngredient = (index, event) => {
    const { name, value } = event.target;
    const list = [...ingredient];
    const itemData = list[index];

    list[index] = { value: value, id: itemData.id };
    setIngredient(list);

    const val = value.trim();
    const errorIndex = errorsIngredient.findIndex((x) => x.id === itemData.id);
    const errorObjData = getErrorObj(val, itemData, "Ingrediant");
    const errorsIngredientCopy = [...errorsIngredient];

    if (errorObjData !== null) {
      if (errorIndex >= 0) {
        errorsIngredientCopy[errorIndex] = errorObjData;
        setErrorIngredient(errorsIngredientCopy);
      } else {
        errorsIngredientCopy.push(errorObjData);
        setErrorIngredient(errorsIngredientCopy);
      }
    } else {
      if (errorIndex >= 0) {
        errorsIngredientCopy.splice(errorIndex, 1);
        setErrorIngredient(errorsIngredientCopy);
      }
    }
  };

  const handleAddInputIngredient = () => {
    setIngredient([...ingredient, { value: "", id: Math.random() }]);
  };

  const handleRemoveInputIngredient = (index) => {
    const list = [...ingredient];
    const listData = list[index];

    list.splice(index, 1);
    setIngredient(list);

    const errorIndex = errorsIngredient.findIndex((x) => x.id === listData.id);
    if (errorIndex >= 0) {
      const errorsIngredientCopy = [...errorsIngredient];
      errorsIngredientCopy.splice(errorIndex, 1);
      setErrorIngredient(errorsIngredientCopy);
    }
  };

  //for steps
  const handleInputChangeSteps = (index, event) => {
    const { name, value } = event.target;
    const list = [...steps];
    const itemData = list[index];

    list[index] = { value: value, id: itemData.id };
    setSteps(list);

    const val = value.trim();
    const errorIndex = errorsSteps.findIndex((x) => x.id === itemData.id);
    const errorObjData = getErrorObj(val, itemData, "Steps");
    const errorsStepsCopy = [...errorsSteps];

    if (errorObjData !== null) {
      if (errorIndex >= 0) {
        errorsStepsCopy[errorIndex] = errorObjData;
        setErrorsSteps(errorsStepsCopy);
      } else {
        errorsStepsCopy.push(errorObjData);
        setErrorsSteps(errorsStepsCopy);
      }
    } else {
      if (errorIndex >= 0) {
        errorsStepsCopy.splice(errorIndex, 1);
        setErrorsSteps(errorsStepsCopy);
      }
    }
  };
  const handleAddInputSteps = () => {
    setSteps([...steps, { value: "", id: Math.random() }]);
  };

  const handleRemoveInputSteps = (index) => {
    const list = [...steps];
    const listData = list[index];

    list.splice(index, 1);
    setSteps(list);

    const errorIndex = errorsSteps.findIndex((x) => x.id === listData.id);
    if (errorIndex >= 0) {
      const errorsStepsCopy = [...errorsSteps];
      errorsStepsCopy.splice(errorIndex, 1);
      setErrorsSteps(errorsStepsCopy);
    }
  };
  const recipeDetails = () => {
    let params = {
      id: id,
    };
    dispatch(
      getRecipeDetails({
        ...params,
        cb(res) {
          if (res?.data) {
            setAccesslevels(res?.data?.data?.access_level);
            setTitle(res?.data?.data?.title);
            setDescription(res?.data?.data?.description);
            setCategoryId(res?.data?.data?.category_id);
            setCategoryTitle(res?.data?.data?.category_title);
            setCookingTime(res?.data?.data?.cooking_time);
            setActiveTabServing(res?.data?.data?.serving);
            setThumbnailImage(res?.data?.data?.thumbnail_image);
            setPreviewThumbnail(res?.data?.data?.recipe_image);
            setPreviewVideo(res?.data?.data?.recipe_video);
            setDirection(res?.data?.data?.direction);
            setIngredient(
              res?.data?.data?.ingredient?.map((data) => ({
                value: data,
                id: Math.random(),
              }))
            );
            setNutrition(
              res?.data?.data?.nutrition?.map((data) => ({
                value: data,
                id: Math.random(),
              }))
            );
            setSteps(
              res?.data?.data?.direction_step?.map((data) => ({
                value: data,
                id: Math.random(),
              }))
            );
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };
  const handleCrossVideo = () => {
    setShowAttach(true);
    setPreviewVideo(null);
    setVideoKey(null);
    setRecipeVideo(null);
  };
  const validateIngrediants = () => {
    let invalidIngrediants = [];

    ingredient.map((item, idx) => {
      const val = item.value.trim();
      const errorIndex = errorsIngredient.findIndex((x) => x.id === item.id);

      if (errorIndex >= 0) {
        return;
      }

      const errorObjData = getErrorObj(val, item, "Ingrediant");
      if (errorObjData !== null) {
        invalidIngrediants.push(errorObjData);
      }
    });

    if (invalidIngrediants.length > 0) {
      const copiedError = [...invalidIngrediants, ...errorsIngredient];
      setErrorIngredient(copiedError);
    }
    return invalidIngrediants;
  };

  const validateNurition = () => {
    let invalidNutrition = [];

    nutrition.map((item, idx) => {
      const val = item.value.trim();
      const errorIndex = errorsNutrition.findIndex((x) => x.id === item.id);

      if (errorIndex >= 0) {
        return;
      }

      const errorObjData = getErrorObj(val, item, "Nutrition");
      if (errorObjData !== null) {
        invalidNutrition.push(errorObjData);
      }
    });

    if (invalidNutrition.length > 0) {
      const copiedError = [...invalidNutrition, ...errorsNutrition];
      setErrorNutrition(copiedError);
    }
    return invalidNutrition;
  };
  const validateSteps = () => {
    let invalidSteps = [];

    steps.map((item, idx) => {
      const val = item.value.trim();
      const errorIndex = errorsSteps.findIndex((x) => x.id === item.id);

      if (errorIndex >= 0) {
        return;
      }

      const errorObjData = getErrorObj(val, item, "Steps");
      if (errorObjData !== null) {
        invalidSteps.push(errorObjData);
      }
    });

    if (invalidSteps.length > 0) {
      const copiedError = [...invalidSteps, ...errorsSteps];
      setErrorsSteps(copiedError);
    }
    return invalidSteps;
  };

  const getErrorObj = (val, item, type) => {
    let letterVal = /^[a-zA-Z\s]*$/;

    if (val === "" || val === undefined || val === null) {
      return {
        id: item.id,
        errorMessage: `${type} Can not be empty`,
      };
    }

    return null;
  };

  const updateRecipe = (e) => {
    e.preventDefault();
    const invalidIngrediantsData = validateIngrediants();
    const invalidNutritionData = validateNurition();
    const invalidSteps = validateSteps();

    let letterVal = /^[a-zA-Z\s]*$/;
    let numberRegex = /^\d+$/;

    if (accesLevels == "") {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please select access level");
      }
      return;
    }
    if (!title) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter title");
      }
      return;
    } else if (title.length <= 2) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Title should be minimum 3 character");
      }
      return;
    } else if (title.length > 50) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(
          "Title should be maximun 50 character only"
        );
      }
      return;
    } else if (!title.match(letterVal)) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter alphabet characters only");
      }
      return;
    } else if (!description) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter description");
        return;
      }
    } else if (description.length <= 2) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(
          "Description should be minimum 3 character"
        );
      }
      return;
    } else if (!categoryId) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please select category type");
      }
      return;
    } else if (!cookingTime) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please add cooking time");
      }
      return;
    }
    //   else if(Number(cookingTime) > 36000){
    //   toast.error("cooking time should not be greater then 36000 min")

    // }
    else if (!cookingTime.match(numberRegex)) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(
          "Please add valid time (in numbers only)"
        );
      }
      return;
    } else if (!activeTabServing) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please add serving peoples");
      }
      return;
    } else if (!thumbnailImage) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please add thumbnail image");
      }
      return;
    } else if (!previewVideo) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please add recipe video");
      }
      return;
    }
    if (
      invalidIngrediantsData.length > 0 ||
      errorsIngredient.length > 0 ||
      invalidNutritionData.length > 0 ||
      errorsNutrition.length > 0 ||
      invalidSteps.length > 0 ||
      errorsSteps.length > 0
    ) {
      toast.error("Please correct errors");
      return;
    } else if (!direction) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter direction ");
      }
      return;
    } else if (direction.length <= 2) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(
          "Direction should be minimum 3 character"
        );
      }
      return;
    }
    // else if (!videoThumbnail) {
    //   if (!toast.isActive(toastId.current)) {
    //     toastId.current =
    //       toast.error("Please add Video thumbnail");
    //   }
    //   return;
    // }

    let params = {
      id: id,
      access_level: accesslevels,
      title: title,
      category_id: categoryId,
      description: description,
      cooking_time: cookingTime,
      serving: activeTabServing,
      direction: direction,
      direction_step: steps,
      thumbnail_image: thumbnailImage,
      recipe_image: videoThumbnail,
      recipe_video: previewVideo,
      // class_video: classVideo.plain_class_video,
      ingredient: ingredient,
      nutrition: nutrition,
    };
    dispatch(
      updateRecipeDetails({
        ...params,
        cb(res) {
          if (res.status) {
            navigate("/admin/recipe");
          } else {
          }
        },
      })
    );
  };
  const getCategory = () => {
    dispatch(
      getCategories({
        cb(res) {
          if (res?.data) {
            setCategories(res?.data?.data);
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };

  useEffect(() => {
    recipeDetails();
    getCategory();
    window.scrollTo(0, 0);
    document.title = "Edit-Recipe";
  }, []);

  const handleUploadImage = (e) => {
    const file = e.target.files[0];
    if (!file.name.match(/\.(jpg|png|jpeg|)$/)) {
      toast.error("Please select a valid image.");
      return false;
    }
    if (file) {
      setThumbnailImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleAddVedio = (e) => {
    setVideoLoading(true);
    const file = e.target.files[0];
    const selectedFile = e.target.files[0];
    var modifiedFile;
    if (selectedFile) {
      const randomNumber = Math.floor(Math.random() * 90000000) + 10000000;
      const fileExtension = selectedFile.name.split(".").pop();
      const newFileName = randomNumber.toString() + "." + fileExtension;
      modifiedFile = new File([selectedFile], newFileName, {
        type: selectedFile.type,
      });
      console.log(modifiedFile, "modifiedFile");
      // Set the modified file
      setRecipeVideo(modifiedFile);
      setShowAttach(false);
    } else {
      setVideoLoading(false);
      return false;
    }
    if (!modifiedFile.name.match(/\.(mp4|video|webm|)$/)) {
      toast.error("Please select a valid video.");
      setVideoLoading(false);
      return false;
    }
    if (modifiedFile) {
      console.log(modifiedFile, "filefile");
      const params = {
        Bucket: process.env.REACT_APP_AWS_S3_BUCKET,
        Key: modifiedFile.name,
        Body: file,
      };
      s3.upload(params, (err, data) => {
        if (err) {
          setVideoLoading(false);
          setVideoLoadingPercent(0);
          console.error("Error uploading file", err);
        } else {
          setVideoLoading(false);
          setVideoLoadingPercent(0);
          console.log("File uploaded successfully", data);
          const videoUrl = data.Key;
          const fileName = videoUrl.split(".").slice(0, -1).join(".");
          const newfileName = fileName.split("/").pop();

          // console.log("fileName", newfileName);

          const fileExtension = videoUrl.split(".").pop();
          setNewFileName(newfileName);
          setFileExtension(fileExtension);
          // console.log("fileExtension", fileExtension);
          const url =
            "https://d1u9rinm8sz0kp.cloudfront.net/" +
            newfileName +
            "/" +
            fileExtension +
            "/" +
            newfileName +
            "." +
            fileExtension;
          // console.log("url=======", url);
          // Check if the URL contains a specific error parameter
          setPreviewVideo(url);
          setLoader(true);
        }
      }).on("httpUploadProgress", function (progress) {
        let progressPercentage = Math.round(
          (progress.loaded / progress.total) * 100
        );
        setVideoLoadingPercent(progressPercentage);
      });
    }
  };

  const handleCrossImage = () => {
    setThumbnailImage(null);
    setPreviewImage(null);
  };

  const handleaddVideoThumbnail = (e) => {
    const file = e.target.files[0];

    if (!file.name.match(/\.(jpg|png|jpeg|)$/)) {
      toast.error("Please select a valid image.");
      return false;
    }
    if (file) {
      setVideoThumbnail(file);
      const reader = new FileReader();

      reader.onloadend = () => {
        setPreviewThumbnail(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
    }
  };
  const handleCrossThubnail = () => {
    setVideoThumbnail(null);
    setPreviewThumbnail(null);
  };
  return (
    <>
      <div className="AddClass">
        <div className="row mt-4">
          <div className="col-12">
            <div className="contentCommon">
              <div className="backBtn">
                <Link to={"/admin/recipe"}>
                  <button className="btn colorPink px-0 pb-2">
                    <img
                      src={Images.rightarrowpink}
                      alt="arrow"
                      className="me-2"
                    />{" "}
                    Go to Back
                  </button>
                </Link>
                <h1 className="courseHeading font-28 pb-2">Edit Recipe</h1>
              </div>
              <form className="profileForm">
                <div className="row ">
                  <div className="mb-3 form-group col-md-4">
                    <label
                      className="form-label pb-2"
                      htmlFor="exampleFormControlSelect1"
                    >
                      Access Type
                    </label>

                    <select
                      className="form-control text"
                      id="exampleFormControlSelect1"
                      required=""
                      value={accesslevels}
                      onChange={(e) => {
                        setAccesslevels(e.target.value);
                      }}
                    >
                      <option value="">Access type</option>
                      {accesLevels?.length > 0 ? (
                        <>
                          {accesLevels?.map((data) => {
                            return (
                              <option value={data?.id}>{data?.title}</option>
                            );
                          })}
                        </>
                      ) : (
                        ""
                      )}
                    </select>
                  </div>
                  <div className="mb-3 form-group col-md-12">
                    <label className="form-label pb-2">Title</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Update Title"
                      value={title}
                      maxLength={50}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>
                  <div className="mb-3 form-group col-md-12">
                    <label className="form-label pb-2">Description</label>
                    <textarea
                      name=""
                      id=""
                      cols="30"
                      rows="10"
                      placeholder="Update Description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      className="form-control"
                    ></textarea>
                  </div>
                  <div className="row">
                    <div className="mb-3 form-group col-md-6">
                      <label
                        className="form-label pb-2"
                        for="exampleFormControlSelect1"
                      >
                        Category Type
                      </label>
                      <select
                        className="form-control text"
                        id="exampleFormControlSelect1"
                        onChange={(event) => setCategoryId(event.target.value)}
                      >
                        <option>{categoryTitle}</option>
                        {Categories.map((data, index) => (
                          <option key={index} value={data.id}>
                            {data.title}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="mb-3 form-group col-md-6">
                      <label className="form-label pb-2">
                        Cooking Time(min)
                      </label>
                      <input
                        className="form-control text"
                        type="number"
                        placeholder=""
                        value={cookingTime}
                        minLength={1}
                        maxLength={5}
                        onChange={(e) => setCookingTime(e.target.value)}
                      />
                    </div>
                    <div className="col-md-12">
                      <label className="form-label pb-0 m-0">Serving</label>
                      <ul className="IngredientsList p-0 my-4">
                        {[1, 2, 3, 4, 5, 6, 7].map((item) => {
                          return (
                            <>
                              <li
                                className={`${activeTabServing == item ? "active" : ""
                                  } text`}
                              >
                                <Link onClick={() => setActiveTabServing(item)}>
                                  {" "}
                                  {item} People
                                </Link>
                              </li>
                            </>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
                <h4 className="customLabel py-2">Add Thumbnail Image </h4>
                <div className="mb-3 form-control col-md-12 fileBorder">
                  <input
                    type="file"
                    id="file-6"
                    className="inputfile inputfile-5"
                    // accept="image/jpeg,image/png,image/gif,image/tiff,image/webp,image/bmp"
                    onChange={(e) => handleUploadImage(e)}
                  />

                  <div className="dnldFileOuter ThumbnailImgOuter">
                    {Boolean(thumbnailImage) ? (
                      <>
                        <img
                          src={previewImage ? previewImage : thumbnailImage}
                          className="img-fluid w-100 mx-0"
                          alt="upload"
                        />
                        <img
                          className="crossIcon"
                          src={Images.whitecross}
                          alt=""
                          onClick={(e) => handleCrossImage(e)}
                        />
                      </>
                    ) : (
                      <label for="file-6" className="labelNewCase">
                        <span className="file_span">
                          <img src={Images.downloadicon} />
                          <br />
                          <span className="colorPink">Browse</span> and drag and
                          drop the file. Upload JPG & PNG.
                        </span>
                      </label>
                    )}
                  </div>
                </div>
                <div className="IngredientsBox">
                  <div className="IngredientsBoxInner">
                    <div className="d-flex align-items-center">
                      <img src={Images.dinner} alt="" />
                      <p className="medText m-0 ms-3">Ingredients</p>
                    </div>
                    <div className="">
                      <button
                        className="login-btn"
                        type="button"
                        onClick={handleAddInputIngredient}
                      >
                        Add
                      </button>
                      <span className="center-img">
                        <img src={Images.centerrectangle} alt="" />
                      </span>
                      <span className="circle-btn recipeBtnCircle">
                        <img src={Images.plussign} alt="" />
                      </span>
                    </div>
                  </div>
                  {ingredient.map((item, idx) => (
                    <div
                      key={item.id}
                      className="IngredientsBoxInner form-group-outer"
                    >
                      <div className="form-group col-md-11">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Add Ingredients"
                          value={item.value}
                          onChange={(event) =>
                            handleInputChangeIngredient(idx, event)
                          }
                        />
                        <div>
                          {errorsIngredient.findIndex(
                            (x) => x.id === item.id
                          ) >= 0 ? (
                            <label style={{ color: "red" }}>
                              {
                                errorsIngredient.find((x) => x.id === item.id)
                                  .errorMessage
                              }
                            </label>
                          ) : null}
                        </div>
                      </div>
                      <div
                        className=""
                        onClick={() => handleRemoveInputIngredient(idx)}
                      >
                        <img src={Images.trash} alt="" />
                      </div>
                    </div>
                  ))}
                </div>
                <div className="IngredientsBox">
                  <div className="IngredientsBoxInner">
                    <div className="d-flex align-items-center">
                      <img src={Images.nutrition} alt="" />
                      <p className="medText m-0 ms-3">Nutrition</p>
                    </div>
                    <div className="">
                      <button
                        className="login-btn"
                        type="button"
                        onClick={handleAddInputNutrition}
                      >
                        Add
                      </button>
                      <span className="center-img">
                        <img src={Images.centerrectangle} alt="" />
                      </span>
                      <span className="circle-btn recipeBtnCircle">
                        <img src={Images.plussign} alt="" />
                      </span>
                    </div>
                  </div>
                  {nutrition.map((item, idx) => (
                    <div className="IngredientsBoxInner form-group-outer">
                      <div className="form-group col-md-11">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Add Nutrition"
                          value={item.value}
                          onChange={(event) =>
                            handleInputChangeNutrition(idx, event)
                          }
                        />
                        <div>
                          {errorsNutrition.findIndex((x) => x.id === item.id) >=
                            0 ? (
                            <p style={{ color: "red" }}>
                              {
                                errorsNutrition.find((x) => x.id === item.id)
                                  .errorMessage
                              }
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div
                        className=""
                        onClick={() => handleRemoveInputNutrition(idx)}
                      >
                        <img src={Images.trash} alt="" />
                      </div>
                    </div>
                  ))}
                </div>
                <div className="mt-4 mb-3 form-group col-md-12">
                  <p className="medText">Directions</p>
                  <label className="form-label pb-2">Directions Details</label>
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="10"
                    placeholder="Add Description"
                    className="form-control"
                    value={direction}
                    onChange={(e) => setDirection(e.target.value)}
                  ></textarea>
                </div>
                <div className="stepOuter">
                  <p>Steps</p>
                  <div className="">
                    <button
                      className="login-btn"
                      type="button"
                      onClick={handleAddInputSteps}
                    >
                      Add
                    </button>
                    <span className="center-img">
                      <img src={Images.centerrectangle} alt="" />
                    </span>
                    <span className="circle-btn recipeBtnCircle">
                      <img src={Images.plussign} alt="" />
                    </span>
                  </div>
                </div>
                {steps.map((item, idx) => (
                  <div className="d-flex justify-content-between stepInnerrr">
                    <div className="position-relative">
                      <img src={Images.stepbg} alt="" />
                      <p className="subtext stepText colorWhite">
                        Step{idx + 1}
                      </p>
                    </div>
                    <div className="mb-3 form-group col-md-11">
                      <textarea
                        name=""
                        id=""
                        cols="30"
                        rows="4"
                        maxLength={250}
                        placeholder="Add Steps"
                        className="form-control pararecipe"
                        value={item.value}
                        onChange={(event) => handleInputChangeSteps(idx, event)}
                      ></textarea>
                      <div>
                        {errorsSteps.findIndex((x) => x.id === item.id) >= 0 ? (
                          <p style={{ color: "red" }}>
                            {
                              errorsSteps.find((x) => x.id === item.id)
                                .errorMessage
                            }
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div
                      className=""
                      onClick={() => handleRemoveInputSteps(idx)}
                    >
                      <img src={Images.trash} alt="" />
                    </div>
                  </div>
                ))}
                <h4 className="customLabel py-2">Add Video thumbnail </h4>
                <div className="mb-3 form-control col-md-12 fileBorder">
                  <input
                    type="file"
                    id="file-10"
                    accept="image/jpg,image/png,image/jpeg"
                    className="inputfile inputfile-5"
                    onChange={(e) => handleaddVideoThumbnail(e)}
                  />

                  <div className="dnldFileOuter ThumbnailImgOuter">
                    {Boolean(previewthumbnail) ? (
                      <>
                        <img
                          src={
                            previewthumbnail
                              ? previewthumbnail
                              : Images.downloadicon
                          }
                          className="img-fluid w-100 mx-0"
                          alt="upload"
                        />
                        <img
                          className="crossIcon"
                          src={Images.whitecross}
                          alt=""
                          onClick={(e) => handleCrossThubnail(e)}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <label htmlFor="file-10" className="labelNewCase">
                    <span className="file_span">
                      <img src={Images.downloadicon} />
                      <br />
                      <span className="colorPink">Browse</span> and drag and
                      drop the file. Upload JPG & PNG.
                    </span>
                  </label>
                </div>
                <div className="col-md-12 py-2">
                  <h4 className="medText mb-3 mt-2">Recipe Video</h4>

                  {showAttach && (
                    <div
                      className="lernBtnCon mb-3"
                      onClick={() => {
                        setModalDetail({
                          show: true,
                          flag: "AttachClass",
                        });
                        setKey(Math.random());
                      }}
                    >
                      <a className="lernBtn attachPdfBtn attach-pointer">
                        <label className="colorPink attach-pointer" for="">
                          <img src={Images.attactpdf} alt="icon" />
                          &nbsp; Attach From Class
                        </label>
                      </a>
                    </div>
                  )}
                </div>

                {showVideoOuter && (
                  <>
                    {showAttach && showVideoOuter && (
                      <h4 className="customLabel pb-2">Or </h4>
                    )}

                    <div className="">
                      <h4 className="customLabel py-2">Upload Video </h4>
                      <div className="mb-3 form-control col-md-12 fileBorder">
                        <input
                          type="file"
                          id="file-7"
                          className="inputfile inputfile-5"
                          accept="video/mp4,video/webm"
                          onChange={(e) => handleAddVedio(e)}
                        />

                        {videoLoading ? (
                          <>
                            <div className="text-center">
                              <Spinner
                                as="span"
                                animation="border"
                                role="status"
                                aria-hidden="true"
                                className="pinky"
                              />
                              <p className="colorPink">Uploading</p>
                            </div>

                            {videoLoadingPercent > 0 ? (
                              <ProgressBar
                                now={videoLoadingPercent}
                                label={`${videoLoadingPercent}%`}
                              />
                            ) : null}
                          </>
                        ) : (
                          <div
                            className={`  py-3  previewVedio ${videoKey ? "bgPreviewVideo" : ""
                              }`}
                          >
                            {Boolean(previewVideo || videoKey) ? (
                              <React.Fragment key={videoKey}>
                                {loader && (
                                  <p className="colorPink">
                                    Processing
                                    <Spinner
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                  </p>
                                )}
                                <video
                                  className={`img-fluid w-100 vedio ${loader ? "d-none" : ""
                                    }`}
                                  controls
                                  autoPlay
                                  loop
                                  onError={handleVideoError}
                                  onLoadedData={handleVideoSuccess}
                                >
                                  <source src={previewVideo} />
                                </video>
                                <img
                                  className={`crossIcon ${loader ? "d-none" : ""
                                    }`}
                                  src={Images.whitecross}
                                  alt="icon"
                                  onClick={(e) => handleCrossVideo(e)}
                                />
                              </React.Fragment>
                            ) : (
                              <>
                                <label
                                  htmlFor="file-7"
                                  className="labelNewCase"
                                >
                                  <span className="file_span">
                                    <img src={Images.downloadicon} alt="" />
                                    <br />
                                    <span className="colorPink">
                                      Browse
                                    </span>{" "}
                                    and drag and drop the video file.
                                  </span>
                                </label>
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </form>
            </div>
            <div className="row mt-4 mb-4">
              <div className="col-12">
                <div className="courseFoot">
                  <button
                    className="primaryBtn submitBtn m-0"
                    type="button"
                    onClick={(e) => updateRecipe(e)}
                  >
                    <span>Save</span>
                    {loading && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="ms-1"
                      />
                    )}
                    {""}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        className={
          modalDetail.flag === "AttachClass" ? "commonWidth customContent" : ""
        }
        ids={modalDetail.flag === "AttachClass" ? "attachpdf" : ""}
        child={
          modalDetail.flag === "AttachClass" ? (
            <AttachClass
              closeModal={() => {
                handleOnCloseModal();
              }}
              handleAttachVideo={(classVidioData) => {
                setClassVideo(classVidioData);
                setPreviewVideo(classVidioData?.plain_class_video);
              }}
              selectedClassVideo={classVideo}
              userId={modalDetail?.id}
              setShowVideoOuter={setShowVideoOuter}
            />
          ) : (
            ""
          )
        }
        header={
          <div className="modalHeader_">
            <div className="common_">
              {modalDetail.flag === "AttachClass" ? (
                <h2 className="font-28 text-center py-3 m-0">
                  Attach Recipe Video From Class
                </h2>
              ) : (
                ""
              )}
            </div>
          </div>
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default EditRecipe;
