import React, { useEffect, useState } from "react";
import * as Images from "../../../utilities/images";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { getCategories, getClasses } from "../../../redux/slices/admin";
import { useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";
import {
  favClass,
  generateTokenForStreaming,
} from "../../../redux/slices/user";
import moment from "moment";
import { socketInstance } from "../../components/common/socketNew";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import "sweetalert2/dist/sweetalert2.min.css";
import Swal from "sweetalert2";
import { useUserSelector } from "../../../redux/selector/user";

const Classespage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;
  const [categories, setCategories] = useState([]);
  const [liveClasses, setLiveClasses] = useState("");
  const [activeTab, setActivetab] = useState(0);
  const [catId, setCatId] = useState("");
  const [getClass, setGetClass] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [perPageSize, setPerPageSize] = useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [totalViews, setTotalViews] = useState("");
  const [totalrating, setTotalrating] = useState("");
  const [totalComments, setTotalComments] = useState("");
  const [sortData, setSortData] = useState("all");
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate();
  const authUserSelector = useUserSelector();
  const userInfo = authUserSelector?.loginUserProfile?.data;
  const classFlag = true;
  const getCategory = () => {
    dispatch(
      getCategories({
        cb(res) {
          if (res?.data) {
            setCategories(res?.data?.data);
          } else {
          }
        },
      })
    );
  };

  const handleSortData = (id) => {
    setCatId(id);
    classList(currentPage, perPageSize, searchValue, sortData, id);
  };
  const handlePageClick = (selectedItem) => {
    // window.scrollTo({
    //   top: 1000,
    //   behavior: "smooth",
    // });
    const selectedPage = selectedItem.selected + 1;
    setCurrentPage(selectedPage);
    classList(selectedPage, perPageSize);
  };

  const classList = (
    page = currentPage,
    limit = perPageSize,
    keyword = searchValue,
    sortFlag,
    id
  ) => {
    let params = {
      page: page,
      limit: limit,
      keyword: keyword,
      sort: sortFlag ? sortFlag : "all",
      cat_id: id == undefined ? "" : id,
    };
    if (Boolean(keyword)) {
      params = { ...params, keyword: keyword };
    }
    //setLoading(true);
    dispatch(
      getClasses({
        ...params,
        cb(res) {
          if (res?.data) {
            setTotalPages(res?.data?.data?.total_pages);
            setTotalCount(res?.data?.data?.totalRecords);
            setGetClass(res?.data?.data?.list);
            //setLoading(false);
          } else {
          }
        },
      })
    );
  };

  const handleFav = (id, isFav) => {
    let params = {
      class_id: id,
    };
    dispatch(
      favClass({
        ...params,
        cb(res) {
          if (res.status) {
            const classCopy = [...getClass];
            const classIndex = classCopy.findIndex((x) => x.id === id);
            if (classIndex >= 0) {
              let favClass = classCopy[classIndex];
              favClass = {
                ...favClass,
                is_favorite: isFav,
              };
              // .is_favorite=isFav
              classCopy[classIndex] = favClass;
              setGetClass(classCopy);
            }

            //getRecipeList();
          } else {
          }
        },
      })
    );
  };
  const handleShowAlert = () => {
    setShowAlert(true);
    Swal.fire({
      title: "Add subscription",
      text: "Kindly add subscription for view classes",
      icon: "warning",
      iconColor: "red",
      customClass: {
        popup: "mySwalClass",
      },
      showCancelButton: true,
      confirmButtonText: "Add",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("redirectUrl");
        navigate("/addsubscription", { state: pathname });
      }
    });
  };

  const handleViewClassDetails = (data) => {
    if (userInfo?.isSubscribe == false && data?.access_level == 2) {
      handleShowAlert();
      return false;
    }
    if (data?.id) {
      navigate(`/classdetailpage/${data?.id}`);
    }
  };

  const handleFilterDataBy = (flag) => {
    setSortData(flag);
    classList(currentPage, perPageSize, searchValue, flag, catId);
  };

  const handleJoinLive = () => {
    if (userInfo?.isSubscribe == false && liveClasses?.access_level == 2) {
      handleShowAlert();
      return false;
    }
    let params = {
      class_id: liveClasses?.id,
    };
    dispatch(
      generateTokenForStreaming({
        ...params,
        cb(res) {
          if (res?.data) {
            socketInstance.emit("join_class", {});
            socketInstance.on("join_class", (response) => { });
            navigate("/liveStreamingUser", {
              state: {
                tokenId: res?.data?.data?.token,
                roomID: res?.data?.data?.roomID,
                appId: res?.data?.data?.appId,
                channel: res?.data?.data?.channelName,
                uid: res?.data?.data?.uid,
                classId: liveClasses?.id,
              },
            });
            window.location.reload();
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };

  useEffect(() => {
    classList();
    getCategory();
    // window.scrollTo(0, 0);
    document.title = "Class-List";
  }, []);

  const liveEvent = () => {
    socketInstance.on("liveClassReact", (data) => {
      setLiveClasses(data?.data ?? null);
    });
  };

  useEffect(() => {
    socketInstance.connect();

    socketInstance.on("end_for_all", (response) => {
      setLiveClasses("");
    });

    socketInstance.emit("liveClass");
    liveEvent();
    socketInstance.on("sync_data", (response) => {
      setTotalViews(response?.totalUsers);
      setTotalComments(response?.totalcomments);
      setTotalrating(response?.totalrating);
    });
    return () => {
      socketInstance.disconnect();
    };
  }, []);

  const reloadCount = sessionStorage.getItem("reloadCount");

  useEffect(() => {
    if (reloadCount < 2) {
      sessionStorage.setItem("reloadCount", String(reloadCount + 1));
      window.location.reload();
    } else {
      sessionStorage.removeItem("reloadCount");
    }
  }, []);

  return (
    <>
      <section className="favoritesBanner">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center pt-5">
              <h2 className="bigHeading pt-5 pb-4 ">Classes</h2>
              <p className="text px-5">
                Streams Live Recipes and Join our Classes
              </p>
            </div>
          </div>
        </div>
        <div className="classesSlider">

          {liveClasses ? (
            <div className="container">
              <div className="sliderBoxOuter">
                <div className="sliderImgOuter">
                  <img src={liveClasses?.thumbnail_image} alt="" />
                </div>
                <div className="sliderBoxInner">
                  <div className="d-flex justify-content-between align-items-center pb-3 pb-md-5">
                    <div className="d-flex">
                      <div className="subtext classtextbox">
                        <img className="star" src={Images.star} alt="" />
                        {totalrating}
                      </div>
                      <div className="classtextbox subtext mx-2">
                        <img className="me-1" src={Images.commenticon} alt="" />
                        {totalComments}
                      </div>
                      <div className="classtextbox subtext">
                        <img className="me-1" src={Images.eye} alt="" />
                        {totalViews}
                      </div>
                    </div>
                    <button className="button">
                      <span className="youtubeIcon">
                        {" "}
                        <img
                          className="img-fluid"
                          src={Images.youtube}
                          alt=""
                        />
                      </span>{" "}
                      <span>
                        <img src={Images.reddot} alt="" />{" "}
                      </span>
                      Live recipes{" "}
                    </button>
                  </div>
                  <div className="col-md-7">
                    <button
                      className="button"
                      onClick={() => handleJoinLive(liveClasses?.id)}
                    >
                      <img className="me-2" src={Images.eye} alt="" />
                      {totalViews}+ Join
                    </button>
                    <p className="text_  mb-0 colorWhite smtext_ ">
                      {liveClasses?.title}
                    </p>
                    <p className="text_  mb-0 colorWhite smtext_ mb-2">
                      {liveClasses?.description.slice(0, 55)}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    {liveClasses?.instructors?.length > 0 ? (
                      <div className="">
                        <p className=" font-10 text-uppercase m-0 pb-2 colorWhite">
                          instructor:
                        </p>
                        <div className="d-flex">
                          {liveClasses?.instructors?.map((data) => {
                            return (
                              <div className="d-flex">
                                <img
                                  className="instructionUser"
                                  src={data?.img_url ? data?.img_url : ""}
                                  alt=""
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div
                      className="d-flex justify-content-end align-items-center w-100"
                      onClick={(e) => handleJoinLive(e)}
                    >
                      <button
                        className="login-btn joinusBtn"
                        type="button"
                        onClick={(e) => handleJoinLive(e)}
                      >
                        Join
                      </button>
                      <span className="center-img">
                        <img
                          src={Images.centerrectangle}
                          alt=""
                          onClick={(e) => handleJoinLive(e)}
                        />
                      </span>
                      <span className="circle-btn joinBtn">
                        <img
                          src={Images.arrow}
                          alt=""
                          onClick={(e) => handleJoinLive(e)}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>{" "}
            </div>
          ) : (
            <div className="watchvediotext ">
              <img
                className=" img-fluid loaderImg_ animated infinite rotate-full"
                src={Images.loader}
                alt=""
              />
              <p className="noLiveText">No live video started yet</p>
            </div>
          )}

        </div>
      </section>
      <section className={liveClasses ? "classtabsOuter mt-5" : "withOutClass mt-5"}>
        <div className="container">
          <h2 className="bigHeading pb-4 text-center ">Our Classes</h2>
          {/* <p className="text text-center pb-4">
            Lorem Ipsum is simply dummy text of the printing is the industry.{" "}
          </p> */}
          <div className="RecipeTabs ClassRecipeTabs">
            <ul className="nav nav-pills IngredientsList mb-4">
              <li>
                <Link
                  to="#booked"
                  data-toggle="tab"
                  onClick={() => handleFilterDataBy("booked")}
                >
                  Booked
                </Link>
              </li>
              <li className="active">
                <Link
                  to="#recent"
                  data-toggle="tab"
                  onClick={() => handleFilterDataBy("all")}
                >
                  All
                </Link>
              </li>

              <li>
                <Link
                  to="#upcoming"
                  data-toggle="tab"
                  onClick={() => handleFilterDataBy("upcoming")}
                >
                  Upcoming
                </Link>
              </li>
            </ul>
            <div className="filterCategory">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-12">
                    <p className="text text-center py-4">Filter by Category</p>
                    <Swiper
                      navigation={true}
                      modules={[Navigation, Pagination]}
                      className="mySwiper swiperForClasses swiperClass swiperClassResp"
                      spaceBetween={10}
                      slidesPerGroup={2}
                      slidesPerView={categories.length > 0 ? "auto" : 1}
                    >
                      <SwiperSlide>
                        <div>
                          {categories?.length > 0 && (
                            <button
                              className={
                                activeTab === 0 ? "tab activeTab" : "tab"
                              }
                              onClick={() => {
                                handleSortData("");
                                setActivetab(0);
                              }}
                              value=""
                            >
                              <div className="tabIcons">
                                <img src={Images.allicon} alt="" />
                              </div>
                              <span>All</span>
                            </button>
                          )}
                        </div>
                      </SwiperSlide>
                      {categories?.map((data, index) => (
                        <SwiperSlide key={index}>
                          <div>
                            <button
                              className={
                                activeTab === data?.id ? "tab activeTab" : "tab"
                              }
                              onClick={() => {
                                handleSortData(data?.id);
                                setActivetab(data?.id);
                              }}
                            >
                              <div className="tabIcons">
                                <img src={data?.icon} alt="" />
                              </div>
                              <span>{data?.title}</span>
                            </button>
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>

                    {/* <div className="swiper-button-next">
                        <span className="nextArrow">
                          <img src={Images.leftarrow} alt="" />
                        </span>
                      </div>
                      <div className="swiper-button-prev">
                        <span className="prevArrow">
                          <img src={Images.rightarrow} alt="" />
                        </span>
                      </div> */}
                    {/* </Swiper> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tab-content clearfix">
            <div className="tab-pane active" id="recent">
              <div
                className="pb-5 RecipeTabsOuter"
                style={{ position: "relative" }}
              >
                <div className="container">
                  <div className="row">
                    {getClass?.length > 0 ? (
                      getClass?.map((data, index) => {
                        return (
                          <div className="col-md-6 col-lg-4 mb-4 " key={index}>
                            <div className="classBox classbox-outer">
                              <div className="classBoxInner">
                                <div
                                  className="classVedioImg"
                                  onClick={() => handleViewClassDetails(data)}
                                >
                                  <img
                                    className="img-fluid w-100"
                                    src={data?.thumbnail_image}
                                    alt=""
                                  />
                                </div>

                                {data?.type == 2 ? (
                                  <div className="d-flex align-items-center justify-content-between">
                                    <div className="d-flex">
                                      {data?.rating > 0 && (
                                        <div
                                          className="subtext pararecipeclasstextbox me-2"
                                          onClick={() =>
                                            handleViewClassDetails(data)
                                          }
                                        >
                                          <img
                                            className="star"
                                            src={Images.star}
                                            alt=""
                                          />
                                          {data?.rating > 0
                                            ? data?.rating?.toFixed(1)
                                            : ""}
                                        </div>
                                      )}
                                      {data?.comments_count && (
                                        <div
                                          className="classtextbox subtext"
                                          onClick={() =>
                                            handleViewClassDetails(data)
                                          }
                                        >
                                          <img
                                            src={Images.commenticon}
                                            alt=""
                                          />
                                          {data?.comments_count > 0
                                            ? data?.comments_count
                                            : ""}
                                        </div>
                                      )}
                                    </div>
                                    {data?.like_count && (
                                      <div
                                        className="classtextbox subtext"
                                        onClick={() =>
                                          handleViewClassDetails(data)
                                        }
                                      >
                                        <img src={Images.solarlike} alt="" />
                                        {data?.like_count}
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <div className="d-flex align-items-center justify-content-between">
                                    <div className="d-flex">
                                      <div className="classtextbox classdetailtext subtext">
                                        <img
                                          className="me-2"
                                          src={Images.clender}
                                          alt=""
                                        />
                                        {moment(data?.date).format(
                                          "MMMM DD, YYYY"
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )}

                                <h5 className="bigText py-2"> {data?.title}</h5>
                                <p className="subtext pararecipe">
                                  {(data?.description).slice(0, 150)}
                                </p>
                                <div className="d-flex justify-content-between align-items-end">
                                  {data?.instructors?.length > 0 ? (
                                    <div
                                      className=""
                                      onClick={() =>
                                        handleViewClassDetails(data)
                                      }
                                    >
                                      <p className="bigText font-10 text-uppercase m-0 pb-2 colorBlack">
                                        instructor:
                                      </p>

                                      <div className="d-flex align-items-center">
                                        {data?.instructors
                                          ?.slice(0, 3)
                                          ?.map((data, index) => {
                                            return (
                                              <img
                                                key={index}
                                                className="instructionUser"
                                                src={
                                                  data?.img_url
                                                    ? data?.img_url
                                                    : Images.usericon
                                                }
                                                alt=""
                                              />
                                            );
                                          })}
                                        {data?.instructors?.length > 4 ? (
                                          <p
                                            className="font-10 colorPink"
                                            style={{
                                              position: "relative",
                                              top: "5px",
                                              right: "7px",
                                            }}
                                            onClick={() =>
                                              handleViewClassDetails(data)
                                            }
                                          >
                                            view more...
                                          </p>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}

                                  <div
                                    className=""
                                    onClick={() => handleViewClassDetails(data)}
                                  >
                                    {data?.type == 2 ? (
                                      <button className="button seeFullrecipe text-capitalize m-0 text-capitalize">
                                        Watch Video
                                        <span>
                                          <img
                                            className="signup"
                                            src={Images.signuparrow}
                                            alt=""
                                          />
                                        </span>
                                      </button>
                                    ) : (
                                      <>
                                        {data?.is_booked == false &&
                                          data?.id != liveClasses?.id ? (
                                          <>
                                            <button
                                              className="login-btn bookclassBtn"
                                              type="button"
                                            >
                                              Book Classes
                                            </button>
                                            <span className="center-img">
                                              <img
                                                src={Images.centerrectangle}
                                                alt=""
                                              />
                                            </span>
                                            <span className="circle-btn">
                                              <img src={Images.arrow} alt="" />
                                            </span>
                                          </>
                                        ) : data?.id === liveClasses?.id ? (
                                          <button
                                            className="login-btn bookclassBtn"
                                            type="button"
                                          >
                                            Join Class
                                          </button>
                                        ) : (
                                          <button
                                            className="login-btn bookclassBtn"
                                            type="button"
                                          >
                                            Already Booked
                                          </button>
                                        )}
                                      </>
                                    )}
                                  </div>
                                  {data?.type == 2 ? (
                                    <div>
                                      {data?.is_favorite == true ? (
                                        <span className="heartCircle">
                                          <img
                                            src={Images.Vector}
                                            alt=""
                                            onClick={() =>
                                              handleFav(data?.id, false)
                                            }
                                          />
                                        </span>
                                      ) : (
                                        <span className="heartCircle">
                                          <img
                                            src={Images.heart}
                                            alt=""
                                            onClick={() =>
                                              handleFav(data?.id, true)
                                            }
                                          />
                                        </span>
                                      )}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                {data?.access_level == 2 ? (
                                  <button
                                    className="recipe-tagPaid "
                                    onClick={() => handleViewClassDetails(data)}
                                  >
                                    Paid
                                  </button>
                                ) : (
                                  <button
                                    className="recipe-tag "
                                    onClick={() => handleViewClassDetails(data)}
                                  >
                                    Free
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <>
                        {getClass?.length == 0 ? (
                          <>
                            <div className="nodata-found-img">
                              <img src={Images.nodataImage} alt=""></img>
                              <h3 className="noDataText">
                                No classes found in this category{" "}
                              </h3>
                            </div>
                          </>
                        ) : (
                          <Spinner
                            className="pageLoader"
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {getClass?.length > 0 ? (
          <>
            <ReactPaginate
              className="paginationBox RecipePagination mb-0"
              breakLabel=""
              // nextLabel="next >"
              onPageChange={handlePageClick}
              nextClassName={"pagination-next"}
              previousClassName={"pagination-previous"}
              pageRangeDisplayed={5}
              marginPagesDisplayed={0}
              pageCount={totalPages}
              previousLabel="prev"
              renderOnZeroPageCount={null}
            />
          </>
        ) : (
          ""
        )}
      </section>
      {/* )} */}
    </>
  );
};

export default Classespage;
