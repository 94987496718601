import React, { useState, useEffect } from "react";
import * as Images from "../../../utilities/images";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginUserProfile } from "../../../redux/slices/user";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import { Spinner } from "react-bootstrap";

const Myprofile = () => {
  const [adminProfile, setAdminProfile] = useState([]);
  const dispatch = useDispatch();

  const getAdminUser = () => {
    dispatch(
      loginUserProfile({
        cb(res) {
          if (res) {
            setAdminProfile(res?.data?.data);
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };
  useEffect(() => {
    getAdminUser();
    window.scrollTo(0, 0);
    document.title = "My-Profile";
  }, []);

  return (
    <>
      <div className="contentCommon ">
        <div className="space position-relative">
          <div className="myProfile">
            <img
              src={
                adminProfile.profile_image
                  ? adminProfile?.profile_image
                  : Images.dummyAdminlogo
              }
              alt="img"
              className="profileBig"
            />
            {adminProfile && adminProfile?.length != "" ? (
              <div className="myProfileInner">
                <Link className="dropdown-item" to="/admin/updatemyprofile">
                  <button
                    className="btn updateBtn"
                    data-bs-toggle="modal"
                    data-bs-target="#myProfile"
                    data-backdrop="static"
                    data-keyboard="false"
                  >
                    {" "}
                    <img
                      src={Images.editbutton}
                      alt="icon"
                      className="me-2 text-end"
                    />
                    Update Profile
                  </button>
                </Link>
                <h5 className="mb-2 medText">{adminProfile?.full_name}</h5>
                <table className="profileTable">
                  <tr className="details">
                    <td>ID</td>
                    <td></td>
                    <td></td>
                    <td>Email</td>
                    <td>Role</td>
                    <td>Update at</td>
                  </tr>
                  <tr className="data">
                    <td>{adminProfile?.id}</td>
                    <td></td>
                    <td></td>
                    <td>{adminProfile?.email}</td>
                    <td>{adminProfile?.role === 1 ? "Admin" : "User"}</td>
                    <td>
                      {moment(adminProfile?.updatedAt).format("YYYY-DD-MM")}
                    </td>
                  </tr>
                </table>
              </div>
            ) : (
              <Spinner
                className="pageLoader"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Myprofile;
