import React, { useState, useEffect } from "react";
import * as Images from "../../../../utilities/images";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { addPlans } from "../../../../redux/slices/admin";
import { Spinner } from "react-bootstrap";

const Addplandetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toastId = React.useRef(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState([""]);
  const [errorsDescripion, setErrorDescription] = useState([]);
  const [amount, setAmount] = useState("");
  const [interval, setInterval] = useState("");
  const [isPopuler, setIsPopuler] = useState(0);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const handleCheckboxChange = () => {
    setIsPopuler(isPopuler === 0 ? 1 : 0);
  };
  const handleAddInput = () => {
    setDescription([...description, ""]);
  };

  const handleRemoveInput = (index) => {
    const list = [...description];
    const listData = list[index];
    list.splice(index, 1);
    setDescription(list);

    const errorIndex = errorsDescripion.findIndex((x) => x.id === listData.id);
    if (errorIndex >= 0) {
      const errorsDesCopy = [...errorsDescripion];
      errorsDesCopy.splice(errorIndex, 1);
      setErrorDescription(errorsDesCopy);
    }
  };

  const validateDescription = () => {
    let invalidDesciption = [];

    description.map((item, idx) => {
      const val = item.trim();
      const errorIndex = errorsDescripion.findIndex((x) => x.id === item.id);

      if (errorIndex >= 0) {
        return errorsDescripion;
      }

      const errorObjData = getErrorObj(val, item, "Description");
      if (errorObjData !== null) {
        invalidDesciption.push(errorObjData);
      }
    });

    if (invalidDesciption.length > 0) {
      const copiedError = [...invalidDesciption, ...errorsDescripion];
      setErrorDescription(copiedError);
    } else {
      return errorsDescripion;
    }
    return invalidDesciption;
  };
  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const list = [...description];
    const itemData = list[index];
    if (value.length > 500) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(
          "Description cannot be more then 500 characters"
        );
      }

      return;
    }
    list[index] = value;
    setDescription(list);

    const val = value.trim();
    const errorIndex = errorsDescripion.findIndex((x) => x.id === itemData.id);
    const errorObjData = getErrorObj(val, itemData, "Description");
    const errorsStepsCopy = [...errorsDescripion];

    if (errorObjData !== null) {
      if (errorIndex >= 0) {
        errorsStepsCopy[errorIndex] = errorObjData;
        setErrorDescription(errorsStepsCopy);
      } else {
        errorsStepsCopy.push(errorObjData);
        setErrorDescription(errorsStepsCopy);
      }
    } else {
      if (errorIndex >= 0) {
        errorsStepsCopy.splice(errorIndex, 1);
        setErrorDescription(errorsStepsCopy);
      }
    }
  };
  const getErrorObj = (val, item, type) => {
    if (val === "" || val === undefined || val === null) {
      return {
        id: item.id,
        errorMessage: `${type} Can not be empty`,
      };
    } else if (val === "" || val === undefined || val === null) {
      return {
        id: item.id,
      };
    }

    return null;
  };

  const validateInput = (event) => {
    const maxDigits = 6;
    const newInputValue = event.target.value.trim();

    setAmount(event.target.value);

    if (newInputValue.length > maxDigits) {
      const truncatedValue = newInputValue.slice(0, maxDigits);
      setInputValue(truncatedValue);

      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Maximum 6 digits allowed");
      }
    } else {
      setInputValue(newInputValue);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const invalidDescriptonData = validateDescription();

    let letterVal = /^[a-zA-Z\s]*$/;
    let nonNagaviveValue = /^\d*\.?\d*$/;
    let validNum = /^(?!0$)\d+$/;
    let amountVal = /^(?:[1-9]\d{0,3}|10000)$/;

    if (!title) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter title");
      }
      return;
    } else if (title.length <= 2) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Title must have  minimum 3 characters");
      }
      return;
    } else if (title.length > 25) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(
          "Title must have maximun 25 characters only"
        );
      }
      return;
    } else if (!title.match(letterVal)) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(
          "Please enter only alphabet characters in title"
        );
      }
      return;
    } else if (invalidDescriptonData.length > 0) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Description cannot be empty");
      }
      return;
    } else if (!amount) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please add amount");
      }
      return;
    } else if (!amount.match(nonNagaviveValue)) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter only Positive amount");
      }
      return;
    } else if (!amount.match(validNum) || amount <= 0) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please Enter Valid amount");
      }
      return;
    } else if (!amount.match(amountVal)) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Amount limit is 10,000 only");
      }
      return;
    } else if (!interval) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please select interval");
      }
      return;
    }

    let params = {
      title: title,
      amount: amount,
      description: description.toString(),
      interval: interval,
      is_popular: isPopuler,
    };
    setLoading(true);
    dispatch(
      addPlans({
        ...params,
        cb(res) {
          setLoading(false);
          if (res.status) {
            navigate("/admin/plan");
          } else {
          }
        },
      })
    );
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Add-Plans";
  }, []);

  return (
    <>
      <div className="AddClass">
        <div className="row mt-4">
          <div className="col-12">
            <div className="contentCommon">
              <div className="backBtn">
                <Link to={"/admin/plan"}>
                  <button className="btn colorPink px-0 pb-2">
                    <img
                      src={Images.rightarrowpink}
                      alt="arrow"
                      className="me-2"
                    />{" "}
                    Go Back
                  </button>
                </Link>
                <h1 className="courseHeading font-28 pb-2">Add Plan</h1>
              </div>
              <form className="profileForm">
                <div className="row ">
                  <div className="mb-3 form-group col-md-12">
                    <label className="form-label pb-2">Title</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter title"
                      value={title}
                      maxLength={25}
                      onChange={(e) => setTitle(e.target.value.trimStart())}
                    />
                  </div>

                  <div className="stepOuter">
                    <p>Description</p>
                    <div className="" onClick={handleAddInput}>
                      <button
                        className="login-btn"
                        type="button"
                        onClick={handleAddInput}
                      >
                        Add
                      </button>
                      <span className="center-img">
                        <img
                          src={Images.centerrectangle}
                          alt=""
                          onClick={handleAddInput}
                        />
                      </span>
                      <span
                        className="circle-btn recipeBtnCircle"
                        onClick={handleAddInput}
                      >
                        <img
                          src={Images.plussign}
                          alt=""
                          onClick={handleAddInput}
                        />
                      </span>
                    </div>
                  </div>

                  {description?.map((data, idx) => {
                    return (
                      <div className="d-flex justify-content-between align-items-center">
                        <div className=" form-group col-md-10 mb-3" key={idx}>
                          <textarea
                            name=""
                            id=""
                            cols="30"
                            rows="4"
                            placeholder="Enter description"
                            className="form-control"
                            value={data}
                            onChange={(event) => handleInputChange(idx, event)}
                          ></textarea>
                        </div>
                        <div
                          className=""
                          onClick={() => handleRemoveInput(idx)}
                        >
                          <img src={Images.trash} alt="" />
                        </div>
                      </div>
                    );
                  })}

                  <div className="row">
                    <div className="mb-3 form-group col-md-6">
                      <label className="form-label pb-2">Amount</label>
                      <input
                        className="form-control text"
                        type="number"
                        placeholder="$"
                        value={inputValue}
                        min={1}
                        maxLength={99999}
                        onChange={(e) => validateInput(e)}
                      />
                    </div>
                    <div className="mb-3 form-group col-md-6">
                      <label className="form-label pb-2">Interval</label>
                      <select
                        className="form-control text"
                        id="exampleFormControlSelect1"
                        onChange={(event) => setInterval(event.target.value)}
                      >
                        <option selected disabled>
                          Select Interval
                        </option>
                        <option value="day">Day</option>
                        <option value="week">Week</option>
                        <option value="month">Month</option>
                        <option value="year"> Year</option>
                      </select>
                    </div>
                    <div className="form-check d-flex align-items-end">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id=""
                        checked={isPopuler === 1}
                        onChange={handleCheckboxChange}
                      />
                      <label htmlFor="">
                        <p className="text mb-0">isPopuler</p>
                      </label>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="row mt-4 mb-4">
              <div className="col-12">
                <div className="courseFoot">
                  <button
                    className="primaryBtn submitBtn m-0"
                    type="button"
                    onClick={(e) => handleSubmit(e)}
                  >
                    <span>Save</span>
                    {loading && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="ms-1"
                      />
                    )}{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Addplandetail;
