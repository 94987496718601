import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { goLive } from "../../../../redux/slices/admin";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Spinner } from "react-bootstrap";

const GoLive = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [serverError, setServerError] = useState("");
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      topicOfLive: "",
      agenda: "",
    },
    validationSchema: Yup.object({
      topicOfLive: Yup.string()
        .required("Topic of Live is required")
        .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed"),
      agenda: Yup.string()
        .required("Agenda is required")
        .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed"),
    }),
    onSubmit: (values) => {
      let params = {
        topic_live: values.topicOfLive,
        agenda: values.agenda,
      };
      setLoading(true);
      dispatch(
        goLive({
          ...params,
          cb(res) {
            setLoading(false);
            if (res?.data?.status_code == 400) {
              setServerError({ error: res.data.message });
              return false;
            }
            if (res?.data?.status_code == 200) {
              setServerError("");
              navigate("/admin/goLive", {
                state: {
                  tokenId: res?.data?.data?.token,
                  roomID: res?.data?.data?.roomID,
                  appId: res?.data?.data?.appId,
                  channel: res?.data?.data?.channelName,
                  uid: res?.data?.data?.uid,
                },
              });
              props.closeModal();
            }
          },
        })
      );
    },
  });

  return (
    <>
      {" "}
      <form onSubmit={formik.handleSubmit}>
        <div className="goliveModal">
          <div className="profileForm">
            <div class="mb-3 form-group col-md-12">
              <label class="form-label pb-2">Topic of Live</label>
              <input
                class="form-control"
                type="text"
                placeholder="Enter Topic"
                {...formik.getFieldProps("topicOfLive")}
              />
              {formik.touched.topicOfLive && formik.errors.topicOfLive ? (
                <div className="validationMsg">{formik.errors.topicOfLive}</div>
              ) : null}
            </div>
            <div class="mb-3 form-group col-md-12">
              <label class="form-label pb-2">Agenda</label>
              <textarea
                name=""
                id=""
                cols="30"
                rows="10"
                maxlength="500"
                placeholder="Type Here"
                class="form-control"
                {...formik.getFieldProps("agenda")}
              ></textarea>
              {formik.touched.agenda && formik.errors.agenda ? (
                <div className="validationMsg">{formik.errors.agenda}</div>
              ) : null}
            </div>
          </div>
          {serverError ? (
            <span className={serverError?.error ? "validationMsg" : "d-none"}>
              {serverError?.error ? serverError?.error : ""}
            </span>
          ) : (
            ""
          )}
          <div className="goLiveModalFoot">
            <button
              className="primaryBtn submitBtn m-0 px-5 py-3"
              style={{
                backgroundColor: "rgba(185, 176, 187,10%)",
                color: "#B9B0BB",
              }}
              onClick={(e) => props.closeModal()}
            >
              Cancel
            </button>
            <button className="primaryBtn submitBtn m-0 ">
              Start Live
              {loading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="ms-1"
                />
              )}{" "}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};
export default GoLive;