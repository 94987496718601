import { Link, useNavigate } from "react-router-dom";
import * as Images from "../../../utilities/images";
import React, { useEffect, useMemo, useState } from "react";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import useResponsiveFontSize from "../subscription/useResponsiveFontSize";
import { useDispatch } from "react-redux";
import { addCards } from "../../../redux/slices/user";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import moment from "moment";

const AddCard = () => {
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [redirectUrl, setRedirectUrl] = useState("");

  const useOptions = () => {
    const fontSize = useResponsiveFontSize();
    const options = useMemo(
      () => ({
        style: {
          base: {
            fontSize,
            color: "#424770",
            letterSpacing: "0.025em",
            fontFamily: "Source Code Pro, monospace",
            "::placeholder": {
              color: "#aab7c4",
            },
          },
          invalid: {
            color: "#9e2146",
          },
        },
      }),
      [fontSize]
    );

    return options;
  };
  const options = useOptions();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }

    // Create a payment method
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
    });

    if (error) {
      toast.error(error.message);
      setLoading(false);
      // Handle error appropriately
    } else {
      // console.log("Payment method created:", paymentMethod);
      // Proceed to use the payment method for payment or other purposes
    }
    let params = {
      paymentMethodId: paymentMethod.id,
    };

    dispatch(
      addCards({
        ...params,
        cb(res) {
          if (res.status) {
            setLoading(false);
            navigate("/addsubscription", { state: redirectUrl });
          } else {
            setLoading(false);
            //toast("Failed to create subscription");
          }
        },
      })
    );
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Add-card";
    setRedirectUrl(localStorage.getItem("redirectUrl"));
  }, []);
  return (
    <>
      <section className="loginpage">
        <div className="container-fluid">
          <div className="row item-center flex-row">
            <div className=" col-lg-5 col-md-6">
              <div className="logininner changePasswordOuter">
                <div className="header-logo">
                  {/* <Link to={"/"}>
                    <img src={Images.logo} alt="" />
                  </Link> */}
                </div>
                <div className="loginForm">
                  <Link className="colorpink" to={"/PaymentMethod"}>
                    <img className="backicon" src={Images.backarrow} alt="" />
                    Go Back
                  </Link>
                  <h1 className="heading py-3">Add New Card</h1>
                </div>
                <div className="">
                  <p className="subtext copyrightText pb-3">
                    © copyright{moment().year()}. All rights reserved
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-7 p-0">
              <div className="imgouter editProfile">
                <div className="formTabs">
                  <div className="header-logo">
                    <Link to={"/"}>
                      <img src={Images.logo} alt="" />
                    </Link>
                  </div>
                  <div className="loginForm">
                    <Link className="colorpink" to="/viewEdit">
                      <img className="backicon" src={Images.backarrow} alt="" />
                      Go Back
                    </Link>
                    <h1 className="heading py-2">Add New Card</h1>
                  </div>
                </div>
                <p className="text pb-2 mb-0">
                  Add new payment method with Credit/Debit card here.
                </p>
                <form className="adminProfileForm" onSubmit={handleSubmit}>
                  <div className="mb-4 form-group updateFormGroup">
                    <label className="text">Card No.</label>
                    <CardNumberElement
                      options={options}
                      className="card_input"
                    />
                    {/* <input
                      type=""
                      className="form-control colorPink medText"
                      name="firstName"
                      placeholder="Hello12"
                    /> */}
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-4 form-group updateFormGroup ChangePW">
                        <label className="text">Expires on</label>
                        <CardExpiryElement
                          options={options}
                          className="card_inputbox"
                        />
                        {/* <input
                          type=""
                          className="form-control medText"
                          name="lastName"
                          placeholder="MM/DD"
                        /> */}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-4 form-group updateFormGroup ChangePW">
                        <label className="text">CVC</label>
                        <CardCvcElement
                          options={options}
                          className="card_inputbox"
                        />
                        {/* <input
                          type=""
                          className="form-control medText"
                          name="lastName"
                          placeholder="025"
                        /> */}
                      </div>
                    </div>
                  </div>
                  {/* <button className="login-btn discardBtn">Discard</button>
                  <button className="login-btn editProfileBtn" type="submit">Add</button> */}

                  <button
                    className="login-btn mt-4"
                    type="submit"
                    disabled={loading}
                  >
                    Add Card
                    {loading && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="ms-1"
                      />
                    )}{" "}
                  </button>
                  <div className="copyrightBottom pt-5">
                    <p className="subtext copyrightText pb-1">
                      © copyright{moment().year()}. All rights reserved
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddCard;
