import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { deletePlans } from "../../../../redux/slices/admin";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";

const DeletePlan = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleDeleteUser = () => {
    let params = {
      id: props?.planId,
    };
    setLoading(true);
    dispatch(
      deletePlans({
        ...params,
        cb(res) {
          if (res?.data) {
            props.closeModal();
            props.refreshData();
          }
          if (res?.data) {
            setLoading(false);
            navigate("/admin/plan");
          } else {
          }
        },
      })
    );
  };
  return (
    <div className="py-3">
      <h5 className="font-28 text-center">
        Are you Sure you want to delete the plan?
      </h5>
      <p className="text text-center py-3">
        You are about to delete the plan. You won't be able to undo this.
      </p>
      <div className="row justify-content-center mt-4">
        <div className="d-grid gap-1 col-lg-3 col-md-6">
          <button
            className="primaryBtn submitBtn m-0"
            type="button"
            onClick={(e) => handleDeleteUser(e)}
          >
            {loading && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="ms-1"
              />
            )}{" "}
            <span>Yes, Delete</span>
          </button>
        </div>
        <div className="d-grid gap-1 col-lg-3 col-md-6">
          <button
            className="primaryBtn cancelBtn m-0"
            onClick={(e) => props.closeModal()}
          >
            <span>No, Cancel</span>
          </button>
        </div>
      </div>
    </div>
  );
};
export default DeletePlan;
