import React from "react";
import { useDispatch } from "react-redux";
import { deleteReport } from "../../../../redux/slices/admin";

const DeleteReport = (props) => {
  const dispatch = useDispatch();
  const handleDeleteUser = () => {
    let params = {
      id:props.reportId
    };
    dispatch(
      deleteReport({
        ...params,
        cb(res) {
          if (res?.data) {
            props.closeModal();
            props.refreshData();
          } else {
          }
        },
      })
    );
  };
  return (
    <div className="py-3">
      <h5 className="font-28 text-center">
        Are you Sure you want to delete the Report ?
      </h5>
      <p className="text text-center py-3">
        You are about to delete the card. You won't be able to undo this.
      </p>
      <div className="row justify-content-center mt-4">
        <div className="d-grid gap-1 col-lg-3 col-md-6">
          <button
            className="primaryBtn submitBtn m-0"
            type="button"
            onClick={(e) => handleDeleteUser(e)}
          >
            <span>Yes, Delete</span>
          </button>
        </div>
        <div className="d-grid gap-1 col-lg-3 col-md-6">
          <button
            className="primaryBtn cancelBtn m-0"
            onClick={(e) => props.closeModal()}
          >
            <span>No, Cancel</span>
          </button>
        </div>
      </div>
    </div>
  );
};
export default DeleteReport;
