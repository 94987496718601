import React, { useEffect } from "react";

const Dashboard = () => {
  useEffect(() => {
    document.title = "Dashboard";
  }, []);

  return (
    <>
      <h1 className="landing_page">Dashboard</h1>
    </>
  );
};

export default Dashboard;
