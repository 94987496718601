import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import MainFooter from "../components/common/mainFooter";
import MainNav from "../components/common/mainNav";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useAuthSelector } from "../../redux/selector/auth";
import { useEffect } from "react";
import { useUserSelector } from "../../redux/selector/user";

const MainLayout = () => {
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );
  const authSelector = useAuthSelector();
  const navigate = useNavigate();
  var token = localStorage.getItem("authToken");
  const isLoggedIn = authSelector.isLoggedIn;

  const authUserSelector = useUserSelector();
  const ValidLoggedIn = authUserSelector?.loginUserProfile?.data;

  useEffect(() => {
    if (ValidLoggedIn && ValidLoggedIn?.role === 1) {
      navigate("/");
    }
  }, [ValidLoggedIn?.role]);
  return (
    <>
      {token && isLoggedIn ? (
        <>
          <MainNav />
          <main className="main">
            <Elements stripe={stripePromise}>
              <Outlet />
            </Elements>
          </main>
          <MainFooter />
        </>
      ) : (
        <Navigate to="/"></Navigate>
      )}
    </>
  );
};

export default MainLayout;
