import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import * as Images from "../../../utilities/images";
import moment from "moment";
import { useDispatch } from "react-redux";
import {
  isRead,
  loginUserProfile,
  notificationsList,
} from "../../../redux/slices/user";
import CustomModal from "../../components/modals/modal";
import ConfirmModalNotification from "./confirmDeleteNotificationModal";
import { Spinner } from "react-bootstrap";
import "sweetalert2/dist/sweetalert2.min.css";
import { useUserSelector } from "../../../redux/selector/user";
import { toggleNotification } from "../../../redux/slices/admin";
import Swal from "sweetalert2";

const Notification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const [notificationList, setNotificationList] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [key, setKey] = useState(Math.random());
  const authUserSelector = useUserSelector();
  const userInfo = authUserSelector?.loginUserProfile?.data;
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
  const getNotificationList = () => {
    dispatch(
      notificationsList({
        cb(res) {
          setNotificationList(res?.data?.data);
        },
      })
    );
  };
  const updateNotificationstatus = (data) => {
    let params = {
      id: data?.id,
    };
    dispatch(
      isRead({
        ...params,
        cb(res) {
          if (res) {
            getNotificationList();
          } else {
          }
        },
      })
    );
  };

  const getUpdatedToggle = () => {
    dispatch(
      loginUserProfile({
        cb(res) {
          if (res) {
            setIsChecked(
              res?.data?.data?.notification_status === 1 ? true : false
            );
          }
        },
      })
    );
  };

  useEffect(() => {
    getUpdatedToggle();
  }, []);

  const handleShowAlert = () => {
    setShowAlert(true);
    Swal.fire({
      title: "Add subscription",
      text: "Kindly add subscription for view recipies and classes",
      icon: "warning",
      iconColor: "red",
      customClass: {
        popup: "mySwalClass",
      },
      showCancelButton: true,
      confirmButtonText: "Add",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("redirectUrl");
        navigate("/addsubscription", { state: pathname });
      }
    });
  };

  useEffect(() => {
    getNotificationList();
  }, []);

  const handleGoToPage = (data) => {
    const recipeComntFlag = true;
    const classcomntFlag = true;
    if (userInfo?.isSubscribe == false && data?.access_level == 2) {
      handleShowAlert();
      return false;
    } else if (data?.case == "1") {
      navigate(`/recipedetailpage/${data?.id}`);
    } else if (data?.case == "2") {
      navigate(`/classdetailpage/${data?.id}`);
    } else if (data?.case == "3") {
      navigate("/");
    } else if (data?.modelType == "1") {
      navigate(`/recipedetailpage/${data?.modelId}`, {
        state: { recipeComntFlag },
      });
    } else if (data?.modelType == "2") {
      navigate(`/classdetailpage/${data?.modelId}`, {
        state: { classcomntFlag },
      });
    }
  };

  const handleClearNotification = () => {
    setModalDetail({
      show: true,
      title: "confirm",
      flag: "confirm",
    });
    setKey(Math.random());
  };

  // switch toggle
  const handleSwitchToggle = (e) => {
    setIsChecked(!isChecked);
    let params = {
      is_notification: !isChecked === true ? 1 : 0,
    };
    dispatch(
      toggleNotification({
        ...params,
        cb(res) {
          if (res?.status === 200) {
            getUpdatedToggle();
          }
        },
      })
    );
  };

  return (
    <>
      <section className="loginpage">
        <div className="container-fluid">
          <div className="row item-center flex-row">
            <div className=" col-lg-5 col-md-6">
              <div className="logininner changePasswordOuter">
                <div className="header-logo">
                  <Link to={"/"}>
                    <img src={Images.logo} alt="" />
                  </Link>
                </div>
                <div className="loginForm">
                  <Link className="colorpink" to="/">
                    <img className="backicon" src={Images.backarrow} alt="" />
                    Go Back
                  </Link>
                  <h1 className="heading py-3">Notifications </h1>
                  <p className="recive-notifications">
                    {isChecked == false
                      ? "You’re set to off receive Notifications"
                      : "You’re set to on receive Notifications"}
                  </p>

                  <div className="notification-toggle-outer">
                    <label className="switch-toggle notification-toggle">
                      <input
                        onChange={(e) => handleSwitchToggle(e)}
                        checked={isChecked}
                        type="checkbox"
                      />
                      <span
                        className={
                          isChecked
                            ? "slider-switch round slider-switch-on"
                            : "slider-switch round slider-switch-off"
                        }
                      ></span>
                      <span
                        className={
                          isChecked
                            ? "toggle-text toggle-on"
                            : "toggle-text toggle-off"
                        }
                      >
                        {isChecked ? "ON" : "OFF"}
                      </span>
                    </label>
                  </div>
                </div>

                <div className="">
                  <p className="subtext copyrightText pb-3">
                    © copyright{moment().year()}. All rights reserved
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-7 p-0">
              <div className="imgouter editProfile heightouter">
                <div className="formTabsOuter">
                  <div className="formTabs">
                    <div className="header-logo">
                      <Link to={"/"}>
                        <img src={Images.logo} alt="" />
                      </Link>
                    </div>
                    <div className="loginForm">
                      <Link className="colorpink" to="/">
                        <img
                          className="backicon"
                          src={Images.backarrow}
                          alt=""
                        />
                        Go Back
                      </Link>
                      <h1 className="heading py-2">Notifications</h1>
                      <div className="notification-toggle-outer">
                    <label className="switch-toggle notification-toggle">
                      <input
                        onChange={(e) => handleSwitchToggle(e)}
                        checked={isChecked}
                        type="checkbox"
                      />
                      <span
                        className={
                          isChecked
                            ? "slider-switch round slider-switch-on"
                            : "slider-switch round slider-switch-off"
                        }
                      ></span>
                      <span
                        className={
                          isChecked
                            ? "toggle-text toggle-on"
                            : "toggle-text toggle-off"
                        }
                      >
                        {isChecked ? "ON" : "OFF"}
                      </span>
                    </label>
                  </div>
                    </div>
                  </div>
                  {notificationList?.list?.length > 0 && (
                    <div className="noticlear text-end mt-2">
                      <button
                        className="login-btn editProfileBtn ClearBtn mb-3 text-end"
                        onClick={handleClearNotification}
                      >
                        Clear
                      </button>
                    </div>
                  )}
                </div>

                <div className="notifyOuter">
                  {notificationList?.list?.length > 0 ? (
                    notificationList?.list?.map((data, idx) => {
                      return (
                        <div
                          key={idx}
                          className={`notifyBoxunred ${
                            data?.is_read == 1 ? "notifyBox" : "notifyBoxunred"
                          }`}
                        >
                          <div className="notifyInner">
                            <div className="innernotify">
                              <img
                                className="img-fluid notifyImage_"
                                src={
                                  data?.data?.case == "6"
                                    ? data?.data?.image
                                    : data?.data?.thumbnail_image
                                    ? data?.data?.thumbnail_image
                                    : Images.roundImg
                                }
                                alt=""
                              />
                              {data?.data?.case == "6" && (
                                <img
                                  className="notifyUserImg"
                                  src={
                                    data?.data?.created_by?.profile_image
                                      ? data?.data?.created_by?.profile_image
                                      : Images.userimg
                                  }
                                  alt=""
                                />
                              )}
                            </div>

                            <div className="notifyContent">
                              <p className="bigText">
                                {data?.data?.case == "6" &&
                                data?.data?.modelType == "1"
                                  ? `${data?.data?.created_by?.first_name} reply on Your Comment of recipe  "${data?.data?.modelTitle}"`
                                  : data?.data?.case == "6" &&
                                    data?.data?.modelType == "2"
                                  ? `${data?.data?.created_by?.first_name} reply on Your Comment of class  "${data?.data?.modelTitle}"`
                                  : data?.data?.title}
                              </p>
                              <p className="subtext">
                                {data?.data?.case == "6"
                                  ? data?.title
                                  : data?.data?.case == "1"
                                  ? "View recipe details"
                                  : data?.data?.case == "2"
                                  ? "View class details"
                                  : data?.data?.case == "3"
                                  ? "Join live stream"
                                  : data?.data?.case == "4"
                                  ? "Join live stream"
                                  : ""}
                              </p>
                            </div>
                            <div className="buttonOuter">
                              <p className="extraSmallText text-end pb-4">
                                {moment(data?.createdAt).fromNow()}
                              </p>
                              <div className="">
                                <button
                                  className="login-btn StreamNow"
                                  onClick={() => {
                                    handleGoToPage(data?.data);
                                    updateNotificationstatus(data);
                                  }}
                                >
                                  {data?.data?.case == "1"
                                    ? "View Recipe"
                                    : data?.data?.case == "2" &&
                                      data?.data?.type == "2"
                                    ? "View Class"
                                    : data?.data?.case == "2" &&
                                      data?.data?.type == "1"
                                    ? "View Live Class"
                                    : data?.data?.case == "6"
                                    ? "View comment"
                                    : data?.data?.case == "4"
                                    ? "join LiveStreaming"
                                    : ""}
                                </button>
                                <span
                                  className="center-img"
                                  onClick={() => {
                                    handleGoToPage(data?.data);
                                    updateNotificationstatus(data);
                                  }}
                                >
                                  <img src={Images.centerrectangle} alt="" />
                                </span>
                                <span
                                  className="circle-btn streamCircle"
                                  onClick={() => {
                                    handleGoToPage(data?.data);
                                    updateNotificationstatus(data);
                                  }}
                                >
                                  <img
                                    className="img-fluid"
                                    src={Images.arrow}
                                    alt=""
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <>
                      {notificationList?.list?.length === 0 ? (
                        <h3 className="noDataText mt-3 mb-3">
                          No Notices Right Now!
                        </h3>
                      ) : (
                        <Spinner
                          className="pageLoader"
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <CustomModal
          key={key}
          show={modalDetail.show}
          backdrop="static"
          showCloseBtn={false}
          isRightSideModal={false}
          mediumWidth={false}
          className={modalDetail.flag === "confirm" ? "confirm" : ""}
          child={
            modalDetail.flag === "confirm" ? (
              <ConfirmModalNotification
                closeModal={() => {
                  handleOnCloseModal();
                }}
                refreshData={() => {
                  getNotificationList();
                }}
              />
            ) : (
              ""
            )
          }
          onCloseModal={() => handleOnCloseModal()}
        />
      </section>
    </>
  );
};
export default Notification;
