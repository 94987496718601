import React, { useEffect, useState } from "react";
import * as Images from "../../../utilities/images";
import { Swiper, SwiperSlide } from "swiper/react";
import { useAuthSelector } from "../../../redux/selector/auth";
import {
  getCategories,
  getRecipe,
  getRecipeWithUser,
} from "../../../redux/slices/admin";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { favRecipe, trendingRecipes } from "../../../redux/slices/user";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import { Pagination, Navigation, Scrollbar } from "swiper/modules";
import { useUserSelector } from "../../../redux/selector/user";
import { Spinner } from "react-bootstrap";
import "sweetalert2/dist/sweetalert2.min.css";
import Swal from "sweetalert2";

const HomePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const authSelector = useAuthSelector();
  const [getRecipes, setGetRecipes] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [trendingRecipe, setTrendingRecipe] = useState(null);
  const [categories, setCategories] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [perPageSize, setPerPageSize] = useState(6);
  const [activeTab, setActivetab] = useState(0);
  const [catId, setCatId] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  var token = localStorage.getItem("authToken");
  const isLoggedIn = authSelector?.isLoggedIn;
  const authUserSelector = useUserSelector();
  const userInfo = authUserSelector?.loginUserProfile?.data;

  function printWelcomeMessage() {
    const welcomeText = "Thank You For Choose Us\\|";
    const welcomeLength = welcomeText.length;
    const paddingLength = Math.floor((40 - welcomeLength) / 2);
    // console.log("*".repeat(40));
    // console.log(
    //   "*".repeat(paddingLength) + `%c${welcomeText}`,
    //   "font-size: 44px; font-family: Arial; color: #333;" + paddingLength
    // );
    // console.log("*".repeat(40));
  }

  printWelcomeMessage();

  const getRecipeList = (
    page = currentPage,
    limit = perPageSize,
    keyword = searchValue,
    id
  ) => {
    let params = {
      page: page,
      limit: limit,
      keyword: keyword,
      cat_id: id == undefined ? "" : id,
    };

    if (Boolean(keyword)) {
      params = { ...params, keyword: keyword };
    }

    dispatch(
      getRecipe({
        ...params,
        cb(res) {
          if (res?.data) {
            setTotalPages(res?.data?.data?.total_pages);
            setTotalCount(res?.data?.data?.totalRecords);
            setGetRecipes(res?.data?.data?.list);
          }
        },
      })
    );
  };

  const getCategory = () => {
    dispatch(
      getCategories({
        cb(res) {
          if (res?.data) {
            setCategories(res?.data?.data);
          } else {
          }
        },
      })
    );
  };

  const getRecipeListWithUser = (
    page = currentPage,
    limit = perPageSize,
    userid = userInfo?.id,
    keyword = searchValue,
    id
  ) => {
    let params = {
      page: page,
      limit: limit,
      userid: userid,
      keyword: keyword,
      cat_id: id == undefined ? "" : id,
    };
    if (Boolean(keyword)) {
      params = { ...params, keyword: keyword };
    }

    dispatch(
      getRecipeWithUser({
        ...params,
        cb(res) {
          if (res?.data) {
            setTotalPages(res?.data?.data?.total_pages);
            setTotalCount(res?.data?.data?.totalRecords);
            setGetRecipes(res?.data?.data?.list);
          }
        },
      })
    );
  };

  const getTrendingRecipes = () => {
    dispatch(
      trendingRecipes({
        cb(res) {
          if (res?.data) {
            setTrendingRecipe(res?.data?.data?.popular_recipes);
          } else {
          }
        },
      })
    );
  };

  const handleShowAlert = () => {
    setShowAlert(true);
    Swal.fire({
      title: "Add subscription",
      text: "Kindly add subscription for view recipes",
      icon: "warning",
      iconColor: "red",
      customClass: {
        popup: "mySwalClass",
      },
      showCancelButton: true,
      confirmButtonText: "Add",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("redirectUrl");
        navigate("/addsubscription", { state: pathname });
      }
    });
  };

  const handleViewRecipe = (data) => {
    if (!token) {
      navigate("/login");
    } else if (userInfo?.isSubscribe == false && data?.access_level == 2) {
      handleShowAlert();
      return;
    } else if (data?.id) {
      navigate(`/recipedetailpage/${data?.id}`);
    }
  };

  const handleSortData = (id) => {
    setCatId(id);
    getRecipeList(currentPage, perPageSize, searchValue, id);
  };

  const handleFav = (id, isFav) => {
    let params = {
      recipe_id: id,
    };
    dispatch(
      favRecipe({
        ...params,
        cb(res) {
          if (res.status) {
            const recipesCopy = [...getRecipes];
            const recipeIndex = recipesCopy.findIndex((x) => x.id === id);
            if (recipeIndex >= 0) {
              let favRecipe = recipesCopy[recipeIndex];
              favRecipe = {
                ...favRecipe,
                is_favorite: isFav,
              };

              recipesCopy[recipeIndex] = favRecipe;
              setGetRecipes(recipesCopy);
            }
          }
        },
      })
    );
  };

  useEffect(() => {
    if (token && isLoggedIn) {
      getRecipeListWithUser();
    } else {
      getRecipeList();
    }
    getCategory();
    getTrendingRecipes();
    window.scrollTo(0, 0);
    document.title = "Home";
  }, []);

  const checkClasses = () => {
    if (!token) {
      navigate("/login");
    } else {
      navigate("/classespage");
    }
  };

  const handleViewAllRecipies = () => {
    if (!token) {
      navigate("/login");
    } else {
      navigate("/recipes");
    }
  };

  const handleCheckRecipe = () => {
    if (!token) {
      navigate("/login");
    } else {
      navigate("/recipes");
    }
  };
  const handleClickDown = () => {
    window.scrollTo({ top: 10000, behavior: "smooth" });
  };
  return (
    <>
      <section className="banner-section">
        <div className="container-xxl container-fluid ps-md-4">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="banner-content">
                <p className="text colorPink">Welcome to Lynnette’s Kitchen</p>
                <h1 className="bigHeading py-3">
                  Cook like a chef with Lynnette’s Kitchen.
                </h1>
                <p className="text pb-3">
                  Start or Learn Cooking With Lynnette’s recipes Made fresh.
                </p>

                <div className="" onClick={handleCheckRecipe}>
                  <button className="login-btn">Check our recipes</button>
                  <span className="center-img">
                    <img src={Images.centerrectangle} alt="" />
                  </span>
                  <span className="circle-btn">
                    <img src={Images.arrow} alt="" />
                  </span>
                </div>
              </div>
              <div className="rectangleImg">
                <div className="btn-outer">
                  <button className="button" onClick={checkClasses}>
                    <span className="youtubeIcon">
                      {" "}
                      <img src={Images.youtube} alt="" />
                    </span>
                    Stream{" "}
                    <span>
                      <img src={Images.reddot} alt="" />{" "}
                    </span>
                    Live recipes{" "}
                  </button>
                  <button className="button" onClick={checkClasses}>
                    {" "}
                    Book Classes
                  </button>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-sm-12">
              <Swiper
                navigation={true}
                modules={[Pagination, Navigation]}
                className="mySwiper"
                spaceBetween={30}
              >
                <SwiperSlide>
                  <div className="rightShape" onClick={handleCheckRecipe}>
                    <img className="img-fluid" src={Images.foodplate} alt="" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="rightShape" onClick={handleCheckRecipe}>
                    <img className="img-fluid" src={Images.foodplate} alt="" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="rightShape" onClick={handleCheckRecipe}>
                    <img className="img-fluid" src={Images.foodplate} alt="" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="rightShape" onClick={handleCheckRecipe}>
                    <img className="img-fluid" src={Images.foodplate} alt="" />
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
          <section>
            <div className="container-xxl container-fluid">
              {" "}
              <div className="ourRecipe RecipesPage">
                <div className="row align-items-center justify-content-between">
                  {categories?.length > 0 ? (
                    <div className="col-md-3">
                      <h2 className="medText">Our Recipes</h2>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="col-md-3 col-lg-4">
                    {/* <p className="text">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </p> */}
                  </div>
                  <div className="col-lg-4 col-md-5">
                    <div className="mb-4 form-group searchInput">
                      <input
                        type="text"
                        className="form-control"
                        name="email"
                        placeholder="Search recipes..."
                        value={searchValue}
                        onChange={(e) => {
                          setSearchValue(e.target.value);
                          getRecipeList(1, 10, e.target.value);
                        }}
                      />
                      <span className="whiteRec">
                        {/* <img src={Images.whiterectange} alt="" /> */}
                      </span>
                      <span className="circle-btn searchCirle">
                        <img src={Images.searchicon} alt="" />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row py-3 justify-between">
                  <div className="col-lg-2">
                    {/* {token && isLoggedIn && ( */}
                    <ul className="tabnav tabnavouter">
                      <li>
                        {categories?.length > 0 ? (
                          <button
                            className={activeTab == 0 ? "tab activeTab" : "tab"}
                            onClick={() => {
                              handleSortData("");
                              setActivetab(0);
                            }}
                            value=""
                          >
                            <div className="tabIcons">
                              <img src={Images.allicon} alt="" />
                            </div>
                            <span>All</span>
                          </button>
                        ) : (
                          ""
                        )}
                      </li>
                      {categories?.map((data, idx) => {
                        return (
                          <li key={idx}>
                            <button
                              className={
                                activeTab == data?.id ? "tab activeTab" : "tab"
                              }
                              onClick={() => {
                                handleSortData(data?.id);
                                setActivetab(data?.id);
                              }}
                            >
                              <div className="tabIcons">
                                <img src={data?.icon} alt="" />
                              </div>
                              <span>{data?.title}</span>
                            </button>
                          </li>
                        );
                      })}
                    </ul>
                    {/* )} */}
                  </div>
                  {categories?.length > 0 ? (
                    <div className="col-lg-2">
                      <div className="verticalRecipeImg">
                        <img
                          className="foodTopImg img-fluid w-100"
                          src={Images.horizontalimg}
                          alt=""
                        />
                      </div>
                      <div className="verticalimg">
                        <img
                          className="foodTopImg img-fluid w-100"
                          src={Images.verticalrecipeimg}
                          alt=""
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="col-lg-8">
                    <div className="row justify-content-around">
                      {getRecipes?.length > 0 ? (
                        getRecipes?.map((data, index) => {
                          return (
                            <div key={index} className="col-md-6 col-lg-5 mb-4">
                              <div className="foodBoxOuter ">
                                <div className="foodInner">
                                  <div className="boxImg ">
                                    <div className="fullrecipeImg">
                                      <img
                                        src={data?.thumbnail_image}
                                        alt=""
                                        onClick={() => handleViewRecipe(data)}
                                      />
                                    </div>
                                    {data?.rating > 0 ? (
                                      <div
                                        className="subtext ratingBox"
                                        onClick={() => handleViewRecipe(data)}
                                      >
                                        <img
                                          className="star"
                                          src={Images.star}
                                          alt=""
                                        />
                                        {data?.rating > 0
                                          ? data?.rating?.toFixed(1)
                                          : ""}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className="foodBox ps-0">
                                    <div className="foodboxInner">
                                      <div className="title-tag mb-1">
                                        <h5 className="bigText home">
                                          {data?.title}{" "}
                                        </h5>
                                        {data?.access_level == 2 ? (
                                          <button
                                            className="recipe-tagPaid"
                                            onClick={() =>
                                              handleViewRecipe(data)
                                            }
                                          >
                                            Paid
                                          </button>
                                        ) : (
                                          <button
                                            className="recipe-tag"
                                            onClick={() =>
                                              handleViewRecipe(data)
                                            }
                                          >
                                            Free
                                          </button>
                                        )}
                                      </div>
                                      <p className="subtext pararecipe ">
                                        {data?.description}
                                      </p>

                                      <div className="d-flex align-items-center">
                                        <button
                                          className="button seeFullrecipe recipeDetailsBtn text-capitalize"
                                          onClick={() => handleViewRecipe(data)}
                                        >
                                          See full Recipe
                                          <span>
                                            <img
                                              className="signup"
                                              src={Images.signuparrow}
                                              alt=""
                                            />
                                          </span>
                                        </button>

                                        {data?.is_favorite == true ? (
                                          <span className="heartCircle">
                                            <img
                                              src={Images.Vector}
                                              alt=""
                                              onClick={() =>
                                                handleFav(data?.id, false)
                                              }
                                            />
                                          </span>
                                        ) : (
                                          <span className="heartCircle">
                                            <img
                                              src={Images.heart}
                                              alt=""
                                              onClick={() =>
                                                handleFav(data?.id, true)
                                              }
                                            />
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <>
                          {getRecipes?.length == 0 ? (
                            <>
                              <div className="text-center">
                                <img
                                  src={Images.nodataImage}
                                  alt="no-recipe-found"
                                />
                                <h3 className="noDataText mt-3">
                                  No recipies found in this category{" "}
                                </h3>
                              </div>
                            </>
                          ) : (
                            <Spinner
                              className="pageLoader"
                              as="span"
                              animation="grow"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                {getRecipes?.length > 5 ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    onClick={handleViewAllRecipies}
                  >
                    <button className="login-btn sendButton">View All</button>
                    <span className="center-img">
                      <img src={Images.centerrectangle} alt="" />
                    </span>
                    <span className="circle-btn">
                      <img src={Images.arrow} alt="" />
                    </span>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </section>
          {/* )} */}
        </div>
      </section>
      <section className="tradingRecipe trading-recipe-resp">
        <div className="container">
          <div className="row justify-content-center">
            <div className="topHeading">
              <h3 className="subheading text-center">Trending Recipes</h3>
              {/* <p className="text text-center">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry.
              </p> */}
            </div>
            <div className="sliderOuter sliderOuterScrollbar row">
              {trendingRecipe && trendingRecipe?.length > 0 ? (
                <Swiper
                  navigation={true}
                  scrollbar={{
                    hide: false,
                  }}
                  modules={[Pagination, Navigation, Scrollbar]}
                  className={
                    trendingRecipe?.length > 4
                      ? "mySwiper"
                      : "mySwiper hide-icons"
                  }
                  slidesPerView={5}
                  spaceBetween={0}
                  breakpoints={{
                    1800: {
                      slidesPerView: 5,
                    },
                    1200: {
                      slidesPerView: 4,
                    },
                    1024: {
                      slidesPerView: 4,
                    },
                    992: {
                      slidesPerView: 3,
                    },
                    991: {
                      slidesPerView: 3,
                    },
                    768: {
                      slidesPerView: 3,
                    },
                    767: {
                      slidesPerView: 2,
                    },
                    480: {
                      slidesPerView: 2,
                    },
                    375: {
                      slidesPerView: 1,
                    },
                    320: {
                      slidesPerView: 1,
                    },
                  }}
                >
                  {trendingRecipe?.map((data, index) => {
                    return (
                      <React.Fragment>
                        <SwiperSlide key={index}>
                          <div className="col-md-12">
                            <div className="Slide One ">
                              <button
                                className="button  slideOnebtn slideOnebtnViewResp"
                                onClick={handleCheckRecipe}
                              >
                                <span className="youtubeIcon">
                                  {" "}
                                  <img src={Images.showicon} alt="" />
                                </span>
                                {data?.view_count - 1}+Views{" "}
                              </button>
                              <div
                                className="sliderImg"
                                onClick={handleCheckRecipe}
                              >
                                <img
                                  className="img-fluid"
                                  src={
                                    data?.recipe_image
                                      ? data?.recipe_image
                                      : Images.tranding1
                                  }
                                  alt=""
                                />
                              </div>
                              <div
                                className="imgContent"
                                onClick={handleCheckRecipe}
                              >
                                <p className="">
                                  {data?.rating > 0 ? (
                                    <span className="subtext ratingStar">
                                      <img
                                        className="star"
                                        src={Images.star}
                                        alt=""
                                      />
                                      {data?.rating?.toFixed(1)}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </p>

                                <div className="d-flex justify-content-between">
                                  <div className="">
                                    <p className="text colorWhite">
                                      {data?.title}
                                    </p>
                                    <p className="colorWhite pararecipe">
                                      {data?.description}
                                    </p>
                                  </div>
                                  <div className="RotateArrow">
                                    {/* <img src={Images.downarrow} alt="" /> */}
                                    <img
                                      src={Images.downarrowWhite}
                                      onClick={handleCheckRecipe}
                                      alt=""
                                    />
                                  </div>
                                  {/* <div className="RotateArrow ">
                                  <img src={Images.downarrowWhite} alt="" />
                                </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      </React.Fragment>
                    );
                  })}
                </Swiper>
              ) : (
                <div className="text-center">
                  <img src={Images.nodataImage} alt="no-recipe-found" />
                  <h3 className="noDataText mt-3">
                    No recipies found in this category{" "}
                  </h3>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <div className="downarrow">
        <img src={Images.downarrowWhite} alt="" onClick={handleClickDown} />
      </div>
      <section className="streamsLive" id="streams">
        <div className="container-md container-fluid">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="streamsInner">
                <div className="">
                  <img className="img-fluid" src={Images.chef2} alt="" />
                </div>
                <button className="button chefBtn" onClick={checkClasses}>
                  <span className="youtubeIcon">
                    {" "}
                    <img src={Images.youtube} alt="" />
                  </span>
                  <span>
                    <img src={Images.reddot} alt="" />{" "}
                  </span>
                  Live recipes{" "}
                </button>
                <div className="iconBox">
                  <img src={Images.youtubepink} alt="" />
                </div>
                <div className="verticalImg">
                  <img
                    className="img-fluid streamimg"
                    src={Images.chefimg}
                    alt=""
                  />
                  <button
                    className="button chefBtn chefBtn2"
                    onClick={checkClasses}
                  >
                    <span className="youtubeIcon">
                      {" "}
                      <img src={Images.youtube} alt="" />
                    </span>
                    <span>
                      <img src={Images.reddot} alt="" />{" "}
                    </span>
                    Live recipes{" "}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="streamContent">
                <h3 className="subheading">
                  Streams Live Recipes and Join our Classes
                </h3>
                {/* <p className="text">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry.
                </p> */}
                <div className="rectangleImg streamBox">
                  <div className="btn-outer">
                    <button className="button" onClick={checkClasses}>
                      <span className="youtubeIcon">
                        {" "}
                        <img src={Images.youtube} alt="" />
                      </span>
                      Stream{" "}
                      <span>
                        <img src={Images.reddot} alt="" />{" "}
                      </span>
                      Live recipes{" "}
                    </button>
                  </div>
                </div>
                <div className="">
                  <button className="login-btn" onClick={checkClasses}>
                    Book Classes
                  </button>
                  <span className="center-img">
                    <img
                      src={Images.centerrectangle}
                      alt=""
                      onClick={checkClasses}
                    />
                  </span>
                  <span className="circle-btn">
                    <img src={Images.arrow} alt="" onClick={checkClasses} />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="ourCustomers">
        <div className="container">
          <div className="py-4">
            <h3 className="subheading text-center">What Our Customers Say</h3>
            {/* <p className="text text-center pb-3 mb-0">
              Lorem Ipsum is simply best and typesetting dummy text.{" "}
            </p> */}
          </div>

          <div className="row">
            <Swiper
              navigation={true}
              modules={[Pagination, Navigation]}
              className="mySwiper"
              slidesPerView={3}
              spaceBetween={30}
              breakpoints={{
                1800: {
                  slidesPerView: 3,
                },
                1200: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 2,
                },
                992: {
                  slidesPerView: 2,
                },
                991: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 1,
                },
                767: {
                  slidesPerView: 1,
                },
                480: {
                  slidesPerView: 1,
                },
                375: {
                  slidesPerView: 1,
                },
                360: {
                  slidesPerView: 1,
                },
                320: {
                  slidesPerView: 1,
                },
              }}
            >
              <SwiperSlide>
                <div className="col-md-12">
                  <div className="shapeBg">
                    <div className="userImg">
                      <img className="img-fluid" src={Images.userimg} alt="" />
                    </div>
                    <div className="shapebgInner">
                      <div className="">
                        <h6 className="bigText text-center">Julia Hoffmann</h6>
                        <ul className="starOuter">
                          <li>
                            <img
                              className="img-fluid"
                              src={Images.star}
                              alt=""
                            />
                          </li>
                          <li>
                            <img
                              className="img-fluid"
                              src={Images.star}
                              alt=""
                            />
                          </li>
                          <li>
                            <img
                              className="img-fluid"
                              src={Images.star}
                              alt=""
                            />
                          </li>
                          <li>
                            <img
                              className="img-fluid"
                              src={Images.star}
                              alt=""
                            />
                          </li>
                          <li>
                            <img
                              className="img-fluid"
                              src={Images.star}
                              alt=""
                            />
                          </li>
                        </ul>
                        <p className="text-center subtext">
                          Lorem Ipsum is simply dummy text of the printing is
                          the best and typesetting industry Lorem Ipsum is
                          simply dummy text of the printing is the best and
                          typesetting industry Ipsum is simply dummy tex
                          typesetting industry Lorem Ipsum is simply dummy text
                          of the printing is the best and typesetting
                          industryIpsum is simply dummy tex.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="col-md-12">
                  <div className="shapeBg">
                    <div className="userImg">
                      <img className="img-fluid" src={Images.userimg} alt="" />
                    </div>
                    <div className="shapebgInner">
                      <div className="">
                        <h6 className="bigText text-center">Julia Hoffmann</h6>
                        <ul className="starOuter">
                          <li>
                            <img className="img-fluid" src={Images.star} />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} />
                          </li>
                        </ul>
                        <p className="text-center subtext">
                          Lorem Ipsum is simply dummy text of the printing is
                          the best and typesetting industry Lorem Ipsum is
                          simply dummy text of the printing is the best and
                          typesetting industry Ipsum is simply dummy tex
                          typesetting industry Lorem Ipsum is simply dummy text
                          of the printing is the best and typesetting
                          industryIpsum is simply dummy tex.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="col-md-12">
                  <div className="shapeBg">
                    <div className="userImg">
                      <img className="img-fluid" src={Images.userimg} alt="" />
                    </div>
                    <div className="shapebgInner">
                      <div className="">
                        <h6 className="bigText text-center">Julia Hoffmann</h6>
                        <ul className="starOuter">
                          <li>
                            <img className="img-fluid" src={Images.star} />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} />
                          </li>
                        </ul>
                        <p className="text-center subtext">
                          Lorem Ipsum is simply dummy text of the printing is
                          the best and typesetting industry Lorem Ipsum is
                          simply dummy text of the printing is the best and
                          typesetting industry Ipsum is simply dummy tex
                          typesetting industry Lorem Ipsum is simply dummy text
                          of the printing is the best and typesetting
                          industryIpsum is simply dummy tex.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="col-md-12">
                  <div className="shapeBg">
                    <div className="userImg">
                      <img className="img-fluid" src={Images.userimg} alt="" />
                    </div>
                    <div className="shapebgInner">
                      <div className="">
                        <h6 className="bigText text-center">Julia Hoffmann</h6>
                        <ul className="starOuter">
                          <li>
                            <img className="img-fluid" src={Images.star} />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} />
                          </li>
                        </ul>
                        <p className="text-center subtext">
                          Lorem Ipsum is simply dummy text of the printing is
                          the best and typesetting industry Lorem Ipsum is
                          simply dummy text of the printing is the best and
                          typesetting industry Ipsum is simply dummy tex
                          typesetting industry Lorem Ipsum is simply dummy text
                          of the printing is the best and typesetting
                          industryIpsum is simply dummy tex.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="col-md-12">
                  <div className="shapeBg">
                    <div className="userImg">
                      <img className="img-fluid" src={Images.userimg} alt="" />
                    </div>
                    <div className="shapebgInner">
                      <div className="">
                        <h6 className="bigText text-center">Julia Hoffmann</h6>
                        <ul className="starOuter">
                          <li>
                            <img className="img-fluid" src={Images.star} />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} />
                          </li>
                        </ul>
                        <p className="text-center subtext">
                          Lorem Ipsum is simply dummy text of the printing is
                          the best and typesetting industry Lorem Ipsum is
                          simply dummy text of the printing is the best and
                          typesetting industry Ipsum is simply dummy tex
                          typesetting industry Lorem Ipsum is simply dummy text
                          of the printing is the best and typesetting
                          industryIpsum is simply dummy tex.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="col-md-12">
                  <div className="shapeBg">
                    <div className="userImg">
                      <img className="img-fluid" src={Images.userimg} alt="" />
                    </div>
                    <div className="shapebgInner">
                      <div className="">
                        <h6 className="bigText text-center">Julia Hoffmann</h6>
                        <ul className="starOuter">
                          <li>
                            <img className="img-fluid" src={Images.star} />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} />
                          </li>
                        </ul>
                        <p className="text-center subtext">
                          Lorem Ipsum is simply dummy text of the printing is
                          the best and typesetting industry Lorem Ipsum is
                          simply dummy text of the printing is the best and
                          typesetting industry Ipsum is simply dummy tex
                          typesetting industry Lorem Ipsum is simply dummy text
                          of the printing is the best and typesetting
                          industryIpsum is simply dummy tex.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="col-md-12">
                  <div className="shapeBg">
                    <div className="userImg">
                      <img className="img-fluid" src={Images.userimg} alt="" />
                    </div>
                    <div className="shapebgInner">
                      <div className="">
                        <h6 className="bigText text-center">Julia Hoffmann</h6>
                        <ul className="starOuter">
                          <li>
                            <img className="img-fluid" src={Images.star} />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} />
                          </li>
                        </ul>
                        <p className="text-center subtext">
                          Lorem Ipsum is simply dummy text of the printing is
                          the best and typesetting industry Lorem Ipsum is
                          simply dummy text of the printing is the best and
                          typesetting industry Ipsum is simply dummy tex
                          typesetting industry Lorem Ipsum is simply dummy text
                          of the printing is the best and typesetting
                          industryIpsum is simply dummy tex.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="col-md-12">
                  <div className="shapeBg">
                    <div className="userImg">
                      <img className="img-fluid" src={Images.userimg} alt="" />
                    </div>
                    <div className="shapebgInner">
                      <div className="">
                        <h6 className="bigText text-center">Julia Hoffmann</h6>
                        <ul className="starOuter">
                          <li>
                            <img className="img-fluid" src={Images.star} />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} />
                          </li>
                        </ul>
                        <p className="text-center subtext">
                          Lorem Ipsum is simply dummy text of the printing is
                          the best and typesetting industry Lorem Ipsum is
                          simply dummy text of the printing is the best and
                          typesetting industry Ipsum is simply dummy tex
                          typesetting industry Lorem Ipsum is simply dummy text
                          of the printing is the best and typesetting
                          industryIpsum is simply dummy tex.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="col-md-12">
                  <div className="shapeBg">
                    <div className="userImg">
                      <img className="img-fluid" src={Images.userimg} alt="" />
                    </div>
                    <div className="shapebgInner">
                      <div className="">
                        <h6 className="bigText text-center">Julia Hoffmann</h6>
                        <ul className="starOuter">
                          <li>
                            <img className="img-fluid" src={Images.star} />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} />
                          </li>
                          <li>
                            <img className="img-fluid" src={Images.star} />
                          </li>
                        </ul>
                        <p className="text-center subtext">
                          Lorem Ipsum is simply dummy text of the printing is
                          the best and typesetting industry Lorem Ipsum is
                          simply dummy text of the printing is the best and
                          typesetting industry Ipsum is simply dummy tex
                          typesetting industry Lorem Ipsum is simply dummy text
                          of the printing is the best and typesetting
                          industryIpsum is simply dummy tex.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </section>
      <section className="whyChooseus">
        <div className="container">
          <div className="py-3 py-sm-2">
            <h3 className="subheading text-center">
              Why Choose Lynnette’s Kitchen
            </h3>
            {/* <p className="text text-center pb-3 mb-0 pb-sm-2">
              Lorem Ipsum is simply dummy text of the printing is the best.{" "}
            </p> */}
          </div>
          <div className="row mb-4">
            <div className="col-md-4 text-center">
              <div className="whyChooseContent" onClick={handleCheckRecipe}>
                <img src={Images.live1} alt="" />
                <h5 className="medText py-3">Library of Recipes</h5>
                <p className="text">
                  Lynnette’s Kitchen app covers an array of recipes from savory to desserts to sauces, marinades, homemade dressings, and much more.  Subscribers of the app will get recipes, recipe tips, and tricks along with step-by-step video instructions from a professional chef leading to a valuable experience.  Subscribers will also leave a rating on recipes or give tips to help other subscribers who may try a recipe in the future.
                </p>
              </div>
            </div>
            <div className="col-md-4 text-center">
              <div className="whyChooseContent" onClick={checkClasses}>
                <img src={Images.live2} alt="" />
                <h5 className="medText py-3">Live Streams</h5>
                <p className="text">
                  Lynnette’s Kitchen app features live streaming (as well as pre-recorded) cooking videos.  The live stream will feature a chatroom allowing subscribers to dialogue with one another during live cooking demonstrations, ask Chef Lynnette’s questions, or simply comment on the video during the live stream.
                </p>
              </div>
            </div>
            <div className="col-md-4 text-center">
              <div className="whyChooseContent" onClick={checkClasses}>
                <img src={Images.live3} alt="" />
                <h5 className="medText py-3">Cooking Classes</h5>
                <p className="text">
                  Lynnette’s Kitchen app offers specialty classes in the areas of cake baking and decorating, cookie baking and decorating, small party catering, and more.  Classes will be offered in both live and pre-recorded formats.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="downloadAppsOuter">
          <div className="container-lg container-fluid px-sm-4">
            <div className="downloadApps-bg">
              <div className="row">
                <div className="col-lg-5 col-md-12">
                  <h3 className="subheading colorWhite">Download Our App</h3>
                  <p className="text colorWhite">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting.
                  </p>
                  <div className="d-flex appBtnOuter">
                    <button className="appBtn">
                      <div className="appIcon">
                        <img src={Images.apple} alt="" />
                      </div>
                      <div className="">
                        <p className="extraSmallText m-0 colorWhite">
                          Download on the
                        </p>
                        <p className="text m-0 colorWhite">App Store</p>
                      </div>
                    </button>
                    <button className="appBtn">
                      <div className="appIcon">
                        <img src={Images.playstore} alt="" />
                      </div>
                      <div className="">
                        <p className="extraSmallText m-0 colorWhite text-start">
                          Get it On
                        </p>
                        <p className="text m-0 colorWhite">App Store</p>
                      </div>
                    </button>
                  </div>
                </div>
                <div className="col-lg-7 col-md-12">
                  <div className="twoMobileImg">
                    <img className="img-fluid" src={Images.twomobile} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomePage;
