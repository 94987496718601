const ApiPath = {
  AuthApiPath: {
    SIGN_UP: "api/v1/users/signup",
    LOGIN_USER: "/api/v1/users/login",
    FORGOT_PASSWOD: "api/v1/users/verify-forgot-password",
    SEND_OTP: "/api/v1/users/send-otp",
    RESET_PASSWORD: "api/v1/users/reset-password",
    LOGOUT: "/api/v1/users/logout",
  },
  UserApiPath: {
    GETALLUSER: "api/v1/users/get/all",
    UPDATE_PROFILE: "api/v1/users/update-profile",
    UPLOAD_PICTURE: "/api/v1/users/upload-profile",
    GETUSER_BYID: "/api/v1/users/get-one",
    DELTEUSER_BYADMIN: "/api/v1/users/delete",
    REMOVE_PICTURE: "/api/v1/users/remove/image",
    USER_STATUS: "/api/v1/users/update-status",
    DASHBOARD_USER: "/api/v1/admin/dashboard/",
    GET_ADMINUSER: "/api/v1/users/get",
    TRENDING_RECIPES: "/api/v1/users/home",
    CHANGE_PASSWORD: "api/v1/users/change-password",
    DELETE_COMMENT: "/api/v1/comments/delete",
  },
  adminApiPath: {
    //Recipes
    GET_RECIPES: "/api/v1/recipes/get",
    ALL_CATEGORIES: "/api/v1/categories/get-all",
    ADD_RECIPES: "/api/v1/recipes/create",
    ADD_CATEGORIES: "/api/v1/categories/add",
    GET_RECIPESDETAILS: "/api/v1/recipes/get",
    UPDATE_RECIPE: "/api/v1/recipes/update",
    DELETE_RECIPE: "/api/v1/recipes/delete",

    //chat module
    GET_CHAT_LIST: "/api/v1/chat/get",
    REMOVE_CHAT: "",

    //classes
    GET_CLASSESLIST: "/api/v1/classes/get",
    ADD_CLASSES: "/api/v1/classes/create",
    GET_CLASSDEATILS: "/api/v1/classes/get",
    UPDATE_CLASS: "/api/v1/classes/update",
    DELETE_CLASS: "/api/v1/classes/delete",
    UPLOAD_FILES: "/api/v1/users/upload-file",

    //Reports
    GETALLREPORS: "/api/v1/reports/getAll",
    DELETE_REPORTS: "/api/v1/reports/delete",

    //notification
    GET_NOTIFICATIONS_FOR_ADMIN: "",
    NOTIFICATION_COUNT_FOR_ADMIN: "",
    IS_READ: "",
    NOTIFICATION_ONOFF: "/api/v1/users/update-notifiction",
  },
  reviewsComments: {
    CREATE_REVIEW: "/api/v1/reviews/create",
    DELETE_REVIEW: "/api/v1/reviews/delete",
    GET_SINGLEREVIE: "/api/v1/reviews/get",
    UPDATE_REVIEW: "/api/v1/reviews/update",
    GETALLREVIES: "api/v1/reviews/get",
    CREATE_LIKE: "/api/v1/likes/create",
    REPLY_COMMENT: "/api/v1/comments/create",
    FAV_RECIPE: "/api/v1/favourites/create",
    REPORT: "/api/v1/reports/create",
    SUGGESTION: "/api/v1/suggestions/create",
    FAV_RECIPELIST: "/api/v1/favourites/getByUser?,",
    FAV_CLASSlIST: "api/v1/classfavourites/getByUser",
    LIKE_CLASS: "/api/v1/classlikes/create",
    FAV_CLASS: "/api/v1/classfavourites/create",
  },
  plansSubscription: {
    GET_ALLPLANS: "/api/v1/plans",
    ADD_PLANS: "/api/v1/plans/create",
    EDIT_PLANS: "/api/v1/plans/update",
    DELETE_PLANS: "/api/v1/plans/delete",
    GET_ONE_PLAN: "/api/v1/plans",
    CREATE_SUBSCRIPTION: "/api/v1/subscriptions/create",
    CANCEL_SUBSCRIPTION: "/api/v1/subscriptions/delete",
    UPDATE_SUBSCRIPTION: "/api/v1/subscriptions/update",
  },
  cards: {
    GETCARDS_LIST: "/api/v1/subscriptions/getlist",
    CREATE_CARDS: "/api/v1/subscriptions/createCard",
    DELETE_CARDS: "/api/v1/subscriptions/deleteCard",
    Set_DEFAULT: "/api/v1/subscriptions/setDefault",
  },
  bookingClasses: {
    CREATE_BOOKING: "/api/v1/bookclasses/create",
    CANCEL_BOOKING: "/api/v1/bookclasses/cancel",
    GET_BOOKEDCLASSES: "/api/v1/bookclasses/get",
  },
  infoPgaes: {
    getPages: "/api/v1/pages/getOne?page_type=",
    updatePages: "/api/v1/pages/create",
  },
  AgoraLiveStreaming: {
    GENERATE_TOKEN: "/api/v1/agora/generate-token",
    LIVE_COMMENTSLIST: "/api/v1/livecomments/get?room_id",
    GOLIVE: "/api/v1/agora/generate-token-withoutclass",
    JOINLIVE: "/api/v1/agora/generate-token-livestreaming",
    CHECKLIVEEVENT: "/api/v1/agora/live",
    CHECKCURRENT_LIVEEVENT: "/api/v1/agora/live",
  },
  contactUs: {
    CONTACT_US: "/api/v1/contactus/send",
  },
  PushNotifications: {
    PUSH_SUBSCRIPTIONID_FOR_NOTIFICATIONS: "/api/v1/users/update-onesignal",
    NOTIFICATION_LIST: "/api/v1/notifications/get",
    CLEAR_ALL_NOTIFICATIONS: "/api/v1/notifications/delete",
    IS_NOTIFY: "/api/v1/users/update-notifiction",
    TOTAL_NOTIFICATIONCOUNT: "/api/v1/notifications/total",
    NOTIFICATION_READ: "/api/v1/notifications/isread/",
  },
};

export default ApiPath;
