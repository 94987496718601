import React from "react";
import { socketInstance } from "../../../components/common/socketNew";
import { useNavigate } from "react-router-dom";

const ConfirmModal = (props) => {
  const navigate = useNavigate();
  const handleEndCall = () => {
    socketInstance.emit("end_class", {});
    socketInstance.on("end_class", (data) => {});
    navigate("/admin/classes");
    window.location.reload();
  };
  return (
    <div className="py-3">
      <h5 className="font-28 text-center">
        Are you Sure you want to end the live class ?
      </h5>

      <div className="row justify-content-center mt-4">
        <div className="d-grid gap-1 col-lg-3 col-md-6">
          <button
            className="primaryBtn submitBtn m-0"
            type="button"
            onClick={(e) => handleEndCall(e)}
          >
            <span>Yes, Exit</span>
          </button>
        </div>
        <div className="d-grid gap-1 col-lg-3 col-md-6">
          <button
            className="primaryBtn cancelBtn m-0"
            onClick={(e) => props.closeModal()}
          >
            <span>No, Cancel</span>
          </button>
        </div>
      </div>
    </div>
  );
};
export default ConfirmModal;
