import { createSlice } from "@reduxjs/toolkit";
import { restAllData } from "../commanAction";

const initialState = {
  isLoggedIn: false,
  loading: false,
  getRecipe: {},
  getCategories: {},
  getRecipeDetails: {},
  getClasses: {},
  getClassDetails: {},
  goLive: {},
};

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  extraReducers: (builder) => builder.addCase(restAllData, () => initialState),
  reducers: {
    getRecipe: (state) => {
      state.loading = true;
    },
    setRecipe: (state, action) => {
      state.loading = false;
      state.getRecipe = action.payload;
    },
    getRecipeWithUser: (state) => {
      state.loading = true;
    },
    setRecipeWithUser: (state, action) => {
      state.loading = false;
      state.getRecipeWithUser = action.payload;
    },

    addRecipes: (state) => {
      state.loading = true;
    },
    setAddRecipe: (state, action) => {
      state.loading = false;
    },
    addCategory: (state) => {
      state.loading = true;
    },
    setAddCategory: (state, action) => {
      state.loading = false;
    },
    getCategories: (state) => {
      state.loading = true;
    },
    setCategories: (state, action) => {
      state.loading = false;
      state.getCategories = action.payload;
    },
    getRecipeDetails: (state) => {
      state.loading = true;
    },
    setRecipeDetails: (state, action) => {
      state.loading = false;
      state.getRecipeDetails = action.payload;
    },
    updateRecipeDetails: (state) => {
      state.loading = true;
    },
    setUpdateRecipeDetails: (state, action) => {
      state.loading = false;
    },
    deleteRecipe: (state) => {
      state.loading = true;
    },

    setDeleteRecipe: (state, action) => {
      state.loading = false;
    },

    getClasses: (state) => {
      state.loading = true;
    },
    setClasses: (state, action) => {
      state.loading = false;
      state.getClasses = action.payload;
    },
    addClasses: (state) => {
      state.loading = true;
    },
    setAddClasses: (state, action) => {
      state.loading = false;
    },
    updateClassDetails: (state) => {
      state.loading = true;
    },
    setUpdateClassDetails: (state, action) => {
      state.loading = false;
    },
    deleteClass: (state) => {
      state.loading = true;
    },

    setDeleteClass: (state, action) => {
      state.loading = false;
    },
    getClassDetails: (state) => {
      state.loading = true;
    },
    setClassDetails: (state, action) => {
      state.loading = false;
      state.getClassDetails = action.payload;
    },
    uploadFiles: (state) => {
      state.loading = true;
    },
    setuploadFiles: (state, action) => {
      state.loading = false;
      //state.getClassDetails = action.payload
    },
    getPlans: (state) => {
      state.loading = true;
    },
    setPlans: (state, action) => {
      state.loading = false;
      state.getPlans = action.payload;
    },
    addPlans: (state) => {
      state.loading = true;
    },
    setAddPlans: (state, action) => {
      state.loading = false;
      //state.addPlans = action.payload
    },
    updatePlans: (state) => {
      state.loading = true;
    },
    setUpdatePlans: (state, action) => {
      state.loading = false;
      state.updatePlans = action.payload;
    },
    deletePlans: (state) => {
      state.loading = true;
    },
    setDeletePlans: (state, action) => {
      state.loading = false;
      state.updatePlans = action.payload;
    },
    getOnePlan: (state) => {
      state.loading = true;
    },
    setOnePlan: (state, action) => {
      state.loading = false;
      state.getOnePlan = action.payload;
    },
    getAboutUs: (state) => {
      state.loading = true;
    },
    setAboutUs: (state, action) => {
      state.loading = false;
      state.getAboutUs = action.payload;
    },
    getPrivacyPolicy: (state) => {
      state.loading = true;
    },
    setPrivacyPolicy: (state, action) => {
      state.loading = false;
      state.getPrivacyPolicy = action.payload;
    },
    getTermsConditions: (state) => {
      state.loading = true;
    },
    setTermsConditions: (state, action) => {
      state.loading = false;
      state.getTermsConditions = action.payload;
    },
    getUpcommingList: (state) => {
      state.loading = true;
    },
    setUpcommingList: (state, action) => {
      state.loading = false;
      state.getUpcommingList = action.payload;
    },
    getTodaysLiveClasses: (state) => {
      state.loading = true;
    },
    setTodaysLiveClasses: (state, action) => {
      state.loading = false;
      state.getTodaysLiveClasses = action.payload;
    },
    updateInfo: (state) => {
      state.loading = true;
    },
    setUpdateInfo: (state, action) => {
      state.loading = false;
      state.updateInfo = action.payload;
    },
    getReports: (state) => {
      state.loading = true;
    },
    setReports: (state, action) => {
      state.loading = false;
      state.getReports = action.payload;
    },
    goLive: (state) => {
      state.loading = true;
    },
    setGoLive: (state, action) => {
      state.goLive = action.payload;
    },
    deleteReport: (state) => {
      state.loading = true;
    },

    setDeleteReport: (state, action) => {
      state.loading = false;
    },
    notificationListForAdmin: (state) => {
      state.loading = true;
    },

    setNotificationListForAdmin: (state, action) => {
      state.loading = false;
      state.notificationListForAdmin = action.payload;
    },
    notificationCountForAdmin: (state) => {
      state.loading = true;
    },
    setNotificationCountForAdmin: (state, action) => {
      state.loading = false;
      state.notificationCountForAdmin = action.payload;
    },
    isReadAdmin: (state) => {
      state.loading = true;
    },
    setIsReadAdmin: (state, action) => {
      state.loading = false;
      state.isRead = action.payload;
    },
    toggleNotification: (state) => {
      state.loading = true;
    },
    setToggleNotification: (state, action) => {
      state.loading = false;
      state.toggleNotification = action.payload;
    },
    getChatList: (state) => {
      state.loading = true;
    },
    setGetChatList: (state, action) => {
      state.loading = false;
      state.getChatList = action.payload;
    },
    onErrorStopLoad: (state) => {
      state.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getRecipe,
  setRecipe,
  getRecipeWithUser,
  setRecipeWithUser,
  addRecipes,
  setAddRecipe,
  addCategory,
  setAddCategory,
  getCategories,
  setCategories,
  onErrorStopLoad,
  getRecipeDetails,
  setRecipeDetails,
  updateRecipeDetails,
  setUpdateRecipeDetails,
  deleteRecipe,
  setDeleteRecipe,
  getClasses,
  setClasses,
  addClasses,
  setAddClasses,
  updateClassDetails,
  setUpdateClassDetails,
  deleteClass,
  setDeleteClass,
  getClassDetails,
  setClassDetails,
  uploadFiles,
  setuploadFiles,
  getPlans,
  setPlans,
  addPlans,
  setAddPlans,
  updatePlans,
  setUpdatePlans,
  deletePlans,
  setDeletePlans,
  getOnePlan,
  setOnePlan,
  getAboutUs,
  setAboutUs,
  updateInfo,
  setUpdateInfo,
  getUpcommingList,
  setUpcommingList,
  getPrivacyPolicy,
  setPrivacyPolicy,
  getTodaysLiveClasses,
  setTodaysLiveClasses,
  getTermsConditions,
  setTermsConditions,
  getReports,
  setReports,
  deleteReport,
  setDeleteReport,
  goLive,
  setGoLive,
  notificationCountForAdmin,
  setNotificationCountForAdmin,
  notificationListForAdmin,
  setNotificationListForAdmin,
  isReadAdmin,
  setIsReadAdmin,
  toggleNotification,
  getChatList,
  setGetChatList,
  setToggleNotification,
} = adminSlice.actions;

export default adminSlice.reducer;
