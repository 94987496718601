import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import * as Images from "../../../utilities/images";
import moment from "moment";
import { socketInstance } from "../../components/common/socketNew";
import { useUserSelector } from "../../../redux/selector/user";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

const SupportChatUser = () => {
  document.title = "Support-Chat";
  const chatRef = useRef(null);
  const toastId = useRef(null);
  const [responseRoomId, setResponseRoomId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [chatList, setChatList] = useState([]);
  const authUserSelector = useUserSelector();
  const userInfo = authUserSelector?.loginUserProfile?.data;

  const handleChatScroll = () => {
    if (chatRef?.current) {
      chatRef?.current?.scrollIntoView({
        block: "end",
        inline: "end",
        behavior: "smooth",
      });
    }
  };

  const handleSendMessages = (e) => {
    let sendChat = e.target[0].value;
    e.preventDefault();
    if (!sendChat) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please add comment!");
        return false;
      }
    } else {
      socketInstance.emit("send_message", {
        to_id: 1,
        message: sendChat,
        message_type: 1,
      });
      socketInstance.on("send_message", (data) => {
        if (data.statusCode === 200) {
          e.target.reset();
        }
        socketInstance.emit("get_chat", {
          room_id: responseRoomId,
          page: 1,
          limit: 1000,
        });
      });
    }
  };

  useEffect(() => {
    if (socketInstance) {
      socketInstance.connect();
      socketInstance.emit("join_chat", {
        to_id: 1,
      });
      socketInstance.on("join_chat", (data) => {
        setResponseRoomId(data?.data?.room_id);
        socketInstance.emit("get_chat", {
          room_id: data?.data?.room_id,
          page: 1,
          limit: 1000,
        });
      });
      socketInstance.on("get_chat", (data) => {
        setSortedChatData(data?.data?.list);
        setIsLoading(false);
        handleChatScroll();
      });
      return () => {
        socketInstance.disconnect();
      };
    }
  }, []);

  function setSortedChatData(chatDataResult) {
    const sortedListData = [...chatDataResult].sort(
      (a, b) => new Date(a?.created_at) - new Date(b?.created_at)
    );
    setChatList(sortedListData);
    handleChatScroll();
  }

  return (
    <>
      <section className="loginpage">
        <div className="container-fluid">
          <div className="row item-center flex-row">
            <div className=" col-lg-5 col-md-6">
              <div className="logininner changePasswordOuter">
                <div className="header-logo">
                  <Link to={"/"}>
                    <img src={Images.logo} alt="" />
                  </Link>
                </div>
                <div className="loginForm">
                  <Link className="colorpink" to="/">
                    <img className="backicon" src={Images.backarrow} alt="" />
                    Go Back
                  </Link>
                  <h1 className="heading py-3">Support Chat </h1>
                </div>

                <div className="">
                  <p className="subtext copyrightText pb-3">
                    © copyright{moment().year()}. All rights reserved
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-7 p-0">
              <div className="imgouter supportChatInner">
                <div className="supportChatHeader">
                  <div className="chatInnerSupport">
                    <div className="chat-bg">
                      <img
                        className="w-100"
                        alt="chat-logo"
                        src={Images.supportChatChat}
                      />
                    </div>
                    <div className="smiley-text">
                      <div className="d-flex align-items-center">
                        <h1>Hello</h1>
                        <img alt="smile-logo" src={Images.smileyLogo} />
                      </div>
                      <p>How can i help you?</p>
                    </div>
                  </div>
                </div>
                {isLoading ? (
                  <Spinner
                    className="pageLoader"
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  <div className="comment-content">
                    <div className="comment-inner">
                      <div className="chat-scrollbar">
                        {chatList?.length > 0 ? (
                          chatList?.map((data, index) => {
                            return (
                              <>
                                {userInfo?.id == data?.from_id ? (
                                  <>
                                    <div
                                      ref={
                                        index === chatList.length - 1
                                          ? chatRef
                                          : null
                                      }
                                      key={index}
                                      className={
                                        userInfo?.id == data?.from_id
                                          ? "comment-right"
                                          : data?.from_id == 1
                                          ? "comment-left"
                                          : ""
                                      }
                                    >
                                      <div className="comment-msg">
                                        <h1>{userInfo?.full_name} (You)</h1>
                                        <p>
                                          {" "}
                                          {moment(data?.created_at).fromNow()}
                                        </p>
                                        <span>{data?.message}</span>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}
                                <>
                                  {data?.from_id == 1 ? (
                                    <div key={index} className="comment-left">
                                      <div className="comment-msg-left">
                                        <h1>Admin</h1>
                                        <p>
                                          {moment(data?.created_at).fromNow()}
                                        </p>
                                        <span>{data?.message}</span>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </>
                              </>
                            );
                          })
                        ) : (
                          <h3 className="noDataText">
                            {" "}
                            No Chat Found, Start the conversation!
                          </h3>
                        )}
                      </div>
                      <form onSubmit={(e) => handleSendMessages(e)}>
                        <div className="commentchat-input">
                          <input type="text" placeholder="Your Comment" />
                          <button className="border-0" type="submit">
                            <img alt="send-logo" src={Images.sendLogo} />
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SupportChatUser;
