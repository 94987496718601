import React, { useState, useEffect } from "react";
import * as Images from "../../../../utilities/images";
import { useDispatch } from "react-redux";
import { getReports } from "../../../../redux/slices/admin";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import moment from "moment";
import CustomModal from "../../../components/modals/modal";
import DeleteReport from "./deleteReport";
import { Spinner } from "react-bootstrap";

const Reports = () => {
  const dispatch = useDispatch();
  const [reportData, setReportData] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [perPageSize, setPerPageSize] = useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [key, setKey] = useState(Math.random());

  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
  const ReportList = (
    page = currentPage,
    limit = perPageSize,
    keyword = searchValue
  ) => {
    let params = {
      page: page,
      limit: limit,
      keyword: keyword,
    };

    if (Boolean(keyword)) {
      params = { ...params, keyword: keyword };
    }

    dispatch(
      getReports({
        ...params,
        cb(res) {
          if (res?.data) {
            setTotalPages(res?.data?.data?.total_pages);
            setTotalCount(res?.data?.data?.totalRecords);
            setReportData(res?.data?.data?.list);
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };
  useEffect(() => {
    ReportList();
    window.scrollTo(0, 0);
    document.title = "Reports";
  }, []);

  const handleChangePages = (e) => {
    setPerPageSize(e.target.value);
    setCurrentPage(1);
    ReportList(1, e.target.value);
  };
  const handlePageClick = (selectedItem) => {
    const selectedPage = selectedItem.selected + 1;
    setCurrentPage(selectedPage);
    ReportList(selectedPage, perPageSize);
  };

  const handleDeleteRecipe = (ReportId) => {
    setModalDetail({
      show: true,
      title: "DeleteReport",
      flag: "Delete",
      id: ReportId,
    });
    setKey(Math.random());
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="tab-content" id="pills-tabContent">
            <div className="tab-pane fade active show" id="pills-home">
              <div className="contentCommon mb-2">
                <div className="d-flex justify-content-between">
                  <div className="searchDataTable">
                    <div className="serachBar">
                      <img
                        src={Images.searchiconpink}
                        alt="Search"
                        className="searchIcon"
                      />
                      <input
                        type="text"
                        name="Search"
                        className="form-control search subtext"
                        placeholder="Search..."
                        value={searchValue}
                        onChange={(e) => {
                          setSearchValue(e.target.value);
                          ReportList(1, 10, e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="dataTables_length pt-3"
                    id="staffsTable_length"
                  >
                    <label className="d-flex align-items-center">
                      {" "}
                      <span className="text me-2">Show</span>{" "}
                      <select
                        name="staffsTable_length"
                        aria-controls="staffsTable"
                        className="custom-select custom-select-sm form-control form-control-sm"
                        onChange={(e) => handleChangePages(e)}
                        value={perPageSize}
                      >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="75">75</option>
                        <option value="100">100</option>
                      </select>
                    </label>
                  </div>
                </div>
                <div className="courseCardCon">
                  {reportData?.length > 0 ? (
                    reportData?.map((data, idx) => {
                      return (
                        <div className="courseCard mt-3" key={idx}>
                          <div className="courseImg">
                            <img
                              src={
                                data?.avidence_file
                                  ? data?.avidence_file
                                  : Images.foodbox
                              }
                              alt="img"
                            />
                          </div>
                          <div className="courseDetails">
                            <div className="courseDetailsInner">
                              <div className="toptextOuter outerText_">
                                <div className="d-flex justify-content-between">
                                  <p className="bigText pb-2 m-0 pe-3">
                                    {data?.title}
                                  </p>
                                  <p className="text m-0 pe-2">
                                    <span className="me-2">
                                      <img src={Images.reportsicon} alt="" />{" "}
                                    </span>{" "}
                                    Report ID: {data?.id}
                                  </p>
                                </div>
                                {/* <p className="font-10">Description:</p>
                          <p className="text p-0">
                          {data?.description}
                          </p> */}
                              </div>
                              <ul className="courseDurationDetials reportsOuter">
                                <li>
                                  <p>
                                    <img src={Images.type1} alt="icon" />
                                    &nbsp; by: {data?.created_by?.full_name}
                                  </p>
                                </li>
                                <li>
                                  <p>
                                    <img src={Images.clender} alt="icon" />
                                    &nbsp;{" "}
                                    {moment(data?.date).format("MMMM D, YYYY")}
                                  </p>
                                </li>
                              </ul>
                            </div>
                            <div className="rightArrowOuter">
                              <div className="rightArrow dropdown text-end">
                                <img
                                  src={Images.trash}
                                  alt=""
                                  className="dots dropdown-toggle"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  onClick={() => handleDeleteRecipe(data?.id)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <>
                      {reportData?.length == 0 ? (
                        <h3 className="noDataText">No Data Found</h3>
                      ) : (
                        <Spinner
                          className="pageLoader"
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                    </>
                  )}
                </div>
                <div className="">
                  <div
                    className="dataTables_length pt-3"
                    id="staffsTable_length"
                  >
                    &nbsp;{" "}
                    <span className="subtext">
                      Showing {`${perPageSize * (currentPage - 1) + 1}`} to{" "}
                      {`${
                        perPageSize * (currentPage - 1) + reportData?.length
                      }`}{" "}
                      of {`${totalCount}`} entries{" "}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="tab-pane fade" id="pills-profile">
              <div className="contentCommon mb-2">
                <div className="searchDataTable">
                  <div className="serachBar">
                    <img
                      src={Images.Login}
                      alt="Search"
                      className="searchIcon"
                    />
                    <input
                      type="text"
                      name="Search"
                      className="form-control search"
                      placeholder="Search..."
                    />
                  </div>
                  <div className="dataTables_length" id="staffsTable_length">
                    <label>
                      {" "}
                      Show{" "}
                      <select
                        name="staffsTable_length"
                        aria-controls="staffsTable"
                        className="custom-select custom-select-sm form-control form-control-sm"
                      >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="75">75</option>
                        <option value="100">100</option>
                      </select>
                      &nbsp;{" "}
                      <span className="subtext">
                        Showing {`${perPageSize * (currentPage - 1) + 1}`} to{" "}
                        {`${
                          perPageSize * (currentPage - 1) + reportData?.length
                        }`}{" "}
                        of {`${totalCount}`} entries{" "}
                      </span>{" "}
                      &nbsp;{" "}
                      <span className="subtext">
                        Showing {`${perPageSize * (currentPage - 1) + 1}`} to{" "}
                        {`${
                          perPageSize * (currentPage - 1) + reportData?.length
                        }`}{" "}
                        of {`${totalCount}`} entries{" "}
                      </span>{" "}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        className={modalDetail.flag === "Delete" ? "delete" : ""}
        child={
          modalDetail.flag === "Delete" ? (
            <DeleteReport
              closeModal={() => {
                handleOnCloseModal();
              }}
              refreshData={() => ReportList()}
              reportId={modalDetail?.id}
            />
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
      {reportData?.length > 0 ? (
        <>
          <ReactPaginate
            className="paginationBox mb-0"
            breakLabel=""
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            marginPagesDisplayed={0}
            pageCount={totalPages}
            previousLabel="< prev"
            renderOnZeroPageCount={null}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default Reports;
