import React, { useEffect, useState } from "react";
import * as Images from "../../../utilities/images";
import { useDispatch } from "react-redux";
import { getPlans } from "../../../redux/slices/admin";
import Card from "./card";
import CustomModal from "../../components/modals/modal";
import { Spinner } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const Subscription = () => {
  const location = useLocation();
  const { state } = location;
  const [showPlans, setShowPlans] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [key, setKey] = useState(Math.random());

  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const handleShowCard = (planId) => {
    setModalDetail({
      show: true,
      title: "CardDetails",
      flag: "cardDetail",
      id: planId,
    });
    setKey(Math.random());
  };

  const getAllPlans = () => {
    setLoading(true);
    dispatch(
      getPlans({
        cb(res) {
          if (res?.data) {
            setShowPlans(res?.data?.data);
            setLoading(false);
          } else {
          }
        },
      })
    );
  };

  useEffect(() => {
    getAllPlans();
    window.scrollTo(0, 0);
    document.title = "Plans & Subscription";
  }, []);

  const currentSubscribedPlan = showPlans?.find((val) => val?.Subscription);

  useEffect(() => {
    if (state) {
      localStorage.setItem("redirectUrl", state);
    } else {
      localStorage.removeItem("redirectUrl");
    }
  }, []);

  return (
    <>
      <section className="favoritesBanner subscripinner_ pb-2">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center pt-5">
              <h2 className="bigHeading pt-5 pb-4 ">Our Subscription Plans</h2>
              {/* <p className="text px-3">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p> */}
            </div>
          </div>
        </div>
      </section>
      {loading ? (
        <div className="subscription-loader">
          <Spinner
            className="pageLoader"
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <div className="subscriptionOuter">
          <div className="subscription-inner">
            <div className="container">
              <div className="row">
                {showPlans?.map((data, index) => {
                  return (
                    <>
                      <div className="col-lg-6 col-md-6 mb-4" key={index}>
                        <div
                          className={`SubscriptionBox ${data?.Subscription
                              ? "SubscriptionBoxActive"
                              : "SubscriptionBox"
                            }`}
                        >
                          <div className="SubscriptionBoxInner">
                            <span className="text substext text-capitalize">
                              {data?.nickname}
                            </span>
                            <h2 className="bigHeading py-4 text-center">
                              ${data?.amount / 100}
                            </h2>
                            <p className="text pb-3 text-center text-capitalize">
                              {data?.interval}
                            </p>
                            <div className="d-flex align-items-center ScrollText">
                              <p className="text DefaultPara m-0 ms-2">
                                {data?.metadata?.description
                                  .split(",")
                                  .map((data) => {
                                    return (
                                      <div className="d-flex">
                                        <p className="m-0">
                                          <img
                                            className="darktick"
                                            src={Images.tick}
                                            alt=""
                                          />

                                          <img
                                            className="tickwhite"
                                            src={Images.tickwhite}
                                            alt=""
                                          />
                                        </p>
                                        <p
                                          className={`text DefaultPara m-0 ms-2 text-capitalize ${data?.subscription
                                              ? "textActive "
                                              : "text"
                                            }`}
                                        >
                                          {data}
                                        </p>
                                      </div>
                                    );
                                  })}
                              </p>
                            </div>
                            <div className="d-flex align-items-center pb-4"></div>
                            <div className="d-flex justify-content-center align-items-center subscriptionButton">
                              {data?.Subscription ? (
                                <>
                                  <button
                                    className="activePlan"
                                    type="button"
                                    disabled
                                  >
                                    Active Plan
                                  </button>
                                </>
                              ) : (
                                <>
                                  <button
                                    className="login-btn"
                                    type="button"
                                    onClick={() => handleShowCard(data?.id)}
                                  >
                                    {data?.Subscription ||
                                      data?.amount > currentSubscribedPlan?.amount
                                      ? "Upgrade"
                                      : "Buy Now"}
                                  </button>
                                  <span
                                    className="center-img"
                                    onClick={() => handleShowCard(data?.id)}
                                  >
                                    <img
                                      className="darkrechtangle"
                                      src={Images.centerrectangle}
                                      alt=""
                                      onClick={() => handleShowCard(data?.id)}
                                    />
                                    <img
                                      className="whiterectabgle"
                                      src={Images.centerrectanglewhite}
                                      alt=""
                                      onClick={() => handleShowCard(data?.id)}
                                    />
                                  </span>
                                  <span
                                    className="circle-btn"
                                    onClick={() => handleShowCard(data?.id)}
                                  >
                                    <img
                                      className="whitearrow"
                                      src={Images.arrow}
                                      alt=""
                                      onClick={() => handleShowCard(data?.id)}
                                    />
                                    <img
                                      className="darkarrow"
                                      src={Images.arrowrotatedark}
                                      alt=""
                                      onClick={() => handleShowCard(data?.id)}
                                    />
                                  </span>
                                </>
                              )}
                            </div>
                          </div>
                          {data?.metadata?.is_popular === "1" ? (
                            <div className="mostPopularImg">
                              <img src={Images.mostpopular} alt="" />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        className={
          modalDetail.flag === "cardDetail" ? "commonWidth customContent" : ""
        }
        ids={
          modalDetail.flag === "cardDetail"
            ? "cardDetail"
            : modalDetail.flag === "Delete"
              ? "delete"
              : ""
        }
        child={
          modalDetail.flag === "cardDetail" ? (
            <Card
              closeModal={() => {
                handleOnCloseModal();
              }}
              refreshData={() => getAllPlans()}
              planId={modalDetail?.id}
            />
          ) : (
            ""
          )
        }
        header={
          <div className="modalHeader_">
            <div className="common_">
              {modalDetail.flag === "cardDetail" ? (
                <h3 className="headingSmall_">Add Subscription</h3>
              ) : modalDetail.flag === "Delete" ? (
                <h2 className="headingSmall_"></h2>
              ) : (
                ""
              )}
            </div>
          </div>
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default Subscription;
