import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useUserSelector } from "../../../redux/selector/user";
import { logout } from "../../../redux/slices/auth";
import {
  loginUserProfile,
  notificationsList,
  openSideMenu,
} from "../../../redux/slices/user";
import * as Images from "../../../utilities/images";
import AdminNotification from "../common/adminNotification";
import CustomModal from "../modals/modal";

const Navbar = () => {
  const authUserSelector = useUserSelector();
  const [isSidebarOpen, setIsSidebarOpen] = useState(
    authUserSelector?.isOpenSidebar
  );
  const [notificationList, setNotificationList] = useState(null);
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const loggedInUser = authUserSelector?.loginUserProfile?.data;

  const getNotificationList = () => {
    dispatch(
      notificationsList({
        cb(res) {
          if (res?.status === 200) {
            setNotificationList(res?.data?.data);
            const unreadNotifications = res?.data?.data?.list
              ?.filter((value) => value?.is_read === 0)
              ?.reduce((acc) => acc + 1, 0);
            setUnreadNotificationCount(unreadNotifications);
          }
        },
      })
    );
  };

  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "notificationAdmin",
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "notificationAdmin",
    });
    setKey(Math.random());
  };

  const handleDelete = (e, go) => {
    e.preventDefault();
    if (go === "profile") {
      navigate("myProfile");
    } else {
      dispatch(
        logout({
          cb(res) {
            if (res?.data) {
            } else {
            }
          },
        })
      );
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    dispatch(openSideMenu(isSidebarOpen));
  }, [isSidebarOpen]);

  const getAdminUser = () => {
    dispatch(
      loginUserProfile({
        cb(res) {
          if (res) {
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };

  useEffect(() => {
    getAdminUser();
    dispatch(openSideMenu(false));
    getNotificationList();
  }, []);

  const handleGoToNotifications = () => {
    setModalDetail({
      show: true,
      title: "DeletePlan",
      flag: "notificationAdmin",
    });
    setKey(Math.random());
  };
  return (
    <>
      <div className="Topnavbar">
        <h4 className="navBarText">
          {location.pathname === "/admin/dashboard"
            ? "Dashboard"
            : location.pathname === "/admin/user"
            ? "Users-List"
            : location.pathname === "/admin/recipe"
            ? "Recipe-List"
            : location.pathname === "/admin/classes"
            ? "Classes-List"
            : location.pathname === "/admin/detailrecipe/:id"
            ? "Recipe Details"
            : location.pathname === "/admin/addclass"
            ? "Add Class"
            : location.pathname === "/admin/classdetail/:id"
            ? "Class Details"
            : location.pathname === "/admin/addrecipe"
            ? "Add Recipe"
            : location.pathname === "/admin/editclass/:id"
            ? "Edit ClassDetails"
            : location.pathname === "/admin/editrecipe/:id"
            ? "Edit RecipeDetails"
            : location.pathname === "/admin/plan"
            ? "Plans"
            : location.pathname === "/admin/addplan"
            ? "Add Plan"
            : location.pathname === "/admin/editplan/:id"
            ? "Edit Plan"
            : location.pathname === "/admin/info"
            ? "Info-Pages"
            : location.pathname === "/admin/reports"
            ? "Reports"
            : location.pathname === "/admin/supportChat"
            ? "Support-Chat"
            : ""}
        </h4>

        {/* <h4>Dashboard</h4> */}

        <div className="headerRight dropdown">
          <div
            data-bs-toggle="dropdown"
            aria-expanded="false"
            className="me-4 admin-profile-dropdown"
          >
            <img
              src={
                loggedInUser?.profile_image
                  ? loggedInUser?.profile_image
                  : Images.defaultUser
              }
              alt="user"
              className="user_img"
            />
            <button
              className="btn dropdown-toggle profile-dropdown-btn"
              // data-bs-toggle="dropdown"
              // aria-expanded="false"
            >
              <span className="headeRightUser"> {loggedInUser?.full_name}</span>
              <span className="downArrow">
                <img src={Images.downArrow} alt="down_arrow" />
              </span>

              <small className="mb-0">Admin</small>
            </button>
            <ul className="dropdown-menu">
              <li onClick={(e) => handleDelete(e, "profile")}>
                <Link className="dropdown-item" to="myProfile">
                  <img src={Images.users} alt="profile icon" className="me-3" />{" "}
                  Profile
                </Link>
              </li>
              <li className="dropdown-item" onClick={(e) => handleDelete(e)}>
                <img src={Images.trash} alt="logout icon" className="me-3" />
                Logout
              </li>
            </ul>
          </div>
          <div className="navbar_box">
            <div onClick={handleGoToNotifications} className="favouriteIcon">
              <div className="countOuter">
                <span>{unreadNotificationCount}</span>
              </div>
              <span>
                <img src={Images.bell} alt="" />
              </span>
            </div>
          </div>
          <div>
            <button className="bg-white" onClick={toggleSidebar}>
              <i className="fas fa-bars  bas-toggle-button"></i>
            </button>
          </div>
        </div>
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={
          modalDetail.flag === "notificationAdmin"
            ? "notificationAdmin"
            : "notificationAdmin"
        }
        child={
          modalDetail.flag === "notificationAdmin" ? (
            <AdminNotification
              notificationList={notificationList}
              getNotificationList={getNotificationList}
              close={() => handleOnCloseModal()}
            />
          ) : (
            ""
          )
        }
        header={
          <>
            <div className="notificationAdmin">
              <h2 className="modalHeading mb-0 align-items-center gap-2">
                <figure>
                  <img
                    src={Images.notificationnew}
                    alt="img"
                    onClick={() => handleOnCloseModal()}
                  />
                </figure>
                <p className="notificationHeading">Notification</p>
              </h2>
              <button className="closeButton d-none">
                <img
                  src={Images.bell}
                  alt="img"
                  onClick={() => handleOnCloseModal()}
                />
              </button>
            </div>
          </>
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default Navbar;
