import React, { useState, useEffect } from "react";
import * as Images from "../../../../utilities/images";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import Deleterecipe from "./deleteRecipe";
import ReactPaginate from "react-paginate";
import { getRecipe, getCategories } from "../../../../redux/slices/admin";
import CustomModal from "../../../components/modals/modal";
import { Spinner } from "react-bootstrap";

const Recipe = () => {
  const dispatch = useDispatch();
  const [getRecipes, setGetRecipes] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [perPageSize, setPerPageSize] = useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [Categories, setCategories] = useState([]);
  const [catId, setCatId] = useState("");
  const [option, setOption] = useState("");
  const [key, setKey] = useState(Math.random());
  const navigate = useNavigate();

  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const getRecipeList = (
    page = currentPage,
    limit = perPageSize,
    keyword = searchValue,
    id
  ) => {
    let params = {
      page: page,
      limit: limit,
      keyword: keyword,
      cat_id: id == undefined ? "" : id,
    };

    if (Boolean(keyword)) {
      params = { ...params, keyword: keyword };
    }
    //setLoading(true);
    dispatch(
      getRecipe({
        ...params,
        cb(res) {
          if (res?.data) {
            setTotalPages(res?.data?.data?.total_pages);
            setTotalCount(res?.data?.data?.totalRecords);
            setGetRecipes(res?.data?.data?.list);
            //setLoading(false);
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };
  useEffect(() => {
    getRecipeList();
    getCategory();
    window.scrollTo(0, 0);
    document.title = "Recipe-List";
  }, []);

  const getCategory = () => {
    dispatch(
      getCategories({
        cb(res) {
          if (res?.data) {
            setCategories(res?.data?.data);
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };
  const handleFilterData = (id, title) => {
    setCatId(id);
    setOption(title);
    getRecipeList(currentPage, perPageSize, searchValue, id);
  };

  const handleDeleteRecipe = (Recipeid, Recipetitle) => {
    setModalDetail({
      show: true,
      title: "Deleterecipe",
      flag: "Delete",
      id: Recipeid,
      Recipetitle: Recipetitle,
    });
    setKey(Math.random());
  };

  const handleViewRecipe = (id) => {
    if (id) {
      navigate(`/admin/detailrecipe/${id}`);
    }
  };
  const handleEditRecipe = (id) => {
    if (id) {
      navigate(`/admin/editrecipe/${id}`);
    }
  };

  const handlePageClick = (selectedItem) => {
    const selectedPage = selectedItem.selected + 1;
    setCurrentPage(selectedPage);
    getRecipeList(selectedPage, perPageSize);
  };

  const handleChangePages = (e) => {
    setPerPageSize(e.target.value);
    setCurrentPage(1);
    getRecipeList(1, e.target.value);
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div
            className="tab-content recipe-contols-outer"
            id="pills-tabContent"
          >
            <div className="tab-pane fade active show" id="pills-home">
              <div className="contentCommon insidecontent_ mb-2">
                <div className="searchDataTable">
                  <div className="serachBar">
                    <img
                      src={Images.searchiconpink}
                      alt="Search"
                      className="searchIcon"
                    />
                    <input
                      type="text"
                      name="Search"
                      className="form-control search subtext"
                      placeholder="Search..."
                      value={searchValue}
                      onChange={(e) => {
                        setSearchValue(e.target.value);
                        getRecipeList(1, 10, e.target.value);
                      }}
                    />
                  </div>
                  <div className="innersearchbar inner-controls">
                    <div className="customSelect">
                      <label className="text">Filter By</label>
                      <select
                        onChange={(e) => {
                          handleFilterData(e.target.value);
                        }}
                      >
                        <option value={""}> All</option>
                        {Categories.map((data, index) => (
                          <option key={index} value={data.id}>
                            {data.title}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="dataTables_length" id="staffsTable_length">
                      <label className="text"> Show </label>
                      <select
                        name="staffsTable_length"
                        aria-controls="staffsTable"
                        className="custom-select custom-select-sm form-control form-control-sm"
                        onChange={(e) => handleChangePages(e)}
                        value={perPageSize}
                      >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="75">75</option>
                        <option value="100">100</option>
                      </select>{" "}
                    </div>
                    <div className="add-recipe-btn">
                      <Link to={"/admin/addrecipe"}>
                        <button className="login-btn">Add Recipes</button>
                        <span className="center-img">
                          <img src={Images.centerrectangle} alt="" />
                        </span>
                        <span className="circle-btn recipeBtnCircle ">
                          <img
                            className="img-fluid"
                            src={Images.plussign}
                            alt=""
                          />
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="innerCourseCart_ space  bgremove_">
                  {getRecipes?.length > 0 ? (
                    getRecipes?.map((data, index) => {
                      return (
                        <div
                          className="courseCardCon courseCardConRecipe "
                          key={index}
                        >
                          <div className="courseCard mt-3">
                            <div className="courseImg">
                              <img
                                onClick={() => handleViewRecipe(data?.id)}
                                classes="img-fluid"
                                src={
                                  data?.thumbnail_image
                                    ? data?.thumbnail_image
                                    : Images.usericon
                                }
                                alt="img"
                              />
                            </div>
                            <div className="courseDetails">
                              <div className="courseDetailsInner pe-2">
                                <div className="toptextOuter">
                                  <p className="bigText pb-2 m-0">
                                    {data?.title}
                                  </p>
                                  <p className="text p-0">
                                    {data?.description
                                      ? data?.description
                                      : "Test dummy data"}
                                  </p>
                                </div>
                                <ul className="courseDurationDetials">
                                  <li>
                                    <p>
                                      <img src={Images.type1} alt="icon" />
                                      &nbsp; Type
                                    </p>
                                    <h6>{data?.category_title}</h6>
                                  </li>
                                  <li>
                                    <p>
                                      <img src={Images.clockicon} alt="icon" />
                                      &nbsp; Cooking Time
                                    </p>
                                    <h6>{data?.cooking_time} Min</h6>
                                  </li>
                                  <li>
                                    <p>
                                      {" "}
                                      <img src={Images.celendericon} alt="icon" />
                                      &nbsp; Created At
                                    </p>
                                    <h6>
                                      {moment(data?.createdAt).format(
                                        "YYYY-MM-DD"
                                      )}
                                    </h6>
                                  </li>
                                </ul>
                              </div>

                              <div className="rightArrow dropdown">
                                <img
                                  onClick={() => handleViewRecipe(data?.id)}
                                  src={Images.nextcircle}
                                  alt="arrow"
                                  className="me-3 eye"
                                />

                                <img
                                  src={Images.threedot}
                                  alt=""
                                  className="dots dropdown-toggle"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                />
                                <ul className="dropdown-menu courseDrpodown">
                                  <li
                                    onClick={() => handleViewRecipe(data?.id)}
                                  >
                                    <a className="dropdown-item text_ innertxt">
                                      {" "}
                                      <img
                                        src={Images.eye}
                                        alt="icon"
                                        className="me-3"
                                      />{" "}
                                      View Details
                                    </a>
                                  </li>
                                  <li
                                    onClick={() => handleEditRecipe(data?.id)}
                                  >
                                    <a className="dropdown-item text_ innertxt">
                                      <img
                                        src={Images.editpendark}
                                        alt="icon"
                                        className="me-3"
                                      />{" "}
                                      &nbsp;Edit
                                    </a>
                                  </li>
                                  <li
                                    onClick={() =>
                                      handleDeleteRecipe(data?.id, data?.title)
                                    }
                                  >
                                    <a
                                      className="dropdown-item text_ innertxt"
                                      // href=""
                                    >
                                      {" "}
                                      <img
                                        src={Images.trash}
                                        alt="icon"
                                        className="me-3"
                                      />{" "}
                                      Delete
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <>
                      {getRecipes?.length == 0 ? (
                        <h3 className="noDataText mt-3 mb-3">
                          No Recipe Found
                        </h3>
                      ) : (
                        <Spinner
                          className="pageLoader"
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                    </>
                  )}
                </div>
                {getRecipes?.length > 0 ? (
                  <>
                    <ReactPaginate
                      className="paginationBox mb-0"
                      breakLabel=""
                      nextLabel="next >"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      marginPagesDisplayed={0}
                      pageCount={totalPages}
                      previousLabel="< prev"
                      renderOnZeroPageCount={null}
                    />
                  </>
                ) : (
                  ""
                )}
                <div className="d-flex justify-content-between align-items-center">
                  <div
                    className="dataTables_length pt-3"
                    id="staffsTable_length"
                  >
                    <label>
                      {" "}
                      <span className="text">Show</span>{" "}
                      <select
                        name="staffsTable_length"
                        aria-controls="staffsTable"
                        className="custom-select custom-select-sm form-control form-control-sm"
                        onChange={(e) => handleChangePages(e)}
                        value={perPageSize}
                      >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="75">75</option>
                        <option value="100">100</option>
                      </select>
                      &nbsp;{" "}
                      <span className="subtext">
                        Showing {`${perPageSize * (currentPage - 1) + 1}`} to{" "}
                        {`${
                          perPageSize * (currentPage - 1) + getRecipes?.length
                        }`}{" "}
                        of {`${totalCount}`} entries{" "}
                      </span>{" "}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CustomModal
          key={key}
          show={modalDetail.show}
          backdrop="static"
          showCloseBtn={false}
          isRightSideModal={false}
          mediumWidth={false}
          className={modalDetail.flag === "Delete" ? "delete" : ""}
          child={
            modalDetail.flag === "Delete" ? (
              <Deleterecipe
                closeModal={() => {
                  handleOnCloseModal();
                }}
                refreshData={() => getRecipeList()()}
                userId={modalDetail?.id}
                Recipetitle={modalDetail?.Recipetitle}
              />
            ) : (
              ""
            )
          }
          onCloseModal={() => handleOnCloseModal()}
        />
      </div>
      {/* )} */}
    </>
  );
};

export default Recipe;
