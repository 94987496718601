import { all, call, put, take, takeLatest } from "redux-saga/effects";
import { ApiClient } from "../../../utilities/api";
import {
  onErrorStopLoad,
  setRecipe,
  setCategories,
  setAddCategory,
  setAddRecipe,
  setRecipeDetails,
  setUpdateRecipeDetails,
  setDeleteRecipe,
  setClasses,
  setAddClasses,
  setDeleteClass,
  setUpdateClassDetails,
  setClassDetails,
  setuploadFiles,
  setAddPlans,
  setPlans,
  setUpdatePlans,
  setDeletePlans,
  setOnePlan,
  setUpdateInfo,
  setUpcommingList,
  setAboutUs,
  setPrivacyPolicy,
  setTermsConditions,
  setReports,
  setDeleteReport,
  setRecipeWithUser,
  setGoLive,
  setIsReadAdmin,
  setNotificationCountForAdmin,
  setNotificationListForAdmin,
  setToggleNotification,
  setGetChatList,
} from "../../slices/admin";
import ApiPath from "../../../constants/apiPath";
import { toast } from "react-toastify";
import { CloudFormation } from "aws-sdk";

// Worker saga will be fired on USER_FETCH_REQUESTED actions
function* getRecipe(action) {
  let url = `${ApiPath.adminApiPath.GET_RECIPES}?page=${action.payload.page}&limit=${action.payload.limit}&cat_id=${action.payload.cat_id}`;

  if (Boolean(action.payload.keyword)) {
    url = `${url}&keyword=${action.payload.keyword}`;
  }
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = url),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setRecipe(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      //toast.success(action.res.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setRecipe({}));
    yield put(onErrorStopLoad());
    //toast.error(e.response.data.message);
  }
}
function* getRecipeWithUser(action) {
  let url = `${ApiPath.adminApiPath.GET_RECIPES}?page=${action.payload.page}&limit=${action.payload.limit}&userid=${action.payload.userid}&cat_id=${action.payload.cat_id}`;

  if (Boolean(action.payload.keyword)) {
    url = `${url}&keyword=${action.payload.keyword}`;
  }
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = url),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setRecipeWithUser(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      //toast.success(action.res.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setRecipe({}));
    yield put(onErrorStopLoad());
    //toast.error(e.response.data.message);
  }
}

function* getClasses(action) {
  let url = `${ApiPath.adminApiPath.GET_CLASSESLIST}?page=${action.payload.page}&limit=${action.payload.limit}`;
  if (Boolean(action.payload.keyword)) {
    url = `${url}&keyword=${action.payload.keyword}`;
  }
  if (Boolean(action.payload.sort)) {
    url = `${url}&sort=${action.payload.sort}`;
  }
  if (Boolean(action.payload.cat_id)) {
    url = `${url}&cat_id=${action.payload.cat_id}`;
  }
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = url),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setClasses(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      //toast.success(action.res.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setClasses({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}
function* getUpcommingList(action) {
  let url = `${ApiPath.adminApiPath.GET_CLASSESLIST}?sort=${action.payload.sort}`;

  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = url),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setUpcommingList(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      //toast.success(action.res.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setUpcommingList({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}
function* getTodaysLiveClasses(action) {
  let url = `${ApiPath.adminApiPath.GET_CLASSESLIST}?sort=${action.payload.sort}`;

  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = url),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setUpcommingList(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      //toast.success(action.res.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setUpcommingList({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}
function* getCategories(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = ApiPath.adminApiPath.ALL_CATEGORIES),
      (action.payload = action.payload)
    );
    yield put(setCategories(resp.data));
    yield call(action.payload.cb, (action.res = resp));
  } catch (e) {
    yield put(setCategories({}));
  }
}
function* addCategory(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.adminApiPath.ADD_RECIPES),
      (action.payload = action.payload)
    );
    yield put(setAddCategory(resp.data));
    yield call(action.payload.cb, (action.res = resp));
  } catch (e) {
    yield put(setAddCategory({}));
  }
}
function* addRecipes(action) {
  try {
    const params = { ...action.payload };
    let formData = new FormData();

    if (params.thumbnail_image) {
      formData.append("thumbnail_image", params?.thumbnail_image);
    }

    if (params.recipe_image) {
      formData.append("recipe_image", params?.recipe_image);
    }
    if (params.recipe_video) {
      formData.append("recipe_video", params?.recipe_video);
    } else if (params.class_video) {
      formData.append("class_video", params?.class_video);
    }
    formData.append("access_level", params?.access_level);
    formData.append("title", params?.title);
    formData.append("description", params?.description);
    formData.append("category_id", params?.category_id);
    formData.append("cooking_time", params?.cooking_time);
    formData.append("serving", params?.serving);
    formData.append("direction", params?.direction);

    var stepArray = params?.direction_step;
    for (var i = 0; i < stepArray.length; i++) {
      formData.append("direction_step[]", stepArray[i].value);
    }
    var ingredientArray = params?.ingredient;
    for (var i = 0; i < ingredientArray.length; i++) {
      formData.append("ingredient[]", ingredientArray[i].value);
    }
    var nutritionArray = params?.nutrition;
    for (var i = 0; i < nutritionArray.length; i++) {
      formData.append("nutrition[]", nutritionArray[i].value);
    }

    const resp = yield call(
      ApiClient.postFormDataAll,
      (action.url = ApiPath.adminApiPath.ADD_RECIPES),
      (action.formData = formData)
    );
    if (resp.status) {
      yield put(setAddRecipe(resp.data.payload));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(action.res.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setAddRecipe({}));
    toast.error(e.response.data.message);
  }
}

function* getRecipeDetails(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.adminApiPath.GET_RECIPESDETAILS}/${action.payload.id}`)
    );
    if (resp.status) {
      yield put(setRecipeDetails(resp.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setRecipeDetails({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.msg);
  }
}
function* deleteRecipe(action) {
  try {
    const resp = yield call(
      ApiClient.delete,
      (action.url = `${ApiPath.adminApiPath.DELETE_RECIPE}/${action.payload.id}`)
    );
    //yield put(setDeleteRecipe(resp.data));
    yield call(action.payload.cb, (action.res = resp));
    toast.success(action.res.data.message);
  } catch (e) {
    yield put(setDeleteRecipe({}));
  }
}
function* updateRecipeDetails(action) {
  try {
    const params = { ...action.payload };
    let formData = new FormData();

    if (params.thumbnail_image) {
      formData.append("thumbnail_image", params?.thumbnail_image);
    }
    if (params.recipe_image) {
      formData.append("recipe_image", params?.recipe_image);
    }
    if (params.recipe_video) {
      formData.append("recipe_video", params?.recipe_video);
    } else if (params.class_video) {
      formData.append("class_video", params?.class_video);
    }
    formData.append("access_level", params?.access_level);
    formData.append("title", params?.title);
    formData.append("description", params?.description);
    formData.append("category_id", params?.category_id);
    formData.append("cooking_time", params?.cooking_time);
    formData.append("serving", params?.serving);
    formData.append("direction", params?.direction);

    var stepArray = params?.direction_step;
    if (stepArray?.length > 0) {
      for (var i = 0; i < stepArray.length; i++) {
        formData.append("direction_step[]", stepArray[i].value);
      }
    } else {
      formData.append("direction_step[]", []);
    }
    var ingredientArray = params?.ingredient;
    if (ingredientArray?.length > 0) {
      for (var i = 0; i < ingredientArray.length; i++) {
        formData.append("ingredient[]", ingredientArray[i].value);
      }
    } else {
      formData.append("ingredient[]", []);
    }
    var nutritionArray = params?.nutrition;
    if (nutritionArray?.length > 0) {
      for (var i = 0; i < nutritionArray.length; i++) {
        formData.append("nutrition[]", nutritionArray[i].value);
      }
    } else {
      formData.append("nutrition[]", []);
    }
    const resp = yield call(
      ApiClient.patchFormData,
      (action.url = `${ApiPath.adminApiPath.UPDATE_RECIPE}/${action.payload.id}`),
      (action.formData = formData)
    );
    if (resp.status) {
      yield put(setUpdateRecipeDetails(resp.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(action.res.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setUpdateRecipeDetails({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}
function* addClasses(action) {
  try {
    const params = { ...action.payload };
    let formData = new FormData();

    if (params.video_thumbnail) {
      formData.append("video_thumbnail", params?.video_thumbnail);
    }
    if (params.class_video) {
      formData.append("class_video", params?.class_video);
    }

    formData.append("access_level", params?.access_level);
    formData.append("type", params?.type);
    formData.append("title", params?.title);
    formData.append("description", params?.description);
    formData.append("category_id", params?.category_id);
    if (params?.type == 1) {
      if (params.date) {
        formData.append("date", params?.date);
      }
    }
    if (params.time) {
      formData.append("time", params?.time);
    }
    if (params.duration) {
      formData.append("duration", params?.duration);
    }
    var instructorsArray = params?.instrucator;
    for (var i = 0; i < instructorsArray.length; i++) {
      formData.append(
        "instrucator[]",
        JSON.stringify({
          full_name: instructorsArray[i].full_name,
          img: instructorsArray[i].file,
        })
      );
    }
    const resp = yield call(
      ApiClient.postFormDataAll,
      (action.url = ApiPath.adminApiPath.ADD_CLASSES),
      (action.formData = formData)
    );
    if (resp.status) {
      yield put(setAddClasses(resp.data.payload));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(action.res.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setAddClasses({}));
    toast.error(e.response.data.message);
  }
}

function* getClassDetails(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.adminApiPath.GET_CLASSDEATILS}/${action.payload.id}`)
    );
    if (resp.status) {
      yield put(setClassDetails(resp.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setClassDetails({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}
function* deleteClass(action) {
  try {
    const resp = yield call(
      ApiClient.delete,
      (action.url = `${ApiPath.adminApiPath.DELETE_CLASS}/${action.payload.id}`)
    );
    //yield put(setDeleteClass(resp.data));
    yield call(action.payload.cb, (action.res = resp));
    toast.success(action.res.data.message);
  } catch (e) {
    yield put(setDeleteClass({}));
  }
}
function* updateClassDetails(action) {
  try {
    const params = { ...action.payload };
    let formData = new FormData();

    if (params.video_thumbnail) {
      formData.append("video_thumbnail", params?.video_thumbnail);
    }
    if (params.class_video) {
      formData.append("class_video", params?.class_video);
    }
    formData.append("access_level", params?.access_level);
    formData.append("type", params?.type);
    formData.append("title", params?.title);
    formData.append("description", params?.description);
    formData.append("category_id", params?.category_id);
    if (params.date) {
      formData.append("date", params?.date);
    }
    if (params.time) {
      formData.append("time", params?.time);
    }
    if (params.duration) {
      formData.append("duration", params?.duration);
    }

    var instructorsArray = params?.instructors;
    if (instructorsArray?.length > 0) {
      for (var i = 0; i < instructorsArray.length; i++) {
        formData.append(
          "instrucator[]",
          JSON.stringify({
            full_name: instructorsArray[i].full_name,
            img: instructorsArray[i].file,
          })
        );
      }
    } else {
      formData.append("instrucator[]", [
        JSON.stringify({
          full_name: "",
          img: "",
        }),
      ]);
    }

    const resp = yield call(
      ApiClient.patchFormData,
      (action.url = `${ApiPath.adminApiPath.UPDATE_CLASS}/${action.payload.id}`),
      (action.formData = formData)
    );
    if (resp.status) {
      yield put(setUpdateClassDetails(resp.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(action.res.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setUpdateClassDetails({}));
    yield put(onErrorStopLoad());
    toast.error(e?.response?.data?.message);
  }
}

function* uploadFiles(action) {
  try {
    const resp = yield call(
      ApiClient.postFilesData,
      (action.url = ApiPath.adminApiPath.UPLOAD_FILES),
      (action.payload = action.payload)
    );
    if (resp.status) {
      //yield put(setUploadPicture(resp.data.payload));
      yield call(action.payload.cb, (action.res = resp));
      //toast.success(action.res.data.msg);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setuploadFiles({}));
    toast.error(e.response.data.msg);
  }
}

function* getPlans(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = ApiPath.plansSubscription.GET_ALLPLANS),
      (action.payload = action.payload)
    );
    yield put(setPlans(resp.data));
    yield call(action.payload.cb, (action.res = resp));
  } catch (e) {
    yield put(setPlans({}));
  }
}
function* addPlans(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.plansSubscription.ADD_PLANS),
      (action.payload = action.payload)
    );
    yield put(setAddPlans(resp.data));
    yield call(action.payload.cb, (action.res = resp));
    toast.success(action.res.data.message);
  } catch (e) {
    yield put(setAddPlans({}));
    yield call(action.payload.cb, (action.res = e.response.data));
    toast.error(e.response.data.message);
  }
}
function* deletePlans(action) {
  try {
    const resp = yield call(
      ApiClient.delete,
      (action.url = `${ApiPath.plansSubscription.DELETE_PLANS}/${action.payload.id}`)
    );
    //yield put(setDeleteClass(resp.data));
    yield call(action.payload.cb, (action.res = resp));
    toast.success(action.res.data.message);
  } catch (e) {
    yield put(setDeletePlans({}));
  }
}
function* updatePlans(action) {
  try {
    const resp = yield call(
      ApiClient.patch,
      (action.url = ApiPath.plansSubscription.EDIT_PLANS),
      (action.payload = action.payload)
    );
    yield put(setUpdatePlans(resp.data.payload));
    yield call(action.payload.cb, (action.res = resp));
    toast.success(action.res.data.message);
  } catch (e) {
    yield put(setUpdatePlans({}));
    yield call(action.payload.cb, (action.res = e.response.data));
    toast.error(e.response.data.message);
  }
}
function* getOnePlan(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.plansSubscription.GET_ONE_PLAN}/${action.payload.planId}`)
    );
    if (resp.status) {
      yield put(setOnePlan(resp.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setOnePlan({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}
function* getAboutUs(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.infoPgaes.getPages}${action.payload.page_type}`)
    );
    if (resp.status) {
      yield put(setAboutUs(resp.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setAboutUs({}));
    yield put(onErrorStopLoad());
    // toast.error(e.response.data.message);
  }
}
function* getPrivacyPolicy(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.infoPgaes.getPages}${action.payload.page_type}`)
    );
    if (resp.status) {
      yield put(setPrivacyPolicy(resp.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setPrivacyPolicy({}));
    yield put(onErrorStopLoad());
    // toast.error(e.response.data.message);
  }
}
function* getTermsConditions(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.infoPgaes.getPages}${action.payload.page_type}`)
    );
    if (resp.status) {
      yield put(setTermsConditions(resp.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setTermsConditions({}));
    yield put(onErrorStopLoad());
    // toast.error(e.response.data.message);
  }
}
function* updateInfo(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.infoPgaes.updatePages),
      (action.payload = action.payload)
    );
    yield put(setUpdateInfo(resp.data));
    yield call(action.payload.cb, (action.res = resp));
    toast.success(action.res.data.message);
  } catch (e) {
    yield put(setUpdateInfo({}));
    yield call(action.payload.cb, (action.res = e.response.data));
    toast.error(e.response.data.message);
  }
}
function* getReports(action) {
  let url = `${ApiPath.adminApiPath.GETALLREPORS}?page=${action.payload.page}&limit=${action.payload.limit}`;

  if (Boolean(action.payload.keyword)) {
    url = `${url}&keyword=${action.payload.keyword}`;
  }
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = url),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setReports(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      //toast.success(action.res.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setReports({}));
    yield put(onErrorStopLoad());
    //toast.error(e.response.data.message);
  }
}
function* deleteReport(action) {
  try {
    const resp = yield call(
      ApiClient.delete,
      (action.url = `${ApiPath.adminApiPath.DELETE_REPORTS}/${action.payload.id}`)
    );
    //yield put(setDeleteClass(resp.data));
    yield call(action.payload.cb, (action.res = resp));
    toast.success(action.res.data.message);
  } catch (e) {
    yield put(setDeleteReport({}));
  }
}
function* goLive(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.AgoraLiveStreaming.GOLIVE),
      (action.payload = action.payload)
    );
    // console.log(resp, "respresp");
    yield put(setGoLive(resp.data));
    yield call(action.payload.cb, (action.res = resp));
    //toast.success(action.res.data.message);
  } catch (e) {
    yield put(setGoLive({}));
  }
}
function* isReadAdmin(action) {
  try {
    const resp = yield call(
      ApiClient.patch,
      (action.url = `${ApiPath.adminApiPath.IS_READ}${action.payload.id}`)
    );
    action.payload = action.payload;

    yield put(setIsReadAdmin(resp.data.payload));
    yield call(action.payload.cb, (action.res = resp));
  } catch (e) {
    yield put(setIsReadAdmin({}));
    toast.error(e.response.data.msg);
  }
}
function* notificationCountForAdmin(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = ApiPath.adminApiPath.NOTIFICATION_COUNT_FOR_ADMIN)
    );
    if (resp.status) {
      yield put(setNotificationCountForAdmin(resp.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setNotificationCountForAdmin({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.msg);
  }
}
function* notificationListForAdmin(action) {
  let url = `${ApiPath.adminApiPath.GET_NOTIFICATIONS_FOR_ADMIN}?page=${action.payload.page}&limit=${action.payload.limit}`;
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = url),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setNotificationListForAdmin(resp.data));
      yield call(action.payload.cb, (action.res = resp));
      //toast.success(action.res.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setNotificationListForAdmin({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}
function* toggleNotification(action) {
  try {
    const dataToSend = { ...action.payload };
    const resp = yield call(
      ApiClient.patch,
      (action.url = ApiPath.adminApiPath.NOTIFICATION_ONOFF),
      (action.params = dataToSend)
    );
    yield put(setToggleNotification(resp.data.payload));
    yield call(action.payload.cb, (action.res = resp));
  } catch (e) {
    yield put(setToggleNotification({}));
    toast.error(e.response.data.msg);
  }
}
function* getChatList(action) {
  // console.log("vishu", action)
  let url = `${ApiPath.adminApiPath.GET_CHAT_LIST}?page=${action.payload.page}&limit=${action.payload.limit}`;
  // console.log(url, 'urll')
  if (Boolean(action.payload.keyword)) {
    url = `${url}&keyword=${action.payload.keyword}`;
  }

  try {
    // console.log(action.payload, "actionPayloaddd")
    const resp = yield call(
      ApiClient.get,
      (action.url = url),
      (action.payload = action.payload)
    );
    // console.log(resp, "responsedAtaMy")
    if (resp.status) {
      yield put(setGetChatList(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      //toast.success(action.res.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    // console.log(e,"please tell me the error")
    // console.log("erorr is occuring pleaaseeee elllll")
    yield put(setGetChatList({}));
    yield put(onErrorStopLoad());
    //toast.error(e.response.data.message);
  }
}
function* adminSaga() {
  yield all([
    takeLatest("admin/getRecipe", getRecipe),
    takeLatest("admin/getRecipeWithUser", getRecipeWithUser),
    takeLatest("admin/getCategories", getCategories),
    takeLatest("admin/addCategory", addCategory),
    takeLatest("admin/addRecipes", addRecipes),
    takeLatest("admin/getRecipeDetails", getRecipeDetails),
    takeLatest("admin/deleteRecipe", deleteRecipe),
    takeLatest("admin/updateRecipeDetails", updateRecipeDetails),
    takeLatest("admin/getClasses", getClasses),
    takeLatest("admin/updateClassDetails", updateClassDetails),
    takeLatest("admin/addClasses", addClasses),
    takeLatest("admin/deleteClass", deleteClass),
    takeLatest("admin/getClassDetails", getClassDetails),
    takeLatest("admin/uploadFiles", uploadFiles),
    takeLatest("admin/getPlans", getPlans),
    takeLatest("admin/addPlans", addPlans),
    takeLatest("admin/getOnePlan", getOnePlan),
    takeLatest("admin/updatePlans", updatePlans),
    takeLatest("admin/deletePlans", deletePlans),
    takeLatest("admin/getAboutUs", getAboutUs),
    takeLatest("admin/getPrivacyPolicy", getPrivacyPolicy),
    takeLatest("admin/getTermsConditions", getTermsConditions),
    takeLatest("admin/getUpcommingList", getUpcommingList),
    takeLatest("admin/updateInfo", updateInfo),
    takeLatest("admin/getTodaysLiveClasses", getTodaysLiveClasses),
    takeLatest("admin/getReports", getReports),
    takeLatest("admin/deleteReport", deleteReport),
    takeLatest("admin/goLive", goLive),
    takeLatest("admin/notificationCountForAdmin", notificationCountForAdmin),
    takeLatest("admin/notificationListForAdmin", notificationListForAdmin),
    takeLatest("admin/isReadAdmin", isReadAdmin),
    takeLatest("admin/toggleNotification", toggleNotification),
    takeLatest("admin/getChatList", getChatList),
  ]);
}
export default adminSaga;
