import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { cancelSubscription } from "../../../redux/slices/user";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";

const DeleteSubscription = (props) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  
  const handleCancelSubs = () => {
    let params = {
      subscriptionId: props.subscription_id,
    };
    setLoading(true);
    dispatch(
      cancelSubscription({
        ...params,
        cb(res) {
          if (res?.data) {
            toast.success("Subscription cancelled")
            setLoading(false);
            props.closeModal();
            props.refreshData();
          } else {
          }
        },
      })
    );
  };
  return (
    <div className="py-3">
      <h5 className="font-28 text-center">
        Are you Sure you want to Cancel the subscription ?
      </h5>
      <p className="text text-center py-3">
        You are about to Cancel the subscription . You won't be able to undo this.
      </p>
      <div className="row  justify-content-center mt-4">
        <div className="subscrip d-grid gap-1 col-5 col-lg-3 col-md-3 col-sm-3">
          <button
            className="primaryBtn submitBtn m-0"
            type="button"
            onClick={(e) => handleCancelSubs(e)}
          >
            <span>Yes, Cancel</span>
            {loading && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="ms-1"
              />
            )}{" "}
          </button>
        </div>
        <div className="subscrip d-grid gap-1 col-5 col-lg-3 col-md-3 col-sm-3">
          <button
            className="primaryBtn cancelBtn m-0"
            onClick={(e) => props.closeModal()}
          >
            <span>No, Cancel</span>
          </button>
        </div>
      </div>
    </div>
  );
};
export default DeleteSubscription;
