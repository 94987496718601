import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthSelector } from "../../../../redux/selector/auth";
import AgoraRTC from "agora-rtc-sdk-ng";
import { toast } from "react-toastify";
import * as Images from "../../../../utilities/images";
import { socketInstance } from "../../../components/common/socketNew";
import CustomModal from "../../../components/modals/modal";
import { showLiveComments } from "../../../../redux/slices/user";
import EndCallModal from "./endCall";
import { MenuItem, Select } from "@mui/material";

const LiveModal = () => {
  const chatRef = useRef();
  const authSelector = useAuthSelector();
  let navigate = useNavigate();
  const toastId = React.useRef(null);
  const userId = authSelector?.userLogin?.data?.user;
  const location = useLocation();
  const dispatch = useDispatch();
  const [totalViews, setTotalViews] = useState(0);
  const [totalComments, setTotalComments] = useState(0);
  const [showCommentsSection, setshowCommentsSection] = useState(false);
  const [showUsersCommentsSection, setShowUserCommentSection] = useState(true);
  const [liveCommentsList, setLiveCommentsList] = useState([]);
  const [cameraList, setCameraList] = useState([]);
  const [selectedCamera, setSelectedCamera] = useState(null);

  const channelParameters = {
    localAudioTrack: null,
    localVideoTrack: null,
    remoteAudioTrack: null,
    remoteVideoTrack: null,
    remoteUid: null,
  };
  const [key, setKey] = useState(Math.random());

  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
  const [uidId, setUidId] = useState("");
  const [appId, setAppId] = useState("");
  const [channelName, setChannelName] = useState("");
  const [tokenId, setToeknId] = useState("");
  const [roomID, setRoomID] = useState("");
  const agoraEngine = AgoraRTC.createClient({ mode: "live", codec: "vp8" });

  const newStartVideoProcess = async () => {
    const wrapper = document.getElementById("localVideoPlayer");
    const localPlayerContainer = document.createElement("div");

    const options = {
      appId: appId,
      channel: channelName,
      token: tokenId,
      uid: uidId,
      role: "host",
    };
    await agoraEngine.setClientRole(options.role);
    await agoraEngine.join(
      options.appId,
      options.channel,
      options.token,
      options.uid
    );
    wrapper.append(localPlayerContainer);
    channelParameters.localAudioTrack =
      await AgoraRTC.createMicrophoneAudioTrack();
    channelParameters.localVideoTrack = await AgoraRTC.createCameraVideoTrack({
      encoderConfig: "720p_2",
      cameraId: selectedCamera,
    });
    if (options.role == "host") {
      await agoraEngine.publish([
        channelParameters.localAudioTrack,
        channelParameters.localVideoTrack,
      ]);

      channelParameters.localVideoTrack.play(localPlayerContainer);
    }
  };

  const handleCameraChange = async (event) => {
    const newSelectedCamera = event.target.value;
    setSelectedCamera(newSelectedCamera);

    const wrapper = document.getElementById("localVideoPlayer");
    const localPlayerContainer = document.createElement("div");

    if (channelParameters.localVideoTrack) {
      channelParameters.localVideoTrack.stop();
      await channelParameters.localVideoTrack.close();
    }
    wrapper.append(localPlayerContainer);
    channelParameters.localAudioTrack =
      await AgoraRTC.createMicrophoneAudioTrack();
    channelParameters.localVideoTrack = await AgoraRTC.createCameraVideoTrack({
      cameraId: newSelectedCamera,
    });
    channelParameters.localVideoTrack.play(localPlayerContainer.current);
  };

  const getCameraList = async () => {
    try {
      const devices = await AgoraRTC.getDevices();
      const cameras = devices.filter((device) => device.kind === "videoinput");
      setCameraList(cameras);
      if (cameras.length > 0) {
        setSelectedCamera(cameras[0].deviceId);
      }
    } catch (error) {
      console.error("Error fetching camera list:", error);
    }
  };

  useEffect(() => {
    if (location?.state?.uid) {
      setUidId(location?.state?.uid);
      setAppId(location?.state?.appId);
      setChannelName(location?.state?.channel);
      setToeknId(location?.state?.tokenId);
      setRoomID(location?.state?.roomID);
      localStorage.setItem("liveStreamAdmin", JSON.stringify(location.state));
    } else if (localStorage.getItem("liveStreamAdmin")) {
      let location = localStorage.getItem("liveStreamAdmin");
      location = JSON.parse(location);
      setUidId(location?.uid);
      setAppId(location?.appId);
      setChannelName(location?.channel);
      setToeknId(location?.tokenId);
      setRoomID(location?.roomID);
    }
    return () => {
      socketInstance.disconnect();
    };
  }, []);

  useEffect(() => {
    let location = localStorage.getItem("liveStreamAdmin");
    location = JSON.parse(location);
    if (socketInstance && (location?.state?.roomID || location?.roomID)) {
      socketInstance.connect();
      socketInstance.on("connection", (response) => { });
      socketInstance.emit("sync_live_data", {
        room_id: location?.state?.roomID || location?.roomID,
      });
      socketInstance.on("sync_live_data", (response) => {
        setTotalViews(response?.data?.totalUsers);
        setTotalComments(response?.data?.totalcoments);
      });
      broadcastEvent();
      socketInstance.on("add_livestreaming_comment", (data) => {
        getAllLiveComments(location?.state?.roomID || location?.roomID);
      });
      getAllLiveComments(location?.state?.roomID || location?.roomID);
    }
  }, [roomID]);

  useEffect(() => {
    getCameraList();
  }, []);

  const broadcastEvent = () => {
    socketInstance.emit("liveStreaming", {
      room_id: roomID,
    });
    socketInstance.on("liveStreaming", (data) => { });
  };

  const handleShowCommentBoxes = () => {
    setshowCommentsSection(true);
    setShowUserCommentSection(false);
  };

  const handleCross = () => {
    setshowCommentsSection(false);
    setShowUserCommentSection(true);
  };

  const scrollBottom = () => {
    if (chatRef.current) {
      chatRef.current.scrollIntoView({
        block: "end",
        inline: "end",
        behavior: "smooth",
      });
    }
  };

  const handleSendComments = (event) => {
    let addComment = event.target[0].value;
    event.preventDefault();
    if (!addComment) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please add comment!");
        return false;
      }
    } else {
      socketInstance.emit("add_livestreaming_comment", {
        room_id: roomID,
        comment: addComment,
      });
      socketInstance.on("add_livestreaming_comment", (data) => {
        if (data.statusCode === 200) {
          event.target.reset();
          setLiveCommentsList((prevData) => {
            const isIdPresent = prevData.some(
              (item) => item?.id === data?.data?.id
            );
            if (!isIdPresent) {
              return [...prevData, data.data];
            } else {
              return prevData;
            }
          });
          scrollBottom();
        } else {
          if (!toast.isActive(toastId.current)) {
            toastId.current = toast.error("Comment is too long");
            return false;
          }
        }
        socketInstance.emit("sync_live_data", {
          room_id: roomID,
        });
        socketInstance.on("sync_live_data", (response) => {
          setTotalViews(response?.data?.totalUsers);
          setTotalComments(response?.data?.totalcoments);
        });
      });
    }
  };

  useEffect(() => {
    if ((agoraEngine, uidId, selectedCamera)) {
      newStartVideoProcess();
    }
    return async () => {
      if (channelParameters.localAudioTrack) {
        channelParameters.localAudioTrack.stop();
        channelParameters.localAudioTrack.close();
      }
      if (channelParameters.localVideoTrack) {
        channelParameters.localVideoTrack.stop();
        channelParameters.localVideoTrack.close();
      }
      await agoraEngine.leave();
    };
  }, [agoraEngine, uidId, selectedCamera]);

  const handleEndCall = async () => {
    if (channelParameters.localAudioTrack) {
      channelParameters.localAudioTrack.stop();
      channelParameters.localAudioTrack.close();
    }
    if (channelParameters.localVideoTrack) {
      channelParameters.localVideoTrack.stop();
      channelParameters.localVideoTrack.close();
    }
    await agoraEngine.leave();
    setModalDetail({
      show: true,
      title: "confirm",
      flag: "confirm",
    });
    setKey(Math.random());
  };

  useEffect(() => {
    window.onpopstate = () => {
      handleGoBack();
    };
  });

  const handleGoBack = () => {
    socketInstance.emit("endliveStreaming", {
      room_id: roomID,
    });
    socketInstance.on("endliveStreaming", (data) => { });
    navigate("/admin/dashboard");
    window.location.reload();
  };

  window.addEventListener("beforeunload", function (event) {
    socketInstance.emit("endliveStreaming", {
      room_id: roomID,
    });
    socketInstance.on("endliveStreaming", (data) => { });
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Live-Streaming";
  }, []);

  const getChat = (chatData) => {
    let count = 0;
    let res = [];

    chatData.map((data, idx) => {
      if (data?.created_by?.id != 1) {
        res.push(
          <div key={idx} className="chatBoxOuter border-26">
            <div className="chatBoxInner">
              <div className="d-flex">
                <span>
                  <img
                    className="user"
                    src={data?.created_by?.profile_image}
                    alt=""
                  />
                </span>

                <div className="ms-3">
                  <p className="colorWhite subtext mb-0">
                    {data?.created_by?.full_name}
                  </p>
                  <p className="colorWhite subtext mb-0 textLiveStreaming">
                    {data?.comment}
                  </p>
                </div>
              </div>
            </div>
          </div>
        );
        count++;
      }
    });
    res = res?.slice(Math.max(res?.length - 6, 0));
    // res.sort();
    return res;
  };

  const getAllLiveComments = (roomID) => {
    let params = {
      room_id: roomID,
    };
    dispatch(
      showLiveComments({
        ...params,
        cb(res) {
          if (res?.data) {
            const sortedData = [...res?.data?.data?.commentlist].sort(
              (a, b) => new Date(a?.updatedAt) - new Date(b?.updatedAt)
            );
            setLiveCommentsList(sortedData);
          } else {
          }
        },
      })
    );
  };

  const reloadCount = sessionStorage.getItem('reloadCount')

  useEffect(() => {
    if (reloadCount < 2) {
      sessionStorage.setItem('reloadCount', String(reloadCount + 1));
      window.location.reload();
    } else {
      sessionStorage.removeItem('reloadCount');
    }
  }, []);


  return (
    <div>
      <>
        <section className="liveStreaming">
          <div className="container-fluid px-0">
            <div className="livestreamingOuter">
              <div id="localVideoPlayer" className="localVideoPlayer"></div>
              <div
                className="livevedio"
                style={{ display: "flex", width: "100vw", height: "100vh" }}
              >
                <div id="localStream" className="adminvedioContainer">
                  <div className="flexRow">
                    <div className="adminvedioInner">
                      <button className="button mb-0" onClick={handleGoBack}>
                        <span className="youtubeIcon">
                          {" "}
                          <img
                            src={Images.backarrowwhite}
                            alt=""
                            onClick={handleGoBack}
                          />
                        </span>
                        Go Back{" "}
                      </button>

                      <div className="d-flex align-items-center topboxOuter">
                        <div className="subtextOuter">
                          <p className="subtext mb-0">
                            <span className="me-2">
                              <img src={Images.pinkeye} alt="" />
                            </span>
                            {totalViews}
                          </p>
                        </div>
                        <div className="subtextOuter">
                          <p className="subtext mb-0">
                            <span className="me-2">
                              <img src={Images.commenticon} alt="" />
                            </span>
                            {totalComments}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between align-items-end">
                    <div className="liveStreamingFooter">
                      <div className="liveText liveTextResp">
                        <p className="">
                          <span className="redDot"></span>LIVE
                        </p>
                      </div>
                      <div className="live-end-camera">
                        <div>
                          <button
                            className="endCallButton w-100 login-btn me-3 bg-danger"
                            type="button"
                            id="leave"
                            onClick={(e) => handleEndCall(e)}
                          >
                            <img
                              className="muteIcon"
                              src={Images.endcall}
                              alt=""
                            />
                            End
                          </button>
                        </div>

                        {cameraList?.length > 0 ? (
                          <>
                            <label className="me-2" htmlFor="cameras">
                              Select Camera:{" "}
                            </label>
                            <div className="camera-select-dropdown">
                              <Select
                                className="select-camera"
                                value={selectedCamera || ""}
                                label="Age"
                                onChange={handleCameraChange}
                              >
                                {" "}
                                {cameraList?.map((camera) => (
                                  <MenuItem
                                    key={camera?.deviceId}
                                    value={camera?.deviceId}
                                  >
                                    {" "}
                                    {camera?.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </div>
                          </>
                        ) : (
                          <label className="me-2" htmlFor="cameras">
                            Please Allow Camera Permission
                          </label>
                        )}
                      </div>

                      {showUsersCommentsSection && (
                        <div className="chatBoxSend">
                          {getChat(liveCommentsList)}
                        </div>
                      )}

                      <div className="mszBox mszBoxTop">
                        <img
                          className="w-100"
                          src={Images.msgbox}
                          alt=""
                          onClick={(e) => handleShowCommentBoxes(e)}
                        />
                      </div>
                    </div>
                    {showCommentsSection && (
                      <div className="col-md-4 p-0">
                        <div className="msgBox">
                          <div className="msgBoxHead">
                            <div className="d-flex align-items-center">
                              <div className="msg_box">
                                <img
                                  className="w-100"
                                  src={Images.msgbox}
                                  alt=""
                                />
                              </div>
                              <span className="colorWhite font-26">
                                Messages
                              </span>
                            </div>
                            <div className="">
                              <img
                                className="img-fluid"
                                src={Images.crossCircle}
                                alt=""
                                onClick={(e) => handleCross(e)}
                              />
                            </div>
                          </div>

                          <div
                            ref={chatRef}
                            className="pt-4 px-5 pb-4 chat_Box"
                          >
                            {liveCommentsList?.map((data) => {
                              return (
                                <>
                                  {userId?.id == data?.created_by?.id ? (
                                    <div className="chatBoxOuter border-26 replyChatBox">
                                      <div className="chatBoxInner chatboxReply">
                                        <span>
                                          <img
                                            className="user"
                                            src={
                                              data?.created_by?.profile_image
                                            }
                                            alt=""
                                          />
                                        </span>
                                        <div className="me-3">
                                          <p className="colorWhite subtext mb-0">
                                            {data?.created_by?.full_name}
                                          </p>
                                          <p className="colorWhite subtext mb-0 textLiveStreaming">
                                            {data?.comment}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="chatBoxOuter border-26">
                                      <div className="chatBoxInner">
                                        <div className="d-flex">
                                          <span>
                                            <img
                                              className="user"
                                              src={
                                                data?.created_by?.profile_image
                                                  ? data?.created_by
                                                    ?.profile_image
                                                  : Images.userimg
                                              }
                                              alt=""
                                            />
                                          </span>
                                          <div className="ms-3">
                                            <p className="colorWhite subtext mb-0">
                                              {data?.created_by?.full_name}
                                            </p>
                                            <p className="colorWhite subtext mb-0 textLiveStreaming">
                                              {data?.comment}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </>
                              );
                            })}
                          </div>

                          <div className="py-3 px-3  chatInputbox chatInputboxResp">
                            <form onSubmit={handleSendComments}>
                              <input
                                className="text"
                                type="text"
                                placeholder="Type here..."
                              />

                              <button className="send-live-msg" type="submit">
                                <img src={Images.adminsend} alt="" />
                              </button>
                            </form>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <CustomModal
            key={key}
            show={modalDetail.show}
            backdrop="static"
            showCloseBtn={false}
            isRightSideModal={false}
            mediumWidth={false}
            className={modalDetail.flag === "confirm" ? "confirm" : ""}
            child={
              modalDetail.flag === "confirm" ? (
                <EndCallModal
                  closeModal={() => {
                    handleOnCloseModal();
                  }}
                />
              ) : (
                ""
              )
            }
            onCloseModal={() => handleOnCloseModal()}
          />
        </section>
      </>
    </div>
  );
};
export default LiveModal;
