import { createSlice } from "@reduxjs/toolkit";
import { restAllData } from "../commanAction";

const initialState = {
  userSignUp: {},
  userLogin: {},
  isLoggedIn: false,
  loading: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: (builder) => builder.addCase(restAllData, () => initialState),
  reducers: {
    userSignUp: (state) => {
      state.loading = true;
    },
    setUserSignUp: (state, action) => {
      state.loading = false;
      state.userSignUp = action.payload;
    },
    userLogin: (state) => {
      // console.log('done1');
      state.loading = true;
    },
    setUserLogin: (state, action) => {
      // console.log('done2');
      state.loading = false;
      state.isLoggedIn = true;
      state.userLogin = action.payload;
    },
    forgotPass: (state) => {
      state.loading = true;
    },
    setforgotPass: (state, action) => {
      state.loading = false;
    },

    userOtp: (state) => {
      state.loading = true;
    },
    setUserOtp: (state, action) => {
      state.loading = false;
    },
    resetPass: (state) => {
      state.loading = true;
    },
    setResetPass: (state, action) => {
      state.loading = false;
    },
    logout: (state) => {
      state.loading = true;
    },
    setLogout: (state, action) => {
      state.loading = false;
      state.userInfo = {};
      state.userSignUp = {};
      state.userLogin = {};
      state.isLoggedIn = false;
    },
    dashboardUser: (state) => {
      state.loading = true;
    },

    setDashboardUser: (state, acion) => {
      state.loading = false;
    },
    onErrorStopLoad: (state) => {
      state.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  userLogin,
  setUserLogin,
  userSignUp,
  setUserSignUp,
  logout,
  setLogout,
  onErrorStopLoad,
  forgotPass,
  setforgotPass,
  userOtp,
  setUserOtp,
  resetPass,
  dashboardUser,
  setDashboardUser,
  setResetPass,
} = authSlice.actions;

export default authSlice.reducer;
