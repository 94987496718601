import React, { useState } from "react";
import * as Images from "../../../utilities/images";
import { reportComment } from "../../../redux/slices/user";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { uploadFiles } from "../../../redux/slices/admin";

const ReportModal = (props) => {
  const dispatch = useDispatch();
  const [reportTitle, setReportTitle] = useState("");
  const [uploadEvidance, setUploadEvidance] = useState("");
  const [previewEvidance, setPreviewEvidance] = useState("");

  const handleSubmitReport = (e) => {
    e.preventDefault();
    if (!reportTitle) {
      toast.error("Reason is required!");
      return;
    } else if (reportTitle.length <= 2) {
      toast.error(" Title must have minimum 3 characters");
      return;
    } else if (reportTitle.length > 25) {
      toast.error("Title must have maximum 25 characters only");
      return;
    } else if (!uploadEvidance) {
      toast.error("Evidance is required!");
      return;
    }
    let params = {
      title: reportTitle,
      avidence_file: uploadEvidance,
      model_id: props.reportId,
      model_type: 1,
    };

    dispatch(
      reportComment({
        ...params,
        cb(res) {
          if (res?.data) {
            props.onCloseModal();
          } else {
          }
        },
      })
    );
  };

  const handleSaveFiles = (file) => {
    let params = {
      uploadfile: file,
    };

    dispatch(
      uploadFiles({
        ...params,
        cb(res) {
          if (res.status) {
            setUploadEvidance(res?.data?.data?.file_name);
          } else {
          }
        },
      })
    );
  };
  const handleUploadEvidance = (e) => {
    const file = e.target.files[0];
    const fileSizeInMB = file.size / (1024 * 1024);

    if (!file.name.match(/\.(jpg|png|jpeg)$/)) {
      toast.error("Please select a valid file.");
      return false;
    }
    if (fileSizeInMB > 30) {
      toast.error("File size exceeds 30MB. Please select a smaller video");
      return;
    }
    if (file) {
      setUploadEvidance(file);
      const reader = new FileReader();

      reader.onloadend = () => {
        setPreviewEvidance(reader.result);
      };
      reader.readAsDataURL(file);
      handleSaveFiles(file);
    } else {
    }
  };
  const handleCrossImage = () => {
    setUploadEvidance(null);
    setPreviewEvidance(null);
  };

  return (
    <>
      <div className="reportsModal">
        <div className="reportModalInner">
          <h2 className="subheading pb-2 text-center">Report</h2>
          {/* <p className="text text-center">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </p> */}

          <div className="">
            <label className="DefaultPara medText text-start pb-2">
              Reason to Report{" "}
            </label>
            <textarea
              className="contactUsforminput_field contactusTextarea mb-1"
              type="text"
              placeholder="Reason type here"
              rows="4"
              cols="50"
              value={reportTitle}
              onChange={(e) => setReportTitle(e.target.value.trimStart())}
            ></textarea>
          </div>

          <div className="custom-file">
            {uploadEvidance ? (
              <div className="dnldFileOuter ThumbnailImgOuter">
                {Boolean(previewEvidance) ? (
                  <>
                    <img
                      src={
                        previewEvidance ? previewEvidance : Images.uploadfile
                      }
                      className="img-fluid w-100 mx-0"
                      alt="upload"
                    />
                    <img
                      className="crossIcon"
                      src={Images.whitecross}
                      alt=""
                      onClick={(e) => handleCrossImage(e)}
                    />
                  </>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <>
                <label
                  className="DefaultPara medText text-start pb-2 custom-file__label"
                  for="field-upload"
                >
                  <label className="DefaultPara medText text-start pb-2">
                    Upload Any Evidence{" "}
                  </label>
                  <div className="contactUsforminput_field contactusTextarea uploadEvidence">
                    <span>
                      <img src={Images.uploadfile} alt="" />
                    </span>
                  </div>
                </label>
                <input
                  type="file"
                  class="custom-file__input"
                  id="field-upload"
                  name="upload"
                  onChange={(e) => handleUploadEvidance(e)}
                />
              </>
            )}
          </div>
          <div className="d-flex justify-content-center align-items-center pt-3">
            <button
              className="login-btn"
              type="button"
              onClick={(e) => handleSubmitReport(e)}
            >
              Submit Report
            </button>
            <span className="center-img">
              <img src={Images.centerrectangle} alt="" />
            </span>
            <span className="circle-btn">
              <img src={Images.arrow} alt="" />
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportModal;
