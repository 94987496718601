import React, { useState, useEffect } from "react";
import * as Images from "../../../../utilities/images";
import { useDispatch } from "react-redux";
import { getClasses } from "../../../../redux/slices/admin";
import { toast } from "react-toastify";

const AttachClass = (props) => {
  const dispatch = useDispatch();
  const [getClass, setGetClass] = useState([]);
  const [classData, setClassdata] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [selectedAttachVideo, setAttachVideo] = useState(
    props.selectedClassVideo || null
  );

  const classList = (
    page = 1,
    limit = 5,
    keyword = searchValue,
    sort = "existing"
  ) => {
    let params = {
      page: page,
      limit: limit,
      keyword: keyword,
      sort: sort,
    };
    dispatch(
      getClasses({
        ...params,
        cb(res) {
          if (res.status) {
            if (getClass.length == 0) {
              setGetClass(res.data.data?.list);
            } else {
              setGetClass((prevData) => {
                return [...res.data.data?.list];
              });
            }
            setClassdata(res.data.data);
            setCurrentPage(res.data.data?.current_page);
          }
        },
      })
    );
  };

  useEffect(() => {
    classList();
  }, []);

  const resetData = () => {
    props.setShowVideoOuter(true);
    props.handleAttachVideo(null);
    setAttachVideo(null);
  };

  const onAttachClick = () => {
    props.handleAttachVideo(selectedAttachVideo);
    if (selectedAttachVideo) {
      toast.success("Class Video attached successfully!");
      props.closeModal();
    } else {
      toast.error("Please attach class video");
    }
    props.setShowVideoOuter(true);
  };
  const radioOnChange = (event, data) => {
    setAttachVideo(selectedAttachVideo?.id === data?.id ? null : data);
    props.setShowVideoOuter(false);
  };

  return (
    <>
      <div className=" col-md-12">
        <div className="attachPdf">
          <div className="serachBar modalSearch">
            <img
              src={Images.searchiconpink}
              alt="Search"
              className="searchIcon mt-1 ms-2"
            />
            <input
              type="text"
              name="Search"
              className="form-control search subtext"
              placeholder="Search..."
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
                classList(1, 5, e.target.value, "existing");
              }}
            />
          </div>

          {getClass?.length > 0
            ? getClass?.map((data, idx) => {
                return (
                  <div key={idx} className="addRecipeBox">
                    <div className="addRecipeBoxInner">
                      <div className="recipeImgOuter">
                        <div className="recipeImg">
                          <img
                            className="w-100"
                            src={
                              data?.thumbnail_image
                                ? data?.thumbnail_image
                                : Images.foodbox
                            }
                            alt=""
                          />
                        </div>
                        <p htmlFor={idx} className="text mb-0 ms-4">
                          {data?.title}
                        </p>
                      </div>
                      <div className="form-check attach-pointer">
                        <input
                          className="form-check-input attach-pointer"
                          type="radio"
                          value={data.id}
                          name="radiobtn"
                          // id="flexCheckDefault"
                          checked={selectedAttachVideo?.id === data?.id}
                          id={idx}
                          onClick={(e) => radioOnChange(e, data)}
                        />
                      </div>
                    </div>
                  </div>
                );
              })
            : "No result found"}
          {getClass?.length > 0 &&
            classData.totalRecords > getClass?.length && (
              <button
                className="DefultPara colorPink viewmore"
                style={{ top: "10px" }}
                onClick={() => {
                  classList(parseInt(currentPage), 10);
                }}
              >
                View more
              </button>
            )}

          <div className="row justify-content-center mt-4 w-100 pb-4">
            <div className="d-grid gap-1 col-lg-4 col-md-6">
              <button
                className="primaryBtn submitBtn m-0"
                type="button"
                onClick={(e) => onAttachClick(e)}
              >
                <span>Attach</span>
              </button>
            </div>
            <div className="d-grid gap-1 col-lg-4 col-md-6">
              <button
                className="primaryBtn cancelBtn m-0"
                type="button"
                onClick={(e) => resetData(e)}
              >
                <span>Discard</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AttachClass;
