import { Link, useNavigate } from "react-router-dom";
import * as Images from "../../../utilities/images";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getPlans } from "../../../redux/slices/admin";
import CustomModal from "../../components/modals/modal";
import DeleteSubscription from "./cancelSubscriptionConfirmModal";
import moment from "moment";
import { Spinner } from "react-bootstrap";
const PlanSubscription = () => {
  const [getCurrentPlan, setCurrentPlan] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const currentPlan = () => {
    dispatch(
      getPlans({
        cb(res) {
          if (res?.data) {
            setCurrentPlan(res?.data?.data);
          } else {
          }
        },
      })
    );
  };
  const handlecancelSubscription = (subscription_id) => {
    setModalDetail({
      show: true,
      title: "CancelSubsc",
      flag: "CancelSubsc",
      id: subscription_id,
    });
    setKey(Math.random());
  };
  useEffect(() => {
    currentPlan();
    window.scrollTo(0, 0);
    document.title = "Plans & Subscription";
  }, []);

  const handleUpgrade = () => {
    navigate("/addsubscription");
  };
  const handleShowCard = () => {
    navigate("/addsubscription");
  };
  const currentSubscribedPlan = getCurrentPlan?.find(
    (val) => val?.Subscription
  );
  const timestamp = currentSubscribedPlan?.Subscription?.end_date;
  const date = moment.unix(timestamp);
  const formattedDate = date.format("D MMMM, YYYY");

  return (
    <>
      <section className="loginpage">
        <div className="container-fluid">
          <div className="row item-center flex-row">
            <div className=" col-lg-5 col-md-6">
              <div className="logininner changePasswordOuter">
                <div className="header-logo">
                  <Link to={"/"}>
                    <img src={Images.logo} alt="" />
                  </Link>
                </div>
                <div className="loginForm">
                  <Link className="colorpink" to={"/addsubscription"}>
                    <img className="backicon" src={Images.backarrow} alt="" />
                    Go Back
                  </Link>
                  <h1 className="heading py-3">Plan & Subscription</h1>
                </div>
                <div className="">
                  <p className="subtext copyrightText pb-3">
                    © copyright{moment().year()}. All rights reserved
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-12 col-lg-7 p-0">
              <div className="imgouter editProfile">
                {currentSubscribedPlan ? (
                  <>
                    <div className="formTabs">
                      <div className="header-logo">
                        <Link to={"/"}>
                          <img src={Images.logo} alt="" />
                        </Link>
                      </div>
                      <div className="loginForm">
                        <Link className="colorpink" to="/addsubscription">
                          <img
                            className="backicon"
                            src={Images.backarrow}
                            alt=""
                          />
                          Go Back
                        </Link>
                        <h1 className="heading py-3">Plan & Subscription</h1>
                      </div>
                    </div>
                    <div className="planBoxOuterNew">
                      <div className="planBoxInner">
                        <div className="leftContent">
                          <span className="text textBg">
                            {currentSubscribedPlan?.interval
                              .charAt(0)
                              .toUpperCase() +
                              currentSubscribedPlan?.interval.slice(1)}
                          </span>
                          <p className="bigHeading py-4">
                            $
                            {currentSubscribedPlan?.amount_decimal ? (
                              currentSubscribedPlan?.amount_decimal / 100
                            ) : (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            )}{" "}
                            <span className="text">
                              /{currentSubscribedPlan?.nickname}
                            </span>
                          </p>

                          {currentSubscribedPlan?.metadata?.description
                            ?.split(",")
                            .map((data) => {
                              return (
                                <div className="">
                                  <span className="p-2">
                                    <img src={Images.tick} alt="" />
                                  </span>
                                  <span className="text DefaultPara ">
                                    {data}
                                  </span>
                                </div>
                              );
                            })}
                        </div>

                        <div className="">
                          <div className="mb-4">
                            {currentSubscribedPlan?.amount > 2000 && (
                              <>
                                <button
                                  className="login-btn"
                                  onClick={handleUpgrade}
                                >
                                  Upgrade
                                </button>
                                <span className="center-img">
                                  <img src={Images.centerrectangle} alt="" />
                                </span>
                                <span className="circle-btn">
                                  <img src={Images.arrow} alt="" />
                                </span>
                              </>
                            )}
                          </div>

                          <p className="subtext text-end">
                            Expire on: {formattedDate ? formattedDate : "----"}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center pt-4 justify-content-between w-100">
                      <div className="d-flex align-items-center">
                        {/* <div className="autoRenew">
                          <img
                            className="img-fuild"
                            src={Images.autoRenew}
                            alt=""
                          />
                        </div>
                        <span className="text ms-2">Auto Renew</span> */}
                      </div>
                      <button
                        className="cancelSubsText"
                        onClick={() =>
                          handlecancelSubscription(
                            currentSubscribedPlan?.Subscription?.subscription_id
                          )
                        }
                      >
                        Cancel Subscription
                      </button>
                    </div>
                    <div className="copyrightBottom pt-5">
                      <p className="subtext copyrightText pb-3">
                        © copyright{moment().year()}. All rights reserved
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <h2 className="noDataText">
                      No subscription plans added yet!
                    </h2>
                    <button
                      className="login-btn mt-4"
                      type="button"
                      onClick={(e) => handleShowCard(e)}
                    >
                      Add Subscription
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        className={modalDetail.flag === "CancelSubsc" ? "CancelSubsc" : ""}
        child={
          modalDetail.flag === "CancelSubsc" ? (
            <DeleteSubscription
              closeModal={() => {
                handleOnCloseModal();
              }}
              refreshData={() => currentPlan()}
              subscription_id={modalDetail?.id}
            />
          ) : (
            ""
          )
        }
        header={
          <div className="modalHeader_">
            <div className="common_">
              {modalDetail.flag === "Default" ? (
                <h2 className="headingSmall_">Cancel Subscription</h2>
              ) : (
                ""
              )}
            </div>
          </div>
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};
export default PlanSubscription;
