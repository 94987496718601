import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getPrivacyPolicy } from "../../../redux/slices/admin";
import { toast } from "react-toastify";


const Privacypolicy = () => {
  const dispatch = useDispatch();
  const [privacyPolicy, setPrivacyPolicy] = useState(null);

  const PrivacyPolicyData = () => {
    let params = {
      page_type: 2,
    };
    dispatch(
      getPrivacyPolicy({
        ...params,
        cb(res) {
          if (res) {
            setPrivacyPolicy(res?.data?.data?.description);
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };

  useEffect(() => {
    PrivacyPolicyData();
    window.scrollTo(0, 0);
    document.title = "Privacy Policy";
  }, []);

  return (
    <>
      <section className="recipeBanner favoritesBanner termsBanner py-0">
        <div className="container">
          <div className="row pt-5">
            <div className="col-md-12 text-center pt-5">
              <h2 className="bigHeading pt-5 pb-4 ">Privacy Policy</h2>
              {/* <p className="text px-3">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p> */}
            </div>
          </div>
        </div>
      </section>
      <div className="container pb-5">
        <div className="containerInner">
          <p className="text">
            <div dangerouslySetInnerHTML={{ __html: privacyPolicy }} />
          </p>

        </div>
      </div>
    </>
  );
};

export default Privacypolicy;
