import React, { useEffect, useState } from "react";
import * as Images from "../../../utilities/images";
import OtpInput from "react-otp-input";
import { Link, useNavigate } from "react-router-dom";
import { userOtp, userSignUp } from "../../../redux/slices/auth";
import { useDispatch } from "react-redux";
import moment from "moment";
const Verification = () => {
  const navigate = useNavigate();
  const [hideResendButton, setHideResendButon] = useState(true);
  const [errorMessage, setErrorMessage] = useState({});
  const [error, setError] = useState();
  const userDataObj = localStorage.getItem("userData");
  const userDataParsed = JSON.parse(userDataObj);

  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");

  const handleChangePin = (newValue) => {
    setOtp(newValue);

  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!otp) {
      // setErrorMessage("Please enter OTP");
      setError("Please enter OTP")
      return;
    }
    // console.log(error, "error");

    let params = {
      email: userDataParsed.email,
      first_name: userDataParsed.firstName,
      last_name: userDataParsed.lastName,
      password: userDataParsed.password,
      otp: otp,
    };
    dispatch(
      userSignUp({
        ...params,
        cb(res) {
          if (res?.data?.status_code == 400) {
            setErrorMessage({ error: res.data.message });
            return false;
          }
          if (res?.data?.status_code == 200) {
            navigate("/");
            setErrorMessage("");
          } else {
          }
        },
      })
    );
  };

  // console.log(errorMessage,"errorvi")

  const handleResendOtp = (e) => {
    e.preventDefault();
    let params = {
      email: userDataParsed.email,
      type: "1",
    };
    dispatch(
      userOtp({
        ...params,
        cb(res) {
          if (res) {
            setHideResendButon(false);
            setTimeout(() => {
              setHideResendButon(true);
            }, 5000);
          } else {
          }
        },
      })
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Verification";
  }, []);
  return (
    <>
      <section className="loginpage">
        <div className="container-fluid">
          <div className="row item-center flex-row">
            <div className=" col-lg-5 col-md-6">
              <div className="logininner">
                <div className="header-logo">
                  <Link to={"/"}>
                    <img src={Images.logo} alt="" />
                  </Link>
                </div>
                <div className="loginForm">
                  <Link className="colorpink" to="/signup">
                    <img className="backicon" src={Images.backarrow} alt="" />
                    Back
                  </Link>
                  <h1 className="heading py-2">Verify Email</h1>
                  <p className="text pb-3">
                    Enter the OTP that we just sent your on your email address.
                  </p>
                  <div className="mb-4 form-group otpBox">
                    <OtpInput
                      className="otpBox"
                      type="number"
                      value={otp}
                      onChange={handleChangePin}
                      numInputs={4}
                      renderInput={(props) => (
                        <input className="inputdigit" {...props} />
                      )}
                    />
                    {errorMessage ? (
                      <span
                        className={
                          errorMessage?.otp || errorMessage?.error
                            ? "validationMsg"
                            : "d-none"
                        }
                      >
                        {errorMessage?.otp
                          ? errorMessage?.otp
                          : errorMessage?.error}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  {hideResendButton && (
                    <p
                      className="text resendText"
                      onClick={(e) => handleResendOtp(e)}
                    >
                      {" "}
                      Resend
                    </p>
                  )}

                  <form>
                    <div className="dflex justify-between py-5 btnaction_">
                      <div className="">
                        <button
                          className="login-btn"
                          type="button"
                          onClick={(e) => {
                            handleSubmit(e);
                          }}
                        >
                          Verify
                        </button>
                        <span className="center-img">
                          <img src={Images.centerrectangle} alt="" />
                        </span>
                        <span
                          className="circle-btn"
                          onClick={(e) => handleSubmit(e)}
                        >
                          <img src={Images.arrow} alt="" />
                        </span>
                      </div>
                    </div>
                    <p className="subtext copyrightText pb-3">
                      ©copyright{moment().year()}. All rights reserved
                    </p>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-7 pr-0">
              <div className="imgouter">
                <img className="img-fluid w-100" src={Images.banner} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Verification;
