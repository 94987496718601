import React, { useEffect, useState } from "react";
import * as Images from "../../../utilities/images";
import { resetPass } from "../../../redux/slices/auth";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";

const Recoverpassword = () => {
  const [serverError, setServerError] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordConfirmShown, setConfirmPasswordShown] = useState(false);

  const formik = useFormik({
    initialValues: {
      new_password: "",
      confirmPassword: "",
    },
 
    validationSchema: Yup.object({
      new_password: Yup.string()
        .matches(
          /^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,
          "Password must contain at least 1 number, 1 uppercase letter, and 1 special character"
        )
        .required("New Password is required"),
      confirmPassword: Yup.string()
        .oneOf(
          [Yup.ref("new_password"), null],
          "New Password and confirm Password must match"
        )
        .required("Confirm Password is required"),
    }),
    onSubmit: (values) => {
      let params = {
        new_password: values?.new_password,
      };
      dispatch(
        resetPass({
          ...params,
          cb(res) {
            if (res?.data?.status_code == 400) {
              setServerError({ error: res.data.message });
              return false;
            }
            if (res?.data?.status_code == 200) {
              toast.success("Password recovered succesfully!");
              navigate("/login");
              setServerError("");

            } else {
            }
          },
        })
      );
    },
  });
  //password field show or hide
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const toggleConfirmPasswordVisiblity = () => {
    setConfirmPasswordShown(passwordConfirmShown ? false : true);
  };

  const isValidChars =
    /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d.!@#$%^&*()_+]{8,}$/.test(
  formik.values.new_password
    );
  const isPasswordValidLength = formik.values.new_password?.length >= 8;

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Recover Password";
  }, []);

  return (
    <>
      <section className="loginpage">
        <div className="container-fluid">
          <div className="row item-center flex-row">
            <div className=" col-lg-5 col-md-6">
              <div className="logininner">
                <div className="header-logo">
                  <Link to={"/"}>
                    <img src={Images.logo} alt="" />
                  </Link>
                </div>
                <div className="loginForm">
                  <Link className="colorPink text" to="/login">
                    <img className="backicon" src={Images.backarrow} alt="" />
                    Back to Login
                  </Link>
                  <h1 className="heading py-2">Recover Password</h1>
                  <p className="text">Create your new password here.</p>

                  <form onSubmit={formik.handleSubmit}>
                    <div className="mb-3 form-group">
                      <div className="position-relative">
                        <input
                          className="form-control id_password GG"
                          placeholder="New Password"
                          name="new_password"
                          type={passwordShown ? "text" : "password"}
                          {...formik.getFieldProps("new_password")}
                      
                        
                        />
                        <img className="icon_" src={Images.lock} alt="" />
                        <span
                        className="toggle_password_ info_icon"
                        onClick={() => {
                          togglePasswordVisiblity(!passwordShown);
                        }}
                      >
                        <span
                          className={
                            passwordShown
                              ? "show-icon togglePassword"
                              : "hide-icon togglePassword"
                          }
                          id=""
                        ></span>
                      </span>
                      </div>
                      {formik.touched.new_password &&
                        formik.errors.new_password ? (
                          <div className="validationMsg"> {formik.errors.new_password}</div>
                        ) : null}
                      {/* <img className="inputIcon" src={Images.hide} alt=""/> */}
                    </div>
                    <div className="mb-3 form-group">
                      <div className="position-relative">
                        <input
                          className="form-control id_password FF"
                          placeholder="Confirm Password"
                          name="confirmPass"
                          type={passwordConfirmShown ? "text" : "password"}
                          {...formik.getFieldProps("confirmPassword")}
                   
                        />
                        <img className="icon_" src={Images.lock} alt="" />
                        <span
                        className="toggle_password_ info_icon"
                        onClick={() => {
                          toggleConfirmPasswordVisiblity(!passwordConfirmShown);
                        }}
                      >
                        <span
                          className={
                            passwordConfirmShown
                              ? "show-icon togglePassword"
                              : "hide-icon togglePassword"
                          }
                          id=""
                        ></span>
                      </span>
                      </div>
                      {formik.touched.confirmPassword &&
                        formik.errors.confirmPassword ? (
                          <div className="validationMsg">{formik.errors.confirmPassword}</div>
                        ) : null}

                      {serverError ? (
                        <span
                          className={
                            serverError?.error ? "validationMsg" : "d-none"
                          }
                        >
                          {serverError?.error ? serverError?.error : ""}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="">
                      <artical
                        className={
                          isPasswordValidLength
                            ? "correct_password mb-2"
                            : "incorrect_password mb-2"
                        }
                      >
                        <figure>
                          <img
                            src={
                              isPasswordValidLength ? Images.tick : Images.cross
                            }
                            alt="LeftIcon"
                            className={
                              isPasswordValidLength
                                ? "correctIcon"
                                : "incorrectIcon"
                            }
                          />
                        </figure>
                        <p className="mb-0">Must be at least 8 Characters!</p>
                      </artical>
                    </div>
                    <div className="">
                      <artical
                        className={
                          isValidChars
                            ? "correct_password"
                            : "incorrect_password "
                        }
                      >
                        <figure>
                          <img
                            src={isValidChars ? Images.tick : Images.cross}
                            alt="LeftIcon"
                            className={
                              isValidChars ? "correctIcon" : "incorrectIcon"
                            }
                          />
                        </figure>
                        <p>
                          Must be at least 1 Number & 1 Capital Case later & 1
                          special character!
                        </p>
                      </artical>
                    </div>
                    <div className="dflex justify-between py-3">
                      <div className="">
                        <button className="login-btn" type="submit">
                          Reset Password
                        </button>
                        <span className="center-img">
                          <img src={Images.centerrectangle} alt="" onClick={formik.handleSubmit} />
                        </span>
                        <span className="circle-btn">
                          <img src={Images.arrow} alt="" onClick={formik.handleSubmit}/>
                        </span>
                      </div>
                    </div>
                    <p className="subtext copyrightText pb-3">
                      © copyright{moment().year()}. All rights reserved
                    </p>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-7 pr-0">
              <div className="imgouter">
                <img className="img-fluid w-100" src={Images.banner} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Recoverpassword;
