import React, { useEffect, useState } from "react";
import * as Images from "../../../utilities/images";
import OtpInput from "react-otp-input";
import { Link, useNavigate } from "react-router-dom";
import { forgotPass, userOtp } from "../../../redux/slices/auth";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import moment from "moment";

const Enterotp = () => {
  const [otp, setOtp] = useState("");
  const [errorMessage, setErrorMessage] = useState({});
  const [hideResendButton, setHideResendButon] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let email = localStorage.getItem("email");
  const handleChangeOtp = (value) => {
    setOtp(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let numberRegex = /^\d+$/;
    if (!otp) {
      setErrorMessage({ otp: "Please enter OTP" });
      return;
    } else if (!otp.match(numberRegex)) {
      setErrorMessage({ otp: "Otp should be number" });
      return;
    }

    let params = {
      email: email,
      otp: otp,
    };
    dispatch(
      forgotPass({
        ...params,
        cb(res) {
          if (res?.data?.status_code == 400) {
            setErrorMessage({ error: res.data.message });
            return false;
          }
          if (res?.data?.status_code == 200) {
            toast.success("OTP verified");
            navigate("/recoverpassword");
            setErrorMessage("");
          } else {
          }
        },
      })
    );
  };

  const handleResendOtp = (e) => {
    e.preventDefault();
    let params = {
      email: email,
      type: "2",
    };
    dispatch(
      userOtp({
        ...params,
        cb(res) {
          if (res) {
            setHideResendButon(false);
            setTimeout(() => {
              setHideResendButon(true);
            }, 5000);
          } else {
          }
        },
      })
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Enter-otp";
  }, []);
  return (
    <>
      <section className="loginpage">
        <div className="container-fluid">
          <div className="row item-center flex-row">
            <div className=" col-lg-5 col-md-6">
              <div className="logininner">
                <div className="header-logo">
                  <Link to={"/"}>
                    <img src={Images.logo} alt="" />
                  </Link>
                </div>
                <div className="loginForm">
                  <Link className="colorPink text" to="/login">
                    <img className="backicon" src={Images.backarrow} alt="" />
                    Back
                  </Link>
                  <h1 className="heading py-2">Enter OTP</h1>
                  <p className="text pb-3">
                    Enter the OTP that we just sent your on your email address.
                  </p>
                  <form>
                    <div className="mb-4 form-group otpBox">
                      <OtpInput
                        className="otpBox"
                        value={otp}
                        onChange={handleChangeOtp}
                        numInputs={4}
                        renderInput={(props) => (
                          <input className="inputdigit" {...props} />
                        )}
                      />
                      {errorMessage ? (
                        <span
                          className={
                            errorMessage?.otp || errorMessage?.error
                              ? "validationMsg"
                              : "d-none"
                          }
                        >
                          {errorMessage?.otp
                            ? errorMessage?.otp
                            : errorMessage?.error}
                          {/* {console.log(errorMessage.otp)} */}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                    {hideResendButton && (
                      <p
                        className="text resendText"
                        onClick={(e) => handleResendOtp(e)}
                      >
                        {" "}
                        Resend
                      </p>
                    )}
                    <div className="dflex justify-between py-5">
                      <div className="">
                        <button
                          className="login-btn"
                          type="button"
                          onClick={(e) => handleSubmit(e)}
                        >
                          Submit
                        </button>
                        <span className="center-img">
                          <img src={Images.centerrectangle} alt=""
                            onClick={(e) => handleSubmit(e)}
                          />
                        </span>
                        <span
                          className="circle-btn"
                          onClick={(e) => handleSubmit(e)}
                        >
                          <img src={Images.arrow} alt="" />
                        </span>
                      </div>
                    </div>
                    <p className="subtext copyrightText pb-3">
                      © copyright{moment().year()}. All rights reserved
                    </p>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-7 pr-0">
              <div className="imgouter">
                <img className="img-fluid w-100" src={Images.banner} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Enterotp;
