import React, { useState, useEffect } from "react";
import * as Images from "../../../utilities/images";
import { Link, useNavigate } from "react-router-dom";
import { dashboardUser } from "../../../redux/slices/user";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import { getTodaysLiveClasses } from "../../../redux/slices/admin";
import { Spinner } from "react-bootstrap";

const AdminDashboard = () => {
  const [dashboradUsers, setDashboardUsers] = useState(null);
  const [todaysLive, setTodaysLive] = useState([]);
  const [totaluserRecords, setTotaluserRecords] = useState("");
  const [totalReceipies, setTotalReceipies] = useState("");
  const [totalclasses, settotalclasses] = useState("");
  const [totalLiveCount, setTotalLiveCount] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const GetDashboardUser = () => {
    dispatch(
      dashboardUser({
        cb(res) {
          if (res?.data) {
            setDashboardUsers(res?.data?.data?.data);
            setTotaluserRecords(res?.data?.data?.totalRecords);
            setTotalReceipies(res?.data?.data?.totalRecipes);
            settotalclasses(res?.data?.data?.totalClasses);
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };

  const getTodayLiveClassData = () => {
    let params = {
      sort: "today_live",
    };

    dispatch(
      getTodaysLiveClasses({
        ...params,
        cb(res) {
          if (res?.data) {
            setTodaysLive(res?.data?.data?.list);
            setTotalLiveCount(res?.data?.data?.totalRecords);
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };

  const startStreaming = () => {
    navigate("/admin/classes");
  };
  useEffect(() => {
    GetDashboardUser();
    getTodayLiveClassData();
    window.scrollTo(0, 0);
    document.title = "Dashboard";
  }, []);

  const handlGoUsersPage = () => {
    navigate("/admin/user");
  };
  const handlGoRecipePage = () => {
    navigate("/admin/recipe");
  };
  const handlGoClassPage = () => {
    navigate("/admin/classes");
  };
  return (
    <>
      <div className="contentCommon dashboardContent">
        <div className="dashHead">
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-2" onClick={handlGoUsersPage}>
              <div className="dashCard pinkBoxOuter">
                <div className="dashCardImg pinkBox">
                  <img src={Images.userslight} alt="user icon" />
                </div>
                <div className="dashCardHeading">
                  <h2>Total Users</h2>
                  <p className="mb-0 medText text">{totaluserRecords}</p>
                </div>
                <div className="RotateArrowpink">
                  <Link
                    className="colorPink subtext"
                    to="/admin/user"
                    style={{ backgroundColor: "transparent" }}
                  >
                    <img src={Images.toparrowPink} alt="" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-2" onClick={handlGoRecipePage}>
              <div className="dashCard pinkBoxOuter">
                <div className="dashCardImg pinkBox">
                  <img src={Images.recipeiconlight} alt="user icon" />
                </div>
                <div className="dashCardHeading">
                  <h2>Total Recipes</h2>
                  <p className="mb-0  medText text">{totalReceipies}</p>
                </div>
                <div className="RotateArrowpink">
                  <Link
                    className="colorPink subtext"
                    to="/admin/recipe"
                    style={{ backgroundColor: "transparent" }}
                  >
                    <img src={Images.toparrowPink} alt="" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-2" onClick={handlGoClassPage}>
              <div className="dashCard pinkBoxOuter">
                <div className="dashCardImg pinkBox">
                  <img src={Images.userslight} alt="user icon" />
                </div>
                <div className="dashCardHeading">
                  <h2>Total Classes</h2>
                  <p className="mb-0  medText text">{totalclasses}</p>
                </div>
                <div className="RotateArrowpink">
                  <Link
                    className="colorPink subtext"
                    to="/admin/classes"
                    style={{ backgroundColor: "transparent" }}
                  >
                    <img src={Images.toparrowPink} alt="" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8 col-md-12">
            <div className="d-flex justify-content-between align-items-center px-2">
              <p className="py-3 graphconText m-0">Recent User Join </p>
              {dashboradUsers?.length > 10 ? (
                <Link
                  className="colorPink subtext"
                  to="/admin/user"
                  style={{ backgroundColor: "transparent" }}
                >
                  View All
                </Link>
              ) : (
                ""
              )}
            </div>
            <div className="graphCon graphCon-dashboard">
              <div className="row">
                <table>
                  <thead>
                    <tr>
                      <th>Full Name </th>
                      <th>Email</th>
                      <th>Joined At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* <div className="tableScrollBody"> */}
                    {dashboradUsers?.length > 0 ? (
                      dashboradUsers?.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td>{data?.full_name}</td>
                            <td>{data?.email}</td>
                            <td>
                              {moment(data?.createdAt).format("YYYY-DD-MM")}
                            </td>

                            <td>
                              <div className="arrowRotate">
                                <Link
                                  className="colorPink subtext"
                                  to="/admin/user"
                                  style={{ backgroundColor: "transparent" }}
                                >
                                  <img src={Images.toparrowPink} alt="" />
                                </Link>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <>
                      
                        {dashboradUsers?.length == 0 ? (
                          <h3 className="text-center">No Data Found</h3>
                        ) : (
                          <Spinner
                            className="pageLoader"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )}
                      </>
                    )}
                    {/* </div> */}
                  </tbody>
                </table>
              </div>
              {/* <!-- /.card --> */}
            </div>
          </div>
          <div className="col-lg-4 col-md-8">
            <div className="d-flex justify-content-between align-items-center py-3">
              <div className="dashboardStream   graphconText m-0 d-flex align-items-center">
                Today’s Live Streams
                <div className="digitBox">
                  {totalLiveCount == 0
                    ? "No Live Classes added yet"
                    : totalLiveCount}
                </div>
              </div>
              {totalLiveCount > 3 ? (
                <Link
                  className="colorPink subtext"
                  to="/admin/classes"
                  style={{ backgroundColor: "transparent" }}
                >
                  View All
                </Link>
              ) : (
                ""
              )}
            </div>
            {todaysLive?.map((data, index) => {
              return (
                <div className="rightScrollBox">
                  <div className="rightScrollInner" key={index}>
                    <div className="ScrollBoxContent">
                      <div className="ScrollBoxImg">
                        <img
                          className="img-fluid"
                          src={data?.thumbnail_image}
                          alt=""
                        />
                      </div>
                      <div className="ms-3">
                        <span>
                          <img className="me-1" src={Images.clender} alt="" />
                        </span>
                        <span className="text">
                          {moment(data?.date).utc().format("MMMM D, YYYY")}
                        </span>
                        <p className="text medText p-0 my-0">{data?.title}</p>
                        <span className="subtext">
                          <img className="me-1" src={Images.clock} alt="" />
                          {moment(data?.time, "HH:mm").format("hh:mm A")}
                        </span>{" "}
                        <span className="subtext">
                          <img className="me-1" src={Images.clock} alt="" />
                          {data?.duration} Min
                        </span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center infocicleOuter  pt-3">
                      <div className="infoCirle me-2">
                        <img src={Images.infoicon} alt="" />
                      </div>
                      <div className="">
                        <button
                          className="login-btn StartStremBtn"
                          onClick={(e) => startStreaming()}
                        >
                          <span>
                            <img className="me-2" src={Images.youtube} alt="" />
                          </span>
                          Start Streaming
                        </button>
                        <span className="center-img">
                          <img
                            src={Images.centerrectangle}
                            alt=""
                            onClick={(e) => startStreaming(e)}
                          />
                        </span>
                        <span className="circle-btn StartStremCircle">
                          <img
                            src={Images.arrow}
                            alt=""
                            onClick={(e) => startStreaming(e)}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;
