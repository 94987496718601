import axios from "axios";
import BaseUrl from "../constants/baseUrl";

const axiosInstance = axios.create({
  baseURL: BaseUrl.API_URL,
  headers: {
    Accept: "application/json",
  },
});

// console.log(BaseUrl.API_URL,"axiosInstancea")
//axios.defaults.headers.common['Authorization'] = localStorage.getItem('token')

// Set the AUTH token for any request
axiosInstance.interceptors.request.use(function (config) {
  const token = localStorage.getItem("authToken");
  config.headers.Authorization = token ? token : "";
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Handle response errors
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("authToken");
      localStorage.removeItem("persist:root");
      localStorage.removeItem("authToken");
      //toast.warning('Session expired, Please login again')
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
    return Promise.reject(error);
  }
);

const axiosGet = (url, params = {}) => {
 
  return axiosInstance
    .get(url, params)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((err) => {
      throw err;
    });
};

const axiosPut = (url, params = {}) => {
  return axiosInstance
    .put(url, params)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((err) => {
      throw err;
    });
};

const axiosPost = (url, params = {}) => {
  return axiosInstance
    .post(url, params)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((err) => {
      throw err;
    });
};

const axiosPatch = (url, params = {}) => {
  return axiosInstance
    .patch(url, params)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((err) => {
      throw err;
    });
};

const postDelete = (url, params = {}) => {
  return axiosInstance
    .delete(url, params)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((err) => {
      throw err;
    });
};

const axiosPostFormData = (url, params) => {
  if (params.image) {
    var formData = new FormData();
    formData.append("image", params?.image);
  } else if (params.file) {
    let files = params?.file;
    var formData = new FormData();
    files.forEach((val, index) => {
      formData.append("file", val);
    });
  } else {
    var formData = new FormData();
    formData.append("photo", params?.photo);
  }
  return axiosInstance
    .post(url, formData)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((err) => {
      throw err;
    });
};

const axiosPostFormDataAll = (url, formData) => {
  return axiosInstance
    .post(url, formData)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((err) => {
      throw err;
    });
};

const axiosPatchFormDataAll = (url, formData) => {
  return axiosInstance
    .patch(url, formData)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((err) => {
      throw err;
    });
};

const axiosPostFiles = (url, params) => {
  if (params.uploadfile) {
    var formData = new FormData();
    formData.append("uploadfile", params?.uploadfile);
  } else if (params.file) {
    let files = params?.file;
    var formData = new FormData();
    files.forEach((val, index) => {
      formData.append("file", val);
    });
  } else {
    var formData = new FormData();
    formData.append("uploadfile", params?.uploadfile);
  }
  return axiosInstance
    .post(url, formData)
    .then((response) => {
      return { status: response.status, data: response.data };
    })
    .catch((err) => {
      throw err;
    });
};
export const ApiClient = {
  get: axiosGet,
  put: axiosPut,
  post: axiosPost,
  patch: axiosPatch,
  delete: postDelete,
  postFormData: axiosPostFormData,
  postFormDataAll: axiosPostFormDataAll,
  patchFormData: axiosPatchFormDataAll,
  postFilesData: axiosPostFiles,
};
