import { Link } from "react-router-dom";
import * as Images from "../../../utilities/images";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { loginUserProfile } from "../../../redux/slices/user";
import { toast } from "react-toastify";

const ViewEdit = () => {
  const [userProfile, setUserProfile] = useState([]);
  const dispatch = useDispatch();

  const getAdminUser = () => {
    dispatch(
      loginUserProfile({
        cb(res) {
          if (res) {
            setUserProfile(res?.data?.data);
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };
  useEffect(() => {
    getAdminUser();
    window.scrollTo(0, 0);
    document.title = "My-Profile";
  }, []);
  return (
    <>
      <section className="loginpage">
        <div className="container-fluid">
          <div className="row item-center flex-row">
            <div className=" col-lg-5 col-md-6">
              <div className="logininner changePasswordOuter">
                <div className="header-logo">
                  <Link to={"/"}>
                    <img src={Images.logo} alt="" />
                  </Link>
                </div>
                <div className="loginForm">
                  <Link className="colorpink" to="/">
                    <img className="backicon" src={Images.backarrow} alt="" />
                    Go Back
                  </Link>
                  <h1 className="heading py-3">My Profile</h1>
                  <div className="my-3 userproImg">
                    <img
                      className="img-fluid"
                      src={
                        userProfile?.profile_image
                          ? userProfile?.profile_image
                          : Images.dummyAdminlogo
                      }
                      alt=""
                    />
                  </div>
                </div>
                <div className="">
                  {" "}
                  <p className="subtext copyrightText pb-3">
                    © copyright 2023. All rights reserved
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-7 p-0">
              <div className="imgouter editProfile commonheight">
                <button className="login-btn editProfileBtn">
                  <Link to="/updateProfile">
                    <span className="editIcon">
                      <img src={Images.pencil} alt="" />
                    </span>
                    Edit Profile
                  </Link>
                </button>
                <form className="adminProfileForm">
                  <div className="formTabs">
                    <div className="header-logo">
                      <Link to={"/"}>
                        <img src={Images.logo} alt="" />
                      </Link>
                    </div>
                    <div className="loginForm">
                      <Link className="colorpink" to="/">
                        <img
                          className="backicon"
                          src={Images.backarrow}
                          alt=""
                        />
                        Go Back
                      </Link>
                      <h1 className="heading py-3">My Profile</h1>
                    </div>
                  </div>
                  <div className="row myProflieOuter justify-content-center">
                    <div className="mb-4 form-group">
                      <label className="text">Name</label>
                      <input
                        type="text"
                        className="form-control subheading"
                        name="name"
                        placeholder={userProfile?.full_name}
                        disabled
                      />
                    </div>

                    <div className="profile-photoSmall mb-4 form-group">
                      <img
                        className="img-fluid"
                        src={
                          userProfile?.profile_image
                            ? userProfile?.profile_image
                            : Images.dummyAdminlogo
                        }
                        alt=""
                      />
                    </div>

                    <div className="mb-4 form-group">
                      <label className="text">Email</label>
                      <input
                        type="text"
                        className="form-control Text"
                        name="email"
                        placeholder={userProfile?.email}
                        disabled
                      />
                    </div>
                    <div className="mb-4 form-group">
                      <label className="text">Password</label>
                      <input
                        type=""
                        className="form-control medText"
                        name="password"
                        placeholder="************"
                        disabled
                      />
                    </div>
                    <Link className="text colorPink" to="/changePassword">
                      Change Password
                    </Link>
                  </div>
                  <div className="copyrightBottom">
                    <p className="subtext copyrightText pb-3">
                      © copyright 2023. All rights reserved
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default ViewEdit;
