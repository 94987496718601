import moment from "moment";
import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isRead } from "../../../redux/slices/user";
import * as Images from "../../../utilities/images";

const AdminNotification = ({
  close,
  notificationList,
  getNotificationList,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const notifyrecipe = true;

  const updateNotificationstatus = (data) => {
    let params = {
      id: data?.id,
    };
    dispatch(
      isRead({
        ...params,
        cb(res) {
          if (res?.status === 200) {
            getNotificationList();
          }
        },
      })
    );
  };

  const handleGoToPage = (data) => {
    if (data?.model_type == "1") {
      navigate(`/admin/detailrecipe/${data?.model_id}`, {
        state: { notifyrecipe },
      });
      close();
    } else if (data?.model_type == "2") {
      navigate(`/admin/classdetail/${data?.model_id}`);
      close();
    }
  };

  useEffect(() => {
    getNotificationList();
  }, []);

  const unreadData = notificationList?.list?.filter(
    (data) => data?.is_read === 0
  );

  return (
    <>
      {notificationList?.list?.length > 0 ? (
        notificationList?.list?.map((data, index) => {
          return (
            <>
              {data?.is_read == 0 ? (
                <div key={index} className="notoficationBox">
                  <div className="notification-images-text">
                    <div className="notification-images">
                      <div className="notificaton-first-img">
                        <img
                          src={
                            data?.data?.case == "6"
                              ? data?.data?.image
                              : Images.roundImg
                          }
                          alt="user"
                        />
                      </div>
                      <div className="notificaton-second-img">
                        <img
                          src={
                            data?.data?.created_by?.profile_image
                              ? data?.data?.created_by?.profile_image
                              : Images.userimg
                          }
                          alt="user"
                        />
                      </div>
                    </div>
                    <div>
                      <p className="notificationBoxHeading">
                        {data?.data?.modelType == "1"
                          ? `${data?.data?.created_by?.first_name} Comment on recipe of  "${data?.data?.modelTitle}"`
                          : data?.data?.modelType == "2"
                          ? `${data?.data?.created_by?.first_name} Comment on class of  "${data?.data?.modelTitle}"`
                          : data?.data?.title}
                      </p>
                    </div>
                  </div>
                  <p className="NotificationComment"> {data?.data?.review}</p>
                  <div className="flexBox justify-content-between">
                    <p className="notificationTime">
                      {" "}
                      {moment(data?.createdAt).fromNow()}
                    </p>
                    <div className="buttonBox">
                      <button
                        className="login-btn"
                        type="button"
                        onClick={() => {
                          handleGoToPage(data?.data);
                          updateNotificationstatus(data);
                        }}
                      >
                        View Comment
                      </button>
                      <span
                        className="center-img"
                        onClick={() => {
                          handleGoToPage(data?.data);
                          updateNotificationstatus(data);
                        }}
                      >
                        <img
                          src={Images.centerrectangle}
                          alt="centerrectangle"
                        />
                      </span>
                      <span
                        className="circle-btn"
                        onClick={() => {
                          handleGoToPage(data?.data);
                          updateNotificationstatus(data);
                        }}
                      >
                        <img src={Images.arrow} alt="arrow" />
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </>
          );
        })
      ) : (
        <>
          {notificationList?.list?.length == 0 ? (
            <h3 className="noDataText mt-4 mb-4">No Notices Right Now! </h3>
          ) : (
            <Spinner
              className="pageLoader ms-1"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
        </>
      )}
      <>
        {unreadData?.length == 0 && notificationList?.list?.length > 0 ? (
          <h3 className="noDataText mt-4 mb-4">
            No Unread Notifications available!{" "}
          </h3>
        ) : (
          ""
        )}
      </>
    </>
  );
};

export default AdminNotification;
