import { all, call, put, takeLatest } from "redux-saga/effects";
import { ApiClient } from "../../../utilities/api";
import {
  onErrorStopLoad,
  setLogout,
  setUserLogin,
  setUserSignUp,
  setUserOtp,
  setforgotPass,
  setResetPass,
  setDashboardUser,
} from "../../slices/auth";
import ApiPath from "../../../constants/apiPath";
import { toast } from "react-toastify";

// Worker saga will be fired on USER_FETCH_REQUESTED actions

function* userSignUp(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.AuthApiPath.SIGN_UP),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setUserSignUp(resp.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(action.res.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    yield call(action.payload.cb, (action.res = e.response));
  }
}
function* userLogin(action) {
  // console.log(action,"actionData")
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.AuthApiPath.LOGIN_USER),
      (action.payload = action.payload)
    );

    if (resp.status) {
      localStorage.setItem(
        "authToken",
        resp.data.data.token ? resp.data.data.token : ""
      );
      yield put(setUserLogin(resp.data));
      yield call(action.payload.cb, (action.res = resp));
      //toast.success(action.res.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    // console.log(e,'errorrrrrrrrrrrrr');
    yield put(setUserLogin({}));
    yield put(onErrorStopLoad());
    yield call(action.payload.cb, (action.res = e.response));
    //toast.error(e.response.data.message);
  }
}
function* forgotPass(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.AuthApiPath.FORGOT_PASSWOD),
      (action.payload = action.payload)
    );
    if (resp.status) {
      localStorage.setItem(
        "authToken",
        resp.data.data.token ? resp.data.data.token : ""
      );
      yield put(setforgotPass());
      yield call(action.payload.cb, (action.res = resp));
      //toast.success(action.res.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setforgotPass({}));
    yield put(onErrorStopLoad());
    yield call(action.payload.cb, (action.res = e.response));
    //toast.error(e.response.data.message);
  }
}
function* resetPass(action) {
  try {
    const resp = yield call(
      ApiClient.put,
      (action.url = ApiPath.AuthApiPath.RESET_PASSWORD),
      (action.payload = action.payload)
    );
    if (resp.status) {
      localStorage.removeItem("authToken");
      yield put(setResetPass(resp.status));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(action.res.data.msg);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setResetPass({}));
    yield put(onErrorStopLoad());
    yield call(action.payload.cb, (action.res = e.response));
    //toast.error(e.response.data.msg);
  }
}

function* userOtp(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.AuthApiPath.SEND_OTP),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setUserOtp(resp.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(action.res.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setUserOtp({}));
    yield put(onErrorStopLoad());
    yield call(action.payload.cb, (action.res = e.response));
    //toast.error(e.response.data.message);
  }
}

function* userLogout(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = ApiPath.AuthApiPath.LOGOUT),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield call(action.payload.cb, (action.res = resp));
      localStorage.clear();
      // toast.success(action.res.data.message);
      yield put(setLogout());
    } else {
      throw resp;
    }
  } catch (e) {
    toast.error(e.response.data.message);
  }
}
function* dashboardUser(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = ApiPath.UserApiPath.DASHBOARD_USER),
      (action.payload = action.payload)
    );
    yield put(setDashboardUser(resp.data.payload));
    yield call(action.payload.cb, (action.res = resp));
  } catch (e) {
    yield put(setDashboardUser({}));
  }
}
function* authSaga() {
  yield all([
    takeLatest("auth/userSignUp", userSignUp),
    takeLatest("auth/userLogin", userLogin),
    takeLatest("auth/forgotPass", forgotPass),
    takeLatest("auth/userOtp", userOtp),
    takeLatest("auth/resetPass", resetPass),
    takeLatest("auth/logout", userLogout),
    takeLatest("auth/dashboardUser", dashboardUser),
  ]);
}

export default authSaga;
