import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import * as Images from "../../../../utilities/images";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getRecipeDetails } from "../../../../redux/slices/admin";
import moment from "moment-timezone";
import Deleterecipe from "./deleteRecipe";
import CustomModal from "../../../components/modals/modal";
import {
  deleteComment,
  getAllreviews,
  replyComment,
} from "../../../../redux/slices/user";
import ReactStars from "react-rating-stars-component";
import { Spinner } from "react-bootstrap";
import Swal from "sweetalert2";

const DetailRecipe = () => {
  const vidRef = useRef(null);
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const [typeId] = useState(2);
  const [getDetails, setGetDetails] = useState([]);
  const [key, setKey] = useState(Math.random());
  const [isVideoPlayed, setIsVideoPlayed] = useState(false);
  const [commentList, setCommentsList] = useState([]);
  const [totalCommentsrecords, setTotalCommentRecords] = useState("");
  const [commentKey, setCommentKey] = useState(Math.random());
  const [page, setPage] = useState(1);
  const [totalComments, setTotalComments] = useState("");
  const [showCommentBox, setShowCommentBox] = useState(false);
  const [userId, setUserById] = useState("");
  const [replyComments, setReplyComments] = useState("");
  const [loadingSendReplyComment, setLoadingSendReplyComment] = useState(false);
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const handlePlayVideo = () => {
    setIsVideoPlayed(true);
    vidRef.current.play();
  };
  const handlePauseVideo = () => {
    setIsVideoPlayed(false);
    vidRef.current.pause();
  };

  const handleDeleteUser = (userid, Recipetitle) => {
    setModalDetail({
      show: true,
      title: "Deleterecipe",
      flag: "Delete",
      id: userid,
      Recipetitle: Recipetitle,
    });
    setKey(Math.random());
  };
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
  const recipeDetails = () => {
    let params = {
      id: id,
    };
    dispatch(
      getRecipeDetails({
        ...params,
        cb(res) {
          if (res?.data) {
            setGetDetails(res?.data?.data);
            getAllComments(page, res?.data?.data?.id);
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };
  useEffect(() => {
    if (location?.state?.notifyrecipe == true) {
      window.scrollTo(0, document.body.scrollHeight);
    }
    recipeDetails();
    document.title = "Recipedetails";
  }, []);

  //commentsListing
  const getAllComments = (pageCmt = page, id) => {
    let params = {
      page: pageCmt,
      limit: 4,
      model_id: id,
      model_type: 1,
    };
    dispatch(
      getAllreviews({
        ...params,
        cb(res) {
          if (res?.data) {
            if (parseInt(res?.data?.data?.current_page) == 1) {
              setCommentsList(handleComentReplies(res?.data?.data?.list));
              setTotalCommentRecords(res?.data?.data?.totalRecords);
            } else {
              setCommentsList((commentList) => {
                const cmt = [...commentList, ...res?.data?.data?.list];
                return cmt;
              });
            }

            setTotalComments(res?.data?.data?.totalRecords);
            setCommentKey(Math.random());
            setPage(res?.data?.data?.current_page);
          } else {
          }
        },
      })
    );
  };
  const handleComentReplies = (comment) => {
    let _comment = [];
    comment.map((data) => {
      _comment.push({ ...data, itemToShow: 2 });
    });
    return _comment;
  };
  const handleViewMore = (i) => {
    let _commentList = [...commentList];
    _commentList[i].itemToShow += 2;
    setCommentsList(_commentList);
  };
  const handleShowInput = (id) => {
    setShowCommentBox(true);
    setUserById(id);
  };
  const handlePostCommentsReply = (id, index) => {
    if (!replyComments) {
      toast.error("Add a comment");
      return;
    }
    let params = {
      parent_id: id,
      comments: replyComments,
    };
    setLoadingSendReplyComment(true);
    dispatch(
      replyComment({
        ...params,
        cb(res) {
          setLoadingSendReplyComment(false);
          if (res.status) {
            // getAllComments(id,page);
            setShowCommentBox(false);
            setReplyComments("");

            setCommentsList((commentList) => {
              var newData = [...commentList];
              const newComments = [
                res.data.data,
                ...commentList[index].comments,
              ];
              newData[index] = {
                ...newData[index],
                comments: newComments,
              };

              return newData;
            });
          } else {
          }
        },
      })
    );
  };

  // remove comment
  const handleRemoveComment = (id) => {
    Swal.fire({
      title: "Delete Comment",
      text: "Are you sure you want to delete",
      icon: "warning",
      iconColor: "red",
      customClass: {
        popup: "mySwalClass",
      },
      showCancelButton: true,
      confirmButtonText: "Yes, Delete",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed && id) {
        dispatch(
          deleteComment({
            id,
            cb(res) {
              if (res?.status === 200) {
                getAllComments(page, getDetails?.id);
                toast.success("Comment deleted successfully");
              }
            },
          })
        );
      }
    });
  };

  return (
    <>
      <div className="detailRecipe">
        <div className="row mt-4">
          <div className="col-12">
            <div className="contentCommon">
              <div className="px-3">
                <div className="courseHead">
                  <div className="backBtn">
                    <Link to={"/admin/recipe"}>
                      <button className="btn colorPink px-0 pb-2">
                        <img
                          src={Images.rightarrowpink}
                          alt="arrow"
                          className="me-2"
                        />{" "}
                        Go to Back
                      </button>
                    </Link>
                  </div>
                  <div className="detail-recipe-outer">
                    <div className="details-heading-recipe">
                      <h1 className="courseHeading font-28 m-0">
                        {getDetails?.title}
                      </h1>
                    </div>
                    <div className="commonCourse">
                      <div className="editDeleteBtn">
                        <Link to={`/admin/editrecipe/${id}`}>
                          <button
                            type="button"
                            className="btn outlineInfo me-2 editBtn "
                            data-bs-toggle="modal"
                            data-bs-target="#courseEdit"
                            data-backdrop="static"
                            data-keyboard="false"
                          >
                            Edit
                          </button>
                        </Link>
                        <button
                          onClick={() =>
                            handleDeleteUser(getDetails?.id, getDetails?.title)
                          }
                          type="button"
                          className="btn dangerBtn outlineDanger editBtn"
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="courseCard detailRecipeCard">
                    <div className="detailRecipeCardInner">
                      <div className="d-flex me-3 inrecipeout">
                        <img className="me-3" src={Images.typeicon} alt="" />
                        <div className="">
                          <p className="subtext m-0 medText">Type</p>
                          <p className="subtext m-0">
                            {getDetails?.category_title}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex me-3 inrecipeout">
                        <img className="me-3" src={Images.clockicon} alt="" />
                        <div className="">
                          <p className="subtext m-0 medText">Cooking Time </p>
                          <p className="subtext m-0">
                            {getDetails?.cooking_time} Min
                          </p>
                        </div>
                      </div>
                      <div className="d-flex me-3 inrecipeout">
                        <img className="me-3" src={Images.servingicon} alt="" />
                        <div className="">
                          <p className="subtext m-0 medText">Serving</p>
                          <p className="subtext m-0">{getDetails?.serving}</p>
                        </div>
                      </div>
                      <div className="d-flex me-3 inrecipeout">
                        <img
                          className="me-3"
                          src={Images.celendericon}
                          alt=""
                        />
                        <div className="">
                          <p className="subtext m-0 medText">Created At</p>
                          <p className="subtext m-0">
                            {moment(getDetails?.createdAt).format("YYYY-MM-DD")}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex me-3 inrecipeout">
                        <img className="me-3" src={Images.usericon} alt="" />
                        <div className="">
                          <p className="subtext m-0 medText">by</p>
                          <p className="subtext m-0">
                            {/* {getDetails?.created_by_name} */}
                            Lynnette
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {getDetails?.thumbnail_image ? (
                  <div className="py-4 recipebgimg">
                    <img
                      src={
                        getDetails?.thumbnail_image
                          ? getDetails.thumbnail_image
                          : Images.usericon
                      }
                      alt="img"
                      className="img-fluid w-100"
                    />
                  </div>
                ) : (
                  <Spinner
                    className="pageLoader"
                    animation="grow"
                    size="lg"
                    role="status"
                    aria-hidden="true"
                  />
                )}

                <p className="text">
                  {getDetails?.description
                    ? getDetails?.description
                    : " Dummy Data"}
                </p>
                <div className="IngredientsBox">
                  <div className="p-4">
                    <div className="d-flex align-items-center">
                      <img src={Images.dinner} alt="" />
                      <p className="medText m-0 ms-3">Ingredients</p>
                    </div>
                    <ul className="IngredientsList p-0 mt-4">
                      {getDetails?.ingredient?.map((data, idx) => {
                        return (
                          <li key={idx} className="text">
                            {data}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <div className="IngredientsBox">
                  <div className="p-4">
                    <div className="d-flex align-items-center">
                      <img src={Images.nutrition} alt="" />
                      <p className="medText m-0 ms-3">Nutrition</p>
                    </div>
                    <ul className="IngredientsList p-0 mt-4">
                      {getDetails?.nutrition?.map((data, idx) => {
                        return (
                          <li key={idx} className="text">
                            {data}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <div className="my-4">
                  <h5 className="medText">Directions</h5>
                  <p className="text">{getDetails?.direction}</p>
                  <div className="stepsOuter">
                    {getDetails?.direction_step?.map((steps, index) => {
                      return (
                        <div
                          key={index}
                          className="d-flex justify-content-between recipeStepTextBox"
                        >
                          <div className="stepOuterInner">
                            <div className="position-relative">
                              <img src={Images.stepbg} alt="" />
                              <p className="subtext stepText colorWhite">
                                Step{index + 1}
                              </p>
                            </div>
                            <div className="ms-4 me-4">
                              <img src={Images.stepcircle} />
                            </div>
                          </div>
                          <div className="col-md-10">
                            <p className="text mb-0 pe-md-5">{steps}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <h5 className="medText py-3">Recipe Video</h5>

                  <div
                    className={`  py-3  previewVedio ${
                      getDetails?.recipe_video ? "bgPreviewVideo" : ""
                    }`}
                  >
                    {Boolean(getDetails?.recipe_video) ? (
                      <>
                        <div className="vedioOuter videOter_">
                          {isVideoPlayed === false ? (
                            <div className="playVedio">
                              <img
                                src={Images.vedioicon}
                                onClick={() => handlePlayVideo()}
                                alt="Play Video"
                              />
                            </div>
                          ) : (
                            <img
                              className="pausedIcon"
                              src={Images.paused}
                              onClick={() => handlePauseVideo()}
                              alt="Pause Video"
                            />
                          )}
                          <video
                            onPause={() => {
                              setIsVideoPlayed(false);
                            }}
                            onPlay={() => {
                              setIsVideoPlayed(true);
                            }}
                            ref={vidRef}
                            poster={getDetails?.recipe_image}
                            className="img-fluid w-100 vedio"
                            controls
                            autoPlay
                            loop
                          >
                            <source src={getDetails?.recipe_video} />
                          </video>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="borderBox">
                    <hr className="borderLine"></hr>
                  </div>
                  <div className="commentOnrecipeBox mt-5">
                    <div className="flexBox justify-content-between align-items-center">
                      <article>
                        <h6 className="commentsHeading">Comments on Recipe </h6>
                        <p className="commentCount">
                          {totalComments
                            ? `${totalComments} Comments posted by users`
                            : "No Comments posted yet"}
                        </p>
                      </article>
                      <div className="flexBox">
                        <article className="align-items-center">
                          <button type="button" className="ratingButton">
                            Rating
                            <img
                              src={Images.star}
                              alt="star"
                              className="buttonStar"
                            />
                            <span className="ratingValue">
                              {getDetails?.rating?.toFixed(2)}
                            </span>
                          </button>
                        </article>
                        <figure className="m-0">
                          <img
                            src={Images.commeticon}
                            alt="commeticon"
                            className="img-fluid commeticon"
                          />
                        </figure>
                      </div>
                    </div>
                  </div>
                  <div key={commentKey}>
                    {commentList?.map((data, index) => {
                      return (
                        <>
                          <div className="aboutus_Anna">
                            <div className="profile_outer">
                              <img
                                className="img-fluid"
                                src={
                                  data?.created_by?.profile_image
                                    ? data?.created_by?.profile_image
                                    : Images.defaultUser
                                }
                                //src={Images.heart}
                                alt="Anna"
                              />
                              <div className="ms-1 me-1 d-flex w-100 align-items-center">
                                <div className="me-1">
                                  <div className="classdetailText">
                                    <p className="DefaultPara medText m-0">
                                      {data?.created_by?.full_name}
                                    </p>
                                    <p className="subtext m-0">
                                      {moment(data?.createdAt).format(
                                        "MMMM D, YYYY"
                                      )}
                                    </p>
                                  </div>
                                </div>
                                <div>
                                  <ReactStars
                                    count={5}
                                    value={data?.rate}
                                    size={24}
                                    activeColor="#ffd700"
                                    edit={false}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="ReportsOuter">
                              <div className="Readme_button">
                                <button
                                  className="reply_button subtext colorPink "
                                  onClick={() => handleShowInput(data?.id)}
                                >
                                  Reply
                                  <img
                                    className="ms-2"
                                    src={Images.rotatearrowpink}
                                    alt=""
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div>
                            <p className="text">{data?.review}</p>
                          </div>
                          <div className="larrow_">
                            {/* Replied comments */}
                            {data?.comments?.map((replied, i) => {
                              if (i < data?.itemToShow)
                                return (
                                  <>
                                    <img src={Images.aboutusLarrow} alt="" />
                                    <div className="aboutus_leena">
                                      <div className="leena_Profile">
                                        <div className="d-flex align-items-center">
                                          <div className="profile_outer">
                                            <img
                                              className="img-fluid"
                                              src={
                                                replied?.created_by
                                                  ?.profile_image
                                                  ? replied?.created_by
                                                      ?.profile_image
                                                  : Images.defaultUser
                                              }
                                              //src={Images.heart}
                                              alt="Anna"
                                            />
                                          </div>
                                          <div className="ms-3 me-3 replyComments">
                                            <p className="DefaultPara medText m-0">
                                              {replied?.created_by?.full_name}
                                            </p>
                                            <p className="subtext m-0">
                                              {moment(
                                                replied?.createdAt
                                              ).format("MMMM D, YYYY")}
                                            </p>
                                          </div>
                                        </div>
                                        <p className="text replyCommentText">
                                          {replied?.comments}
                                        </p>
                                      </div>
                                      {replied?.created_by?.role === 1 && (
                                        <div
                                          onClick={() =>
                                            handleRemoveComment(replied?.id)
                                          }
                                          className="anna_Report_Outer remove-comment-outer"
                                        >
                                          <img
                                            alt="remove-comment"
                                            src={Images.removeCommentLogo}
                                          />
                                          <span className="remove-comment-text">
                                            Remove
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  </>
                                );
                            })}
                            {data.itemToShow < data?.comments?.length ? (
                              <div
                                onClick={() => handleViewMore(index)}
                                style={{
                                  color: "black",
                                  fontWeight: 500,
                                  cursor: "pointer",
                                }}
                              >
                                View more
                              </div>
                            ) : null}
                            {showCommentBox && userId == data?.id && (
                              <form>
                                <p className="DefaultPara medText">Comment</p>
                                <div className="container p-0">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <textarea
                                        className="contactUsforminput_field contactusTextarea"
                                        type="text"
                                        placeholder="Your Comment"
                                        rows="4"
                                        cols="50"
                                        value={replyComments}
                                        onChange={(e) =>
                                          setReplyComments(
                                            e.target.value.trimStart()
                                          )
                                        }
                                      ></textarea>
                                    </div>
                                  </div>
                                  <div className="mt-4">
                                    <button
                                      className="login-btn"
                                      type="button"
                                      onClick={() =>
                                        handlePostCommentsReply(data?.id, index)
                                      }
                                    >
                                      Post Comment
                                      {loadingSendReplyComment && (
                                        <Spinner
                                          as="span"
                                          animation="border"
                                          size="sm"
                                          role="status"
                                          aria-hidden="true"
                                          className="ms-1"
                                        />
                                      )}
                                    </button>
                                    <span className="center-img">
                                      <img
                                        src={Images.centerrectangle}
                                        alt=""
                                        onClick={() =>
                                          handlePostCommentsReply(
                                            data?.id,
                                            index
                                          )
                                        }
                                      />
                                    </span>
                                    <span className="circle-btn">
                                      <img
                                        src={Images.arrow}
                                        alt=""
                                        onClick={() =>
                                          handlePostCommentsReply(
                                            data?.id,
                                            index
                                          )
                                        }
                                      />
                                    </span>
                                  </div>
                                </div>
                              </form>
                            )}
                          </div>
                        </>
                      );
                    })}
                  </div>

                  {commentList?.length > 3 &&
                  totalCommentsrecords != commentList?.length ? (
                    <div
                      style={{
                        color: "black",
                        fontWeight: 500,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        getAllComments(parseInt(page) + 1, getDetails?.id);
                      }}
                    >
                      View More
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <CustomModal
          key={key}
          show={modalDetail.show}
          backdrop="static"
          showCloseBtn={false}
          isRightSideModal={false}
          mediumWidth={false}
          className={modalDetail.flag === "Delete" ? "delete" : ""}
          child={
            modalDetail.flag === "Delete" ? (
              <Deleterecipe
                closeModal={() => {
                  handleOnCloseModal();
                }}
                refreshData={() => recipeDetails()}
                typeId={typeId}
                userId={modalDetail?.id}
                Recipetitle={modalDetail?.Recipetitle}
              />
            ) : (
              ""
            )
          }
          onCloseModal={() => handleOnCloseModal()}
        />
      </div>
    </>
  );
};

export default DetailRecipe;
