import React, { useState, useEffect } from "react";
import * as Images from "../../../../utilities/images";
import { Link, useNavigate } from "react-router-dom";
import DeletePlan from "./deleteplan";
import CustomModal from "../../../components/modals/modal";
import { useDispatch } from "react-redux";
import { getPlans } from "../../../../redux/slices/admin";
import { Spinner } from "react-bootstrap";

const Addplan = () => {
  const [key, setKey] = useState(Math.random());
  const [getPlan, setGetPlans] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const getAllPlans = () => {
    dispatch(
      getPlans({
        cb(res) {
          if (res?.data) {
            setGetPlans(res?.data?.data);
          } else {
          }
        },
      })
    );
  };

  useEffect(() => {
    getAllPlans();
    window.scrollTo(0, 0);
    document.title = "Plans";
  }, []);

  const handleDeletePlans = (planId) => {
    setModalDetail({
      show: true,
      title: "DeletePlan",
      flag: "Delete",
      id: planId,
    });
    setKey(Math.random());
  };

  const handleEditRecipe = (id) => {
    if (id) {
      navigate(`/admin/editPlan/${id}`);
    }
  };
  const showAddPlan = getPlan?.map((data) => data);
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="tab-content" id="pills-tabContent">
            <div className="tab-pane fade active show" id="pills-home">
              <div className="contentCommon mb-2">
                <div className="searchDataTable">
                  <div className="d-flex align-items-center justify-content-end w-100">
                    <div className="">
                      {/* <Link to={`/admin/addrecipe/${catId}`}><button className="login-btn">Add Recipes</button> */}
                      {showAddPlan?.length < 2 ? (
                        <Link to={"/admin/addplan"}>
                          <button className="login-btn">Add Plan</button>
                          <span className="center-img">
                            <img src={Images.centerrectangle} alt="" />
                          </span>
                          <span className="circle-btn recipeBtnCircle ">
                            <img
                              className="img-fluid"
                              src={Images.plussign}
                              alt=""
                            />
                          </span>
                        </Link>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                {getPlan?.length > 0 ? (
                  getPlan?.map((data, index) => {
                    return (
                      <div
                        key={index}
                        className="courseCardCon courseCardConRecipe"
                      >
                        <div className="courseCard mt-3">
                          <div className="courseDetails">
                            <div className="courseDetailsInner">
                              <ul className="courseDurationDetials">
                                <li className="title planTitle">
                                  <p className="boldText">&nbsp; Title</p>
                                  <h6 className="ms-2 lightText ">
                                    {data?.nickname}
                                  </h6>
                                </li>
                                <li className="discription">
                                  <p className=" boldText">
                                    {" "}
                                    &nbsp;Description
                                  </p>
                                  <h6 className="ms-1 pararecipe lightText">
                                    {data?.metadata?.description}
                                  </h6>
                                </li>
                                <li className="amount">
                                  <p className="boldText">&nbsp; Amount</p>
                                  <h6 className="ms-2 lightText">
                                    {data?.amount / 100}
                                  </h6>
                                </li>
                                <li className="interval">
                                  <p className="boldText"> &nbsp; Interval</p>
                                  <h6 className="ms-2 lightText">
                                    {data?.interval}
                                  </h6>
                                </li>
                                {/* <li>
                            <p> &nbsp; isPopuler</p>
                            <h6 className="ms-2">{data?.metadata?.is_popular==1 ? "True" : "False" }</h6>
                          </li> */}
                              </ul>
                            </div>

                            <div className="rightArrow dropdown rightDots">
                              <img
                                src={Images.threedot}
                                alt=""
                                className="dots dropdown-toggle"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              />

                              <ul className="dropdown-menu courseDrpodown PlanDropdown">
                                {/* </li> */}
                                <li
                                  className="w-100"
                                  onClick={() => handleEditRecipe(data?.id)}
                                >
                                  <a className="dropdown-item text_ innereditxt ms-1">
                                    <img
                                      src={Images.editpendark}
                                      alt="icon"
                                      className="me-2"
                                    />{" "}
                                    Edit
                                  </a>
                                </li>
                                <li
                                  className="w-100"
                                  onClick={() => handleDeletePlans(data?.id)}
                                >
                                  <a
                                    className="dropdown-item text_ innereditxt"
                                    href="#"
                                  >
                                    {" "}
                                    <img
                                      src={Images.trash}
                                      alt="icon"
                                      className=" ms-1 me-2"
                                      style={{ maxWidth: "18px" }}
                                    />{" "}
                                    Delete
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <>
                    {getPlan?.length == 0 ? (
                      <h3 className="noDataText">No Data Found</h3>
                    ) : (
                      <Spinner
                        className="pageLoader"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <CustomModal
          key={key}
          show={modalDetail.show}
          backdrop="static"
          showCloseBtn={false}
          isRightSideModal={false}
          mediumWidth={false}
          className={modalDetail.flag === "Delete" ? "delete" : ""}
          child={
            modalDetail.flag === "Delete" ? (
              <DeletePlan
                closeModal={() => {
                  handleOnCloseModal();
                }}
                planId={modalDetail?.id}
                refreshData={() => {
                  getAllPlans();
                }}
              />
            ) : (
              ""
            )
          }
          onCloseModal={() => handleOnCloseModal()}
        />
      </div>
    </>
  );
};

export default Addplan;
