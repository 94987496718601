import { combineReducers } from "@reduxjs/toolkit";
import { authSlice } from "./auth";
import { userSlice } from "./user";
import {adminSlice} from "./admin";
import { socketSlice } from "./socket";

export const mainReducer = combineReducers({
    auth: authSlice.reducer,
    user:userSlice.reducer,
    admin:adminSlice.reducer,
    socket:socketSlice.reducer,
})