import { Link, useNavigate } from "react-router-dom";
import * as Images from "../../../utilities/images";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  loginUserProfile,
  updateProfile,
  uploadPicture,
} from "../../../redux/slices/user";
import { toast } from "react-toastify";
import moment from "moment";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { Spinner } from "react-bootstrap";

const UpdateProfile = () => {
  const [profilePicOld, setprofilePicOld] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setloading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    email: "",
  });
  const introSchema = Yup.object().shape({
    first_name: Yup.string()
      .matches(/^[a-zA-Z]+$/, "First name should only contain letters.")
      .min(2, "First name is Too Short!")
      .max(25, "First name is Too Long!")
      .required("First name is required!"),
    last_name: Yup.string()
      .matches(/^[a-zA-Z]+$/, "Last name should only contain letters.")
      .min(2, "Last name is Too Short!")
      .max(25, "Last name is Too Long!")
      .required("Last name is required!"),
  });

  const onSubmit = (values) => {
    let params = {
      first_name: values?.first_name,
      last_name: values?.last_name,
      email: values?.email,
    };
    setloading(true);
    dispatch(
      updateProfile({
        ...params,
        cb(res) {
          setloading(false);
          if (res?.data?.status_code == 400) {
            setErrorMessage({ error: res.data.message });
            return false;
          }
          if (res?.data?.status_code == 200) {
            setErrorMessage("");
            getUser();
            navigate("/viewEdit");
            toast.success("Profile Updated Successfully");
          } else {
          }
        },
      })
    );
  };

  const getUser = () => {
    dispatch(
      loginUserProfile({
        cb(res) {
          if (res) {
            const { first_name, last_name, email } = res.data.data;
            setData({
              first_name,
              last_name,
              email,
            });
            setprofilePicOld(res?.data?.data?.profile_image);
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };

  const onUploadPicture = (e) => {
    if (e.target.files?.length <= 0) return;

    const isImageValid = /\.(gif|jpeg|tiff?|png|jpg|PNG|JPEG)$/i.test(
      e.target.files[0].name
    );

    if (isImageValid === false) {
      toast.error("Please select valid image format");
      return;
    }
    let params = {
      image: e.target.files[0],
    };

    dispatch(
      uploadPicture({
        ...params,
        cb(res) {
          if (res.data) {
            getUser();
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };

  useEffect(() => {
    getUser();
    window.scrollTo(0, 0);
    document.title = "Update-Profile";
  }, []);
  return (
    <>
      <section className="loginpage">
        <div className="container-fluid">
          <div className="row item-center flex-row">
            <div className=" col-lg-5 col-md-6">
              <div className="logininner changePasswordOuter">
                <div className="header-logo">
                  <Link to="/viewEdit">
                    <img src={Images.logo} alt="" />
                  </Link>
                </div>
                <div className="loginForm">
                  <Link className="colorpink" to="/viewEdit">
                    <img className="backicon" src={Images.backarrow} alt="" />
                    Go Back
                  </Link>
                  <h1 className="heading py-3">Edit Profile</h1>
                  <div className="updateProfileImg userproImg">
                    <img
                      className="img-fluid editProfileBox"
                      src={
                        profilePicOld ? profilePicOld : Images.dummyAdminlogo
                      }
                      alt=""
                    />

                    <label className="choosenBtn">
                      <div className="updateProfileInner">
                        <img src={Images.pencil} alt="" />
                        <input
                          type="file"
                          id="profileImg"
                          name="image"
                          className="form-control d-none"
                          onChange={onUploadPicture}
                          accept="image/png, image/gif, image/jpeg"
                        />
                      </div>
                    </label>
                  </div>
                </div>
                <div className="">
                  <p className="subtext copyrightText pb-3">
                    © copyright 2023. All rights reserved
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-7 p-0">
              <div className="imgouter editProfile">
                <Formik
                  initialValues={{ ...data }}
                  validationSchema={introSchema}
                  enableReinitialize
                  onSubmit={onSubmit}
                >
                  {({ errors, touched }) => (
                    <Form className="adminProfileForm">
                      <div>
                        <button
                          className="login-btn editProfileBtn"
                          type="submit"
                        >
                          Update
                          {loading && (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              className="ms-1"
                            />
                          )}{" "}
                        </button>

                        <div className="formTabs">
                          <div className="header-logo">
                            <Link to={"/"}>
                              <img src={Images.logo} alt="" />
                            </Link>
                          </div>
                          <div className="loginForm">
                            <Link className="colorpink" to="/viewEdit">
                              <img
                                className="backicon"
                                src={Images.backarrow}
                                alt=""
                              />
                              Go Back
                            </Link>
                            <h1 className="heading py-3">Edit Profile</h1>

                            <div className="updateProfileImg my-4 updateProfileImg-smalll">
                              <img
                                className="img-fluid"
                                src={
                                  profilePicOld
                                    ? profilePicOld
                                    : Images.dummyAdminlogo
                                }
                                alt=""
                              />

                              <label className="choosenBtn">
                                <div className="updateProfileInner">
                                  <img src={Images.pencil} alt="" />
                                  <input
                                    type="file"
                                    id="profileImg"
                                    name="image"
                                    className="form-control d-none"
                                    onChange={onUploadPicture}
                                    accept="image/png, image/gif, image/jpeg"
                                  />
                                </div>
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6">
                            <div className="mb-4 form-group updateFormGroup">
                              <label className="text">First Name</label>

                              <Field
                                name="first_name"
                                placeholder="Update First Name"
                                id="first_name"
                                autoComplete="off"
                                className="form-control mb-2"
                              />
                              {errors.first_name && touched.first_name ? (
                                <div className="validationMsg">
                                  {errors.first_name}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-4 form-group updateFormGroup">
                              <label className="text">Last Name</label>

                              <Field
                                name="last_name"
                                placeholder="Update Last Name "
                                id="last_name"
                                autoComplete="off"
                                className="form-control mb-2"
                              />
                              {errors.last_name && touched.last_name ? (
                                <div className="validationMsg">
                                  {errors.last_name}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="mb-4 form-group updateFormGroup emailBox">
                          <label className="text">Email</label>

                          <Field
                            name="email"
                            placeholder="Update LastName Name"
                            id="email"
                            autoComplete="off"
                            className="form-control mb-2"
                            disabled
                          />
                        </div>
                        {errorMessage ? (
                          <span
                            className={
                              errorMessage?.error ? "validationMsg" : "d-none"
                            }
                          >
                            {errorMessage?.error ? errorMessage?.error : ""}
                          </span>
                        ) : (
                          ""
                        )}
                        <div className="copyrightBottom">
                          <p className="subtext copyrightText pb-3">
                            © copyright{moment().year()}. All rights reserved
                          </p>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default UpdateProfile;
