import "./App.css";
import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "./routes";
import "./public/css/custom.css";
import "./public/css/customNew.css";
import "./public/css/login.css";
import "./public/css/responsive.css";
import "./public/css/adminProfile.css";
import "./public/css/adminCustom.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import store from "./redux";
import { persistor } from "./redux";
import { PersistGate } from "redux-persist/integration/react";
import "swiper/css";
import "swiper/css/navigation";
// import runOneSignal from "./utilities/oneSignal";
import { socketInstance } from "./app/components/common/socketNew";

function App() {
  const getRoomId = store?.getState()?.admin?.goLive?.data;

  // useEffect(() => {
  //   runOneSignal();
  // }, []);

  useEffect(() => {
    if (
      window.location.pathname !== "joinStreaming" &&
      window.location.pathname !== "liveStreamingUser" &&
      store?.getState()?.auth?.userInfo?.userInfo?.role === "user"
    ) {
      socketInstance.emit("Livestreaming_end_for_all", {});
      socketInstance.on("Livestreaming_end_for_all", (response) => { });
    } else if (
      window.location.pathname !== "/admin/goLive" &&
      store?.getState()?.auth?.userLogin?.data?.user?.role === 1
    ) {
      socketInstance.emit("endliveStreaming", {
        room_id: getRoomId?.roomID,
      });
      socketInstance.on("endliveStreaming", (data) => { });
      const handleUnload = (event) => {
        event.preventDefault();
        event.returnValue = "";
        socketInstance.emit("endliveStreaming", {
          room_id: getRoomId?.roomID,
        });
        socketInstance.on("endliveStreaming", (data) => { });
      };
      return () => {
        window.removeEventListener("beforeunload", handleUnload);
      };
    }
  }, []);

  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </PersistGate>
      </Provider>
      <ToastContainer />
    </>
  );
}

export default App;
