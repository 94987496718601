import React, { useEffect } from "react";
import * as Images from "../../../utilities/images";
import { Link } from "react-router-dom";

const EditMyProfile = () => {
  useEffect(()=>{
    window.scrollTo(0, 0);
    document.title = "My-Profile";
  },[])
  return (
    <>
      <div className="contentCommon ">
        <div className="space">
          <div className="cart_max_inner mb-3 mt-4">
            <div className="cart_max_child text-center py-4">
              <div className="cart_img dropdown mb-3">
                <img
                  src={Images.userimg1}
                  className="img-fluid profileBig mx-0"
                  alt="user img"
                />
                <img
                  src={Images.editimg}
                  alt=""
                  className="profile_edit dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                ></img>
                <ul className="dropdown-menu updateProfileDropdown">
                  <li>
                    <Link className="dropdown-item" to="#">
                      {" "}
                      <img src={Images.Login} alt="img" className="me-2" />{" "}
                      Upload New Image
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="#">
                      <img src={Images.Login} alt="Remove" className="me-2" />{" "}
                      &nbsp;Remove
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="row justify-content-center mt-4">
                <div className="col-md-6">
                  <form action="" method="" className="profileForm">
                    <div className="row ">
                      <div className="mb-5 form-group col-md-6">
                        <input
                          className="form-control text"
                          type="text"
                          placeholder="Alex"
                          name=""
                          // value=""
                        />
                        <img className="inputIcon" src={Images.profile} alt=""/>
                      </div>
                      <div className="mb-5 form-group col-md-6">
                        <input
                          className="form-control text"
                          type="text"
                          placeholder="Hamilton"
                          name=""
                          // value=""
                        />
                          <img className="inputIcon" src={Images.profile} alt=""/>
                      </div>
                      <div className="mb-3 form-group col-md-12">
                        <input
                          type="email"
                          className="form-control text"
                          name=""
                          placeholder="admin@yopmail.com"
                        
                        />
                          <img className="inputIcon" src={Images.mail} alt=""/>
                      </div>
                    </div>
                    <div className="row justify-content-center mt-4">
                      <div className="d-grid gap-1 col-lg-3 col-md-6">
                        <button
                          className="primaryBtn cancelBtn m-0"
                          type="submit"
                        >
                          <span>Cancel</span>
                        </button>
                      </div>
                      <div className="d-grid gap-1 col-lg-3 col-md-6">
                        <button
                          className="primaryBtn submitBtn m-0"
                          type="submit"
                        >
                          <span>Update</span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
      

  )
}

export default EditMyProfile
