import { useFormik } from "formik";
import moment from "moment-timezone";
import React, { useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import ReactStars from "react-rating-stars-component";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import swal from "sweetalert";
import "sweetalert2/dist/sweetalert2.min.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import * as Yup from "yup";
import { useAuthSelector } from "../../../redux/selector/auth";
import { useUserSelector } from "../../../redux/selector/user";
import { getRecipeDetails } from "../../../redux/slices/admin";
import {
  addRating,
  createLike,
  favRecipe,
  getAllreviews,
  replyComment,
} from "../../../redux/slices/user";
import * as Images from "../../../utilities/images";
import CustomModal from "../../components/modals/modal";
import ReportModal from "./reportmodal";

const Recipedetailpage = () => {
  const { id } = useParams();
  const myRef = useRef(null);
  const vidRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authUserSelector = useUserSelector();
  const userInfo = authUserSelector?.loginUserProfile?.data;
  const [rate, setRate] = useState(1);
  const [rateKey, setRateKey] = useState(Math.random());
  const [commentList, setCommentsList] = useState([]);
  const [totalCommentsrecords, setTotalCommentRecords] = useState("");
  const [commentKey, setCommentKey] = useState(Math.random());
  const [page, setPage] = useState(1);
  const [totalComments, setTotalComments] = useState("");
  const [replyComments, setReplyComments] = useState("");
  const [showCommentBox, setShowCommentBox] = useState(false);
  const [getDetails, setGetDetails] = useState([]);
  const [isVideoPlayed, setIsVideoPlayed] = useState(false);
  const [key, setKey] = useState(Math.random());
  const [userId, setUserById] = useState("");
  const [recommendedRecipe, setRecommendedRecipe] = useState([]);
  const authSelector = useAuthSelector();
  const infoUser = authSelector?.userLogin?.data?.user;
  const [errorMessage, setErrorMessage] = useState("");
  const [loadingSendReview, setLoadingSendReview] = useState(false);
  const [loadingSendReplyComment, setLoadingSendReplyComment] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const location = useLocation();
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const handlePlayVideo = () => {
    setIsVideoPlayed(true);
    vidRef.current.play();
  };
  const handlePauseVideo = () => {
    setIsVideoPlayed(false);
    vidRef.current.pause();
  };

  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
  };

  const recipeDetails = () => {
    let params = {
      id: id,
    };
    dispatch(
      getRecipeDetails({
        ...params,
        cb(res) {
          if (res?.data?.data?.subscription == false) {
            handleShowAlert();
          }
          if (res?.data) {
            setGetDetails(res?.data?.data);
            setRecommendedRecipe(res?.data?.data?.recommended_recipes);
            getAllComments(page, res?.data?.data?.id);
          }
        },
      })
    );
  };

  //commentsListing
  const getAllComments = (pageCmt = page) => {
    let params = {
      page: pageCmt,
      limit: 4,
      model_id: id,
      model_type: 1,
    };
    dispatch(
      getAllreviews({
        ...params,
        cb(res) {
          if (res?.data) {
            if (parseInt(res?.data?.data?.current_page) == 1) {
              setCommentsList(handleComentReplies(res?.data?.data?.list));
              setTotalCommentRecords(res?.data?.data?.totalRecords);
            } else {
              setCommentsList((commentList) => {
                const cmt = [...commentList, ...res?.data?.data?.list];
                return cmt;
              });
            }

            setTotalComments(res?.data?.data?.totalRecords);
            setCommentKey(Math.random());
            setPage(res?.data?.data?.current_page);
          } else {
          }
        },
      })
    );
  };

  const handleComentReplies = (comment) => {
    let _comment = [];
    comment.map((data) => {
      _comment.push({ ...data, itemToShow: 2 });
    });
    return _comment;
  };

  const handleViewMore = (i) => {
    let _commentList = [...commentList];
    _commentList[i].itemToShow += 2;
    setCommentsList(_commentList);
  };

  const handleSubmitLike = (status) => {
    let params = {
      recipe_id: getDetails?.id,
      status: status,
    };
    dispatch(
      createLike({
        ...params,
        cb(res) {
          if (res.status) {
            recipeDetails();
            //toast.success("You Liked a recipe")
          } else {
            //recipeDetails()
          }
        },
      })
    );
  };

  const handleViewRecipe = (data) => {
    if (userInfo?.isSubscribe == false && data?.access_level == 2) {
      handleShowAlert();
      return;
    } else if (data?.id) {
      recipeDetails();
      navigate(`/recipedetailpage/${data?.id}`);
    }
  };

  const handleShowAlert = () => {
    setShowAlert(true);
    swal({
      title: "Add subscription",
      text: "Kindly add subscription for view recipies",
      icon: "info",
      className: "mySwalClass",
      // buttons: true,
      buttons: {
        confirm: {
          text: "Add",
          value: true,
          visible: true,
        },
        cancel: {
          text: "Cancel",
          value: false,
          visible: true,
        },
      },
    }).then((willDelete) => {
      if (willDelete) {
        navigate("/addsubscription");
      }
    });
  };

  const formik = useFormik({
    initialValues: {
      review: "",
    },
    validationSchema: Yup.object({
      review: Yup.string().required("Please add a review"),
    }),
    onSubmit: (values) => {
      let params = {
        model_id: getDetails?.id,
        model_type: 1,
        rate: Number(rate),
        review: values?.review,
      };
      setLoadingSendReview(true);
      dispatch(
        addRating({
          ...params,
          cb(res) {
            setLoadingSendReview(false);
            if (res?.data?.status_code == 400) {
              setErrorMessage({ error: res.data.message });
              return false;
            }
            if (res?.data?.status_code == 200) {
              setErrorMessage("");
              setRate(0);
              formik.resetForm();
              getAllComments(1, id);
              recipeDetails();
              setRateKey(Math.random());
            } else {
            }
          },
        })
      );
    },
  });

  const handlePostCommentsReply = (id, index) => {
    if (!replyComments) {
      toast.error("Add a comment");
      return;
    }
    let params = {
      parent_id: id,
      comments: replyComments,
    };
    setLoadingSendReplyComment(true);
    dispatch(
      replyComment({
        ...params,
        cb(res) {
          setLoadingSendReplyComment(false);
          if (res.status) {
            setShowCommentBox(false);
            setReplyComments("");
            setCommentsList((commentList) => {
              var newData = [...commentList];
              const newComments = [
                res.data.data,
                ...commentList[index].comments,
              ];
              newData[index] = {
                ...newData[index],
                comments: newComments,
              };

              return newData;
            });
          }
        },
      })
    );
  };

  const handleShowInput = (id) => {
    setShowCommentBox(true);
    setUserById(id);
  };

  const handleFav = (e) => {
    e.preventDefault();
    let params = {
      recipe_id: getDetails?.id,
    };
    dispatch(
      favRecipe({
        ...params,
        cb(res) {
          if (res.status) {
            recipeDetails();
          }
        },
      })
    );
  };

  const handleFavReccomendedRecipe = (id, isFav) => {
    let params = {
      recipe_id: id,
    };
    dispatch(
      favRecipe({
        ...params,
        cb(res) {
          if (res.status === 200) {
            const recipesCopy = [...recommendedRecipe];
            const recipeIndex = recipesCopy.findIndex((x) => x.id === id);
            if (recipeIndex >= 0) {
              let favRecipe = recipesCopy[recipeIndex];
              favRecipe = {
                ...favRecipe,
                is_favorite: isFav,
              };
              recipesCopy[recipeIndex] = favRecipe;
              setRecommendedRecipe(recipesCopy);
            }
            // recipeDetails();
          }
        },
      })
    );
  };
  const handleClickDown = () => {
    window.scrollTo({ top: 10000, behavior: "smooth" });
  };
  const ratingChanged = (value) => {
    setRate(value);
  };
  useEffect(() => {
    if (location?.state?.recipeComntFlag == true) {
      myRef?.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    } else {
      window.scrollTo(0, 0);
    }
    recipeDetails();
    document.title = "Recipe-Details";
  }, [id]);

  return (
    <>
      <section className="detailrecipe favoritesBanner pb-2">
        <div>
          {/* <button onClick={handleShowAlert}>Show SweetAlert</button> */}
          {showAlert && <div>Alert is visible!</div>}
        </div>
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-md-8 text-center pt-0 m-auto">
              <Link to="/recipes">
                <p className="pt-5 text mt-4">
                  Recipe<i class="fa fa-angle-right ms-2"></i>
                  <i class="fa fa-angle-right me-2"></i>
                  <span className="colorPink">{getDetails?.title}</span>{" "}
                </p>
              </Link>
              {!getDetails?.thumbnail_image && (
                <Spinner
                  className="pageLoader"
                  animation="grow"
                  size="lg"
                  role="status"
                  aria-hidden="true"
                />
              )}
              <h2 className="bigHeading pt-3 ">{getDetails?.title}</h2>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="perfectbreakfast">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div>
                  <div className="bc_mainimage recipe-bgmain">
                    {getDetails?.thumbnail_image ? (
                      <div>
                        <img
                          src={getDetails?.thumbnail_image}
                          className="img-fluid Aboutus_foodimage w-100"
                          alt="AboutUsImage"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="ratinglike_Options">
                      <div className="AboutUs_Startrating ">
                        <Link className="Aboutus_star" href="#">
                          <img
                            className=" img-fluid Aboutus_Star me-2"
                            src={Images.yellowstar}
                            alt="startRating"
                          />
                          {getDetails?.rating > 0 && (
                            <span className="text colorRed">
                              {getDetails?.rating?.toFixed(1)}
                            </span>
                          )}
                        </Link>
                      </div>
                      <div className="AboutUs_likeComments">
                        <div
                          className="AboutUs_likecomment_show text colorRed"
                        //onClick={(e) => handleSubmitLike(e)}
                        >
                          {getDetails?.is_like == 1 ? (
                            <img
                              className="img-fluid"
                              src={Images.likefillicon}
                              alt="AboutUs_Likeimg"
                            />
                          ) : (
                            <img src={Images.unfilled} alt="AboutUs_Likeimg" />
                          )}

                          {getDetails?.like_count}
                        </div>

                        <div className="AboutUs_likecomment_show text colorRed">
                          <Link href="#">
                            <img
                              src={Images.commeticon}
                              alt="Aboutus_comments"
                            />
                          </Link>
                          {getDetails?.comments_count}
                        </div>
                        <div className="AboutUs_likecomment_show text colorRed">
                          <Link href="#">
                            <img src={Images.pinkeye} alt="eyeicon_img" />
                          </Link>
                          {getDetails?.view_count}
                        </div>
                      </div>
                      {getDetails?.is_favorite == true ? (
                        <div className="Aboutus_heartimg">
                          <Link href="#">
                            <img
                              className="AboutUs_hearticon fillHeart"
                              src={Images.Vector}
                              alt="eyeicon_img"
                              onClick={(e) => handleFav(e)}
                            />
                          </Link>
                        </div>
                      ) : (
                        <div className="Aboutus_heartimg">
                          <Link href="#">
                            <img
                              className="AboutUs_hearticon"
                              src={Images.pinkheart}
                              alt="eyeicon_img"
                              onClick={(e) => handleFav(e)}
                            />
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="d-flex align-items-center py-5 cookingOut">
                  <div className="d-flex cookingOuter">
                    <img
                      className="Aboutus_clockcook_showimg"
                      src={Images.clockicon}
                      alt="clock_img"
                    />
                    <div className="ms-3">
                      <p className="subtext medText mb-0">Cooking Time </p>
                      <p className="subtext mb-0">
                        {getDetails?.cooking_time} Min
                      </p>
                    </div>
                  </div>
                  <div className="d-flex ms-5 cookingOuter">
                    <img
                      className="Aboutus_clockcook_showimg"
                      src={Images.servingicon}
                      alt="servingimg"
                    />
                    <div className="ms-3">
                      <p className="subtext medText mb-0">Serving</p>
                      <p className="subtext mb-0">{getDetails?.serving}</p>{" "}
                    </div>
                  </div>
                  <div className="d-flex ms-5 cookingOuter">
                    <img
                      className="Aboutus_clockcook_showimg"
                      src={Images.usericon}
                      alt="user_img"
                    />
                    <div className="ms-3">
                      <p className="subtext medText mb-0">by </p>
                      <p className="subtext mb-0">Lynnette’s Kitchen</p>{" "}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <p className="text">{getDetails?.description}</p>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <p className="ingredient_heading medText pb-3">Ingredients</p>
                <div className="aboutus_solidborder mb-3"></div>
                <div className="aboutus_dashedborder"></div>
                <div className="ingre_cards">
                  <ul className="cards_style">
                    {getDetails?.ingredient?.map((data) => {
                      return (
                        <li>
                          <img
                            className="img-fluid me-2"
                            src={Images.whitebell}
                            alt=""
                          />
                          <span className="text colorWhite">{data}</span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <p className="ingredient_heading medText pb-3">Nutrition</p>
                <div className="aboutus_solidborder mb-3"></div>
                <div className="aboutus_dashedborder"></div>
                <div className="Nutrition_cards">
                  <ul className="cards_style">
                    {getDetails?.nutrition?.map((data) => {
                      return (
                        <li>
                          <img
                            className="img-fluid me-2"
                            src={Images.whitenotes}
                            alt=""
                          />
                          <span className="text colorWhite">{data}</span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="container pt-5">
            <div className="col-md-12">
              <h5 className=" ingredient_heading medText  mb-3 pb-3">
                Directions
              </h5>
              <div className="aboutus_solidborder mb-3"></div>
              <div className="aboutus_dashedborder"></div>
            </div>
            <div className="row">
              <div className="col-xl-6 col-ld-6 col-md-12">
                <p className="text">{getDetails?.direction}</p>
                {getDetails?.direction_step?.map((data, index) => {
                  return (
                    <div className="col-md-12">
                      <div className="stepsOuter RecipesStepOuter">
                        <div className="d-flex pb-5">
                          <div className="position-relative">
                            <img className="me-2" src={Images.stepbg} alt="" />
                            <p className="subtext stepText colorWhite">
                              Step{index + 1}
                            </p>
                          </div>
                          <div className="">
                            <img src={Images.stepcircle} alt="" />
                          </div>
                          <div className="col-md-10">
                            <p className="text ms-2">{data}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className="col-lg-6 ms-auto">
                {Boolean(getDetails?.recipe_video) ? (
                  <>
                    <div className="vedioOuter mt-0">
                      {isVideoPlayed === false ? (
                        <div className="playVedio">
                          <img
                            src={Images.vedioicon}
                            onClick={() => handlePlayVideo()}
                            alt="Play Video"
                          />
                        </div>
                      ) : (
                        <img
                          className="pausedIcon"
                          src={Images.paused}
                          onClick={() => handlePauseVideo()}
                          alt="Pause Video"
                        />
                      )}
                      <video
                        onPause={() => {
                          setIsVideoPlayed(false);
                        }}
                        onPlay={() => {
                          setIsVideoPlayed(true);
                        }}
                        poster={getDetails?.recipe_image}
                        ref={vidRef}
                        className="img-fluid w-100 vedio"
                        controls
                        autoPlay
                        loop
                      >
                        <source src={getDetails?.recipe_video} />
                      </video>
                      {/* <h5 className="text recipeText">Watch Recipe Video</h5> */}
                    </div>
                  </>
                ) : (
                  ""
                )}

                <div className="Aboutusrecommendrecipe">
                  <p className="medText">
                    Would You Like to Recommend this Recipe to Other Users ?
                  </p>
                  <div className="likeDislikeOuter d-flex">
                    <div
                      className="likeDislike"
                      onClick={() => handleSubmitLike(1)}
                    >
                      <Link href="#">
                        {getDetails?.is_like == 1 ? (
                          <img
                            className="img-fluid"
                            src={Images.Like}
                            alt="like"
                          />
                        ) : (
                          <img
                            className="img-fluid"
                            src={Images.thumblikeNew}
                            alt="like"
                          />
                        )}

                        <span className="text text-uppercase fw-500 lh-1">
                          Recommend
                        </span>
                      </Link>
                    </div>

                    <div
                      className="likeDislike"
                      onClick={() => handleSubmitLike(2)}
                    >
                      <Link href="#">
                        {getDetails?.is_like == 2 ? (
                          <img
                            className="img-fluid"
                            src={Images.Dbold}
                            alt="unlike"
                          />
                        ) : (
                          <img
                            className="img-fluid dislike_"
                            src={Images.thumblikedownNew}
                            alt="unlike"
                          />
                        )}
                        <div className="text-center">
                          <span className="text text-uppercase fw-500 lh-1">
                            Not Recommend
                          </span>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
                {/* ) : (
                  ""
                )} */}
              </div>
            </div>
          </div>
          <div className="container py-5">
            <div className="row">
              {getDetails?.is_review !== true ? (
                <div className="col-lg-6">
                  <div className="Aboutus_Ratetherecipe mt-3">
                    <div className="Aboutus_rateon_recipe">
                      <p className="subheading">Rate the Recipe </p>
                      <p className="text">
                        Your email address will not be published.Required fields
                        are marked*
                      </p>
                    </div>
                    <div className="Aboutus_reciperating_Star ">
                      <Link className="AboutUs_starstyling_rating " href="#">
                        <img
                          className=" img-fluid Star_rating"
                          src={Images.yellowstar}
                          alt="startRating"
                        />
                        {getDetails?.rating > 0 && (
                          <span className="text_ medText">
                            {" "}
                            {getDetails?.rating?.toFixed(1)}
                          </span>
                        )}
                      </Link>
                    </div>
                  </div>

                  <p className="DefaultPara medText">Rating</p>

                  <div key={rateKey}>
                    <ReactStars
                      count={5}
                      onChange={ratingChanged}
                      size={24}
                      value={rate}
                      activeColor="#ffd700"
                    />
                  </div>

                  <form>
                    <p className="DefaultPara medText">Comment</p>
                    <div className="container p-0">
                      <div className="row">
                        <div className="col-md-12">
                          <textarea
                            className="contactUsforminput_field contactusTextarea"
                            type="text"
                            placeholder="Your Comment"
                            rows="4"
                            cols="50"
                            {...formik.getFieldProps("review")}
                          ></textarea>
                          {formik.touched.review ? (
                            <div className="validationMsg">
                              {formik.errors.review}
                            </div>
                          ) : null}
                        </div>
                        <div class="col-md-6">
                          <input
                            class="contactUsforminput_field"
                            type="text"
                            placeholder="Your Name"
                            value={infoUser?.first_name}
                            disabled
                          />
                        </div>
                        <div class="col-md-6">
                          <input
                            class="contactUsforminput_field"
                            type="text"
                            placeholder="Email"
                            value={infoUser?.email}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-4">
                      <button
                        className="login-btn"
                        type="button"
                        onClick={formik.handleSubmit}
                      >
                        Post Comment
                        {loadingSendReview && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="ms-1"
                          />
                        )}{" "}
                      </button>
                      <span className="center-img">
                        <img
                          src={Images.centerrectangle}
                          alt=""
                          onClick={formik.handleSubmit}
                        />
                      </span>
                      <span className="circle-btn">
                        <img
                          src={Images.arrow}
                          alt=""
                          onClick={formik.handleSubmit}
                        />
                      </span>
                      {errorMessage ? (
                        <span
                          className={
                            errorMessage?.error ? "validationMsg" : "d-none"
                          }
                        >
                          {errorMessage?.error ? errorMessage?.error : ""}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </form>
                </div>
              ) : (
                ""
              )}
              <div
                className={`col-lg-6 ms-auto ${getDetails?.is_review == true
                    ? "col-lg-12 ms-auto"
                    : "col-lg-6 ms-auto"
                  }`}
              >
                {/* <div className="col-lg-6 ms-auto"> */}

                <div ref={myRef}>
                  <div className="commentsright_Side mt-3">
                    <div className="aboutus_comments">
                      <p className="subheading">Comments</p>
                      <p className="text" style={{ marginTop: "10px" }}>
                        {totalComments
                          ? `${totalComments} Comments posted by users`
                          : "No Comments posted yet"}
                      </p>
                    </div>
                    <div className="about_commentson_">
                      <img
                        className="img-fluid"
                        src={Images.bigiconcomment}
                        alt="comments_img"
                      />
                    </div>
                  </div>

                  <div key={commentKey}>
                    {commentList?.map((data, index) => {
                      return (
                        <>
                          <div className="aboutus_Anna">
                            <div className="profile_outer">
                              <img
                                className="img-fluid"
                                src={
                                  data?.created_by?.profile_image
                                    ? data?.created_by?.profile_image
                                    : Images.defaultUser
                                }
                                //src={Images.heart}
                                alt="Anna"
                              />
                              <div className="ms-1 me-1 d-flex w-100 align-items-center">
                                <div className="me-1">
                                  <div className="commentContent">
                                    <p className="DefaultPara medText m-0">
                                      {data?.created_by?.full_name}
                                    </p>
                                    <p className="subtext m-0">
                                      {moment(data?.createdAt).format(
                                        "MMMM D, YYYY"
                                      )}
                                    </p>
                                  </div>
                                </div>
                                <div>
                                  <ReactStars
                                    count={5}
                                    value={data?.rate}
                                    size={24}
                                    activeColor="#ffd700"
                                    edit={false}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="ReportsOuter">
                              <div className="Readme_button">
                                {data?.created_by?.id !== userInfo?.id && (
                                  <button
                                    className="reply_button subtext colorPink "
                                    onClick={() => {
                                      if (
                                        data?.created_by?.id === userInfo?.id
                                      ) {
                                        handleShowInput(data?.created_by?.id);
                                      } else {
                                        handleShowInput(data?.id);
                                      }
                                    }}
                                  >
                                    Reply
                                    <img
                                      className="ms-2"
                                      src={Images.rotatearrowpink}
                                      alt=""
                                    />
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                          <div>
                            <p className="text">{data?.review}</p>
                          </div>
                          <div className="larrow_">
                            {/* Replied comments */}
                            {data?.comments?.map((replied, i) => {
                              if (i < data?.itemToShow)
                                return (
                                  <>
                                    <img src={Images.aboutusLarrow} alt="" />
                                    <div className="aboutus_leena">
                                      <div className="leena_Profile">
                                        <div className="d-flex align-items-center">
                                          <div className="profile_outer">
                                            <img
                                              className="img-fluid"
                                              src={
                                                replied?.created_by
                                                  ?.profile_image
                                                  ? replied?.created_by
                                                    ?.profile_image
                                                  : Images.defaultUser
                                              }
                                              //src={Images.heart}
                                              alt="Anna"
                                            />
                                          </div>
                                          <div className="ms-3 me-3 replyComments">
                                            <p className="DefaultPara medText m-0">
                                              {replied?.created_by?.full_name}
                                            </p>
                                            <p className="subtext m-0">
                                              {moment(
                                                replied?.createdAt
                                              ).format("MMMM D, YYYY")}
                                            </p>
                                          </div>
                                        </div>
                                        <p className="text replyCommentText">
                                          {replied?.comments}
                                        </p>
                                      </div>
                                      <div className="anna_Report_Outer"></div>
                                    </div>
                                  </>
                                );
                            })}
                            {data.itemToShow < data?.comments?.length ? (
                              <div className="commentOnrecipeBox mt-5">
                                <button
                                  className="mt-3 viewMore"
                                  onClick={() => handleViewMore(index)}
                                >
                                  View More
                                  <img
                                    src={Images.downArrow}
                                    alt="downarrow"
                                    className="img-fluid viewMoreIcon"
                                  />
                                </button>
                              </div>
                            ) : null}
                            {showCommentBox && userId == data?.id && (
                              <form onSubmit={formik.handleSubmit}>
                                <p className="DefaultPara medText">Comment</p>
                                <div className="container p-0">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="textArea-delete">
                                        <textarea
                                          className="contactUsforminput_field contactusTextarea"
                                          type="text"
                                          placeholder="Your Comment"
                                          rows="4"
                                          cols="50"
                                          value={replyComments}
                                          onChange={(e) =>
                                            setReplyComments(
                                              e.target.value.trimStart()
                                            )
                                          }
                                        ></textarea>
                                        <i
                                          onClick={() =>
                                            setShowCommentBox(false)
                                          }
                                          className="fa fa-trash"
                                        ></i>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mt-4">
                                    <button
                                      className="login-btn"
                                      type="button"
                                      onClick={() =>
                                        handlePostCommentsReply(data?.id, index)
                                      }
                                    >
                                      Post Comments
                                      {loadingSendReplyComment && (
                                        <Spinner
                                          as="span"
                                          animation="border"
                                          size="sm"
                                          role="status"
                                          aria-hidden="true"
                                          className="ms-1"
                                        />
                                      )}{" "}
                                    </button>
                                    <span
                                      className="center-img"
                                      onClick={() =>
                                        handlePostCommentsReply(data?.id, index)
                                      }
                                    >
                                      <img
                                        src={Images.centerrectangle}
                                        alt=""
                                      />
                                    </span>
                                    <span
                                      className="circle-btn"
                                      onClick={() =>
                                        handlePostCommentsReply(data?.id, index)
                                      }
                                    >
                                      <img src={Images.arrow} alt="" />
                                    </span>
                                  </div>
                                </div>
                              </form>
                            )}
                          </div>
                        </>
                      );
                    })}
                  </div>
                  {commentList?.length > 3 &&
                    totalCommentsrecords != commentList?.length ? (
                    <button
                      className="DefultPara colorPink viewmoreRespBtn"
                      onClick={() => {
                        getAllComments(parseInt(page) + 1, getDetails?.id);
                      }}
                    >
                      View More
                      <span>
                        <img className="ms-2" src={Images.downArrow} alt="" />
                      </span>
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* <div className="downarrow recipedownarrow">
            <img src={Images.downarrow} alt="" />
          </div> */}

          {recommendedRecipe?.length > 0 ? (
            <>
              <section className="ourCustomers RecipeTabsOuter RecipesSwiper">
                <div className="container">
                  <div className="downarrow recipedownarrow upcomingclassarrow">
                    <img
                      src={Images.downarrowWhite}
                      alt=""
                      onClick={handleClickDown}
                    />
                  </div>
                  <div className="py-4">
                    <p className="subheading text-center">
                      Recipes You May Also Like
                    </p>
                    {/* <p className="text text-center">
                      Lorem Ipsum is simply dummy text of the printing is the
                      industry.{" "}
                    </p> */}
                  </div>

                  <div className="row">
                    <Swiper
                      navigation={true}
                      modules={[Pagination, Navigation]}
                      className={
                        recommendedRecipe?.length > 3
                          ? "mySwiper"
                          : "mySwiper hide-icons"
                      }
                      spaceBetween={20}
                      breakpoints={{
                        1200: {
                          slidesPerView: 3,
                        },
                        1024: {
                          slidesPerView: 3,
                        },
                        992: {
                          slidesPerView: 2,
                        },
                        991: {
                          slidesPerView: 2,
                        },
                        768: {
                          slidesPerView: 2,
                        },
                        767: {
                          slidesPerView: 1,
                        },
                        480: {
                          slidesPerView: 1,
                        },
                        375: {
                          slidesPerView: 1,
                        },
                      }}
                    >
                      {recommendedRecipe?.map((data, index) => {
                        return (
                          <SwiperSlide key={index}>
                            <div className="col-md-12">
                              <div className="foodBoxOuter me-4">
                                <div className="foodInner">
                                  <div className="boxImg">
                                    <div className="fullrecipeImg">
                                      <img
                                        src={data?.thumbnail_image}
                                        alt=""
                                        onClick={() => handleViewRecipe(data)}
                                      />
                                    </div>

                                    {data?.rating > 0 && (
                                      <span className="subtext ratingBox">
                                        <img
                                          className="star"
                                          src={Images.star}
                                          alt=""
                                        />
                                        {data?.rating?.toFixed(1)}
                                      </span>
                                    )}
                                  </div>
                                  <div className="foodBox">
                                    <div className="foodboxInner">
                                      <h5 className="bigText">{data?.title}</h5>
                                      <p className="subtext paraGraph pararecipe">
                                        {data?.description}
                                      </p>
                                      <button
                                        className="button seeFullrecipe text-capitalize"
                                        onClick={() => handleViewRecipe(data)}
                                      >
                                        See full Recipe
                                        <span>
                                          <img
                                            className="signup"
                                            src={Images.signuparrow}
                                            alt=""
                                          />
                                        </span>
                                      </button>
                                      {data?.is_favorite == true ? (
                                        <span className="heartCircle">
                                          <img
                                            alt=""
                                            src={Images.Vector}
                                            onClick={() =>
                                              handleFavReccomendedRecipe(
                                                data?.id,
                                                false
                                              )
                                            }
                                          />
                                        </span>
                                      ) : (
                                        <span className="heartCircle">
                                          <img
                                            alt=""
                                            src={Images.heart}
                                            onClick={() =>
                                              handleFavReccomendedRecipe(
                                                data?.id,
                                                true
                                              )
                                            }
                                          />
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </SwiperSlide>
                        );
                      })}

                      {/* <div className="swiper-button-next">
                        <span className="nextArrow">
                          <img src={Images.leftarrow} alt="" />
                        </span>
                      </div>
                      <div className="swiper-button-prev">
                        <span className="prevArrow">
                          <img src={Images.rightarrow} alt="" />
                        </span>
                      </div> */}
                    </Swiper>
                  </div>
                </div>
              </section>
            </>
          ) : (
            // <div className="container">
            //   <h5 className="text-center"> No suggested recipies</h5>
            // </div>
            ""
          )}
        </div>
      </section>

      {modalDetail.show && (
        <CustomModal
          key={key}
          show={modalDetail.show}
          backdrop="static"
          showCloseBtn={false}
          isRightSideModal={false}
          mediumWidth={false}
          className={
            modalDetail.flag === "reports" ? "commonWidth customContent" : ""
          }
          ids={modalDetail.flag === "reports" ? "reports" : ""}
          child={
            modalDetail.flag === "reports" ? (
              <ReportModal
                onCloseModal={() => {
                  handleOnCloseModal();
                }}
                reportId={getDetails?.id}
                userId={modalDetail?.id}
              />
            ) : (
              ""
            )
          }
          header={
            <div className="modalHeader_">
              <div className="common_"></div>
            </div>
          }
          onCloseModal={() => handleOnCloseModal()}
        />
      )}
    </>
  );
};

export default Recipedetailpage;
