import React, { useState, useEffect } from "react";
import * as Images from "../../../utilities/images";
import { useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import { getCategories, getRecipeWithUser } from "../../../redux/slices/admin";
import { useNavigate, useLocation } from "react-router-dom";
import { favRecipe } from "../../../redux/slices/user";
import { useUserSelector } from "../../../redux/selector/user";
import { Spinner } from "react-bootstrap";
import "sweetalert2/dist/sweetalert2.min.css";
import Swal from "sweetalert2";

const Recipes = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;
  var token = localStorage.getItem("authToken");
  const [getRecipes, setGetRecipes] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [perPageSize, setPerPageSize] = useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [categories, setCategories] = useState([]);
  const [catId, setCatId] = useState("");
  const [activeTab, setActivetab] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const authUserSelector = useUserSelector();
  const userInfo = authUserSelector?.loginUserProfile?.data;

  const navigate = useNavigate();
  const getRecipeList = (
    page = currentPage,
    limit = perPageSize,
    userid = userInfo?.id,
    keyword = searchValue,
    id
  ) => {
    let params = {
      page: page,
      limit: limit,
      userid: userid,
      keyword: keyword,
      cat_id: id == undefined ? "" : id,
    };
    if (Boolean(keyword)) {
      params = { ...params, keyword: keyword };
    }

    dispatch(
      getRecipeWithUser({
        ...params,
        cb(res) {
          if (res?.data) {
            setTotalPages(res?.data?.data?.total_pages);
            setTotalCount(res?.data?.data?.totalRecords);
            setGetRecipes(res?.data?.data?.list);
          } else {
          }
        },
      })
    );
  };

  useEffect(() => {
    getRecipeList();
    getCategory();
    window.scrollTo(0, 0);
    document.title = "Recipe-List";
  }, []);

  const getCategory = () => {
    dispatch(
      getCategories({
        cb(res) {
          if (res?.data) {
            setCategories(res?.data?.data);
          }
        },
      })
    );
  };

  const handleShowAlert = () => {
    setShowAlert(true);
    Swal.fire({
      title: "Add subscription",
      text: "Kindly add subscription for view recipes",
      icon: "warning",
      iconColor: "red",
      customClass: {
        popup: "mySwalClass",
      },
      showCancelButton: true,
      confirmButtonText: "Add",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("redirectUrl");
        navigate("/addsubscription", { state: pathname });
      }
    });
  };

  const handleViewRecipe = (data) => {
    if (!token) {
      navigate("/login");
    } else if (userInfo?.isSubscribe == false && data?.access_level == 2) {
      handleShowAlert();
      return false;
    } else if (data?.id) {
      navigate(`/recipedetailpage/${data?.id}`);
    }
  };

  const handlePageClick = (selectedItem) => {
    const selectedPage = selectedItem.selected + 1;
    setCurrentPage(selectedPage);
    getRecipeList(selectedPage, perPageSize);
  };

  let pagingDataLabel = perPageSize * currentPage;
  pagingDataLabel = pagingDataLabel > totalCount ? totalCount : pagingDataLabel;

  const handleSortData = (id) => {
    setCatId(id);
    getRecipeList(currentPage, perPageSize, userInfo?.id, searchValue, id);
  };

  const handleFav = (id, isFav) => {
    let params = {
      recipe_id: id,
    };
    dispatch(
      favRecipe({
        ...params,
        cb(res) {
          if (res.status) {
            const recipesCopy = [...getRecipes];
            const recipeIndex = recipesCopy.findIndex((x) => x.id === id);
            if (recipeIndex >= 0) {
              let favRecipe = recipesCopy[recipeIndex];

              favRecipe = {
                ...favRecipe,
                is_favorite: isFav,
              };
              // .is_favorite=isFav
              recipesCopy[recipeIndex] = favRecipe;
              setGetRecipes(recipesCopy);
            }

            // getRecipeLissst();
          } else {
          }
        },
      })
    );
  };

  return (
    <>
      <section className="recipeBanner recipeBannerRes">
        <div className="positionpaltes_">
          <img src={Images.recipeplate2} alt="" className="recipebannerimg1" />
          <img src={Images.recipeplate3} alt="" className="recipebannerimg2" />
          <img src={Images.recipePlate1} alt="" className="recipebannerimg3" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center pt-5 top-head">
              <h2 className="bigHeading bigHeadingResp pt-5 pb-2">
                Browse Our Recipes
              </h2>
              <p className="text px-5">
                Start or Learn Cooking With Lynnette’s recipes Made fresh.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-5">
        <div className="container">
          {" "}
          <div className="ourRecipe RecipesPage">
            <div className="row align-items-center justify-content-between">
              {categories?.length > 0 ? (
                <div className="col-md-3">
                  <h2 className="medText filterBottomBorder">Filter By</h2>
                </div>
              ) : (
                ""
              )}

              <div className="col-md-4">
                {/* <p
                  className="text mt-2"
                  style={{
                    border: "1px solid #e9a5f3",
                    padding: "14px 30px",
                    width: "100%",
                    maxWidth: "150px",
                    borderRadius: "30px",
                  }}
                >
                  <span className="DefaultPara me-2">All</span>
                  {totalCount} recipes{" "}
                </p> */}
                {/* <p className="text">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p> */}
              </div>
              <div className="col-md-4">
                <div className="mb-4 form-group searchInput">
                  <input
                    type="text"
                    className="form-control"
                    name="email"
                    placeholder="Search recipes..."
                    value={searchValue}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                      getRecipeList(1, 10, userInfo?.id, e.target.value);
                    }}
                  />
                  <span className="whiteRec">
                    {/* <img src={Images.whiterectange} alt="" /> */}
                  </span>
                  <span className="circle-btn searchCirle">
                    <img src={Images.searchicon} alt="" />
                  </span>
                </div>
              </div>
            </div>
            <div className="row pt-5 justify-between">
              <div className="col-lg-2 col-md-3">
                <ul className="tabnav">
                  <li>
                    {categories?.length > 0 ? (
                      <button
                        className={activeTab == 0 ? "tab activeTab" : "tab"}
                        onClick={() => {
                          handleSortData("");
                          setActivetab(0);
                        }}
                        value=""
                      >
                        <div className="tabIcons">
                          <img src={Images.allicon} alt="" />
                        </div>
                        <span>All</span>
                      </button>
                    ) : (
                      ""
                    )}
                  </li>
                  {categories?.map((data) => {
                    return (
                      <li>
                        <button
                          className={
                            activeTab == data?.id ? "tab activeTab" : "tab"
                          }
                          onClick={() => {
                            handleSortData(data?.id);
                            setActivetab(data?.id);
                          }}
                        >
                          <div className="tabIcons">
                            <img src={data?.icon} alt="" />
                          </div>
                          <span>{data?.title}</span>
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="col-lg-10 col-md-9">
                {categories?.length > 0 ? (
                  <div className="">
                    <img
                      className="foodTopImg img-fluid w-100"
                      src={Images.verticalrecipeimg}
                      alt=""
                    />
                  </div>
                ) : (
                  ""
                )}
                <div className="row pt-5 justify-content-around">
                  {getRecipes?.length > 0 ? (
                    getRecipes?.map((data, index) => {
                      return (
                        <div key={index} className="col-lg-5 col-md-10 mb-4">
                          <div className="foodBoxOuter ">
                            <div className="foodInner">
                              <div className="boxImg ">
                                <div className="fullrecipeImg">
                                  <img
                                    src={data?.thumbnail_image}
                                    alt=""
                                    onClick={() => handleViewRecipe(data)}
                                  />
                                </div>

                                {data?.rating > 0 ? (
                                  <div
                                    className="subtext ratingBox"
                                    onClick={() => handleViewRecipe(data)}
                                  >
                                    <img
                                      className="star"
                                      src={Images.star}
                                      alt=""
                                    />
                                    {data?.rating > 0
                                      ? data?.rating?.toFixed(1)
                                      : ""}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="foodBox">
                                <div className="foodboxInner">
                                  <div className="title-tag mb-1">
                                    <h5 className="bigText">{data?.title} </h5>
                                    {data?.access_level == 2 ? (
                                      <button
                                        className="recipe-tagPaid "
                                        onClick={() => handleViewRecipe(data)}
                                      >
                                        Paid
                                      </button>
                                    ) : (
                                      <button
                                        className="recipe-tag"
                                        onClick={() => handleViewRecipe(data)}
                                      >
                                        Free
                                      </button>
                                    )}
                                  </div>
                                  <p className="subtext pararecipe ">
                                    {data?.description}
                                  </p>

                                  <button
                                    className="button seeFullrecipe text-capitalize"
                                    onClick={() => handleViewRecipe(data)}
                                  >
                                    See full Recipe
                                    <span>
                                      <img
                                        className="signup"
                                        src={Images.signuparrow}
                                        alt=""
                                      />
                                    </span>
                                  </button>

                                  {data?.is_favorite == true ? (
                                    <span className="heartCircle">
                                      <img
                                        src={Images.Vector}
                                        alt=""
                                        onClick={() =>
                                          handleFav(data?.id, false)
                                        }
                                      />
                                    </span>
                                  ) : (
                                    <span className="heartCircle">
                                      <img
                                        alt=""
                                        src={Images.heart}
                                        onClick={() =>
                                          handleFav(data?.id, true)
                                        }
                                      />
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <>
                      {getRecipes?.length == 0 ? (
                        <div className="text-center">
                          <img src={Images.nodataImage} alt="no-recipe-found" />
                          <h3 className="noDataText mt-3">
                            No recipies found in this category{" "}
                          </h3>
                        </div>
                      ) : (
                        <Spinner
                          className="pageLoader"
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {getRecipes?.length > 0 ? (
          <>
            <ReactPaginate
              className="paginationBox RecipePagination mb-0 paginationBoxResp"
              breakLabel=""
              // nextLabel="next >"
              onPageChange={handlePageClick}
              nextClassName={"pagination-next"}
              previousClassName={"pagination-previous"}
              pageRangeDisplayed={5}
              marginPagesDisplayed={0}
              pageCount={totalPages}
              previousLabel="prev"
              renderOnZeroPageCount={null}
            />
          </>
        ) : (
          ""
        )}
      </section>
      {/* )} */}
    </>
  );
};

export default Recipes;
