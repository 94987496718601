import React from "react";
import { Outlet, useLocation } from "react-router-dom";

const AuthLayout = () => {
  const location = useLocation();
  const pathName = location.pathname;
  const authRoutes = [
    "/login",
    "/enterotp",
    "/forgotpassword",
    "/recoverpassword",
    "/signup",
    "/verification",
  ];

  return (
    <>
      {/* <AuthNav /> */}
      {authRoutes.includes(pathName) ? (
        <>
          <Outlet />
        </>
      ) : (
        <>
          <main className="main" id="main">
            <div className="container">
              <Outlet />
            </div>
          </main>
        </>
      )}
      {/* <AuthFooter /> */}
    </>
  );
};

export default AuthLayout;
