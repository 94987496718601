import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Images from "../../../utilities/images";
import moment from "moment";

const MainFooter = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const handleClickReadMore = () => {
    navigate("/aboutus");
  };
  return (
    <>
      {location.pathname != "/PaymentMethod" &&
        location.pathname != "/AddCard" &&
        location.pathname != "/notification" &&
        location.pathname != "/viewEdit" &&
        location.pathname != "/changePassword" &&
        location.pathname != "/planSubscription" &&
        location.pathname != "/updateProfile" &&
        location.pathname != "/liveStreaming" &&
        location.pathname != "/liveStreamingUser" &&
        location.pathname != "/joinStreaming" && (
          <footer className="footer-section">
            <div className="topFooter">
              <div className="container-xxl container-fluid px-md-5">
                <div className="row">
                  <div className="col-md-4">
                    <h4 className="heading colorWhite">
                      Join Our <span className="">Email</span> List!
                    </h4>
                  </div>
                  <div className="col-lg-4 col-md-3 text-center">
                    <p className="text">
                      Enter your email for specials, upcoming announcements and
                      other treats!
                    </p>
                  </div>
                  <div className="col-lg-4 col-md-5">
                    <div className="footerInput">
                      <input
                        type="text"
                        placeholder="Enter Your Email Address"
                        disabled
                      />
                      <span className="centerImg">
                        <img src={Images.blackRec} alt="" />
                      </span>
                      <span className="circle-btn footercircle">
                        <img src={Images.paperplane} alt="" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className="footerListOuter">
                <div className="footerList">
                  <div className="footerLogo">
                    <img src={Images.footerlogo} alt="" />
                  </div>
                  <p className="subtext text-center px-5 pb-3 ">
                    Lynnette's Cakes and Catering was established in 2006 by
                    Lynnette Jackson with the purpose of providing her delicious
                    bake good products and catering services, conveying her love
                    for cooking, to the people of the DMV area...
                  </p>

                  <button
                    className="appBtn readMore mb-4"
                    onClick={handleClickReadMore}
                  >
                    Read More
                    <span>
                      {" "}
                      <Link to="/aboutus" className="text colorpink">
                        <img
                          className="signup"
                          src={Images.signuparrow}
                          alt=""
                        />
                      </Link>
                    </span>
                  </button>
                </div>
                <div className="footerListRight">
                  <div className="footerListInner">
                    <ul className="footerLink">
                      <div className="">
                        <p className="bigText colorWhite">Quick Links</p>
                      </div>

                      <li>
                        {" "}
                        <Link to="/aboutus">About</Link>
                      </li>
                      <li>
                        {" "}
                        <Link to="/contactus">Contact</Link>
                      </li>
                      <li>
                        {" "}
                        <Link to="/privacypolicy">Privacy Policy</Link>
                      </li>
                      <li>
                        <Link to="/termsconditions">Terms and Conditions</Link>
                      </li>
                    </ul>
                    <ul className="footerLink">
                      <div className="">
                        <p className="bigText colorWhite">Get in Touch</p>
                      </div>
                      <li>
                        <div className="iconOuter">
                          <img
                            className="footerIcon"
                            src={Images.phone}
                            alt=""
                          />{" "}
                          <Link to="">
                            <span></span>703-232-1566
                          </Link>
                        </div>
                      </li>
                      <li>
                        <div className="iconOuter">
                          <img
                            className="footerIcon"
                            src={Images.location}
                            alt=""
                          />
                          <Link to=""> PO Box 1622 Annandale, VA 22003</Link>
                        </div>
                      </li>
                    </ul>
                    <ul className="footerLink">
                      <div className="">
                        <p className="bigText colorWhite">Follow Us</p>
                      </div>
                      <li>
                        <div className="iconOuter">
                          <img
                            className="footerIcon"
                            src={Images.facebook}
                            alt=""
                          />{" "}
                          <Link to="">Facebook</Link>
                        </div>
                      </li>
                      <li>
                        <div className="iconOuter">
                          <img
                            className="footerIcon"
                            src={Images.twitter}
                            alt=""
                          />
                          <Link to=""> Twitter</Link>
                        </div>
                      </li>
                      <li>
                        <div className="iconOuter">
                          <img
                            className="footerIcon"
                            src={Images.linkedin}
                            alt=""
                          />
                          <Link to=""> Linkedin</Link>
                        </div>
                      </li>
                      <li>
                        <div className="iconOuter">
                          <img
                            className="footerIcon"
                            src={Images.instagram}
                            alt=""
                          />
                          <Link to=""> Instagram</Link>
                        </div>
                      </li>
                    </ul>
                    <ul className="footerLink">
                      <li>
                        <img
                          src={Images.backtotop}
                          alt=""
                          onClick={handleClick}
                        />
                      </li>
                    </ul>
                  </div>
                  <div className="copyrightFooter">
                    <p className="text p-4 m-0">
                      © copyright{moment().year()}. All rights reserved
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        )}
    </>
  );
};

export default MainFooter;
