import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createSubscription, getCards } from "../../../redux/slices/user";
import * as Images from "../../../utilities/images";

const Card = (props) => {
  const [cardLoader, setCardLoader] = useState(false);
  const [showcards, setShowCards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // console.log("redirectUrlredirectUrl", redirectUrl);

  const getCardList = () => {
    setCardLoader(true);
    dispatch(
      getCards({
        cb(res) {
          if (res?.data) {
            setShowCards(res?.data?.data);
            setCardLoader(false);
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };

  useEffect(() => {
    getCardList();
    window.scrollTo(0, 0);
    document.title = "Buy-Now";
    setRedirectUrl(localStorage.getItem("redirectUrl"));
  }, []);

  const DefaultCard = showcards.find((data) => data?.setDefault == true);

  const handleSubmit = async (event) => {
    event.preventDefault();
    let params = {
      plan_id: props.planId,
      paymentMethodId: DefaultCard?.id,
    };
    setLoading(true);
    dispatch(
      createSubscription({
        ...params,
        cb(res) {
          if (redirectUrl) {
            navigate(redirectUrl);
          }
          if (res.status) {
            setLoading(false);
            props.closeModal();
            props.refreshData();
          } else {
            setLoading(false);
          }
        },
      })
    );
  };

  const handleShowCard = () => {
    navigate("/AddCard");
  };

  return (
    <>
      {cardLoader ? (
        <div className="card-loader-outer">
          <Spinner
            className="card-modal-loading"
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <>
          {DefaultCard ? (
            <section className="loginpage">
              <div className="col-md-8 m-auto">
                <div className="cardOuter active">
                  <div className="cardInner">
                    <div className="d-flex justify-content-between align-items-center pb-4">
                      <div>
                        <img src={Images.cardSign} alt="" />
                      </div>
                    </div>
                    <p className="bigText mb-0 pb-4">
                      <img src={Images.starswhite} alt="" />
                      <span>{DefaultCard?.card?.last4}</span>
                    </p>
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="text_ mb-0 medText">
                        {DefaultCard?.billing_details?.name
                          ? DefaultCard?.billing_details?.name
                          : ""}
                      </p>
                      <p className="subtext mb-0 medText">
                        {DefaultCard?.card?.exp_month}/
                        {DefaultCard?.card?.exp_year}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="text-center">
                  <button
                    className="login-btn mt-4"
                    type="button"
                    onClick={(e) => handleSubmit(e)}
                  >
                    Pay Now
                    {loading && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="ms-1"
                      />
                    )}{" "}
                  </button>
                </div>
              </div>
            </section>
          ) : (
            <>
              <h5 className="noDataText">Please add card for payment</h5>
              <div className="text-center">
                <button
                  className="login-btn mt-4"
                  type="button"
                  onClick={(e) => handleShowCard(e)}
                >
                  Add Card
                </button>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};
export default Card;
