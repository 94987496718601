import React, { useState, useEffect, useRef } from "react";
import * as Images from "../../../../utilities/images";
import { Link, useNavigate } from "react-router-dom";
import {
  addClasses,
  getCategories,
  uploadFiles,
} from "../../../../redux/slices/admin";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import AWS from "aws-sdk";
import ProgressBar from "react-bootstrap/ProgressBar";

const AddClass = () => {
  AWS.config.update({
    region: process.env.REACT_APP_AWS_S3_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  });
  const s3 = new AWS.S3();
  const [videoLoading, setVideoLoading] = useState(false);
  const [videoLoadingPercent, setVideoLoadingPercent] = useState(0);
  const [loading, setLoading] = useState(false);
  const toastId = React.useRef(null);
  const [type, setType] = useState(2);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [date, setDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [duration, setDuration] = useState("");
  const [video_thumbnail, setVideo_thumbnail] = useState(null);
  const [previewThumbnail, setPreviewThumbnail] = useState(null);
  const [classVideo, setClassVideo] = useState(null);
  const [previewClassVideo, setPrviewClassVideo] = useState(null);
  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [accessId, setAccessId] = useState("");
  const [instructors, setInstructors] = useState([
    { full_name: "", img: "", file: null, id: null, errorId: Math.random() },
  ]);

  const [errorInstrctors, setErrorInstrctors] = useState([]);
  const [newFileName, setNewFileName] = useState("");
  const [fileExtension, setFileExtension] = useState("");
  const [videoKey, setVideoKey] = useState(0);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const accesLevels = [
    { id: 1, title: "Free" },
    { id: 2, title: "Paid" },
  ];

  const handleKeyDown = (e) => {
    e.preventDefault();
  };
  const handleVideoError = (event) => {
    const url1 =
      "https://d1u9rinm8sz0kp.cloudfront.net/" +
      newFileName +
      "/" +
      fileExtension +
      "/" +
      newFileName +
      "." +
      fileExtension;
    setTimeout(() => {
      setPrviewClassVideo(null);
      setPrviewClassVideo(url1);
      setVideoKey((prevKey) => prevKey + 1);
    }, 30000);
    setLoader(true);

    // Additional error handling logic, if needed
  };
  const handleVideoSuccess = () => {
    setLoader(false);
    // Additional success handling logic, if needed
  };
  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const list = [...instructors];
    const item = list[index];
    list[index] = {
      full_name: value,
      img: item.img,
      file: item.file,
      id: item.id,
      errorId: item.errorId,
    };
    setInstructors(list);
    setInstructorErrorsData(value, item, "instrctors", "instrctors");
  };

  const setInstructorErrorsData = (value, item, errorType, type) => {
    const val = value.trim();
    const errorIndex = errorInstrctors.findIndex(
      (x) => x.errorId === item.errorId && x.errorType === errorType
    );
    const errorObjData = getErrorObj(val, item, type, errorType);
    const errorsInstrctorsCopy = [...errorInstrctors];

    if (errorObjData !== null) {
      if (errorIndex >= 0) {
        setErrorInstrctors(errorsInstrctorsCopy);
      } else {
        errorsInstrctorsCopy.push(errorObjData);
        errorsInstrctorsCopy[errorIndex] = errorObjData;
        setErrorInstrctors(errorsInstrctorsCopy);
      }
    } else {
      if (errorIndex >= 0) {
        errorsInstrctorsCopy.splice(errorIndex, 1);
        setErrorInstrctors(errorsInstrctorsCopy);
      }
    }
  };

  const handleAddInput = () => {
    setInstructors([
      ...instructors,
      { full_name: "", img: "", file: null, id: null, errorId: Math.random() },
    ]);
  };

  const handleRemoveInput = (index) => {
    const list = [...instructors];
    const listData = list[index];
    list.splice(index, 1);
    setInstructors(list);
    removeInstrctorError(listData, "instrctors");
    removeInstrctorError(listData, "Image");
  };

  const removeInstrctorError = (listData, errorType) => {
    const errorIndex = errorInstrctors.findIndex(
      (x) => x.errorId === listData.errorId && x.errorType === errorType
    );
    if (errorIndex >= 0) {
      const errorsInstrctorCopy = [...errorInstrctors];
      errorsInstrctorCopy.splice(errorIndex, 1);
      setErrorInstrctors(errorsInstrctorCopy);
    }
  };
  const getErrorObj = (val, item, type, errorType) => {
    let letterVal = /^[a-zA-Z\s]*$/;

    if (errorType === "instrctors") {
      if (val === "" || val === undefined || val === null) {
        return {
          errorId: item.errorId,
          errorMessage: `${type} Can not be empty`,
          errorType: errorType,
        };
      }
      if (!val.match(letterVal)) {
        return {
          errorId: item.errorId,
          errorMessage: `${type} must have alphabets only`,
          errorType: errorType,
        };
      }
      if (val.length < 3) {
        return {
          errorId: item.errorId,
          errorMessage: `${type} must have minimum 3 charecters`,
          errorType: errorType,
        };
      }
      if (val.length > 25) {
        return {
          errorId: item.errorId,
          errorMessage: `${type} must have maximum 25 charecters`,
          errorType: errorType,
        };
      }
    }

    if (errorType === "Image") {
      if (val === "" || val === undefined || val === null) {
        return {
          errorId: item.errorId,
          errorMessage: `${errorType} Can not be empty`,
          errorType: errorType,
        };
      }
    }

    return null;
  };

  function hasEmptyValues(arr) {
    for (const item in arr) {
      const obj = arr[item];
      console.log("objjjjjj",obj);
      if (obj.full_name == "" || obj.full_name?.length < 3 || obj.img == "") {
        return true;
      }
    }
    return false;
  }

  const moment = require("moment");
  const newDate = date;
  const Newtime = selectedTime;
  const dateTimeString = `${newDate}T${Newtime}:00.000Z`;

  const handleSubmit = (e) => {
    e.preventDefault();
    let letterVal = /^[a-zA-Z\s]*$/;
    if (!accessId) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please select access level");
      }
      return;
    }
    if (!title) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter title");
      }
      return;
    }
    if (title.length <= 2) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Title must have minimum 3 character");
      }
      return;
    }

    if (!title.match(letterVal)) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(
          "Please enter only alphabet characters in title"
        );
      }
      return;
    }
    if (!description) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter description");
      }
      return;
    }
    if (description.length <= 2) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(
          "Description must have  minimum 3 character"
        );
      }
      return;
    }
    if (!categoryId) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please select category type");
      }
      return;
    }

    if (type == 1) {
      if (!date) {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error("Please select date");
        }
        return;
      }
    }

    if (type == 1) {
      if (!selectedTime) {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error("Please select time");
        }
        return;
      }
    }
    if (type == 1) {
      if (!duration) {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error("Please add class duration");
        }
        return;
      }
    }
    if (instructors.length > 0) {
      const checkIns = hasEmptyValues(instructors);
      if (checkIns) {
        toast.error(
          "Image must be added with all instructor name and instructor name must be greater than 3 characters"
        );
        return;
      }
    }

    if (!video_thumbnail) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please add video thumbnail");
      }
      return;
    }

    if (type != 1) {
      if (!previewClassVideo) {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error("Please add class video");
        }
        return;
      }
    }
    let params = {
      type: type,
      title: title,
      access_level: accessId,
      description: description,
      category_id: categoryId,
      date: dateTimeString,
      time: selectedTime,
      duration: duration,
      instrucator: instructors,
      video_thumbnail: video_thumbnail,
      class_video: previewClassVideo,
    };
    setLoading(true);
    dispatch(
      addClasses({
        ...params,
        cb(res) {
          setLoading(false);
          if (res.status) {
            navigate("/admin/classes");
          } else {
            navigate("/");
          }
        },
      })
    );
  };
  const currentTime = new Date().toISOString().split("T")[0];

  const handleSaveFiles = (file, index) => {
    let params = {
      uploadfile: file,
    };
    dispatch(
      uploadFiles({
        ...params,
        cb(res) {
          if (res.status) {
            const list = [...instructors];
            const item = list[index];
            list[index] = {
              full_name: item.full_name,
              id: item.id,
              img: res?.data?.data?.file_url,
              file: res?.data?.data?.file_name,
            };
            setInstructors(list);
            setInstructorErrorsData(
              res?.data?.data?.file_name,
              item,
              "Image",
              "Image"
            );
            //toast.success("instructor Image updated");
          } else {
            navigate("/");
          }
        },
      })
    );
  };
  const getCategory = () => {
    dispatch(
      getCategories({
        cb(res) {
          if (res?.data) {
            setCategories(res?.data?.data);
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };
  useEffect(() => {
    getCategory();
    window.scrollTo(0, 0);
    document.title = "Add-class";
  }, []);

  const handleUploadImage = (e, index) => {
    const file = e.target.files[0];
    if (!file.name.match(/\.(jpg|png|jpeg|)$/)) {
      toast.error("Please select a valid instructor image.");
      return false;
    }
    if (file) {
      const reader = new FileReader();
      reader.onloadend = (event) => {
        setInstructors((prevState) => {
          const oldData = [...prevState];
          oldData[index].img = reader.result;
          return oldData;
        });
      };
      reader.readAsDataURL(file);
      handleSaveFiles(file, index);
    }
  };

  const handleUploadVedioThumbnail = (e) => {
    const file = e.target.files[0];
    if (!file.name.match(/\.(jpg|png|jpeg|)$/)) {
      toast.error("Please select a valid image.");
      return false;
    }
    if (file) {
      setVideo_thumbnail(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewThumbnail(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUploadClassVideo = (e) => {
    setVideoLoading(true);
    const file = e.target.files[0];
    const selectedFile = e.target.files[0];
    var modifiedFile;
    if (selectedFile) {
      const randomNumber = Math.floor(Math.random() * 90000000) + 10000000;
      const fileExtension = selectedFile.name.split(".").pop();
      const newFileName = randomNumber.toString() + "." + fileExtension;
      modifiedFile = new File([selectedFile], newFileName, {
        type: selectedFile.type,
      });
      setClassVideo(modifiedFile);
    } else {
      setVideoLoading(false);
      return false;
    }
    if (!modifiedFile.name.match(/\.(mp4|video|webm|)$/)) {
      toast.error("Please select a valid video.");
      setVideoLoading(false);
      return false;
    }
    if (modifiedFile) {
      const params = {
        Bucket: process.env.REACT_APP_AWS_S3_BUCKET,
        Key: modifiedFile.name,
        Body: file,
      };
      s3.upload(params, (err, data) => {
        if (err) {
          setVideoLoading(false);
          setVideoLoadingPercent(0);
        } else {
          setVideoLoading(false);
          setVideoLoadingPercent(0);
          const videoUrl = data.Key;
          const fileName = videoUrl.split(".").slice(0, -1).join(".");
          const newfileName = fileName.split("/").pop();
          const fileExtension = videoUrl.split(".").pop();
          setNewFileName(newfileName);
          setFileExtension(fileExtension);

          const url =
            "https://d1u9rinm8sz0kp.cloudfront.net/" +
            newfileName +
            "/" +
            fileExtension +
            "/" +
            newfileName +
            "." +
            fileExtension;

          // Check if the URL contains a specific error parameter
          setPrviewClassVideo(url);
          setLoader(true);
        }
      }).on("httpUploadProgress", function (progress) {
        let progressPercentage = Math.round(
          (progress.loaded / progress.total) * 100
        );
        setVideoLoadingPercent(progressPercentage);
      });
    }
  };

  const hanldeCrossClassVideo = () => {
    setClassVideo(null);
    setVideoKey(null);
    setPrviewClassVideo(null);
  };

  const handleCrossImage = () => {
    setPreviewThumbnail(null);
    setVideo_thumbnail(null);
  };
  
  const handleCrossInstructorImage = (index) => {
    const list = [...instructors];
    let item = list[index];
    list[index] = { ...item, file: null, img: "" };
    setInstructors(list);
    setInstructorErrorsData(null, item, "Image", "Image");
  };

  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };

  return (
    <>
      <div className="AddClass">
        <div className="row mt-4">
          <div className="col-12">
            <div className="contentCommon">
              <div className="backBtn">
                <Link to={"/admin/classes"}>
                  <button className="btn colorPink px-0 pb-2">
                    <img
                      src={Images.rightarrowpink}
                      alt="arrow"
                      className="me-2"
                    />{" "}
                    Go to Back
                  </button>
                </Link>
                <h1 className="courseHeading font-28 pb-2">Add Class</h1>
              </div>
              <form className="profileForm">
                <div className="row ">
                  <div className="mb-3 form-group col-md-12">
                    <label className="form-label pb-2">Class Type</label>
                    <div className="d-flex align-items-center">
                      <div className="me-4">
                        <label class="radioOuter">
                          Pre Recorded
                          <input
                            type="radio"
                            checked={type == 2}
                            name="radio"
                            value={2}
                            onChange={(e) => setType(e.target.value)}
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                      <div className="me-4">
                        <label class="radioOuter">
                          Live Stream
                          <input
                            type="radio"
                            //checked="checked"
                            name="radio"
                            value={1}
                            onChange={(e) => setType(e.target.value)}
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="mb-3 form-group col-md-4">
                    <label
                      className="form-label pb-2"
                      htmlFor="exampleFormControlSelect1"
                    >
                      Access Type
                    </label>
                    <select
                      className="form-control text"
                      id="exampleFormControlSelect1"
                      onChange={(event) => setAccessId(event.target.value)}
                    >
                      <option>Select Access Type</option>
                      {accesLevels?.map((data, index) => (
                        <option key={index} value={data.id}>
                          {data.title}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="mb-3 form-group col-md-12">
                    <label className="form-label pb-2">Title</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter title"
                      value={title}
                      maxLength={50}
                      onChange={(e) => setTitle(e.target.value.trimStart())}
                    />
                  </div>
                  <div className="mb-3 form-group col-md-12">
                    <label className="form-label pb-2">Class Overview</label>
                    <textarea
                      name=""
                      id=""
                      cols="30"
                      rows="10"
                      maxLength={1000}
                      placeholder="Enter Description"
                      className="form-control"
                      value={description}
                      onChange={(e) =>
                        setDescription(e.target.value.trimStart())
                      }
                    ></textarea>
                  </div>
                  <div className="row">
                    <div className="mb-3 form-group col-md-6">
                      <label
                        className="form-label pb-2"
                        htmlFor="exampleFormControlSelect1"
                      >
                        Category Type
                      </label>
                      <select
                        className="form-control text"
                        id="exampleFormControlSelect1"
                        onChange={(event) => setCategoryId(event.target.value)}
                      >
                        <option> Select category type</option>
                        {categories.map((data, index) => (
                          <option key={index} value={data.id}>
                            {data.title}
                          </option>
                        ))}
                      </select>
                    </div>
                    {type == 1 ? (
                      <>
                        <div className="mb-3 form-group col-md-6">
                          <label className="form-label pb-2">Date</label>
                          <input
                            className="form-control text"
                            type="date"
                            placeholder="enter date"
                            min={moment().format("YYYY-MM-DD")}
                            value={date}
                            onChange={(e) => {
                              if (
                                moment(
                                  `${selectedTime} ${e.target.value}`,
                                  "YYYY-MM-DD HH:mm"
                                ).isBefore(moment()) &&
                                selectedTime
                              ) {
                                setSelectedTime("");
                                setDate(e.target.value);
                              } else {
                                setDate(e.target.value);
                              }
                            }}
                            onKeyDown={handleKeyDown}
                          />
                        </div>
                        <div className="mb-3 form-group col-md-6">
                          <label className="form-label pb-2">Time</label>
                          <input
                            className="form-control text"
                            type="time"
                            placeholder=""
                            value={selectedTime}
                            disabled={!date}
                            onChange={(e) => {
                              if (
                                moment(
                                  `${date} ${e.target.value}`,
                                  "YYYY-MM-DD HH:mm"
                                ).isBefore(moment())
                              ) {
                                showToast("Please select future time");
                              } else {
                                setSelectedTime(e.target.value);
                              }
                            }}
                          />
                        </div>
                        <div className="mb-3 form-group col-md-6">
                          <label className="form-label pb-2">
                            Duration(min)
                          </label>
                          <input
                            className="form-control text"
                            type="number"
                            placeholder="Enter duration"
                            min={1}
                            max={99999}
                            value={duration}
                            maxLength={5}
                            onChange={(e) => setDuration(e.target.value)}
                          />
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="IngredientsBox">
                  <div className="IngredientsBoxInner">
                    <div className="d-flex align-items-center">
                      <img className="classuser" src={Images.profile} alt="" />
                      <p className="medText m-0 ms-3">instructors</p>
                    </div>
                    <div onClick={handleAddInput} className="">
                      <button className="login-btn" type="button">
                        Add
                      </button>
                      <span className="center-img">
                        <img src={Images.centerrectangle} alt="" />
                      </span>
                      <span className="circle-btn recipeBtnCircle">
                        <img src={Images.plussign} alt="" />
                      </span>
                    </div>
                  </div>
                  {instructors.map((item, idx) => {
                    return (
                      <>
                        <div
                          key={idx}
                          className="IngredientsBoxInner form-group-outer"
                        >
                          <div className="col-md-11">
                            <div className="addRecipeBox px-4">
                              <div className="addRecipeBoxInner d-inline">
                                <div className="recipeImgOuter">
                                  {item?.img ? (
                                    <>
                                      {Boolean(item.img) ? (
                                        <>
                                          <img
                                            src={
                                              Boolean(item.img)
                                                ? item.img
                                                : Images.addImage
                                            }
                                            className="img-fluid profileBig addProfileImg mx-0"
                                            alt=""
                                          />
                                          <img
                                            className="crossIcon addClassCross"
                                            src={Images.whitecross}
                                            alt=""
                                            onClick={() =>
                                              handleCrossInstructorImage(idx)
                                            }
                                          />
                                        </>
                                      ) : (
                                        ""
                                      )}{" "}
                                    </>
                                  ) : (
                                    <label
                                      className="choosenBtn"
                                      htmlFor={`actual-btn${idx}`}
                                    >
                                      <img src={Images.addImage} alt="" />
                                      <input
                                        type="file"
                                        accept="image/jpg,image/png,image/jpeg"
                                        id={`actual-btn${idx}`}
                                        className="custom-file-upload"
                                        onChange={(event) => {
                                          handleUploadImage(event, idx);
                                        }}
                                        hidden
                                      />
                                    </label>
                                  )}

                                  <div className="form-group InputOuter col-md-10 ms-3">
                                    <input
                                      className="form-control"
                                      type="text"
                                      placeholder="Enter Instructor Name"
                                      value={item.full_name}
                                      onChange={(event) =>
                                        handleInputChange(idx, event)
                                      }
                                    />
                                    <div>
                                      {/* <p style={{ color: "red" }}>{imageError?.errorMessage}</p>
                                      <p style={{ color: "red" }}>{fullNameError?.errorMessage}</p> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className=""
                            onClick={() => handleRemoveInput(idx)}
                          >
                            <img
                              className="cursor-pointer-trash"
                              src={Images.trash}
                              alt=""
                            />
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
                <h4 className="customLabel pt-4 pb-2">Add video Thumbnail </h4>
                <div className="mb-3 form-control col-md-12 fileBorder">
                  <input
                    type="file"
                    id="file-6"
                    className="inputfile inputfile-5"
                    accept="image/jpg,image/png,image/jpeg"
                    onChange={(e) => handleUploadVedioThumbnail(e)}
                  />

                  <div className="dnldFileOuter ThumbnailImgOuter">
                    {Boolean(video_thumbnail) ? (
                      <>
                        <img
                          src={
                            previewThumbnail
                              ? previewThumbnail
                              : video_thumbnail
                          }
                          className="img-fluid w-100 mx-0"
                          alt="upload"
                        />
                        <img
                          className="crossIcon"
                          src={Images.whitecross}
                          alt=""
                          onClick={(e) => handleCrossImage(e)}
                        />
                      </>
                    ) : (
                      <label for="file-6" className="labelNewCase">
                        <span className="file_span">
                          <img src={Images.downloadicon} alt="" />
                          <br />
                          <span className="colorPink">Browse</span> and drag and
                          drop the file. Upload JPG & PNG.
                        </span>
                      </label>
                    )}
                  </div>
                </div>
                {type == 2 ? (
                  <>
                    <h4 className="customLabel py-2">Upload Video </h4>
                    <div className="mb-3 form-control col-md-12 fileBorder">
                      <input
                        type="file"
                        id="file-15"
                        className="inputfile inputfile-5"
                        accept="video/mp4,video/webm"
                        onChange={(e) => handleUploadClassVideo(e)}
                      />

                      {videoLoading ? (
                        <>
                          <div className="text-center">
                            <Spinner
                              as="span"
                              animation="border"
                              role="status"
                              aria-hidden="true"
                              className="pinky"
                            />
                            <p className="colorPink">Uploading</p>
                          </div>

                          {videoLoadingPercent > 0 ? (
                            <ProgressBar
                              now={videoLoadingPercent}
                              label={`${videoLoadingPercent}%`}
                            />
                          ) : null}
                        </>
                      ) : (
                        <div
                          className={`  py-3  previewVedio ${
                            videoKey ? "bgPreviewVideo" : ""
                          }`}
                        >
                          {Boolean(previewClassVideo || videoKey) ? (
                            <React.Fragment key={videoKey}>
                              {loader && (
                                <p className="colorPink">
                                  Processing
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                </p>
                              )}
                              <video
                                className={`img-fluid w-100 vedio ${
                                  loader ? "d-none" : ""
                                }`}
                                controls
                                autoPlay
                                loop
                                onError={handleVideoError}
                                onLoadedData={handleVideoSuccess}
                              >
                                <source src={previewClassVideo} />
                              </video>
                              <img
                                className={`crossIcon ${
                                  loader ? "d-none" : ""
                                }`}
                                src={Images.whitecross}
                                alt="icon"
                                onClick={(e) => hanldeCrossClassVideo(e)}
                              />
                            </React.Fragment>
                          ) : (
                            <>
                              <label htmlFor="file-15" className="labelNewCase">
                                <span className="file_span">
                                  <img src={Images.downloadicon} />
                                  <br />
                                  <span className="colorPink">Browse</span> and
                                  drag and drop the video file.
                                </span>
                              </label>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  ""
                )}
              </form>
            </div>
            <div className="row mt-4 mb-4">
              <div className="col-12">
                <div className="courseFoot">
                  <button
                    className="primaryBtn submitBtn m-0"
                    type="button"
                    onClick={(e) => handleSubmit(e)}
                  >
                    <span>Save</span>
                    {loading && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="ms-1"
                      />
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddClass;
