import React, { useEffect, useState } from "react";
import * as Images from "../../../utilities/images";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuthSelector } from "../../../redux/selector/auth";
import CustomModal from "../modals/modal";
import GoLive from "../../containers/admin/goLive";
import { useUserSelector } from "../../../redux/selector/user";
import { openSideMenu } from "../../../redux/slices/user";
import { useDispatch } from "react-redux";

const Sidebar = () => {
  const dispatch = useDispatch();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const [key, setKey] = useState(Math.random());
  const location = useLocation();
  const navigation = useNavigate();
  const authSelector = useAuthSelector();
  const userSelector = useUserSelector();
  const ValidLoggedIn = authSelector?.userLogin?.data?.user;

  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  useEffect(() => {
    setActiveTab(location?.pathname);
  }, [location?.pathname]);

  useEffect(() => {
    if (ValidLoggedIn && ValidLoggedIn?.user?.role === 2) {
      navigation("/");
    }
  }, [ValidLoggedIn, location.pathname, navigation]);

  useEffect(() => {
    setIsSidebarOpen(userSelector?.isOpenSidebar);
  }, [userSelector?.isOpenSidebar]);

  const handleGoLivePage = () => {
    setModalDetail({
      show: true,
      title: "detail",
      flag: "detail",
    });
    setKey(Math.random());
  };
  const crossToggle = () => {
    dispatch(openSideMenu(false));
  };
  return (
    <>
      <div className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
        <div className="SideNavOuter">
          <div className="">
            <div className="sidebarInner">
              <Link to="/admin/dashboard">
                <div className="sidebarLogo">
                  <div className="sideheader">
                    <img src={Images.logo} alt="logo" />
                  </div>
                </div>
              </Link>
              <div className="crossBtn" onClick={crossToggle}>
                <i class="fas fa-times sidebarCancel"></i>
              </div>
              <div className="sidebar_menu">
                <ul className="nav_main">
                  <li
                    className={
                      activeTab == "/admin/dashboard"
                        ? "nav_link active"
                        : "nav_link"
                    }
                  >
                    <Link
                      onClick={() => {
                        setActiveTab("/admin/dashboard");
                        crossToggle();
                      }}
                      to="/admin/dashboard"
                    >
                      <img
                        src={Images.dashboarddark}
                        alt="icon"
                        className="sidebar_icon"
                      />
                      <img src={Images.dashboard} alt="icon" className="hide" />
                      Dashboard
                    </Link>
                  </li>
                  <li
                    className={
                      activeTab == "/admin/user"
                        ? "nav_link active"
                        : "nav_link"
                    }
                  >
                    <Link
                      onClick={() => {
                        setActiveTab("/admin/user");
                        crossToggle();
                      }}
                      to="/admin/user"
                    >
                      <img
                        src={Images.users}
                        alt="icon"
                        className="sidebar_icon"
                      />
                      <img
                        src={Images.userslight}
                        alt="icon"
                        className="hide"
                      />
                      Users
                    </Link>
                  </li>
                  <li
                    className={
                      activeTab == "/admin/recipe" ||
                      activeTab == "/admin/addrecipe" ||
                      activeTab.split("/")[2] == "editrecipe" ||
                      activeTab.split("/")[2] == "detailrecipe"
                        ? "nav_link active"
                        : "nav_link"
                    }
                  >
                    <Link
                      onClick={() => {
                        setActiveTab("/admin/recipe");
                        crossToggle();
                      }}
                      to="/admin/recipe"
                    >
                      <img
                        src={Images.recipeicon}
                        alt="icon"
                        className="sidebar_icon"
                      />
                      <img
                        src={Images.recipeiconlight}
                        alt="icon"
                        className="hide"
                      />
                      Recipes
                    </Link>
                  </li>
                  <li
                    className={
                      activeTab == "/admin/classes" ||
                      activeTab == "/admin/addclass" ||
                      activeTab.split("/")[2] == "classdetail" ||
                      activeTab.split("/")[2] == "editclass"
                        ? "nav_link active"
                        : "nav_link"
                    }
                  >
                    <Link
                      onClick={() => {
                        setActiveTab("/admin/classes");
                        crossToggle();
                      }}
                      to="/admin/classes"
                    >
                      <img
                        src={Images.classes}
                        alt="icon"
                        className="sidebar_icon"
                      />
                      <img
                        src={Images.classeslight}
                        alt="icon"
                        className="hide"
                      />
                      Classes
                    </Link>
                  </li>
                  {/* <li
                    className={
                      activeTab == "/admin/plan"
                        ? "nav_link active"
                        : "nav_link"
                    }
                  >
                    <Link
                      onClick={() => setActiveTab("/admin/plan")}
                      to="/admin/plan"
                    >
                      <img
                        src={Images.plans}
                        alt="icon"
                        className="sidebar_icon planIcon"
                      />
                      <img
                        src={Images.plan}
                        alt="icon"
                        className="hide planIconHide"
                      />
                      Plans
                    </Link>
                  </li> */}
                  <li
                    className={
                      activeTab == "/admin/info"
                        ? "nav_link active"
                        : "nav_link"
                    }
                  >
                    <Link
                      onClick={() => {
                        setActiveTab("/admin/info");
                        crossToggle();
                      }}
                      to="/admin/info"
                    >
                      <img
                        src={Images.infopages}
                        alt="icon"
                        className="sidebar_icon"
                      />
                      <img
                        src={Images.infopageslight}
                        alt="icon"
                        className="hide"
                      />
                      Info Pages
                    </Link>
                  </li>
                  {/* <li
                    className={
                      activeTab == "/admin/reports"
                        ? "nav_link active"
                        : "nav_link"
                    }
                  >
                    <Link
                      onClick={() => setActiveTab("/admin/reports")}
                      to="/admin/reports"
                    >
                      <img
                        src={Images.reports}
                        alt="icon"
                        className="sidebar_icon"
                      />
                      <img
                        src={Images.reportslight}
                        alt="icon"
                        className="hide"
                      />
                      Reports
                    </Link>
                  </li> */}
                  <li
                    className={
                      activeTab == "/admin/supportChat"
                        ? "nav_link active"
                        : "nav_link"
                    }
                  >
                    <Link
                      onClick={() => {
                        setActiveTab("/admin/supportChat");
                        crossToggle();
                        window.location.href = "/admin/supportChat";
                      }}
                      // to="/admin/supportChat"
                    >
                      <img
                        src={Images.supportChat}
                        alt="icon"
                        className="sidebar_icon"
                      />
                      <img
                        src={Images.supportChatActive}
                        alt="icon"
                        className="hide"
                      />
                      Support Chat
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="">
                <div
                  className={
                    activeTab == "/admin/goLive"
                      ? "nav_link active"
                      : "nav_link active"
                  }
                >
                  <Link
                    to="/admin/dashboard"
                    onClick={(e) => handleGoLivePage(e)}
                  >
                    <img
                      src={Images.goliveicondark}
                      alt="icon"
                      className="sidebar_icon img-fluid"
                      style={{ maxWidth: "25px" }}
                    />
                    <img
                      src={Images.goliveicon}
                      alt="icon"
                      className="hide img-fluid"
                      style={{ maxWidth: "50px" }}
                    />
                    Go Live
                  </Link>
                </div>
                <div
                  className={
                    activeTab == "/admin/settings"
                      ? "nav_link active"
                      : "nav_link"
                  }
                >
                  <Link
                    onClick={() => {
                      setActiveTab("/admin/settings");
                      crossToggle();
                    }}
                    to="/admin/settings"
                  >
                    <img
                      src={Images.setting}
                      alt="icon"
                      className="sidebar_icon"
                    />
                    <img
                      src={Images.settinglight}
                      alt="icon"
                      className="hide"
                    />
                    Settings
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <button  onClick={toggleSidebar}>
          <i class="fas fa-bars toggle-button"></i>
        </button> */}

        <CustomModal
          key={key}
          show={modalDetail.show}
          backdrop="static"
          showCloseBtn={false}
          isRightSideModal={false}
          mediumWidth={false}
          className={
            modalDetail.flag === "detail" ? "commonWidth customContent" : ""
          }
          ids={modalDetail.flag === "detail" ? "goLiveModal" : ""}
          child={
            modalDetail.flag === "detail" ? (
              <GoLive
                closeModal={() => {
                  handleOnCloseModal();
                }}
                userId={modalDetail?.id}
              />
            ) : (
              ""
            )
          }
          header={
            <div className="modalHeader_">
              <div className="common_">
                {modalDetail.flag === "detail" ? (
                  <h2 className="font-28 mb-3 text-center live-modal-title">
                    Before start Live enter some details
                  </h2>
                ) : (
                  ""
                )}
              </div>
            </div>
          }
          onCloseModal={() => handleOnCloseModal()}
        />
      </div>
    </>
  );
};
export default Sidebar;
