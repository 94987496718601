import React, { useEffect, useRef, useState } from "react";
import * as Images from "../../../utilities/images";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import CustomModal from "../../components/modals/modal";
import { useDispatch } from "react-redux";
import { getClassDetails, getUpcommingList } from "../../../redux/slices/admin";
import moment from "moment";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import {
  addBooking,
  addRating,
  cancelBooking,
  createClassLike,
  favClass,
  generateTokenForStreaming,
  getAllreviewClass,
  replyComment,
} from "../../../redux/slices/user";
import ReactStars from "react-rating-stars-component";
import { toast } from "react-toastify";
import { useAuthSelector } from "../../../redux/selector/auth";
import ReportModal from "./reportmodal";
import { Spinner } from "react-bootstrap";
import { socketInstance } from "../../components/common/socketNew";
import { useFormik } from "formik";
import * as Yup from "yup";
import "sweetalert2/dist/sweetalert2.min.css";
import swal from "sweetalert";
import { useUserSelector } from "../../../redux/selector/user";

const Classdetailpage = () => {
  const authSelector = useAuthSelector();
  const { id } = useParams();
  const myRef = useRef(null);
  const navigate = useNavigate();
  const vidRef = useRef(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const infoUser = authSelector?.userLogin?.data?.user;
  const [key, setKey] = useState(Math.random());
  const [isVideoPlayed, setIsVideoPlayed] = useState(false);
  const [getDetails, setGetDetails] = useState([]);
  const [rate, setRate] = useState(1);
  const [rateKey, setRateKey] = useState(Math.random());
  const [commentList, setCommentsList] = useState([]);
  const [totalCommentsrecords, setTotalCommentRecords] = useState("");
  const [commentKey, setCommentKey] = useState(Math.random());
  const [page, setPage] = useState(1);
  const [replyComments, setReplyComments] = useState("");
  const [showCommentBox, setShowCommentBox] = useState(false);
  const [userId, setUserById] = useState("");
  const [watchVideo, setWatchVideo] = useState(false);
  const [getClass, setGetClass] = useState([]);
  const [liveClasses, setLiveClasses] = useState("");
  const [totalViews, setTotalViews] = useState("");
  const [totalrating, setTotalrating] = useState("");
  const [totalComments, setTotalComments] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loadingSendReview, setLoadingSendReview] = useState(false);
  const [loadingSendReplyComment, setLoadingSendReplyComment] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const location = useLocation();
  const authUserSelector = useUserSelector();
  const userInfo = authUserSelector?.loginUserProfile?.data;

  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  useEffect(() => {
    if (socketInstance) {
      socketInstance.connect()
      socketInstance.on("connection", (response) => {
        // console.log("Connected", response);
      });
      return () => {
        socketInstance.disconnect();
        // console.log("disconnect");
      };
    }
  }, []);

  const liveEvent = () => {
    socketInstance.on("liveClassReact", (data) => {
      // console.log(data, "joinLiveClasses");
      setLiveClasses(data?.data ?? null);
    });
  };

  useEffect(() => {
    socketInstance.emit("liveClass");
    liveEvent();
    socketInstance.on("sync_data", (response) => {
      // console.log("sync_data", response);
      setTotalViews(response?.totalUsers);
      setTotalComments(response?.totalcomments);
      setTotalrating(response?.totalrating);
    });
    return () => {
      socketInstance.disconnect();
    };
  }, []);

  const handlePlayVideo = () => {
    setWatchVideo(true);
    setIsVideoPlayed(true);
    vidRef.current.play();
  };
  const handlePauseVideo = () => {
    setWatchVideo(false);
    setIsVideoPlayed(false);
    vidRef.current.pause();
  };
  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
  };
  const classDetails = () => {
    let params = {
      id: id,
    };
    dispatch(
      getClassDetails({
        ...params,
        cb(res) {
          if (res?.data?.data?.subscription == false) {
            handleShowAlert();
          }
          if (res?.data) {
            setGetDetails(res?.data?.data);
            getAllComments(1, res?.data?.data?.id);
          } else {
          }
        },
      })
    );
  };
  const handleShowAlert = () => {
    setShowAlert(true);
    swal({
      title: "Add subscription",
      text: "Kindly add subscription for view classes",
      icon: "info",
      className: "mySwalClass",
      // buttons: true,
      buttons: {
        confirm: {
          text: "Add",
          value: true,
          visible: true,
        },
        cancel: {
          text: "Cancel",
          value: false,
          visible: true,
        },
      },
    }).then((willDelete) => {
      if (willDelete) {
        navigate("/addsubscription");
      }
    });
  };

  const handleViewClassDetails = (id) => {
    if (id) {
      navigate(`/classdetailpage/${id}`);
    }
  };
  const classList = () => {
    let params = {
      sort: "upcoming",
    };

    setLoading(true);
    dispatch(
      getUpcommingList({
        ...params,
        cb(res) {
          if (res?.data) {
            setGetClass(res?.data?.data?.list);
            setLoading(false);
          } else {
          }
        },
      })
    );
  };
  useEffect(() => {
    if (location?.state?.classcomntFlag == true) {
      myRef?.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    } else {
      window.scrollTo(0, 0);
    }
    classDetails();
    classList();
    document.title = "Classdetails";
  }, [id]);

  const handleSubmitLike = (status) => {
    let params = {
      class_id: getDetails?.id,
      status: status,
    };
    dispatch(
      createClassLike({
        ...params,
        cb(res) {
          if (res.status) {
            classDetails();
            //toast.success("You Liked a recipe")
          } else {
            //recipeDetails()
          }
        },
      })
    );
  };
  const handleFav = (e) => {
    e.preventDefault();
    let params = {
      class_id: getDetails?.id,
    };
    dispatch(
      favClass({
        ...params,
        cb(res) {
          if (res.status) {
            classDetails();
          } else {
          }
        },
      })
    );
  };
  const ratingChanged = (value) => {
    setRate(value);
  };

  const getAllComments = (pageCmt = page, id) => {
    let params = {
      page: pageCmt,
      limit: 4,
      model_id: id,
      model_type: 2,
    };
    dispatch(
      getAllreviewClass({
        ...params,
        cb(res) {
          if (res?.data) {
            if (parseInt(res?.data?.data?.current_page) == 1) {
              setCommentsList(handleComentReplies(res?.data?.data?.list));
              setTotalCommentRecords(res?.data?.data?.totalRecords);
            } else {
              setCommentsList((commentList) => {
                const cmt = [...commentList, ...res?.data?.data?.list];
                return cmt;
              });
            }
            setTotalComments(res?.data?.data?.totalRecords);
            setCommentKey(Math.random());
            setPage(res?.data?.data?.current_page);
          } else {
          }
        },
      })
    );
  };

  const formik = useFormik({
    initialValues: {
      review: "",
    },
    validationSchema: Yup.object({
      review: Yup.string().required("Please add a review"),
    }),
    onSubmit: (values) => {
      let params = {
        model_id: getDetails?.id,
        model_type: 2,
        rate: Number(rate),
        review: values?.review,
      };
      setLoadingSendReview(true);
      dispatch(
        addRating({
          ...params,
          cb(res) {
            setLoadingSendReview(false);
            if (res?.data?.status_code == 400) {
              setErrorMessage({ error: res.data.message });
              return false;
            }
            if (res?.data?.status_code == 200) {
              setErrorMessage("");
              setRate(0);
              formik.resetForm();
              getAllComments(1, id);
              classDetails();
              setRateKey(Math.random());
            } else {
            }
          },
        })
      );
    },
  });
  const handleComentReplies = (comment) => {
    let _comment = [];
    comment.map((data) => {
      _comment.push({ ...data, itemToShow: 2 });
    });
    return _comment;
  };
  const handleViewMore = (i) => {
    let _commentList = [...commentList];
    _commentList[i].itemToShow += 2;
    setCommentsList(_commentList);
  };
  const handlePostCommentsReply = (id, index) => {
    if (!replyComments) {
      toast.error("Add a comment");
      return;
    }
    let params = {
      parent_id: id,
      comments: replyComments,
    };
    setLoadingSendReplyComment(true);
    dispatch(
      replyComment({
        ...params,
        cb(res) {
          setLoadingSendReplyComment(false);
          if (res.status) {
            // getAllComments(id,page);
            setShowCommentBox(false);
            setReplyComments("");

            setCommentsList((commentList) => {
              var newData = [...commentList];
              const newComments = [
                res.data.data,
                ...commentList[index].comments,
              ];
              newData[index] = {
                ...newData[index],
                comments: newComments,
              };

              return newData;
            });
          } else {
          }
        },
      })
    );
  };

  const handleShowInput = (id) => {
    setShowCommentBox(true);
    setUserById(id);
  };

  const handleBookClass = () => {
    let params = {
      class_id: getDetails?.id,
    };
    setLoading(true);
    dispatch(
      addBooking({
        ...params,
        cb(res) {
          if (res.status) {
            classDetails();
            setLoading(false);
          } else {
          }
        },
      })
    );
  };
  const handlCancelBooking = () => {
    let params = {
      class_id: getDetails?.id,
    };
    setLoading(true);
    dispatch(
      cancelBooking({
        ...params,
        cb(res) {
          if (res.status) {
            toast.success("Booking Cancelled Successfully");
            classDetails();
            setLoading(false);
          } else {
          }
        },
      })
    );
  };
  const handleJoinLive = () => {
    let params = {
      class_id: liveClasses?.id,
    };
    dispatch(
      generateTokenForStreaming({
        ...params,
        cb(res) {
          if (res?.data) {
            navigate("/liveStreamingUser", {
              state: {
                tokenId: res?.data?.data?.token,
                roomID: res?.data?.data?.roomID,
                appId: res?.data?.data?.appId,
                channel: res?.data?.data?.channelName,
                uid: res?.data?.data?.uid,
                classId: liveClasses?.id,
              },
            });
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };

  const handleClickDown = () => {
    window.scrollTo({ top: 10000, behavior: "smooth" });
  };

  return (
    <>
      <section className="recipeBanner favoritesBanner pb-2">
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 text-center pt-0 m-auto">
              <Link to={"/classespage"}>
                <p className="pt-5 mt-4 text">
                  Classes{" "}
                  <span>
                    <i class="fa fa-angle-right"></i>
                    <i class="fa fa-angle-right"></i>
                  </span>{" "}
                  <span className="colorPink">{getDetails?.title}</span>{" "}
                </p>
              </Link>
              <h2 className="bigHeading pt-3 ">{getDetails?.title}</h2>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="perfectbreakfast">
          {getDetails?.id == liveClasses?.id ? (
            <div className="container">
              <div className="sliderBoxOuter sliderBoxdetail">
                {liveClasses?.thumbnail_image ? (
                  <div className="sliderImgOuter">
                    <img src={liveClasses?.thumbnail_image} alt="" />
                  </div>
                ) : (
                  <Spinner
                    className="pageLoader"
                    animation="grow"
                    size="lg"
                    role="status"
                    aria-hidden="true"
                  />
                )}

                <div className="sliderBoxInner">
                  <div className="d-flex justify-content-between align-items-center pb-5">
                    <div className="d-flex">
                      <div
                        className="subtext classtextbox me-2"
                        style={{ backgroundColor: "#57425a" }}
                      >
                        <img className="star" src={Images.star} alt="" />
                        {totalrating}
                      </div>
                      <div
                        className="classtextbox subtext"
                        style={{ backgroundColor: "#57425a" }}
                      >
                        <img src={Images.commenticon} alt="" />
                        {totalComments}
                      </div>
                      <div
                        className="classtextbox subtext ms-2"
                        style={{ backgroundColor: "#57425a" }}
                      >
                        <img src={Images.eye} alt="" />
                        {totalViews}
                      </div>
                    </div>
                    <button className="button">
                      <span className="youtubeIcon">
                        {" "}
                        <img src={Images.youtube} alt="" />
                      </span>{" "}
                      <span>
                        <img src={Images.reddot} alt="" />{" "}
                      </span>
                      Live recipes{" "}
                    </button>
                  </div>
                  <div className="col-md-7">
                    <button
                      className="button"
                      onClick={(e) => handleJoinLive(e)}
                    >
                      <img className="me-2" src={Images.eye} alt="" />
                      {totalViews}+ Join
                    </button>
                    <p className="text_ colorBlack mb-0">
                      {liveClasses?.title}
                    </p>
                    <p className="text_ colorBlack mb-0 pararecipe">
                      {liveClasses?.description}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="">
                      <p className="bigText font-10 text-uppercase m-0 pb-1 colorWhite">
                        instructor:
                      </p>
                      {liveClasses?.instructors?.map((data) => {
                        return (
                          <div className="d-flex">
                            <div className="d-flex">
                              <img
                                className="instructionUser"
                                src={data?.img_url}
                                alt=""
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="" onClick={(e) => handleJoinLive(e)}>
                      <button
                        className="login-btn joinusBtn"
                        type="button"
                        onClick={(e) => handleJoinLive(e)}
                      >
                        Join
                      </button>
                      <span className="center-img">
                        <img
                          src={Images.centerrectangle}
                          alt=""
                          onClick={(e) => handleJoinLive(e)}
                        />
                      </span>
                      <span className="circle-btn joinBtn">
                        <img
                          src={Images.arrow}
                          alt=""
                          onClick={(e) => handleJoinLive(e)}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="mainDiv">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="bc_mainimage classdetailbg">
                      {getDetails?.type == 1 ? (
                        <>
                          <p className="commingsoonText_">Coming Soon</p>
                          {getDetails?.thumbnail_image ? (
                            <img
                              alt=""
                              src={getDetails?.thumbnail_image}
                              //ref={vidRef}
                              className="img-fluid w-100"
                              controls
                              autoPlay
                              loop
                            />
                          ) : (
                            <Spinner
                              className="pageLoader"
                              animation="grow"
                              size="lg"
                              role="status"
                              aria-hidden="true"
                            />
                          )}
                          <div className="d-flex justify-content-center align-items-center booknowBtn_">
                            {getDetails?.is_booked == true ? (
                              <>
                                <button
                                  className="login-btn bookclassBtn"
                                  type="button"
                                  disabled
                                >
                                  Already Booked
                                </button>
                                <button
                                  className="cancelBooking"
                                  type="button"
                                  onClick={(e) => handlCancelBooking(e)}
                                >
                                  Cancel Booking
                                  {loading && (
                                    <Spinner
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                      className="ms-1"
                                    />
                                  )}{" "}
                                </button>
                              </>
                            ) : (
                              <>
                                {userInfo?.isSubscribe == true ? (
                                  <>
                                    <button
                                      className="login-btn"
                                      type="button"
                                      onClick={(e) => handleBookClass(e)}
                                    >
                                      Book Now
                                      {loading && (
                                        <Spinner
                                          as="span"
                                          animation="border"
                                          size="sm"
                                          role="status"
                                          aria-hidden="true"
                                          className="ms-1"
                                        />
                                      )}{" "}
                                    </button>
                                    <span className="center-img">
                                      <img
                                        className="darkrechtangle"
                                        src={Images.centerrectangle}
                                        alt=""
                                        onClick={(e) => handleBookClass(e)}
                                      />
                                      <img
                                        className="whiterectabgle"
                                        src={Images.centerrectanglewhite}
                                        alt=""
                                        onClick={(e) => handleBookClass(e)}
                                      />
                                    </span>
                                    <span className="circle-btn">
                                      <img
                                        className="whitearrow"
                                        src={Images.arrow}
                                        alt=""
                                      />
                                      <img
                                        className="darkarrow"
                                        src={Images.arrowrotatedark}
                                        alt=""
                                      />
                                    </span>
                                  </>
                                ) : (
                                  ""
                                )}
                              </>
                            )}
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      {Boolean(getDetails?.class_video) ? (
                        <>
                          <div className="vedioOuter">
                            {isVideoPlayed === false ? (
                              <div className="playVedio">
                                <img
                                  src={Images.vedioicon}
                                  onClick={() => handlePlayVideo()}
                                  alt="Play Video"
                                />
                              </div>
                            ) : (
                              <img
                                className="pausedIcon"
                                src={Images.paused}
                                onClick={() => handlePauseVideo()}
                                alt="Pause Video"
                              />
                            )}
                            <video
                              onPause={() => {
                                setIsVideoPlayed(false);
                              }}
                              onPlay={() => {
                                setIsVideoPlayed(true);
                              }}
                              poster={getDetails?.thumbnail_image}
                              ref={vidRef}
                              className="img-fluid w-100 vedio"
                              controls
                              autoplay
                              loop
                            >
                              <source src={getDetails?.class_video} />
                            </video>
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      {getDetails?.type == 1 ? (
                        <>
                          <div className="watchvediotext watchdetailpage ">
                            <img
                              className=" img-fluid loaderImg_ animated infinite rotate-full"
                              src={Images.loader}
                              alt=""
                            />
                            <p className="DefaultPara  colorBlack">
                              This Class is not live yet
                            </p>
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      {watchVideo ||
                        (getDetails?.type == 2 && (
                          <p className="DefaultPara watchvediotext colorWhite textWatchVdeo">
                            Watch Class Video
                          </p>
                        ))}

                      {getDetails?.type == 2 ? (
                        <div className="ratinglike_Options">
                          <div className="AboutUs_Startrating">
                            <Link className="Aboutus_star" href="#">
                              <img
                                className=" img-fluid Aboutus_Star"
                                src={Images.yellowstar}
                                alt="startRating"
                              />
                              {getDetails?.rating > 0 && (
                                <span className="text colorWhite">
                                  {getDetails?.rating.toFixed(1)}
                                </span>
                              )}
                            </Link>
                          </div>
                          <div className="AboutUs_likeComments">
                            <div className="AboutUs_likecomment_show text colorWhite">
                              <Link href="#">
                                {/* <img src={Images.solarlike} alt="AboutUs_Likeimg" /> */}
                                {getDetails?.is_like == 1 ? (
                                  <img
                                    src={Images.likefillicon}
                                    alt="AboutUs_Likeimg"
                                  />
                                ) : (
                                  <img
                                    src={Images.unfilled}
                                    alt="AboutUs_Likeimg"
                                  />
                                )}
                              </Link>
                              {getDetails?.like_count}
                            </div>

                            <div className="AboutUs_likecomment_show text colorWhite">
                              <Link href="#">
                                <img
                                  src={Images.commenticon}
                                  alt="Aboutus_comments"
                                />
                              </Link>
                              {getDetails?.comments_count}
                            </div>
                            <div className="AboutUs_likecomment_show text colorWhite">
                              <Link href="#">
                                <img src={Images.pinkeye} alt="eyeicon_img" />
                              </Link>
                              {getDetails?.view_count}
                            </div>
                          </div>
                          {getDetails?.is_favorite == true ? (
                            <div className="Aboutus_heartimg">
                              <Link href="#">
                                <img
                                  className="AboutUs_hearticon fillHewatchVideoart"
                                  src={Images.Vector}
                                  alt="eyeicon_img"
                                  onClick={(e) => handleFav(e)}
                                />
                              </Link>
                            </div>
                          ) : (
                            <div className="Aboutus_heartimg">
                              <Link href="#">
                                <img
                                  className="AboutUs_hearticon"
                                  src={Images.pinkheart}
                                  alt="eyeicon_img"
                                  watchVideo
                                  onClick={(e) => handleFav(e)}
                                />
                              </Link>
                            </div>
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="d-flex align-items-center justify-content-between pt-4 pb-3 instrutOut">
                      <div className="d-flex cookingOut">
                        <div className="d-flex cookingOuter">
                          <img
                            className="Aboutus_clockcook_showimg"
                            src={Images.celendericon}
                            alt="clock_img"
                          />
                          <div className="ms-3">
                            <p className="subtext medText mb-0">Date </p>
                            <p className="subtext mb-0">
                              {moment(getDetails?.date).format("DD-MM-YYYY")}
                            </p>
                          </div>
                        </div>
                        {getDetails?.type == 1 ? (
                          <div className="d-flex ms-5 cookingOuter ">
                            <img
                              className="Aboutus_clockcook_showimg"
                              src={Images.clockicon}
                              alt="servingimg"
                            />

                            <div className="ms-3">
                              <p className="subtext medText mb-0">Time</p>
                              <p className="subtext mb-0">
                                {getDetails?.time}
                              </p>{" "}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {getDetails?.type == 1 ? (
                          <div className="d-flex ms-5 cookingOuter">
                            <img
                              className="Aboutus_clockcook_showimg"
                              src={Images.duration}
                              alt="user_img"
                            />
                            <div className="ms-3">
                              <p className="subtext medText mb-0">Duration </p>
                              <p className="subtext mb-0">
                                {getDetails?.duration} Min
                              </p>{" "}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="d-flex align-items-center  InstructorOuter">
                        <span>
                          <img src={Images.usericon} alt="" />
                        </span>
                        <p className="bigText font-10  mb-0 me-2 ms-2">
                          Instructor:
                        </p>
                        {getDetails?.instructors?.map((data, index) => {
                          return (
                            <div
                              className="d-flex instructionUserOuter"
                              key={index}
                            >
                              <div className="">
                                <img
                                  className="instructionUser"
                                  src={
                                    data?.img_url
                                      ? data?.img_url
                                      : Images.userimg
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="instructorName">
                                {data.full_name}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <h5 className="medText py-4 topText">Class Overview</h5>
                    <div className="solid_border"></div>
                    <div className="dashed_border"></div>
                  </div>

                  <div className="col-lg-12">
                    <p className="text">{getDetails?.description}</p>
                  </div>
                </div>
              </div>

              {getDetails?.type == 1 ? (
                <>
                  <div className="d-flex justify-content-center align-items-center">
                    {getDetails?.is_booked == true ? (
                      <button
                        className="login-btn bookclassBtn"
                        type="button"
                        disabled
                      >
                        Already Booked
                      </button>
                    ) : (
                      <>
                        {userInfo?.isSubscribe == true ? (
                          <>
                            <button
                              className="login-btn"
                              type="button"
                              onClick={(e) => handleBookClass(e)}
                            >
                              Book Now
                              {loading && (
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                  className="ms-1"
                                />
                              )}{" "}
                            </button>
                            <span className="center-img">
                              <img
                                className="darkrechtangle"
                                src={Images.centerrectangle}
                                alt=""
                                onClick={(e) => handleBookClass(e)}
                              />
                              <img
                                className="whiterectabgle"
                                src={Images.centerrectanglewhite}
                                alt=""
                                onClick={(e) => handleBookClass(e)}
                              />
                            </span>
                            <span className="circle-btn">
                              <img
                                className="whitearrow"
                                src={Images.arrow}
                                alt=""
                              />
                              <img
                                className="darkarrow"
                                src={Images.arrowrotatedark}
                                alt=""
                              />
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </div>
                </>
              ) : (
                ""
              )}

              {getDetails?.type == 2 ? (
                <section className="recommendtoOthers">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="recommendOthers">
                          <p className="medText">
                            Would You Like to Recommend this Class to Other
                            Users ?
                          </p>
                          <div className="d-flex likeDislikeOuter">
                            <div
                              className="likeDislike me-5"
                              onClick={() => handleSubmitLike(1)}
                            >
                              <Link href="#">
                                {getDetails?.is_like === 1 ? (
                                  <img
                                    className="img-fluid mb-2"
                                    src={Images.Like}
                                    alt="like"
                                  />
                                ) : (
                                  <img
                                    className="img-fluid dislike_"
                                    src={Images.thumblike}
                                    alt="like"
                                  />
                                )}

                                <span className="font-10 text-uppercase">
                                  {" "}
                                  Recommend{" "}
                                </span>
                              </Link>
                            </div>

                            <div
                              className="likeDislike"
                              onClick={() => handleSubmitLike(2)}
                            >
                              <Link href="#">
                                {getDetails?.is_like === 2 ? (
                                  <img
                                    className="img-fluid"
                                    src={Images.Dbold}
                                    alt="unlike"
                                  />
                                ) : (
                                  <img
                                    className="img-fluid  "
                                    src={Images.thumblikedown}
                                    alt="unlike"
                                  />
                                )}

                                <div className="text-center">
                                  <span className="font-10 text-uppercase">
                                    {" "}
                                    Not
                                  </span>
                                  <p className="font-10 text-uppercase">
                                    {" "}
                                    Recommend
                                  </p>
                                </div>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              ) : (
                ""
              )}

              {getDetails?.type == 2 ? (
                <div className="container py-5">
                  <div className="row">
                    {getDetails?.is_review !== true ? (
                      <div className="col-lg-6">
                        <div className="Aboutus_Ratetherecipe mt-3">
                          <div className="Aboutus_rateon_recipe">
                            <p className="subheading">Rate the Class </p>
                            <p className="text">
                              Your email address will not be published.Required
                              fields are marked*
                            </p>
                          </div>
                          <div className="Aboutus_reciperating_Star ">
                            <Link
                              className="AboutUs_starstyling_rating "
                              href="#"
                            >
                              <img
                                className=" img-fluid Star_rating"
                                src={Images.yellowstar}
                                alt="startRating"
                              />
                              {getDetails?.rating > 0 && (
                                <span className="text_ medText">
                                  {" "}
                                  {getDetails?.rating?.toFixed(1)}
                                </span>
                              )}
                            </Link>
                          </div>
                        </div>

                        <p className="DefaultPara medText">Rating</p>
                        <div key={rateKey}>
                          <ReactStars
                            count={5}
                            onChange={ratingChanged}
                            size={24}
                            value={rate}
                            activeColor="#ffd700"
                          />
                        </div>

                        <form onSubmit={formik.handleSubmit}>
                          <p className="DefaultPara medText">Comment</p>
                          <div className="container p-0">
                            <div className="row">
                              <div className="col-md-12">
                                <textarea
                                  className="contactUsforminput_field contactusTextarea"
                                  type="text"
                                  placeholder="Message"
                                  rows="4"
                                  cols="50"
                                  {...formik.getFieldProps("review")}
                                ></textarea>
                                {formik.touched.review ? (
                                  <div className="validationMsg">
                                    {formik.errors.review}
                                  </div>
                                ) : null}
                              </div>
                              <div className="col-md-6">
                                <input
                                  className="contactUsforminput_field"
                                  type="text"
                                  placeholder="Your Name"
                                  value={infoUser?.first_name}
                                  disabled
                                />
                              </div>
                              <div className="col-md-6">
                                <input
                                  className="contactUsforminput_field"
                                  type="text"
                                  placeholder="Email"
                                  value={infoUser?.email}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="mt-4">
                              <button
                                className="login-btn"
                                type="button"
                                onClick={formik.handleSubmit}
                              >
                                Post Comment
                                {loadingSendReview && (
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    className="ms-1"
                                  />
                                )}{" "}
                              </button>
                              <span className="center-img">
                                <img
                                  src={Images.centerrectangle}
                                  alt=""
                                  onClick={formik.handleSubmit}
                                />
                              </span>
                              <span className="circle-btn">
                                <img
                                  src={Images.arrow}
                                  alt=""
                                  onClick={formik.handleSubmit}
                                />
                              </span>
                              {errorMessage ? (
                                <span
                                  className={
                                    errorMessage?.error
                                      ? "validationMsg"
                                      : "d-none"
                                  }
                                >
                                  {errorMessage?.error
                                    ? errorMessage?.error
                                    : ""}
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </form>
                      </div>
                    ) : (
                      ""
                    )}

                    <div
                      className={`col-lg-6 ms-auto ${getDetails?.is_review == true
                        ? "col-lg-12 ms-auto"
                        : "col-lg-6 ms-auto"
                        }`}
                    >
                      <div ref={myRef}>
                        <div className="commentsright_Side mt-3">
                          <div className="aboutus_comments">
                            <p className="subheading">Comments</p>
                            <p className="text" style={{ marginTop: "10px" }}>
                              {totalComments
                                ? `${totalComments} Comments posted by users`
                                : "No Comments posted yet"}
                            </p>
                          </div>
                          <div className="about_commentson_">
                            <img
                              className="img-fluid"
                              src={Images.bigiconcomment}
                              alt="comments_img"
                            />
                          </div>
                        </div>

                        <div key={commentKey}>
                          {commentList?.map((data, index) => {
                            return (
                              <>
                                <div className="aboutus_Anna">
                                  <div className="profile_outer">
                                    <img
                                      className="img-fluid"
                                      src={
                                        data?.created_by?.profile_image
                                          ? data?.created_by?.profile_image
                                          : Images.defaultUser
                                      }
                                      //src={Images.heart}
                                      alt="Anna"
                                    />
                                    <div className="ms-1 me-1 d-flex w-100 align-items-center">
                                      <div className="me-1">
                                        <div className="classdetailText">
                                          <p className="DefaultPara medText m-0">
                                            {data?.created_by?.full_name}
                                          </p>
                                          <p className="subtext m-0">
                                            {moment(data?.createdAt).format(
                                              "MMMM D, YYYY"
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                      <div>
                                        <ReactStars
                                          count={5}
                                          value={data?.rate}
                                          size={24}
                                          activeColor="#ffd700"
                                          edit={false}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="ReportsOuter">
                                    <div className="Readme_button">
                                      {data?.created_by?.id !== userInfo?.id && (
                                        <button
                                          className="reply_button subtext colorPink "
                                          onClick={() => {
                                            if (
                                              data?.created_by?.id === userInfo?.id
                                            ) {
                                              handleShowInput(data?.created_by?.id);
                                            } else {
                                              handleShowInput(data?.id);
                                            }
                                          }}
                                        >
                                          Reply
                                          <img
                                            className="ms-2"
                                            src={Images.rotatearrowpink}
                                            alt=""
                                          />
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <p className="text">{data?.review}</p>
                                </div>
                                <div className="larrow_">
                                  {/* Replied comments */}
                                  {data?.comments?.map((replied, i) => {
                                    if (i < data?.itemToShow)
                                      return (
                                        <>
                                          <img
                                            src={Images.aboutusLarrow}
                                            alt=""
                                          />
                                          <div className="aboutus_leena">
                                            <div className="leena_Profile">
                                              <div className="d-flex align-items-center">
                                                <div className="profile_outer">
                                                  <img
                                                    className="img-fluid"
                                                    src={
                                                      replied?.created_by
                                                        ?.profile_image
                                                        ? replied?.created_by
                                                          ?.profile_image
                                                        : Images.defaultUser
                                                    }
                                                    //src={Images.heart}
                                                    alt="Anna"
                                                  />
                                                </div>
                                                <div className="ms-3 me-3 replyComments">
                                                  <p className="DefaultPara medText m-0">
                                                    {
                                                      replied?.created_by
                                                        ?.full_name
                                                    }
                                                  </p>
                                                  <p className="subtext m-0">
                                                    {moment(
                                                      replied?.createdAt
                                                    ).format("MMMM D, YYYY")}
                                                  </p>
                                                </div>
                                              </div>
                                              <p className="text replyCommentText">
                                                {replied?.comments}
                                              </p>
                                            </div>
                                            <div className="anna_Report_Outer"></div>
                                          </div>
                                        </>
                                      );
                                  })}
                                  {data.itemToShow < data?.comments?.length ? (
                                    <div>
                                      <button
                                        className="mt-3 viewMore"
                                        onClick={() => handleViewMore(index)}
                                      >
                                        View More
                                        <img
                                          src={Images.downArrow}
                                          alt="downarrow"
                                          className="img-fluid viewMoreIcon"
                                        />
                                      </button>
                                    </div>
                                  ) : null}
                                  {showCommentBox && userId == data?.id && (
                                    <form>
                                      <p className="DefaultPara medText">
                                        Comment
                                      </p>
                                      <div className="container p-0">
                                        <div className="row">
                                          <div className="col-md-12">
                                            <textarea
                                              className="contactUsforminput_field contactusTextarea"
                                              type="text"
                                              placeholder="Your Comment"
                                              rows="4"
                                              cols="50"
                                              value={replyComments}
                                              onChange={(e) =>
                                                setReplyComments(
                                                  e.target.value.trimStart()
                                                )
                                              }
                                            ></textarea>
                                          </div>
                                        </div>
                                        <div className="mt-4">
                                          <button
                                            className="login-btn"
                                            type="button"
                                            onClick={() =>
                                              handlePostCommentsReply(
                                                data?.id,
                                                index
                                              )
                                            }
                                          >
                                            {loadingSendReplyComment && (
                                              <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                              />
                                            )}{" "}
                                            Post Comment
                                          </button>
                                          <span className="center-img">
                                            <img
                                              src={Images.centerrectangle}
                                              alt=""
                                              onClick={() =>
                                                handlePostCommentsReply(
                                                  data?.id,
                                                  index
                                                )
                                              }
                                            />
                                            {loadingSendReview && (
                                              <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                              />
                                            )}{" "}
                                          </span>
                                          <span className="circle-btn">
                                            <img
                                              src={Images.arrow}
                                              alt=""
                                              onClick={() =>
                                                handlePostCommentsReply(
                                                  data?.id,
                                                  index
                                                )
                                              }
                                            />
                                            {loadingSendReview && (
                                              <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                              />
                                            )}{" "}
                                          </span>
                                        </div>
                                      </div>
                                    </form>
                                  )}
                                </div>
                              </>
                            );
                          })}
                        </div>
                        {commentList?.length > 3 &&
                          totalCommentsrecords != commentList?.length ? (
                          <button
                            className="DefultPara colorPink viewmore"
                            onClick={() => {
                              getAllComments(
                                parseInt(page) + 1,
                                getDetails?.id
                              );
                            }}
                          >
                            View More
                            <span>
                              <img
                                className="ms-2"
                                src={Images.downArrow}
                                alt=""
                              />
                            </span>
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          )}

          <section className="ourCustomers  RecipeTabsOuter RecipesSwiper">
            <div className="container">
              {getClass.length > 0 ? (
                <>
                  <div className="downarrow recipedownarrow upcomingclassarrow">
                    <img
                      src={Images.downarrowWhite}
                      alt=""
                      onClick={handleClickDown}
                    />
                  </div>
                  <div className="py-4">
                    <p className="subheading text-center px-3">
                      Upcoming Classes You May Also Book
                    </p>
                    {/* <p className="text text-center">
                      Lorem Ipsum is simply dummy text of the printing is the
                      industry.{" "}
                    </p> */}
                  </div>
                </>
              ) : (
                ""
              )}

              <div className="row">
                <Swiper
                  navigation={true}
                  className={
                    getClass?.length > 4 ? "mySwiper" : " mySwiper hide-icons"
                  }
                  // className="mySwiper"
                  spaceBetween={20}
                  breakpoints={{
                    1200: {
                      slidesPerView: 3,
                    },
                    991: {
                      slidesPerView: 2,
                    },
                    480: {
                      slidesPerView: 1,
                    },
                  }}
                >
                  {getClass
                    ?.filter((val) => val.id !== liveClasses?.id)
                    .map((data, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <div className="col-md-12">
                            <div className="classBox">
                              <div className="classBoxInner">
                                <div className="classVedioImg">
                                  <img
                                    className="img-fluid w-100"
                                    onClick={() =>
                                      handleViewClassDetails(data?.id)
                                    }
                                    src={
                                      data?.thumbnail_image
                                        ? data?.thumbnail_image
                                        : Images.vedioImg
                                    }
                                    alt=""
                                  />
                                </div>
                                <div className="d-flex align-items-center justify-content-between">
                                  <div className="d-flex">
                                    <div className="classtextbox classdetailtext subtext">
                                      <img
                                        className="me-2"
                                        src={Images.clender}
                                        alt=""
                                      />
                                      {moment(data?.date).format(
                                        "MMMM DD, YYYY"
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <h5 className="bigText py-2">{data?.title}</h5>
                                <p className="subtext pararecipe ">
                                  {data?.description}
                                </p>
                                <div className="d-flex justify-content-between align-items-end">
                                  {data?.instructors?.length > 0 ? (
                                    <div className="">
                                      <p className="bigText font-10 text-uppercase m-0 pb-1">
                                        instructor:
                                      </p>

                                      <div className="d-flex">
                                        {data?.instructors?.map((data) => {
                                          return (
                                            <img
                                              className="instructionUser"
                                              src={data?.img_url}
                                              alt=""
                                            />
                                          );
                                        })}
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}

                                  <div className="">
                                    {data?.is_booked == false ? (
                                      <button
                                        className="login-btn bookclassBtn"
                                        type="button"
                                        onClick={() =>
                                          handleViewClassDetails(data?.id)
                                        }
                                      >
                                        Book Classes
                                      </button>
                                    ) : (
                                      <button
                                        className="login-btn bookclassBtn"
                                        type="button"
                                        onClick={() =>
                                          handleViewClassDetails(data?.id)
                                        }
                                      >
                                        Already Booked
                                      </button>
                                    )}
                                    <span className="center-img">
                                      <img
                                        src={Images.centerrectangle}
                                        alt=""
                                      />
                                    </span>
                                    <span className="circle-btn">
                                      <img src={Images.arrow} alt="" />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                </Swiper>
              </div>
            </div>
          </section>
        </div>
      </section>
      {modalDetail.show && (
        <CustomModal
          key={key}
          show={modalDetail.show}
          backdrop="static"
          showCloseBtn={false}
          isRightSideModal={false}
          mediumWidth={false}
          className={
            modalDetail.flag === "reports" ? "commonWidth customContent" : ""
          }
          ids={modalDetail.flag === "reports" ? "reports" : ""}
          child={
            modalDetail.flag === "reports" ? (
              <ReportModal
                onCloseModal={() => {
                  handleOnCloseModal();
                }}
                reportId={getDetails?.id}
                userId={modalDetail?.id}
              />
            ) : (
              ""
            )
          }
          header={
            <div className="modalHeader_">
              <div className="common_">
                {modalDetail.flag === "reports" ? (
                  <h2 className=""></h2>
                ) : modalDetail.flag === "Delete" ? (
                  <h2 className="headingSmall_"></h2>
                ) : (
                  ""
                )}
              </div>
            </div>
          }
          onCloseModal={() => handleOnCloseModal()}
        />
      )}
    </>
  );
};

export default Classdetailpage;
