import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyCWIfxcKSYaQVdesEwhTwY3fFHdIii4g_Q",
    authDomain: "lynnettes-kitchen.firebaseapp.com",
    projectId: "lynnettes-kitchen",
    storageBucket: "lynnettes-kitchen.appspot.com",
    messagingSenderId: "791849448469",
    appId: "1:791849448469:web:b88c8c379549efd4990a1c",
    measurementId: "G-PV8H5F4BFV"
};

const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);