import { all, call, put, takeLatest } from "redux-saga/effects";
import { ApiClient } from "../../../utilities/api";
import {
  onErrorStopLoad,
  setAllUser,
  setGetUserById,
  setDashboardUser,
  setdeleteUserByadmin,
  setRemovePicture,
  setUserStatus,
  setUpdateprofile,
  setUploadPicture,
  setCreateLike,
  setAddRating,
  setAllreviews,
  setReplyComment,
  setFavRecipe,
  setReportComment,
  setFavRecipeList,
  setSuggestion,
  setCreateSubscription,
  setGetCardsList,
  setAddCards,
  setDeleteCards,
  setDefaultCard,
  setGenerateTokenForStreaming,
  setTrendingRecipes,
  setCreateClassLike,
  setFavClass,
  setAllreviewClass,
  setFavClassList,
  setAddBooking,
  setCancelBooking,
  setBooking,
  setShowLiveComments,
  setLoginUserProfile,
  setUpdatePassword,
  setCancelSubscription,
  setUpdateSubscription,
  setJoinliveStream,
  setPostOneSignalSubscriptionId,
  setCheckliveStream,
  setCheckCurrentEvent,
  setNotificationList,
  setClearAllNotificationList,
  setIsNotify,
  setIsRead,
  setTotalCountNotification,
  setDeleteComment,
} from "../../slices/user";
import ApiPath from "../../../constants/apiPath";
import { toast } from "react-toastify";

// Worker saga will be fired on USER_FETCH_REQUESTED actions


function* deleteComment(action) {
  try {
    const resp = yield call(
      ApiClient.delete,
      (action.url = `${ApiPath.UserApiPath.DELETE_COMMENT}/${action.payload.id}`),
      (action.payload = action.payload)
    );
    yield put(setDeleteComment(resp.data.payload));
    yield call(action.payload.cb, (action.res = resp));
  } catch (e) {
    yield put(setDeleteComment({}));
    toast.error(e.response.data.msg);
  }
}



function* getAlluser(action) {
  let url = `${ApiPath.UserApiPath.GETALLUSER}?page=${action.payload.page}&limit=${action.payload.limit}`;

  if (Boolean(action.payload.keyword)) {
    url = `${url}&keyword=${action.payload.keyword}`;
  }
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = url),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setAllUser(resp.data));
      yield call(action.payload.cb, (action.res = resp));
      //toast.success(action.res.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setAllUser({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* updateProfile(action) {
  try {
    const resp = yield call(
      ApiClient.patch,
      (action.url = ApiPath.UserApiPath.UPDATE_PROFILE),
      (action.payload = action.payload)
    );
    yield put(setUpdateprofile(resp.data.payload));
    yield call(action.payload.cb, (action.res = resp));
  } catch (e) {
    yield put(setUpdateprofile({}));
    toast.error(e.response.data.msg);
  }
}

function* uploadPicture(action) {
  try {
    const resp = yield call(
      ApiClient.postFormData,
      (action.url = ApiPath.UserApiPath.UPLOAD_PICTURE),
      (action.payload = action.payload)
    );
    if (resp.status) {
      //yield put(setUploadPicture(resp.data.payload));
      yield call(action.payload.cb, (action.res = resp));
      //toast.success(action.res.data.msg);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setUploadPicture({}));
    toast.error(e.response.data.msg);
  }
}
function* dashboardUser(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = ApiPath.UserApiPath.DASHBOARD_USER),
      (action.payload = action.payload)
    );
    yield put(setDashboardUser(resp.data));
    yield call(action.payload.cb, (action.res = resp));
  } catch (e) {
    yield put(setDashboardUser({}));
  }
}
function* loginUserProfile(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = ApiPath.UserApiPath.GET_ADMINUSER),
      (action.payload = action.payload)
    );
    yield put(setLoginUserProfile(resp.data));
    yield call(action.payload.cb, (action.res = resp));
  } catch (e) {
    yield put(setLoginUserProfile({}));
  }
}

function* getUserById(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.UserApiPath.GETUSER_BYID}/${action.payload.id}`)
    );
    if (resp.status) {
      yield put(setGetUserById(resp.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setGetUserById({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.msg);
  }
}
function* deleteUserByadmin(action) {
  try {
    const resp = yield call(
      ApiClient.delete,
      (action.url = `${ApiPath.UserApiPath.DELTEUSER_BYADMIN}/${action.payload.id}`)
    );

    //yield put(setdeleteUserByadmin(resp.data));
    yield call(action.payload.cb, (action.res = resp));
    toast.success(action.res.data.msg);
  } catch (e) {
    yield put(setdeleteUserByadmin({}));
  }
}

function* removePicture(action) {
  try {
    const resp = yield call(
      ApiClient.patch,
      (action.url = ApiPath.UserApiPath.REMOVE_PICTURE),
      (action.payload = action.payload)
    );
    yield call(action.payload.cb, (action.res = resp));
  } catch (e) {
    yield put(setRemovePicture({}));
  }
}

function* userStatus(action) {
  try {
    const resp = yield call(
      ApiClient.patch,
      (action.url = `${ApiPath.UserApiPath.USER_STATUS}/${action.payload.id} `),
      (action.payload = action.payload)
    );
    // yield put(setUserStatus(resp.data));
    yield call(action.payload.cb, (action.res = resp));
  } catch (e) {
    yield put(setUserStatus({}));
  }
}

function* createLike(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.reviewsComments.CREATE_LIKE),
      (action.payload = action.payload)
    );
    if (resp.status) {
      //yield put(setUploadPicture(resp.data.payload));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(action.res.data.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setCreateLike({}));
    toast.error(e.response.data.msg);
  }
}
function* createClassLike(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.reviewsComments.LIKE_CLASS),
      (action.payload = action.payload)
    );
    if (resp.status) {
      //yield put(setUploadPicture(resp.data.payload));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(action.res.data.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setCreateClassLike({}));
    toast.error(e.response.data.msg);
  }
}
function* addRating(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.reviewsComments.CREATE_REVIEW),
      (action.payload = action.payload)
    );
    if (resp.status) {
      //yield put(setAddRating(resp.data.payload));
      yield call(action.payload.cb, (action.res = resp));
      // toast.success(action.res.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setAddRating({}));
    toast.error(e.response.data.msg);
  }
}

function* getAllreviews(action) {
  let url = `${ApiPath.reviewsComments.GETALLREVIES}?page=${action.payload.page
    }&limit=${action.payload.limit}&model_id=${action.payload.model_id
    }&model_type=${1}`;
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = url),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setAllreviews(resp.data));
      yield call(action.payload.cb, (action.res = resp));
      //toast.success(action.res.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setAllreviews({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}
function* getAllreviewClass(action) {
  let url = `${ApiPath.reviewsComments.GETALLREVIES}?page=${action.payload.page
    }&limit=${action.payload.limit}&model_id=${action.payload.model_id
    }&model_type=${2}`;
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = url),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setAllreviewClass(resp.data));
      yield call(action.payload.cb, (action.res = resp));
      //toast.success(action.res.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setAllreviews({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}
function* replyComment(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.reviewsComments.REPLY_COMMENT),
      (action.payload = action.payload)
    );
    if (resp.status) {
      //yield put(setAddRating(resp.data.payload));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(action.res.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setReplyComment({}));
    toast.error(e.response.data.msg);
  }
}
function* favRecipe(action) {
  // console.log('action', action)
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.reviewsComments.FAV_RECIPE),
      (action.payload = action.payload)
    );
    if (resp.status) {
      //yield put(setAddRating(resp.data.payload));
      yield put(setFavRecipe(resp?.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(action.res.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    // console.log("testtttttt", e);
    yield put(setFavRecipe({}));
    toast.error(e.response.data.msg);
  }
}
function* favClass(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.reviewsComments.FAV_CLASS),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setFavClass(resp?.data));
      yield call(action.payload.cb, (action.res = resp));
      // toast.success(action.res.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setFavClass({}));
    toast.error(e.response.data.msg);
  }
}
function* reportComment(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.reviewsComments.REPORT),
      (action.payload = action.payload)
    );
    if (resp.status) {
      //yield put(setAddRating(resp.data.payload));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(action.res.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setReportComment({}));
    toast.error(e.response.data.msg);
  }
}
function* suggestion(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.reviewsComments.SUGGESTION),
      (action.payload = action.payload)
    );
    if (resp.status) {
      //yield put(setAddRating(resp.data.payload));
      yield call(action.payload.cb, (action.res = resp));
      //toast.success(action.res.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setSuggestion({}));
    toast.error(e.response.data.msg);
  }
}
function* favRecipeList(action) {
  let url = `${ApiPath.reviewsComments.FAV_RECIPELIST}?page=${action.payload.page}&limit=${action.payload.limit}`;
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = url),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setFavRecipeList(resp.data));
      yield call(action.payload.cb, (action.res = resp));
      //toast.success(action.res.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setFavRecipeList({}));
    yield put(onErrorStopLoad());
    //toast.error(e.response.data.message);
  }
}
function* favClassList(action) {
  let url = `${ApiPath.reviewsComments.FAV_CLASSlIST}?page=${action.payload.page}&limit=${action.payload.limit}`;
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = url),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setFavClassList(resp.data));
      yield call(action.payload.cb, (action.res = resp));
      //toast.success(action.res.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setFavClassList({}));
    yield put(onErrorStopLoad());
    //toast.error(e.response.data.message);
  }
}

function* createSubscription(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.plansSubscription.CREATE_SUBSCRIPTION),
      (action.payload = action.payload)
    );
    if (resp.status) {
      //yield put(setAddRating(resp.data.payload));
      yield put(setCreateSubscription(resp.data.payload));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(action.res.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setCreateSubscription({}));
    toast.error(e.response.data.message);
  }
}
function* cancelSubscription(action) {
  try {
    const dataToSend = { ...action.payload };
    delete dataToSend.cb;
    const resp = yield call(
      ApiClient.delete,
      (action.url = ApiPath.plansSubscription.CANCEL_SUBSCRIPTION),
      (action.params = { data: dataToSend })
    );
    if (resp.status) {
      yield call(action.payload.cb, (action.res = resp));
      toast.success(action.res.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setCancelSubscription({}));
    toast.error(e.response.data.message);
  }
}
function* updateSubscription(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.plansSubscription.updateSubscription),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield call(action.payload.cb, (action.res = resp));
      toast.success(action.res.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setUpdateSubscription({}));
    toast.error(e.response.data.message);
  }
}

function* getCards(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = ApiPath.cards.GETCARDS_LIST),
      (action.payload = action.payload)
    );
    if (resp.status) {
      //yield put(setAddRating(resp.data.payload));
      yield call(action.payload.cb, (action.res = resp));
      //toast.success(action.res.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setGetCardsList({}));
    toast.error(e.response.data.message);
  }
}
function* addCards(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.cards.CREATE_CARDS),
      (action.payload = action.payload)
    );
    if (resp.status) {
      //yield put(setAddRating(resp.data.payload));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(action.res.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setAddCards({}));
    toast.error(e.response.data.message);
  }
}
function* deleteCards(action) {
  try {
    const dataToSend = { ...action.payload };
    delete dataToSend.cb;

    const resp = yield call(
      ApiClient.delete,
      (action.url = ApiPath.cards.DELETE_CARDS),
      (action.params = { data: dataToSend })
    );
    if (resp.status) {
      //yield put(setAddRating(resp.data.payload));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(action.res.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setDeleteCards({}));
    toast.error(e.response.data.message);
  }
}
function* defaultCard(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.cards.Set_DEFAULT),
      (action.payload = action.payload)
    );
    if (resp.status) {
      //yield put(setAddRating(resp.data.payload));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(action.res.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setDefaultCard({}));
    toast.error(e.response.data.message);
  }
}
function* generateTokenForStreaming(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.AgoraLiveStreaming.GENERATE_TOKEN),
      (action.payload = action.payload)
    );
    if (resp.status) {
      //yield put(setAddRating(resp.data.payload));
      yield call(action.payload.cb, (action.res = resp));
      //toast.success(action.res.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setGenerateTokenForStreaming({}));
    toast.error(e.response.data.message);
  }
}

function* trendingRecipes(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = ApiPath.UserApiPath.TRENDING_RECIPES),
      (action.payload = action.payload)
    );
    if (resp.status) {
      //yield put(setAddRating(resp.data.payload));
      yield call(action.payload.cb, (action.res = resp));
      //toast.success(action.res.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setTrendingRecipes({}));
    toast.error(e.response.data.message);
  }
}
function* addBooking(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.bookingClasses.CREATE_BOOKING),
      (action.payload = action.payload)
    );
    if (resp.status) {
      //yield put(setAddRating(resp.data.payload));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(action.res.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setAddBooking({}));
    toast.error(e.response.data.message);
  }
}
function* cancelBooking(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.bookingClasses.CANCEL_BOOKING),
      (action.payload = action.payload)
    );
    if (resp.status) {
      //yield put(setAddRating(resp.data.payload));
      yield call(action.payload.cb, (action.res = resp));
      //toast.success(action.res.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setCancelBooking({}));
    toast.error(e.response.data.message);
  }
}
function* getBooking(action) {
  let url = `${ApiPath.bookingClasses.GET_BOOKEDCLASSES}?page=${action.payload.page}&limit=${action.payload.limit}`;
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = url),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setBooking(resp.data));
      yield call(action.payload.cb, (action.res = resp));
      //toast.success(action.res.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setAllUser({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}
function* showLiveComments(action) {
  let url = `${ApiPath.AgoraLiveStreaming.LIVE_COMMENTSLIST}?page=${action.payload.page}&limit=${action.payload.limit}&room_id=${action.payload.room_id}`;
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = url),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setShowLiveComments(resp.data));
      yield call(action.payload.cb, (action.res = resp));
      //toast.success(action.res.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setShowLiveComments({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}
function* changePassword(action) {
  try {
    const resp = yield call(
      ApiClient.put,
      (action.url = ApiPath.UserApiPath.CHANGE_PASSWORD),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setUpdatePassword(resp.status));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(action.res.data.msg);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    yield call(action.payload.cb, (action.res = e.response));
  }
}
function* contactUs(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.contactUs.CONTACT_US),
      (action.payload = action.payload)
    );
    if (resp.status) {
      //yield put(setUploadPicture(resp.data.payload));
      yield call(action.payload.cb, (action.res = resp));
      //toast.success(action.res.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    yield call(action.payload.cb, (action.res = e.response));
  }
}
function* postOneSignalSubscriptionId(action) {
  try {
    const resp = yield call(
      ApiClient.patch,
      (action.url =
        ApiPath.PushNotifications.PUSH_SUBSCRIPTIONID_FOR_NOTIFICATIONS),
      (action.payload = action.payload)
    );
    yield put(setPostOneSignalSubscriptionId(resp.data.payload));
    yield call(action.payload.cb, (action.res = resp));
  } catch (e) {
    yield put(setPostOneSignalSubscriptionId({}));
    toast.error(e.response.data.msg);
  }
}
function* joinliveStream(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.AgoraLiveStreaming.JOINLIVE),
      (action.payload = action.payload)
    );
    if (resp.status) {
      //yield put(setUploadPicture(resp.data.payload));
      yield call(action.payload.cb, (action.res = resp));
      //toast.success(action.res.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setJoinliveStream({}));
    toast.error(e.response.data.message);
  }
}
function* checkliveStream(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.AgoraLiveStreaming.CHECKLIVEEVENT}/${action.payload.room_id}`),
      (action.payload = action.payload)
    );
    yield put(setCheckliveStream(resp.data));
    yield call(action.payload.cb, (action.res = resp));
  } catch (e) {
    yield put(setCheckliveStream({}));
  }
}
function* checkCurrentEvent(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = ApiPath.AgoraLiveStreaming.CHECKCURRENT_LIVEEVENT),
      (action.payload = action.payload)
    );

    yield put(setCheckCurrentEvent(resp.data));
    yield call(action.payload.cb, (action.res = resp));
  } catch (e) {
    yield put(setCheckCurrentEvent({}));
  }
}
function* notificationsList(action) {
  let url = `${ApiPath.PushNotifications.NOTIFICATION_LIST}`;
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = url),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setNotificationList(resp.data));
      yield call(action.payload.cb, (action.res = resp));
      //toast.success(action.res.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setNotificationList({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}
function* clerAllNotificationsList(action) {
  try {
    const dataToSend = { ...action.payload };
    delete dataToSend.cb;
    const resp = yield call(
      ApiClient.delete,
      (action.url = ApiPath.PushNotifications.CLEAR_ALL_NOTIFICATIONS),
      (action.params = { data: dataToSend })
    );
    if (resp.status) {
      yield call(action.payload.cb, (action.res = resp));
      toast.success(action.res.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setClearAllNotificationList({}));
    toast.error(e.response.data.message);
  }
}
function* isNotify(action) {
  try {
    const resp = yield call(
      ApiClient.patch,
      (action.url = ApiPath.PushNotifications.IS_NOTIFY),
      (action.payload = action.payload)
    );
    yield put(setIsNotify(resp.data.payload));
    yield call(action.payload.cb, (action.res = resp));
  } catch (e) {
    yield put(setIsNotify({}));
    toast.error(e.response.data.msg);
  }
}
function* isRead(action) {
  try {
    const resp = yield call(
      ApiClient.patch,
      (action.url = `${ApiPath.PushNotifications.NOTIFICATION_READ}${action.payload.id}`)
    );
    action.payload = action.payload;

    yield put(setIsRead(resp.data.payload));
    yield call(action.payload.cb, (action.res = resp));
  } catch (e) {
    yield put(setIsRead({}));
    toast.error(e.response.data.msg);
  }
}
function* totalCountNotification(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = ApiPath.PushNotifications.TOTAL_NOTIFICATIONCOUNT)
    );
    if (resp.status) {
      yield put(setTotalCountNotification(resp.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setTotalCountNotification({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.msg);
  }
}
function* userSaga() {
  yield all([
    takeLatest("user/getAllUser", getAlluser),
    takeLatest("user/dashboardUser", dashboardUser),
    takeLatest("user/loginUserProfile", loginUserProfile),
    takeLatest("user/getUserById", getUserById),
    takeLatest("user/deleteUserByadmin", deleteUserByadmin),
    takeLatest("user/userStatus", userStatus),
    takeLatest("user/uploadPicture", uploadPicture),
    takeLatest("user/updateProfile", updateProfile),
    takeLatest("user/removePicture", removePicture),
    takeLatest("user/createLike", createLike),
    takeLatest("user/createClassLike", createClassLike),
    takeLatest("user/addRating", addRating),
    takeLatest("user/getAllreviews", getAllreviews),
    takeLatest("user/getAllreviewClass", getAllreviewClass),
    takeLatest("user/replyComment", replyComment),
    takeLatest("user/favRecipe", favRecipe),
    takeLatest("user/favClass", favClass),
    takeLatest("user/reportComment", reportComment),
    takeLatest("user/suggestion", suggestion),
    takeLatest("user/favRecipeList", favRecipeList),
    takeLatest("user/createSubscription", createSubscription),
    takeLatest("user/cancelSubscription", cancelSubscription),
    takeLatest("user/updateSubscription", updateSubscription),
    takeLatest("user/getCards", getCards),
    takeLatest("user/addCards", addCards),
    takeLatest("user/deleteCards", deleteCards),
    takeLatest("user/defaultCard", defaultCard),
    takeLatest("user/generateTokenForStreaming", generateTokenForStreaming),
    takeLatest("user/trendingRecipes", trendingRecipes),
    takeLatest("user/favClassList", favClassList),
    takeLatest("user/addBooking", addBooking),
    takeLatest("user/cancelBooking", cancelBooking),
    takeLatest("user/getBooking", getBooking),
    takeLatest("user/showLiveComments", showLiveComments),
    takeLatest("user/changePassword", changePassword),
    takeLatest("user/contactUs", contactUs),
    takeLatest("user/postOneSignalSubscriptionId", postOneSignalSubscriptionId),
    takeLatest("user/joinliveStream", joinliveStream),
    takeLatest("user/checkliveStream", checkliveStream),
    takeLatest("user/checkCurrentEvent", checkCurrentEvent),
    takeLatest("user/notificationsList", notificationsList),
    takeLatest("user/clerAllNotificationsList", clerAllNotificationsList),
    takeLatest("user/isNotify", isNotify),
    takeLatest("user/isRead", isRead),
    takeLatest("user/totalCountNotification", totalCountNotification),
    takeLatest("user/deleteComment", deleteComment),
  ]);
}
export default userSaga;
