import { Navigate, Outlet, useLocation } from "react-router-dom";

const PrivateCheck = ({ auth }) => {
  const location = useLocation();

  const pathName = location.pathname;

  const authRoutes = ["/resources"];
  const authToken = localStorage.getItem("authToken") ? true : false;

  //add this authentication after implimenting login
  return (
    <>
      {authToken ? (
        <Outlet />
      ) : !authToken ? (
        <Outlet />
      ) : authRoutes.includes(pathName) ? (
        <Outlet />
      ) : authToken ? (
        <Navigate to="/home" />
      ) : (
        <Navigate to="/" />
      )}
    </>
  );
};

export default PrivateCheck;
