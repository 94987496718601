import React, { useEffect, useState } from "react";
import * as Images from "../../../utilities/images";
import { Link, useNavigate } from "react-router-dom";
import { userOtp } from "../../../redux/slices/auth";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";

const Signup = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [passwordShown, setPasswordShown] = useState(false);
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      type: 1,
      password: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .matches(/^[a-zA-Z]+$/, "First Name should only contain letters")
        .min(3, "First Name should be at least 3 characters")
        .required("First Name is required"),
      lastName: Yup.string()
        .matches(/^[a-zA-Z]+$/, "Last Name should only contain letters")
        .min(3, "Last Name should be at least 3 characters")
        .required("Last Name is required"),
      email: Yup.string()
        .email("Please enter valid email address")
        .required("Email is required"),
      password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .matches(
          /^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,
          "Password must contain at least 1 number, 1 uppercase letter, and 1 special character"
        )
        .required("Password is required"),
    }),
    onSubmit: (values) => {
      const userDataObj = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: values.password,
      };
      localStorage.setItem("userData", JSON.stringify(userDataObj));
      let params = {
        email: values.email,
        type: values.type,
      };
      dispatch(
        userOtp({
          ...params,
          cb(res) {
            if (res?.data?.status_code == 400) {
              setErrorMessage({ error: res.data.message });
              return false;
            }
            if (res?.data?.status_code == 200) {
              setErrorMessage("");
              navigate("/verification");
            }
            else {
            }
          },
        })
      );
      // console.log("Form submitted with values:", values);
    },
  });

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Signup";
  }, []);
  return (
    <>
      <section className="loginpage">
        <div className="container-fluid">
          <div className="row item-center flex-row">
            <div className=" col-lg-5 col-md-6 SignupContent">
              <div className="logininner">
                <div className="header-logo">
                  <Link to={"/"}>
                    <img src={Images.logo} alt="" />
                  </Link>
                </div>
                <div className="loginForm">
                  <h1 className="heading py-2">Create Account</h1>
                  <p className="text pb-0">Enter your details below.</p>
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-4 form-group">
                          <div className="position-relative">
                            <input
                              type="text"
                              className="form-control"
                              name="firstName"
                              placeholder="First Name"
                              maxLength={25}
                              {...formik.getFieldProps("firstName")}
                            />

                            <img
                              className="icon_"
                              src={Images.profile}
                              alt=""
                            />
                          </div>
                          {formik.touched.firstName &&
                            formik.errors.firstName ? (
                            <div className="validationMsg">{formik.errors.firstName}</div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="mb-4 form-group">
                          <div className="position-relative">
                            <input
                              type="text"
                              className="form-control"
                              name="lastName"
                              placeholder="Last Name"
                              maxLength={25}
                              {...formik.getFieldProps("lastName")}
                            />
                            <img
                              className="icon_"
                              src={Images.profile}
                              alt=""
                            />
                          </div>
                          {formik.touched.lastName &&
                            formik.errors.lastName ? (
                            <div className="validationMsg">{formik.errors.lastName}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="mb-4 form-group">
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control"
                          name="email"
                          placeholder="Email"
                          {...formik.getFieldProps("email")}
                        />
                        <img className="icon_" src={Images.mail} alt="" />
                      </div>
                      {formik.touched.email && formik.errors.email ? (
                        <div className="validationMsg">{formik.errors.email}</div>
                      ) : null}
                    </div>
                    <div className="mb-3 form-group">
                      <div className="position-relative">
                        <input
                          className="form-control id_password"
                          placeholder="Password"
                          name="password"
                          type={passwordShown ? "text" : "password"}
                          {...formik.getFieldProps("password")}
                        />
                        <img className="icon_" src={Images.lock} alt="" />
                        <span
                          className="toggle_password_ info_icon"
                          onClick={() => {
                            togglePasswordVisiblity(!passwordShown);
                          }}
                        >
                          <span
                            className={
                              passwordShown
                                ? "show-icon togglePassword"
                                : "hide-icon togglePassword"
                            }
                            id=""
                          ></span>
                        </span>
                      </div>
                      {formik.touched.password && formik.errors.password ? (
                        <div className="validationMsg">{formik.errors.password}</div>
                      ) : null}
                    </div>
                    {errorMessage ? (
                      <span
                        className={
                          errorMessage?.error ? "validationMsg" : "d-none"
                        }
                      >
                        {errorMessage?.error ? errorMessage?.error : ""}
                      </span>
                    ) : (
                      ""
                    )}

                    <div className="dflex justify-between py-2 loginBtnOuter">
                      <div className="">
                        <button className="login-btn signUpBtn" type="submit">
                          Sign Up
                        </button>
                        <span className="center-img">
                          <img src={Images.centerrectangle} alt="" onClick={formik.handleSubmit} />
                        </span>
                        <span className="circle-btn">
                          <img src={Images.arrow} alt="" onClick={formik.handleSubmit} />
                        </span>
                      </div>
                      <div className="accountText">
                        <p className="text mb-0 ">Already have an account?</p>
                        <Link className="text colorPink fontBold" to="/login">
                          Log In
                          <img
                            className="signup"
                            src={Images.signuparrow}
                            alt=""
                          />
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
                <p className="subtext copyrightText pb-2">
                  © copyright{moment().year()}. All rights reserved
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-7 pr-0">
              <div className="imgouter">
                <img className="img-fluid w-100" src={Images.banner} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Signup;
