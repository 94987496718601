import React, { useState, useEffect } from "react";
import * as Images from "../../../../utilities/images";
import { getUserById, userStatus } from "../../../../redux/slices/user";
import { useDispatch } from "react-redux";
import moment from "moment-timezone";

const UserDetailPage = (props) => {
  const [getOneUser, setGetOneUser] = useState("");
  const dispatch = useDispatch();

  const getOneUserDetail = () => {
    let params = {
      id: props?.userId,
    };
    dispatch(
      getUserById({
        ...params,
        cb(res) {
          if (res?.data) {
            setGetOneUser(res?.data?.data);
          } else {
          }
        },
      })
    );
  };
  const handleChangeStatus = (Status) => {
    let params = {
      status: Status,
      id: props?.userId,
    };
    dispatch(
      userStatus({
        ...params,
        cb(res) {
          if (res?.data) {
            getOneUserDetail();
            props.refreshData();
          } else {
          }
        },
      })
    );
  };
  useEffect(() => {
    getOneUserDetail();
  }, []);

  return (
    <>
      <div className="text-center">
        <div className="profileCircle mb-4">
          <span className="heading colorWhite">
            {getOneUser?.first_name
              ? getOneUser?.first_name.charAt(0) +
                getOneUser?.last_name.charAt(0)
              : getOneUser?.profile_image}
          </span>
        </div>
        <h1 className="medText">{getOneUser?.full_name}</h1>
        <p className="text">{getOneUser?.email}</p>
        <div className="profileStatus">
          <span className="text">
            Status :{" "}
            {/* <div className=""> */}
              {getOneUser?.status === 1 ? (
                <div className="activeStatus">Active</div>
              ) : (
                <div className="in activeStatus">Inactive</div>
              )}
            {/* </div> */}
          </span>
        </div>
      </div>
      <div className="userStatus">
        <div className="dashCard">
          <div className="dashCardImg">
            <img src={Images.ids} alt="user icon" />
          </div>
          <div className="dashCardHeading">
            <h2 className="text">ID</h2>
            <p className="cardata mb-0">{getOneUser?.id}</p>
          </div>
        </div>
        <div className="dashCard">
          <div className="dashCardImg DcardImg">
            <img src={Images.identify} alt="user icon" />
          </div>
          <div className="dashCardHeading">
            <h2 className="text">Role</h2>
            <p className="cardata mb-0">{getOneUser?.role == 1 ? "Admin" : "User"}</p>
          </div>
        </div>
      </div>
      <div
        className="modal-footer d-flex justify-content-between"
        style={{ borderTop: "1px solid #F5F5F5" }}
      >
        <div className="dashCardHeading">
          <h2>Created at</h2>
          <p className="cardata mb-0">{moment(getOneUser?.createdAt).format("YYYY-DD-MM")}</p>
        </div>

        <div className="d-grid gap-1 col-lg-4 col-md-6">
          <button
            className="primaryBtn cancelBtn m-0 inactiveBtn"
            type="button"
            onClick={() => handleChangeStatus(getOneUser.status == 1 ? 2 : 1)}
          >
            {getOneUser?.status == 1 ? (
              <span className="">Inactive</span>
            ) : getOneUser?.status == 2 ? (
              <span>Active</span>
            ) : (
              ""
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default UserDetailPage;
