import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getTermsConditions } from "../../../redux/slices/admin";
import { toast } from "react-toastify";

const Termsconditions = () => {
  const dispatch = useDispatch();
  const [termsConditions, setTermsConditions] = useState(null);

  const TermsConditionsData = () => {
    let params = {
      page_type: 1,
    };
    dispatch(
      getTermsConditions({
        ...params,
        cb(res) {
          if (res?.data) {
            setTermsConditions(res?.data?.data?.description);
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };
  useEffect(() => {
    TermsConditionsData();
    window.scrollTo(0, 0);
    document.title = "Terms & Conditions";
  }, []);

  return (
    <>
      <section className="recipeBanner favoritesBanner termsBanner py-0">
        <div className="container">
          <div className="row pt-5">
            <div className="col-md-12 text-center pt-5">
              <h2 className="bigHeading pt-5">Terms & Conditions</h2>
            </div>
          </div>
        </div>
      </section>
      <div className="container pb-5">
        <div className="containerInner">
          <p className="text">
            <div dangerouslySetInnerHTML={{ __html: termsConditions }} />
          </p>

        </div>
      </div>
    </>
  );
};

export default Termsconditions;
