import React from "react";
import { clerAllNotificationsList } from "../../../redux/slices/user";
import { useDispatch } from "react-redux";

const ConfirmModalNotification = (props) => {
  // console.log(props,'props');
  const dispatch = useDispatch();

  const handleClearNotification = () => {
    dispatch(
      clerAllNotificationsList({
        cb(res) {
          if (res?.data) {
            //toast.success("Notifications cleared!");
            props.closeModal();
            props.refreshData();
          } else {
          }
        },
      })
    );
  }

  return (
    <div className="py-3">
      <h5 className="font-28 text-center">
        Are you Sure you want to clear all notifications?
      </h5>

      <div className="row justify-content-center mt-4">
        <div className="d-grid gap-1 col-lg-3 col-md-6">
          <button
            className="primaryBtn submitBtn m-0"
            type="button"
            onClick={(e) => handleClearNotification(e)}
          >
            <span>Yes, Clear</span>
          </button>
        </div>
        <div className="d-grid gap-1 col-lg-3 col-md-6">
          <button
            className="primaryBtn cancelBtn m-0"
            onClick={(e) => props.closeModal()}
          >
            <span>No, Cancel</span>
          </button>
        </div>
      </div>
    </div>
  );
};
export default ConfirmModalNotification;
