import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { deleteClass } from "../../../../redux/slices/admin";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";

const Deleteclass = (props) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDeleteUser = (e) => {
    e.preventDefault();

    let params = {
      id: props?.userId,
    };
    setLoading(true);
    dispatch(
      deleteClass({
        ...params,
        cb(res) {
          setLoading(false);
          if (res?.data) {
            if (props?.typeId) {
              navigate("/admin/classes");
            } else {
              props.refreshData();
            }

            toast.success("Class deleted succsessfully");
            props.onCloseModal();
          }
        },
      })
    );
  };

  return (
    <div className="py-3">
      <h5 className="font-28 text-center">
        Are you Sure you want to delete the Class "{props?.Classtitle}"?
      </h5>
      <p className="text text-center py-3">
        You are about to delete the class. You won't be able to undo this.
      </p>
      <div className="row justify-content-center mt-4">
        <div className="d-grid gap-1 col-lg-3 col-md-6">
          <button
            className="primaryBtn submitBtn m-0"
            type="button"
            onClick={(e) => handleDeleteUser(e)}
          >
            <span>
              Yes, Delete{" "}
              {loading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="ms-1"
                />
              )}{" "}
            </span>
          </button>
        </div>
        <div className="d-grid gap-1 col-lg-3 col-md-6">
          <button
            className="primaryBtn cancelBtn m-0"
            type="button"
            onClick={() => props.onCloseModal()}
          >
            <span>No, Cancel</span>
          </button>
        </div>
      </div>
    </div>
  );
};
export default Deleteclass;
